import React, { RefObject } from 'react';
import { InputLabel, FormControl, FormHelperText } from '@material-ui/core';
import { useIntl } from 'react-intl';
import messages, { ErrorKeys } from '../../messages/errors';
import { ConstraintWithContext } from '../../types/form.types';

type PrimitiveType = string | number | boolean | Date | null | undefined;

interface Props {
  children: (props: {
    Label: React.FC<LabelProps>;
    Error: React.FC<ErrorProps>;
    HelperText: React.FC<HelperProps>;
  }) => any;
  className?: string;
}

interface LabelProps {
  labelRef: RefObject<HTMLLabelElement>;
  label: string;
  error: boolean;
}

interface ErrorProps {
  errors?: ConstraintWithContext;
}

interface HelperProps {
  text: string;
}

const Label: React.FC<LabelProps> = ({ labelRef, label, error }) => {
  return (
    <InputLabel error={error} color="secondary" ref={labelRef}>
      {label}
    </InputLabel>
  );
};

export const Error: React.FC<ErrorProps> = ({ errors }) => {
  const { formatMessage } = useIntl();
  const translationKeys: ErrorKeys[] | null = errors
    ? (Object.keys(errors).filter(k => k !== 'context') as Array<ErrorKeys>)
    : null;

  return (
    <>
      {translationKeys?.map(key => (
        <FormHelperText error key={key}>
          {key &&
            formatMessage(
              messages[key],
              errors?.context ? (errors.context[key] as Record<string, PrimitiveType>) : undefined,
            )}
        </FormHelperText>
      ))}
    </>
  );
};

const HelperText: React.FC<HelperProps> = ({ text }) => {
  return <FormHelperText>{text}</FormHelperText>;
};

const Field: React.FC<Props> = ({ children, className }) => {
  return (
    <FormControl variant="outlined" fullWidth classes={{ root: className }}>
      {children({
        Label,
        Error,
        HelperText,
      })}
    </FormControl>
  );
};

export default Field;
