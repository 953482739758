import React, { FC, CSSProperties } from 'react';
import NumericInput from 'react-numeric-input';
import { useTheme, Theme } from '@material-ui/core';

const createStyle = (theme: Theme, isSelected: boolean): Record<string, CSSProperties> => {
  const color = isSelected ? theme.palette.text.primary : theme.palette.text.secondary;
  const borderColor = isSelected ? theme.palette.secondary.main : theme.palette.grey[900];
  const borderWidth = isSelected ? '2px' : '1px';
  return {
    input: {
      fontFamily: theme.typography.fontFamily,
      color,
      width: '100%',
      border: 'none',
      borderBottom: `solid ${borderWidth} ${borderColor}`,
      paddingBottom: '8px',
      WebkitAppearance: 'none',
      textAlign: 'center',
      lineHeight: 'normal',
    },
    'input:focus': {
      outline: 'none',
    },
    btn: {
      cursor: 'pointer',
      border: 'none',
      background: 'none',
      boxShadow: 'none',
    },
    'btn:hover': {
      background: 'none',
    },
    'btn:active': {
      background: 'none',
      boxShadow: 'none',
    },
    'btn:disabled': {
      opacity: 0.5,
    },
    arrowUp: {
      borderWidth: '0 0.4ex 0.4ex 0.4ex',
    },
    arrowDown: {
      borderWidth: '0.4ex 0.4ex 0 0.4ex',
    },
  };
};

interface NumberStepInputProps {
  onChange: (input: number) => void;
  value: string | number | null;
  min?: number;
  max?: number;
  disabled?: boolean;
}

const NumberStepInput: FC<NumberStepInputProps> = props => {
  const { value, min, max, onChange, disabled } = props;

  const nonNullableVal = value || 0;

  const theme = useTheme();
  const style = createStyle(theme, nonNullableVal > 0);

  const minValue = min != null ? min : 0;
  const maxValue = max != null ? max : 999;
  return (
    <NumericInput
      disabled={disabled}
      className="form-control"
      style={style}
      min={minValue}
      max={maxValue}
      value={nonNullableVal}
      onChange={input => onChange(input || 0)}
      mobile={false}
      strict
    />
  );
};

export default NumberStepInput;
