export function formatNumber(
  number?: number | null,
  decimals = 2,
  options: Intl.NumberFormatOptions = {},
): string | null {
  return number != null
    ? Intl.NumberFormat('nl-BE', {
        minimumFractionDigits: number % 1 !== 0 ? decimals : 0,
        maximumFractionDigits: decimals,
        ...options,
      }).format(number)
    : null;
}

export function formatPrice(number?: number | null): string | null {
  return formatNumber(number, 2, { style: 'currency', currency: 'EUR', minimumFractionDigits: 2 });
}
