import React, { FC, useState } from 'react';
import { useParams } from 'react-router';
import { useIntl } from 'react-intl';
import { Grid, Box, Container } from '@material-ui/core';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { useQuery } from 'react-apollo';
import { PageHeader, Loadable, Button } from '../../components';
import { orderMessages, commonMessages, quoteMessages } from '../../messages';
import {
  General,
  Kits,
  Modules,
  Boxes,
  DeliveryDiscount,
  Price,
  ShippingAddress,
} from '../../components/quotes/sections';
import { GET_ORDER } from '../../graphql/queries/order';
import {
  GetOrderQuery,
  OrderItem,
  QuoteItem,
  Module,
  Order,
} from '../../types/graphql-generated.types';
import history from '../../lib/history';
import { usePageView } from '../../hooks';
import {
  Kit,
  deserializeOrderItems,
  getCreatedBy,
  OrderKit,
  getFileName,
  getModulesFromKit,
  generateDataURLs,
} from '../../helpers/quote';
import { useUser } from '../../providers/UserProvider';
import { formatDate } from '../../helpers/date';
import { QuoteOrderPDF } from '../../components/quotes/pdf';
import { usePDF } from '../../components/quotes/hooks';

const OrderDetail: FC = () => {
  usePageView('order_detail');

  const intl = useIntl();
  const { formatMessage } = intl;
  const { companyId, isDistributor, isCustomer } = useUser();
  const { id } = useParams();

  const quoteId = parseInt(id!, 10);

  const [downloading, onDownload] = usePDF();

  const [kits, setKits] = useState<OrderKit[]>([]);
  const [modules, setModules] = useState<OrderItem[]>([]);
  const [boxes, setBoxes] = useState<OrderItem[]>([]);

  const { data, loading } = useQuery<GetOrderQuery>(GET_ORDER, {
    variables: {
      id: quoteId,
      ...(isDistributor && { companyId }),
      ...(isCustomer && { customerId: companyId }),
    },
    onCompleted(result) {
      if (!result.getOrder) {
        history.push('/orders');
      } else {
        const orderItems = deserializeOrderItems(result.getOrder);

        setKits(orderItems.kits);
        setModules(orderItems.modules);
        setBoxes(orderItems.boxes);
      }
    },
  });

  const isReorder = !!data?.getOrder?.quote?.reorderLocation;

  const getTitle = (order: Order, includeReference = true): string => {
    const title = isReorder
      ? formatMessage(quoteMessages.reorderFor, { location: order.quote?.reorderLocation })
      : formatMessage(orderMessages.order);

    return includeReference ? `${title} - ${order.reference}` : title;
  };

  const onPrintPDF = async () => {
    if (data?.getOrder) {
      const order = data.getOrder;

      const svgDataURLs: Record<number, string> = await generateDataURLs([
        ...modules.map(m => m.product as Module),
        ...(kits as Kit[]).reduce((acc, x) => acc.concat(getModulesFromKit(x)), [] as Module[]),
      ]);

      onDownload(
        getFileName(order),
        <QuoteOrderPDF
          intl={intl}
          isReorder={isReorder}
          title={getTitle(order, false)}
          customer={{ ...order.customer, addresses: [order.shippingAddress] }}
          distributor={order.customer?.distributor}
          createdBy={getCreatedBy(order)}
          createdAt={formatDate(order.createdAt)}
          reference={order.reference || ''}
          grandTotal={order.totalPrice || 0}
          totalPrice={order.subTotalPrice || 0}
          discount={order.discount || 0}
          discountedTotal={(order.subTotalPrice || 0) - (order.discountPrice || 0)}
          deliveryCost={order.deliveryCost || 0}
          kits={kits as Kit[]}
          modules={modules as QuoteItem[]}
          boxes={boxes as QuoteItem[]}
          svgDataURLs={svgDataURLs}
        />,
      );
    }
  };

  return (
    <Loadable loading={loading}>
      {data?.getOrder && (
        <>
          <PageHeader title={getTitle(data.getOrder)} back="/orders">
            <Button
              onClick={onPrintPDF}
              className="button-outlined-icon"
              text={formatMessage(commonMessages.printAsPDF)}
              icon={<PictureAsPdfIcon />}
              loading={downloading}
            />
          </PageHeader>
          <Box className="add-edit-form quote-detail">
            <Grid container spacing={3}>
              <General
                customer={data.getOrder.customer?.name || ''}
                createdBy={getCreatedBy(data.getOrder)}
                createdAt={formatDate(data.getOrder.createdAt)}
              />
              <ShippingAddress disabled fields={data?.getOrder} onChange={() => {}} errors={{}} />
              {!isReorder && <Kits disable kits={kits as Kit[]} />}
              <Grid item xs={12} lg={6}>
                <Grid container spacing={3}>
                  <Modules quoteItems={modules as QuoteItem[]} disable />
                  {!isReorder && (
                    <>
                      <Boxes quoteItems={boxes as QuoteItem[]} disable />
                      <DeliveryDiscount
                        fields={{
                          deliveryCost: data.getOrder.deliveryCost,
                          discount: data.getOrder.discount,
                        }}
                        disable
                      />
                    </>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Grid container spacing={3}>
                  <Price
                    totalPrice={data.getOrder.subTotalPrice!}
                    grandTotal={data.getOrder.totalPrice!}
                    discountedTotal={data.getOrder.subTotalPrice! - data.getOrder.discountPrice!}
                    discount={data.getOrder.discount!}
                    deliveryCost={data.getOrder.deliveryCost!}
                    kits={kits as Kit[]}
                    modules={modules as QuoteItem[]}
                    boxes={boxes as QuoteItem[]}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </>
      )}

      {!isCustomer && (
        <Container className="form-actions">
          <Grid className="form-actions-wrapper" container>
            <Button
              className="button-outlined"
              text={formatMessage(commonMessages.cancel)}
              onClick={() => history.push('/orders')}
            />
          </Grid>
        </Container>
      )}
    </Loadable>
  );
};

export default OrderDetail;
