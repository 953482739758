import qs from 'query-string';
import { useUser, Role, Pattern } from '../providers/UserProvider';
import history from '../lib/history';

export interface ImpersonatorQuery {
  ['company-name']?: string | null;
  customer?: number | null;
  distributor?: number | null;
}

export const IMPERSONATOR_KEYS = ['company-name', 'customer', 'distributor'];

export default function useImpersonator() {
  const { isAdmin, isDistributor, isCustomer, companyId, whenRole, role } = useUser();
  const query: ImpersonatorQuery = qs.parse(history.location.search, {
    parseNumbers: true,
  });

  const fakeCompanyName = query['company-name'];
  const fakeCustomerId = query.customer;
  const fakeDistributorId = query.distributor;

  const isImpersonating =
    (isAdmin || isDistributor) && (fakeCustomerId || fakeDistributorId) && fakeCompanyName;
  const isImpersonatingDistributor = !!(isImpersonating && isAdmin && fakeDistributorId);
  const isImpersonatingCustomer = !!(
    isImpersonating &&
    (isAdmin || isDistributor) &&
    fakeCustomerId
  );

  const getFakeVariables = (): Record<string, number | null | undefined> => {
    if (isAdmin && (fakeCustomerId || fakeDistributorId)) {
      return {
        customerId: fakeCustomerId,
        companyId: fakeDistributorId,
      };
    }

    if (isDistributor && fakeCustomerId) {
      return {
        customerId: fakeCustomerId,
      };
    }

    return { [isDistributor ? 'companyId' : 'customerId']: companyId };
  };

  const isRole = (r: Role): boolean => {
    switch (r) {
      case Role.Admin:
        return isAdmin && !isImpersonating;
      case Role.Distributor:
        return (
          (isDistributor && !isImpersonatingCustomer) || (isAdmin && isImpersonatingDistributor)
        );
      case Role.Customer:
        return isCustomer || ((isAdmin || isDistributor) && isImpersonatingCustomer);
      default:
        return false;
    }
  };

  let currentRole = role;

  if (isImpersonating) {
    if (isRole(Role.Admin)) {
      currentRole = Role.Admin;
    } else if (isRole(Role.Distributor)) {
      currentRole = Role.Distributor;
    } else {
      currentRole = Role.Customer;
    }
  }

  function whenFakeRole<T>(pattern: Pattern<T>): T {
    if (isImpersonating) {
      const result = pattern[currentRole];
      return result != null ? result : pattern[Role.Default];
    }

    return whenRole(pattern);
  }

  return {
    fakeCompanyName,
    getFakeVariables,
    isImpersonating,
    isRole,
    whenFakeRole,
  };
}
