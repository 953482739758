import React, { FC } from 'react';
import ReactPDF, { Font, Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { IntlShape } from 'react-intl';
import i18nCountries from 'i18n-iso-countries';
import {
  Kit,
  calculateTotalOfKit,
  sortModuleQuoteItemsFn,
  sortBoxQuoteItemsFn,
  sortKitsFn,
} from '../../../helpers/quote';
import {
  QuoteItem,
  Box,
  Module,
  Customer,
  Distributor,
} from '../../../types/graphql-generated.types';
import { formatPrice, formatNumber } from '../../../helpers/format';
import { quoteMessages, productMessages, commonMessages, labelMessages } from '../../../messages';

import { Regular, SemiBold, Bold, ArabicRegular } from '../../../fonts';

Font.register({
  family: 'Open Sans',
  fonts: [
    { src: Regular, fontWeight: 400 },
    { src: SemiBold, fontWeight: 600 },
    { src: Bold, fontWeight: 700 },
  ],
});

Font.register({
  family: 'Markazi',
  fonts: [{ src: ArabicRegular, fontWeight: 400 }],
});

const styles = StyleSheet.create({
  page: {
    fontSize: 10,
    fontWeight: 400,
    fontFamily: 'Open Sans',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: 40,
  },
  section: {
    margin: 5,
  },
  regularFont: {
    fontFamily: 'Open Sans',
  },
  arabicFont: {
    fontFamily: 'Markazi',
    fontSize: 12,
  },
  sectionContent: {
    flexDirection: 'column',
  },
  sectionRow: {
    flexDirection: 'row',
    paddingTop: 5,
    paddingBottom: 5,
  },
  headerContainer: {
    marginBottom: 0,
  },
  headerTitleContainer: {
    maxWidth: '70%',
  },
  header: {
    fontWeight: 600,
    fontSize: 18,
  },
  subheader: {
    fontSize: 14,
    marginBottom: 8,
    fontWeight: 600,
  },
  row: {
    flexDirection: 'row',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  infoColumn: {
    width: '33%',
  },
  flexEnd: {
    justifyContent: 'flex-end',
  },
  bold: {
    fontWeight: 600,
  },
  underline: {
    textDecoration: 'underline',
  },
  larger: {
    fontSize: 12,
  },
  inset: {
    paddingLeft: 10,
  },
  moduleContainer: {
    width: '100%',
    flexWrap: 'wrap',
  },
  moduleColumn: {
    width: '50%',
  },
  kit: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    borderRadius: 5,
    borderColor: '#000',
    borderStyle: 'solid',
    borderWidth: 1,
  },
  kitMargin: {
    marginBottom: 10,
  },
  icon: {
    height: 20,
    width: 20,
  },
  noMarginBottom: {
    marginBottom: 0,
  },
  item: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  marginRight: {
    marginRight: 5,
  },
  line: {
    marginTop: 5,
    marginBottom: 5,
    borderColor: '#000',
    borderStyle: 'solid',
    borderWidth: 0.5,
  },
  priceItem: {
    width: '20%',
    textAlign: 'right',
  },
  moduleId: {
    width: 15,
    textAlign: 'left',
    fontWeight: 600,
  },
  moduleSubId: {
    width: 25,
    textAlign: 'left',
    fontWeight: 500,
  },
});

const isArabic = (input?: string | null): boolean => {
  if (!input) {
    return false;
  }

  const arabic = /[\u0600-\u06FF]/;
  return arabic.test(input);
};

const getFontStyle = (input?: string | null): ReactPDF.Style =>
  isArabic(input) ? styles.arabicFont : styles.regularFont;

interface QuoteOrderPDFProps {
  intl: IntlShape;
  title: string;
  createdBy: string;
  createdAt: string;
  grandTotal: number;
  totalPrice: number;
  discountedTotal: number;
  discount: number;
  deliveryCost: number;
  reference: string;
  svgDataURLs: Record<number, string>;
  kits: Kit[];
  modules: QuoteItem[];
  boxes: QuoteItem[];
  customer?: Customer | null;
  distributor?: Distributor | null;
  isReorder: boolean;
}

const QuoteOrderPDF: FC<QuoteOrderPDFProps> = ({
  intl,
  title,
  createdAt,
  createdBy,
  customer,
  distributor,
  reference,
  grandTotal,
  totalPrice,
  discount,
  discountedTotal,
  deliveryCost,
  kits,
  modules,
  boxes,
  svgDataURLs,
  isReorder,
}) => {
  const getFullAddress = (c?: Customer | null): string => {
    const address = c?.addresses?.[0];

    if (address) {
      return `${address.addressLineOne} ${address.addressLineTwo || ''}, ${address.postalCode} ${
        address.city
      }, ${address.country ? i18nCountries.getName(address.country, intl.locale) : '-'}`;
    }

    return '-';
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={[styles.section, styles.headerContainer]}>
          <View style={[styles.sectionRow, styles.spaceBetween, { alignContent: 'center' }]}>
            <View style={[styles.sectionContent, styles.headerTitleContainer]}>
              <Text style={styles.header}>{title}</Text>
            </View>
            <View style={styles.sectionContent}>
              <View style={styles.sectionRow}>
                <Text style={[styles.bold, styles.marginRight]}>{`${intl.formatMessage(
                  quoteMessages.reference,
                )}:`}</Text>
                <Text>{reference}</Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.line} />
        <View style={styles.section}>
          <View style={[styles.sectionRow, styles.spaceBetween]}>
            <View style={[styles.infoColumn, styles.sectionContent]}>
              <Text style={styles.bold}>{intl.formatMessage(labelMessages.distributor)}</Text>
              <Text style={getFontStyle(distributor?.name)}>{distributor?.name || '-'}</Text>
            </View>
            <View style={[styles.infoColumn, styles.sectionContent]}>
              <Text style={styles.bold}>{intl.formatMessage(labelMessages.vat)}</Text>
              <Text>{distributor?.vat || '-'}</Text>
            </View>
            <View style={[styles.infoColumn, styles.sectionContent]}>
              <Text style={styles.bold}>{intl.formatMessage(quoteMessages.createdAt)}</Text>
              <Text>{createdAt}</Text>
            </View>
          </View>
          <View style={[styles.sectionRow, styles.spaceBetween]}>
            <View style={[styles.infoColumn, styles.sectionContent]}>
              <Text style={styles.bold}>{intl.formatMessage(commonMessages.customer)}</Text>
              <Text style={getFontStyle(customer?.name)}>{customer?.name || '-'}</Text>
            </View>
            <View style={[styles.infoColumn, styles.sectionContent]}>
              <Text style={styles.bold}>{intl.formatMessage(labelMessages.vat)}</Text>
              <Text>{customer?.vat || '-'}</Text>
            </View>
            <View style={[styles.infoColumn, styles.sectionContent]}>
              <Text style={styles.bold}>{intl.formatMessage(quoteMessages.createdBy)}</Text>
              <Text style={getFontStyle(createdBy)}>{createdBy}</Text>
            </View>
          </View>
          <View style={[styles.sectionRow]}>
            <View style={[styles.sectionContent]}>
              <Text style={styles.bold}>{intl.formatMessage(quoteMessages.deliveryAddress)}</Text>
              <Text style={getFontStyle(getFullAddress(customer))}>{getFullAddress(customer)}</Text>
            </View>
          </View>
        </View>
        {!isReorder ? (
          <View style={styles.section}>
            <Text style={styles.subheader}>{intl.formatMessage(quoteMessages.kits)}</Text>
            {[...kits].sort(sortKitsFn).map(([id, kit]) => (
              <View
                wrap={false}
                style={[
                  styles.sectionContent,
                  styles.kit,
                  kits.length > 1 ? styles.kitMargin : styles.noMarginBottom,
                ]}
                key={id}
              >
                <View style={[styles.sectionRow, styles.spaceBetween]}>
                  <View style={styles.row}>
                    <Text style={[styles.marginRight, styles.bold]}>{`${intl.formatMessage(
                      quoteMessages.location,
                    )}:`}</Text>
                    <Text style={getFontStyle(kit.location)}>{kit.location}</Text>
                  </View>
                  <View style={[styles.row]}>
                    <Text style={[styles.bold, styles.marginRight]}>{`${intl.formatMessage(
                      quoteMessages.total,
                    )}:`}</Text>
                    <Text>{formatPrice(calculateTotalOfKit(kit))}</Text>
                  </View>
                </View>
                <View style={[styles.sectionRow]}>
                  <View style={styles.row}>
                    <Text style={[styles.marginRight, styles.bold]}>{`${intl.formatMessage(
                      productMessages.box,
                    )}:`}</Text>
                    <Text style={getFontStyle((kit.product as Box).name)}>
                      {(kit.product as Box).name}
                    </Text>
                  </View>
                </View>
                <View style={[styles.row, styles.moduleContainer]}>
                  {[...(kit.children || [])].sort(sortModuleQuoteItemsFn).map(item => (
                    <View key={item.id!} style={[styles.sectionRow, styles.moduleColumn]}>
                      <View style={[styles.row, { alignItems: 'center' }]}>
                        <Text style={styles.moduleId}>{(item.product as Module).moduleId}</Text>
                        <Text style={styles.moduleSubId}>
                          {(item.product as Module)?.moduleSubId
                            ? (item.product as Module)?.moduleSubId
                            : ''}
                        </Text>
                        <Image
                          style={[styles.marginRight, styles.icon]}
                          src={svgDataURLs[(item.product as Module).id!]}
                        />
                        <Text style={styles.marginRight}>{(item.product as Module).nameEN}</Text>
                        <Text>{`x ${item.quantity}`}</Text>
                      </View>
                    </View>
                  ))}
                </View>
              </View>
            ))}
          </View>
        ) : (
          <View />
        )}
        {modules.length ? (
          <View style={styles.section}>
            <Text style={styles.subheader}>{intl.formatMessage(productMessages.modules)}</Text>
            {[...modules].sort(sortModuleQuoteItemsFn).map(item => (
              <View wrap={false} style={[styles.sectionContent, styles.item]} key={item.id!}>
                <View style={[styles.sectionRow, styles.spaceBetween]}>
                  <View style={[styles.row, { alignItems: 'center' }]}>
                    <Text style={styles.moduleId}>{(item.product as Module).moduleId}</Text>
                    <Text style={styles.moduleSubId}>
                      {(item.product as Module)?.moduleSubId
                        ? (item.product as Module)?.moduleSubId
                        : ''}
                    </Text>
                    <Image
                      style={[styles.marginRight, styles.icon]}
                      src={svgDataURLs[(item.product as Module).id!]}
                    />
                    <Text style={styles.marginRight}>{(item.product as Module).nameEN}</Text>
                    <Text>{`x ${item.quantity}`}</Text>
                  </View>
                  <Text>{formatPrice(item.totalPrice)}</Text>
                </View>
              </View>
            ))}
          </View>
        ) : (
          <View />
        )}
        {!isReorder && boxes.length ? (
          <View style={styles.section}>
            <Text style={styles.subheader}>{intl.formatMessage(productMessages.boxes)}</Text>
            {[...boxes].sort(sortBoxQuoteItemsFn).map(item => (
              <View wrap={false} style={[styles.sectionContent, styles.item]} key={item.id!}>
                <View style={[styles.sectionRow, styles.spaceBetween]}>
                  <View style={styles.row}>
                    <Text style={styles.marginRight}>{(item.product as Box).name}</Text>
                    <Text>{`x ${item.quantity}`}</Text>
                  </View>
                  <Text>{formatPrice(item.totalPrice)}</Text>
                </View>
              </View>
            ))}
          </View>
        ) : (
          <View />
        )}
        <View style={[styles.section, styles.line]} />
        <View style={styles.section}>
          <View wrap={false} style={[styles.sectionContent, styles.item]}>
            <View style={[styles.sectionRow, styles.flexEnd]}>
              <Text style={styles.bold}>{`${intl.formatMessage(quoteMessages.total)}:`}</Text>
              <Text style={styles.priceItem}>{formatPrice(totalPrice)}</Text>
            </View>
          </View>
          {discount ? (
            <>
              <View wrap={false} style={[styles.sectionContent, styles.item]}>
                <View style={[styles.sectionRow, styles.flexEnd]}>
                  <Text style={styles.bold}>{`${intl.formatMessage(
                    quoteMessages.discount,
                  )}:`}</Text>
                  <Text style={styles.priceItem}>{`${formatNumber(discount)} %`}</Text>
                </View>
              </View>
              <View wrap={false} style={[styles.sectionContent, styles.item]}>
                <View style={[styles.sectionRow, styles.flexEnd]}>
                  <Text style={styles.bold}>{`${intl.formatMessage(
                    quoteMessages.discountedTotal,
                  )}:`}</Text>
                  <Text style={styles.priceItem}>{formatPrice(discountedTotal)}</Text>
                </View>
              </View>
            </>
          ) : (
            <View />
          )}
          <View wrap={false} style={[styles.sectionContent, styles.item]}>
            <View style={[styles.sectionRow, styles.flexEnd]}>
              <Text style={styles.bold}>{`${intl.formatMessage(
                quoteMessages.deliveryCost,
              )}:`}</Text>
              <Text style={styles.priceItem}>{formatPrice(deliveryCost)}</Text>
            </View>
          </View>
        </View>
        <View style={[styles.section, styles.line]} />
        <View style={styles.section}>
          <View wrap={false} style={[styles.sectionContent, styles.item]}>
            <View style={[styles.sectionRow, styles.flexEnd, styles.larger]}>
              <Text style={styles.bold}>{`${intl.formatMessage(
                quoteMessages.grandTotal,
              )} (${intl.formatMessage(quoteMessages.vatExcluded)}):`}</Text>
              <Text style={styles.priceItem}>{formatPrice(grandTotal)}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default QuoteOrderPDF;
