import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useIntl } from 'react-intl';
import { Box, GridSize } from '@material-ui/core';
import { PageHeader, Pagination, Loadable, OverviewSearch } from '../../components';
import { GET_PAGED_QUOTES, createSearch } from '../../graphql/queries/quote';
import { useOptions, usePageView, useImpersonator } from '../../hooks';
import { setSortableQuery } from '../../helpers/query';
import { quoteMessages, commonMessages, productMessages, labelMessages } from '../../messages';
import {
  GetPagedQuotesQuery,
  SortDirection,
  GetPagedQuotesQueryVariables,
} from '../../types/graphql-generated.types';
import { useUser, Role } from '../../providers/UserProvider';
import { TableHeader, TableCell, TableRow, EmptyRow } from '../../components/table';
import { getNextDirection } from '../../helpers/sort';
import { formatDate } from '../../helpers/date';
import { formatPrice } from '../../helpers/format';
import { QuoteStatus } from '../../components/quotes';
import history from '../../lib/history';

const Quotes: React.FC = () => {
  usePageView('quotes_overview');

  const { formatMessage } = useIntl();
  const { isImpersonating, getFakeVariables, fakeCompanyName, isRole } = useImpersonator();
  const { getContextVariables } = useUser();

  const isAdmin = isRole(Role.Admin);
  const isDistributor = isRole(Role.Distributor);

  const { options, changeParams, resetPagination } = useOptions({
    page: 0,
    offset: 0,
    pageCount: 1,
    limit: 20,
    sortColumn: 'quote.createdAt',
    sortDirection: SortDirection.Desc,
  });

  const { pageCount, page, ...rest } = options;

  const [search, setSearch] = useState<string | undefined>();

  const { loading, data } = useQuery<GetPagedQuotesQuery, GetPagedQuotesQueryVariables>(
    GET_PAGED_QUOTES,
    {
      variables: {
        ...(isImpersonating ? getFakeVariables() : getContextVariables()),
        ...rest,
        ...(search && { filter: createSearch(isRole, search) }),
      },
      onCompleted(result) {
        if (result?.getPagedQuotes?.pageInfo?.pageCount) {
          changeParams('pageCount', result.getPagedQuotes.pageInfo.pageCount);
        }
      },
    },
  );

  const onSearch = (value: string | undefined) => {
    resetPagination();
    setSearch(value);
  };

  const onChangeSort = (key: string) => {
    setSortableQuery(key, getNextDirection(key, options.sortColumn, options.sortDirection));
  };

  const isEmpty = !data?.getPagedQuotes?.items?.length;

  let createdAtWidth: GridSize;

  if (isAdmin) {
    createdAtWidth = 3;
  } else if (isDistributor) {
    createdAtWidth = 3;
  } else {
    createdAtWidth = 6;
  }

  return (
    <div>
      <PageHeader
        title={`${formatMessage(quoteMessages.quotes)}${
          isImpersonating ? ` (${fakeCompanyName})` : ''
        }`}
      >
        <OverviewSearch onSearch={onSearch} onClear={() => onSearch(undefined)} />
      </PageHeader>
      <Box>
        <TableHeader
          sortOptions={{
            column: options.sortColumn,
            direction: options.sortDirection,
            onChange: onChangeSort,
          }}
        >
          {({ Sortable }) => (
            <>
              {isAdmin && (
                <TableCell xs={3}>
                  <Sortable name="distributor.name">
                    {formatMessage(labelMessages.distributor)}
                  </Sortable>
                </TableCell>
              )}
              {(isAdmin || isDistributor) && (
                <TableCell xs={isAdmin ? 2 : 3}>
                  <Sortable name="customer.name">{formatMessage(commonMessages.customer)}</Sortable>
                </TableCell>
              )}
              <TableCell xs={createdAtWidth}>
                <Sortable name="quote.createdAt">{formatMessage(quoteMessages.createdAt)}</Sortable>
              </TableCell>
              <TableCell xs={2}>
                <Sortable name="quote.totalPrice">{formatMessage(productMessages.price)}</Sortable>
              </TableCell>
              <TableCell xs={2}>
                <Sortable name="quote.status">{formatMessage(productMessages.status)}</Sortable>
              </TableCell>
            </>
          )}
        </TableHeader>
        <Loadable loading={loading}>
          {isEmpty ? (
            <EmptyRow />
          ) : (
            data?.getPagedQuotes?.items?.map(quote => (
              <TableRow key={quote.id!} onClick={() => history.push(`/quotes/${quote.id!}`)}>
                {isAdmin && <TableCell body xs={3} label={quote.customer?.distributor?.name} />}
                {(isAdmin || isDistributor) && (
                  <TableCell body primary xs={isAdmin ? 2 : 3} label={quote.customer?.name} />
                )}
                <TableCell
                  body
                  xs={createdAtWidth}
                  label={quote.createdAt ? formatDate(quote.createdAt) : '-'}
                />
                <TableCell xs={2} body label={formatPrice(quote.totalPrice)} />
                <TableCell xs={2} body>
                  {quote.status && <QuoteStatus status={quote.status} />}
                </TableCell>
              </TableRow>
            ))
          )}

          <Pagination options={options} />
        </Loadable>
      </Box>
    </div>
  );
};

export default Quotes;
