import React from 'react';
import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import { useMutation } from 'react-apollo';
import { useUser } from '../../providers/UserProvider';
import PageHeader from '../../components/PageHeader';
import FormActions from '../../components/FormActions';
import { usePageView, useForm, useImpersonator } from '../../hooks';
import messages from '../../messages/users';
import { CREATE_USER } from '../../graphql/queries/user';
import { UserInputCreate } from '../../types/graphql-generated.types';
import { General } from '../../components/users/section';
import { userFields } from '../../constants/form-constants';

const CreateUser = () => {
  usePageView('new_user');

  const { formatMessage } = useIntl();
  const useUserVars = useUser();
  const { companies } = useUserVars;
  let { companyId } = useUserVars;

  const { isImpersonating, getFakeVariables, fakeCompanyName } = useImpersonator();

  const fakeVariables = getFakeVariables();
  companyId = fakeVariables.companyId || fakeVariables.customerId;

  const companyName = isImpersonating
    ? fakeCompanyName
    : companies?.find(c => c?.id === companyId)?.name;

  const [createUser, { loading: isSaving }] = useMutation(CREATE_USER);
  const { fields, handleChange, errors, handleCreateUpdate, disabled } = useForm<UserInputCreate>(
    createUser,
    userFields,
    true,
  );

  return (
    <>
      <PageHeader title={`${formatMessage(messages.newUser)} - ${companyName}`} back="/users" />

      <div className="add-edit-form">
        <Grid container spacing={3}>
          <General fields={fields} onChange={handleChange} errors={errors} />
        </Grid>
      </div>

      <FormActions
        onCreateUpdate={() => handleCreateUpdate('/users', { companyId })}
        create
        disabled={disabled}
        loading={isSaving}
        createLabel={formatMessage(messages.createLabel)}
      />
    </>
  );
};

export default CreateUser;
