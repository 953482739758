import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import { useIntl } from 'react-intl';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { commonMessages, labelMessages } from '../../../messages';
import { OutlinedNumberField } from '../../inputs';
import { CompanySectionProps, CostsSection } from '../../../types/company.types';
import { Constraint } from '../../../types/form.types';
import { DEFAULT_PRICE_MASK } from '../../../constants/number-mask';

const decimalMask = createNumberMask({ ...DEFAULT_PRICE_MASK });

const Costs: FC<CompanySectionProps<CostsSection>> = ({ onChange, fields, errors, setField }) => {
  const { formatMessage } = useIntl();

  const handleFocus = () => {
    if (fields.defaultDeliveryCost === 0) {
      setField('defaultDeliveryCost', null);
    }
  };

  return (
    <Grid item xs={12}>
      <div className="form-section">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className="form-section-title">
              <div className="section-icon">
                <EuroSymbolIcon />
              </div>
              <p>{formatMessage(commonMessages.costs)}</p>
            </div>
          </Grid>
          <Grid item xs={6}>
            <OutlinedNumberField
              name="defaultDeliveryCost"
              labelText={formatMessage(labelMessages.defaultDeliveryCost)}
              value={fields.defaultDeliveryCost}
              startAdornment="€"
              onChange={onChange}
              mask={decimalMask}
              onFocus={handleFocus}
              error={errors?.defaultDeliveryCost as Constraint}
              defaultTo={0}
              maxLength={100}
            />
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

export default Costs;
