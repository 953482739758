import { gql } from 'apollo-boost';

export const GET_LABELS_FOR_INPUT = gql`
  query getLabelsForInput($customerId: Int!) {
    getLabels(customerId: $customerId) {
      id
      name
    }
  }
`;

export const GET_LABELS_FOR_FILTER = gql`
  query getLabelsForFilter($customerId: Int, $companyId: Int) {
    getLabels(customerId: $customerId, companyId: $companyId) {
      id
      name
    }
  }
`;
