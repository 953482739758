import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      margin: theme.spacing(2),
    },
    paddingV: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    paddingH: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    gutter: {
      marginBottom: theme.spacing(2),
    },
    description: {
      fontSize: '16px',
      lineHeight: 1.35,
      color: theme.palette.text.hint,
      marginBottom: theme.spacing(2),
    },
    actions: {
      background: 'none',
    },
    icon: {
      width: '56px',
    },
    name: {
      maxWidth: 'calc(100% - 56px)',
    },
  }),
);

export default useStyles;
