import React, { FC } from 'react';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import { useIntl } from 'react-intl';
import messages from '../messages/products';

interface ProductStatusProps {
  active: boolean;
}

const ProductStatus: FC<ProductStatusProps> = ({ active }) => {
  const { formatMessage } = useIntl();

  return (
    <span className="product-status">
      {active ? (
        <>
          <span className="status-icon-glow active">
            <CheckCircleOutlineIcon fontSize="small" />
          </span>
          {formatMessage(messages.statusActive)}
        </>
      ) : (
        <>
          <span className="status-icon-glow inactive">
            <NotInterestedIcon fontSize="small" />
          </span>
          {formatMessage(messages.statusInactive)}
        </>
      )}
    </span>
  );
};

export default ProductStatus;
