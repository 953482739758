import qs from 'query-string';
import history from '../lib/history';

/**
 * Helper to compose a new query uri
 * loops through the parsed query object and adds every param to the string
 * @param query any
 * @param page string
 */
export const createQueryUri = (query: any, page: number): string => {
  let queryParams = '?';
  Object.keys(query).forEach(param => {
    const index = Object.keys(query).indexOf(param);

    // if index of the object key > 0, set & prefix
    // if param is page, add 1 because the index of the pagination component starts from 0
    queryParams += `${index === 0 ? '' : '&'}${param}=${
      param === 'page' ? page + 1 : query[param]
    }`;
  });

  return queryParams;
};

export const setPaginationQuery = (page: number): void => {
  const query = { ...qs.parse(history.location.search), page: page + 1 };

  history.push({
    search: `?${qs.stringify(query)}`,
  });
};

export const setSortableQuery = (column: string, sort: string): void => {
  const query = { ...qs.parse(history.location.search), column, sort, page: 1 };

  history.replace({
    search: `?${qs.stringify(query)}`,
  });
};

export const takeKeys = (search: string, keys: string[]): string => {
  const query = qs.parse(search);

  const limited = Object.keys(query).reduce((acc, k) => {
    if (keys.includes(k) && query[k] != null) {
      return { ...acc, [k]: query[k] };
    }

    return acc;
  }, {});

  return qs.stringify(limited);
};
