import React, { FC } from 'react';
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { InventoryItem, Module } from '../../../types/graphql-generated.types';
import { commonMessages, inventoryMessages } from '../../../messages';
import Button from '../../Button';
import ModuleHeader from '../ModuleHeader';
import { useDialogStyles } from '../hooks';
import { formatDate } from '../../../helpers/date';

const useStyles = makeStyles(theme =>
  createStyles({
    text: {
      color: theme.palette.text.secondary,
      fontSize: '16px',
      textTransform: 'capitalize',
    },
  }),
);

interface Props {
  item: InventoryItem;
  onClose: () => void;
  onUse: (item: InventoryItem) => void;
}

const UseItemDialog: FC<Props> = props => {
  const { item, onClose, onUse } = props;
  const localClasses = useStyles(props);
  const classes = useDialogStyles(props);
  const { formatMessage } = useIntl();

  if (!item.batch) {
    return null;
  }

  const module = item.product as Module;

  return (
    <Dialog fullWidth open onClose={onClose} classes={{ paper: classes.root }}>
      <DialogTitle className={classNames(classes.paddingH, classes.paddingV)}>
        <ModuleHeader module={module} />
      </DialogTitle>
      <DialogContent className={classes.paddingH}>
        <Grid container justify="space-between">
          <Grid item>
            <Typography className={localClasses.text}>
              {formatMessage(inventoryMessages.expires)}:
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={localClasses.text}>
              {formatDate(item.batch.expiryDate)}
            </Typography>
          </Grid>
        </Grid>
        <Grid container justify="space-between">
          <Grid item>
            <Typography className={localClasses.text}>
              {formatMessage(inventoryMessages.batch)}:
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={localClasses.text}>{item.batch.lotNumber}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classNames(classes.paddingH, classes.paddingV, classes.actions)}>
        <Button
          className="button-outlined"
          onClick={onClose}
          text={formatMessage(commonMessages.cancel)}
        />
        <Button
          className="button-filled"
          onClick={() => onUse(item)}
          text={formatMessage(inventoryMessages.use)}
        />
      </DialogActions>
    </Dialog>
  );
};

export default UseItemDialog;
