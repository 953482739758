import React from 'react';
import { useParams } from 'react-router';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Grid } from '@material-ui/core';
import set from 'set-value';
import produce from 'immer';
import { GET_DISTRIBUTOR, UPDATE_DISTRIBUTOR } from '../../graphql/queries/distributor';
import PageHeader from '../../components/PageHeader';
import FormActions from '../../components/FormActions';
import { companyFields } from '../../constants/form-constants';
import { General, Address, Contact, Costs } from '../../components/company/section';
import { CompanyLinks } from '../../components/company';
import { useForm, usePageView } from '../../hooks';
import { Loadable } from '../../components';
import history from '../../lib/history';
import { CompanyForm } from '../../types/company.types';
import { countries } from '../../helpers/country';
import { useUser } from '../../providers/UserProvider';

const Detail: React.FC = () => {
  usePageView('distributor_detail');

  const { isAdmin } = useUser();

  const { id } = useParams();
  const parsedId = parseInt(id!, 10);
  const [updateDistributor, { loading: isSaving }] = useMutation(UPDATE_DISTRIBUTOR);
  const {
    fields,
    errors,
    disabled,
    handleChange,
    handleCreateUpdate,
    updateFields,
    updateField,
  } = useForm(updateDistributor, companyFields, false, parsedId);

  const { loading } = useQuery(GET_DISTRIBUTOR, {
    variables: {
      id: parsedId,
    },
    onCompleted(result) {
      if (result.getDistributor) {
        const { id: _, ...noId } = result.getDistributor;
        updateFields(noId);
      } else {
        history.push('/distributors');
      }
    },
  });

  const serializer = (f: CompanyForm) => {
    if (!countries.find(c => f.addresses?.[0].country === c.id)) {
      return produce(f, draft => {
        set(draft, 'addresses.0.country', null);
      });
    }

    return f;
  };

  return (
    <Loadable loading={loading}>
      <PageHeader title={fields.name} back="/distributors" />
      <div className="add-edit-form">
        <Grid container spacing={3}>
          <General
            fields={fields}
            onChange={handleChange}
            errors={errors}
            variant={isAdmin ? 'semi' : 'full'}
          />
          {isAdmin && (
            <Grid item xs={6}>
              <CompanyLinks type="distributor" id={parsedId} name={fields.name} />
            </Grid>
          )}
          <Address fields={fields} onChange={handleChange} errors={errors} />
          <Contact fields={fields} onChange={handleChange} errors={errors} />
          <Costs fields={fields} onChange={handleChange} errors={errors} setField={updateField} />
        </Grid>
      </div>
      <FormActions
        onCreateUpdate={() => handleCreateUpdate('/distributors', {}, serializer)}
        disabled={disabled}
        loading={isSaving}
      />
    </Loadable>
  );
};

export default Detail;
