import React, { FC, useState } from 'react';
import { Typography, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { useMutation } from 'react-apollo';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import {
  InventoryItem,
  Module,
  UpdateInventoryItemMutation,
  InventoryItemStatus,
} from '../../../types/graphql-generated.types';
import { inventoryMessages, commonMessages, labelMessages } from '../../../messages';
import { OutlinedTextField, OutlinedNumberField, CheckboxField } from '../../inputs';
import Button from '../../Button';
import { UPDATE_INVENTORY_ITEM } from '../../../graphql/queries/inventory';
import ModuleHeader from '../ModuleHeader';
import { useDialogStyles } from '../hooks';
import { DEFAULT_INTEGER_MASK } from '../../../constants/number-mask';

const numberMask = createNumberMask({ ...DEFAULT_INTEGER_MASK });

interface Props {
  item: InventoryItem;
  onClose: () => void;
  onSuccess: () => void;
}

const ConfirmUseItemDialog: FC<Props> = props => {
  const { item, onClose, onSuccess } = props;
  const classes = useDialogStyles(props);
  const { formatMessage } = useIntl();

  const [batchId, setBatchId] = useState(item.batch?.lotNumber);
  const [summary, setSummary] = useState('');
  const [notFound, setNotFound] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);

  const [updateInventoryItem, { loading }] = useMutation<UpdateInventoryItemMutation>(
    UPDATE_INVENTORY_ITEM,
  );

  const module = item.product as Module;

  const isReusableModule = module.moduleId && /[a-zA-Z]/.test(module.moduleId);

  const onUpdateBatchId = async () => {
    try {
      await updateInventoryItem({
        variables: {
          id: item.id!,
          inventoryItem: { batchId, status: InventoryItemStatus.Used },
          ...(summary !== '' && { summary }),
          ...(!isEmpty && isReusableModule && { isPartial: true }),
        },
      });
      onSuccess();
    } catch (err) {
      setNotFound(true);
    }
  };

  return (
    <Dialog fullWidth open onClose={onClose} classes={{ paper: classes.root }}>
      <DialogTitle className={classNames(classes.paddingH, classes.paddingV)}>
        <ModuleHeader module={module} />
      </DialogTitle>
      <DialogContent className={classNames(classes.paddingH, classes.gutter)}>
        <OutlinedNumberField
          className={classes.gutter}
          name="batchId"
          labelText="Batch nr"
          value={batchId}
          mask={numberMask}
          updateOnBlur={false}
          error={notFound ? { notFound: '' } : undefined}
          onChange={({ value }) => {
            setNotFound(false);
            setBatchId(value as number);
          }}
        />
        <Typography className={classes.description}>
          {formatMessage(inventoryMessages.confirmUseSummaryDescription)}
        </Typography>
        <OutlinedTextField
          className={classes.gutter}
          name="summary"
          labelText={formatMessage(inventoryMessages.confirmUseSummary)}
          helperText={`(${formatMessage(labelMessages.optional)})`}
          value={summary}
          maxLength={250}
          multiline
          rows={4}
          onChange={({ value }) => setSummary(value as string)}
        />
        {isReusableModule && (
          <CheckboxField
            className={classes.gutter}
            labelText={formatMessage(inventoryMessages.confirmUseIsEmpty)}
            name="isPartial"
            value={isEmpty}
            onChange={({ value }) => setIsEmpty(!!value)}
          />
        )}
      </DialogContent>
      <DialogActions className={classNames(classes.paddingH, classes.paddingV, classes.actions)}>
        <Button
          className="button-outlined"
          onClick={onClose}
          text={formatMessage(commonMessages.cancel)}
        />
        <Button
          className="button-filled"
          onClick={() => onUpdateBatchId()}
          text={formatMessage(commonMessages.save)}
          loading={loading}
          disabled={batchId == null}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmUseItemDialog;
