import { gql } from 'apollo-boost';

export const GET_USER_BY_EXTERNAL_ID = gql`
  query getUserByExternalId {
    getUserByExternalId {
      id
      companies {
        __typename
        ... on Distributor {
          id
          email
          name
        }
        ... on Customer {
          id
          email
          name
        }
      }
    }
  }
`;

export const GET_USER = gql`
  query getUser($id: Int, $companyId: Int) {
    getUser(id: $id, companyId: $companyId) {
      id
      firstName
      lastName
      email
      companies {
        __typename
        ... on Distributor {
          name
        }
        ... on Customer {
          name
        }
      }
    }
  }
`;

export const GET_USERS = gql`
  query getPagedUsers(
    $offset: Int!
    $limit: Int!
    $sortColumn: String
    $sortDirection: SortDirection
    $companyId: Int
  ) {
    getPagedUsers(
      offset: $offset
      limit: $limit
      sortColumn: $sortColumn
      sortDirection: $sortDirection
      companyId: $companyId
    ) {
      items {
        id
        firstName
        lastName
        email
        companies {
          __typename
          ... on Distributor {
            name
          }
          ... on Customer {
            name
          }
        }
      }
      pageInfo {
        itemCount
        pageCount
        total
      }
    }
  }
`;

export const CREATE_USER = gql`
  mutation createUser($companyId: Int!, $type: UserInputCreate!) {
    createUser(user: $type, companyId: $companyId) {
      id
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($id: Int!, $type: UserInputUpdate!) {
    updateUser(id: $id, user: $type) {
      id
      firstName
      lastName
      email
    }
  }
`;
