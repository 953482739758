import React from 'react';
import { Grid } from '@material-ui/core';
import { useMutation } from 'react-apollo';
import { useIntl } from 'react-intl';
import { PageHeader, FormActions } from '../../components';
import { General, Address, Contact, Info } from '../../components/company/section';
import { useForm, usePageView } from '../../hooks';
import { CREATE_CUSTOMER } from '../../graphql/queries/customer';
import { customerFields } from '../../constants/form-constants';
import { CustomerForm } from '../../types/company.types';
import { commonMessages, customerMessages } from '../../messages';
import { useUser } from '../../providers/UserProvider';

const Create = () => {
  const { formatMessage } = useIntl();
  usePageView('customer_new');

  const { isDistributor, companyId } = useUser();

  const [createCustomer, { loading: isSaving }] = useMutation<CustomerForm>(CREATE_CUSTOMER);
  const { fields, errors, disabled, handleChange, handleCreateUpdate } = useForm(
    createCustomer,
    { ...customerFields, ...(isDistributor && { distributor: companyId }) },
    true,
  );

  return (
    <>
      <PageHeader
        title={formatMessage(customerMessages.newCustomer)}
        back="/customers"
        subtitle={`- ${formatMessage(commonMessages.prospect).toLowerCase()}`}
      />
      <div className="add-edit-form">
        <Grid container spacing={3}>
          <General fields={fields} onChange={handleChange} errors={errors} isCustomer />
          <Info fields={fields} onChange={handleChange} errors={errors} />
          <Address fields={fields} onChange={handleChange} errors={errors} />
          <Contact fields={fields} onChange={handleChange} errors={errors} />
        </Grid>
      </div>
      <FormActions
        onCreateUpdate={() => handleCreateUpdate('/customers', { companyId })}
        create
        loading={isSaving}
        disabled={disabled}
      />
    </>
  );
};

export default Create;
