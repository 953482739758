import React, { FC, ChangeEvent } from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import Field from '../form/Field';
import { InputProps } from '../../types/form.types';
import { getUpdate } from '../../helpers/form';

const CheckboxField: FC<InputProps> = ({ onChange = () => {}, ...props }) => {
  return (
    <Field className="checkbox-container">
      {() => (
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              disabled={props.disabled}
              checked={!!props.value}
              onChange={e =>
                onChange(getUpdate(e as ChangeEvent<HTMLInputElement>, { isCheckbox: true }))
              }
              name={props.name}
              value={props.value}
            />
          }
          label={props.labelText}
        />
      )}
    </Field>
  );
};

export default CheckboxField;
