import React, { useContext, createContext, useEffect, useState } from 'react';
import { useLazyQuery } from 'react-apollo';
import { GetUserByExternalIdQuery } from '../types/graphql-generated.types';
import { GET_USER_BY_EXTERNAL_ID } from '../graphql/queries/user';
import { useAuth0 } from './AuthProvider';
import { Loadable } from '../components';

const SESSION_KEY = 'modulaid:current-company';

export enum Role {
  Distributor = 'Distributor',
  Customer = 'Customer',
  Admin = 'Admin',
  Default = 'Default',
}

export interface Pattern<T> {
  [Role.Default]: T;
  [Role.Admin]?: T;
  [Role.Distributor]?: T;
  [Role.Customer]?: T;
}

interface UserContext {
  isDistributor: boolean;
  isCustomer: boolean;
  isAdmin: boolean;
  isAuthenticated: boolean;
  userId: number | undefined;
  companyId: number | null | undefined;
  companies: Exclude<GetUserByExternalIdQuery['getUserByExternalId'], null>['companies'];
  whenRole: <T>(pattern: Pattern<T>) => T;
  getContextVariables: () => Record<string, any>;
  selectCompany: (companyId: number | undefined) => void;
  role: Role;
}

export const UserContext = createContext<UserContext | null>(null);
export const useUser = () => useContext(UserContext)!;
const UserProvider: React.FC = ({ children }) => {
  const { isAuthenticated, loading: authLoading } = useAuth0();
  const [getUser, { loading, data }] = useLazyQuery<GetUserByExternalIdQuery>(
    GET_USER_BY_EXTERNAL_ID,
  );
  const [currentCompanyId, setCurrentCompanyId] = useState<number | null>();

  useEffect(() => {
    if (isAuthenticated && !data) {
      getUser();
    }
  }, [isAuthenticated, data, getUser]);

  const companies = data?.getUserByExternalId?.companies;

  // Set company id if user does not have multiple companies
  useEffect(() => {
    const storedCompanyString = sessionStorage.getItem(SESSION_KEY);
    const storedCompanyId = storedCompanyString != null ? parseInt(storedCompanyString, 10) : null;

    if (companies?.length === 1) {
      setCurrentCompanyId(companies[0]?.id);
    } else if (storedCompanyId != null && companies?.find(c => c?.id === storedCompanyId)) {
      setCurrentCompanyId(storedCompanyId);
    }
  }, [companies, getUser]);

  // TODO: allow choosing of company + more specific admin checks
  const role = data?.getUserByExternalId?.companies?.[0]?.__typename
    ? Role[data?.getUserByExternalId?.companies?.[0]?.__typename]
    : Role.Admin;

  function whenRole<T>(pattern: Pattern<T>): T {
    const result = pattern[role];
    return result == null ? pattern[Role.Default] : result;
  }

  function selectCompany(companyId: number | undefined) {
    if (companyId === undefined) {
      sessionStorage.removeItem(SESSION_KEY);
    } else {
      sessionStorage.setItem(SESSION_KEY, String(companyId));
    }

    setCurrentCompanyId(companyId);
  }

  const value: Omit<UserContext, 'getContextVariables'> = {
    isDistributor: role === Role.Distributor,
    isCustomer: role === Role.Customer,
    isAdmin: role === Role.Admin,
    userId: data?.getUserByExternalId?.id || undefined,
    companyId: currentCompanyId,
    companies: data?.getUserByExternalId?.companies || null,
    selectCompany,
    whenRole,
    role,
    isAuthenticated,
  };

  function getContextVariables(): Record<string, any> {
    if (value.isAdmin) {
      return {};
    }

    return { [value.isCustomer ? 'customerId' : 'companyId']: value.companyId };
  }

  return (
    <Loadable loading={authLoading || (!authLoading && loading)}>
      <UserContext.Provider value={{ ...value, getContextVariables }}>
        {children}
      </UserContext.Provider>
    </Loadable>
  );
};

export default UserProvider;
