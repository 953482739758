import React, { FC, useState } from 'react';
import { useParams } from 'react-router';
import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import shortid from 'shortid';
import { useQuery, useMutation } from 'react-apollo';
import { PageHeader, FormActions, Loadable } from '../../components';
import { quoteMessages } from '../../messages';
import {
  General,
  Kits,
  Modules,
  Boxes,
  DeliveryDiscount,
  Price,
  ShippingAddress,
} from '../../components/quotes/sections';
import { ModulesPanel, BoxesPanel, KitsPanel } from '../../components/quotes/panels';
import { GET_CUSTOMER_FOR_QUOTE } from '../../graphql/queries/customer';
import { CREATE_QUOTE, GET_CONFIRMED_ADDRESSES } from '../../graphql/queries/quote';
import {
  GetCustomerForQuoteQuery,
  QuoteInputCreate,
  QuoteItem,
  GetConfirmedAddressesQueryVariables,
  GetConfirmedAddressesQuery,
  Address,
} from '../../types/graphql-generated.types';
import history from '../../lib/history';
import { useForm, usePageView } from '../../hooks';
import {
  removeKitFromList,
  Kit,
  serializeQuoteItems,
  serializeKits,
  calculateTotalOfQuoteItems,
  calculateDiscount,
  calculateTotalOfKit,
} from '../../helpers/quote';
import { ConfirmDialog } from '../../components/quotes';
import { useUser } from '../../providers/UserProvider';
import { getDisplayAddress } from '../../components/quotes/sections/ShippingAddress';
import { FieldUpdate } from '../../helpers/form';

const calculatePrices = (
  kits: Kit[],
  quoteItems: QuoteItem[],
  discount: number,
  deliveryCost: number,
) => {
  const totalPriceKits = kits.reduce((sum, kit) => sum + calculateTotalOfKit(kit[1]), 0);
  const totalPriceSupplements = calculateTotalOfQuoteItems(quoteItems);
  const totalPrice = totalPriceKits + totalPriceSupplements;
  const discountedTotal = calculateDiscount(totalPrice, discount || 0);
  const grandTotal = discountedTotal + (deliveryCost || 0);

  return { totalPrice, discountedTotal, grandTotal };
};

const CreateQuote: FC = () => {
  usePageView('quote_create');

  const { formatMessage, locale } = useIntl();
  const { id } = useParams();
  const customerId = parseInt(id!, 10);

  const { isDistributor, companyId } = useUser();

  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openSaveDialog, setOpenSaveDialog] = useState(false);

  const [selectedAddress, setSelectedAddress] = useState<Address>();

  const [isKitsPanelOpen, setKitsPanelOpen] = useState(false);
  const [editingKit, setEditingKit] = useState<Kit>();
  const [kits, setKits] = useState<Kit[]>([]);

  const [isModulePanelOpen, setModulePanelOpen] = useState(false);
  const [suppModules, setSuppModules] = useState<QuoteItem[]>([]);

  const [isBoxPanelOpen, setBoxPanelOpen] = useState(false);
  const [suppBoxes, setSuppBoxes] = useState<QuoteItem[]>([]);

  const [createQuote, { loading: isSaving }] = useMutation(CREATE_QUOTE);
  const { fields, handleChange, errors, updateFields, handleCreateUpdate } = useForm<
    QuoteInputCreate
  >(createQuote, { quoteItems: [] }, true);

  const { data, loading } = useQuery<GetCustomerForQuoteQuery>(GET_CUSTOMER_FOR_QUOTE, {
    variables: {
      id: customerId,
      ...(isDistributor && { companyId }),
    },
    onCompleted(result) {
      if (!result.getCustomer) {
        history.push('/customers');
      } else {
        const { distributor, addresses } = result.getCustomer;
        const deliveryCost = distributor?.defaultDeliveryCost;

        setSelectedAddress(addresses![0]!);

        updateFields({
          quoteItems: [],
          deliveryCost: deliveryCost || 0,
          discount: 0,
          shippingAddress: addresses![0]!,
        });
      }
    },
  });

  const { data: addressesData, loading: addressesLoading } = useQuery<
    GetConfirmedAddressesQuery,
    GetConfirmedAddressesQueryVariables
  >(GET_CONFIRMED_ADDRESSES, {
    variables: { customerId },
  });

  const onCancel = () => {
    history.push('/customers');
  };

  const onSave = async () => {
    setOpenSaveDialog(false);

    const quoteItems = [
      ...serializeKits(kits),
      ...serializeQuoteItems(suppModules),
      ...serializeQuoteItems(suppBoxes),
    ];

    const distributorId = data?.getCustomer?.distributor?.id;

    if (distributorId) {
      await handleCreateUpdate(
        '/quotes',
        {
          companyId: distributorId,
          customerId,
        },
        q => ({
          ...q,
          quoteItems,
          shippingAddress: {
            addressLineOne: q.shippingAddress?.addressLineOne,
            addressLineTwo: q.shippingAddress?.addressLineTwo,
            postalCode: q.shippingAddress?.postalCode,
            city: q.shippingAddress?.city,
            country: q.shippingAddress?.country,
          },
        }),
      );
    }
  };

  const onDeleteKit = (kit: Kit) => {
    setKits(removeKitFromList(kits, kit));
  };

  const onCreateKit = (newKit: QuoteItem) => {
    setKits([...kits, [shortid(), newKit]]);
  };

  const onUpdateKit = (kit: Kit) => {
    setKits([...removeKitFromList(kits, kit), kit]);
  };

  const onOpenKit = (kit: Kit) => {
    setEditingKit(kit);
    setKitsPanelOpen(true);
  };

  const onCloseKitPanel = () => {
    setEditingKit(undefined);
    setKitsPanelOpen(false);
  };

  const onCopyKit = (kit: Kit, quantity: number) => {
    const copies = Array<Kit>(quantity)
      .fill(kit)
      .map<Kit>(([, original], idx) => [
        shortid(),
        {
          ...original,
          location: `${original.location} ${idx}`,
        },
      ]);

    setKits([...kits, ...copies]);
  };

  const onSelectAddress = (address: Address) => {
    handleChange({ path: 'shippingAddress.addressLineOne', value: address.addressLineOne });
    handleChange({ path: 'shippingAddress.addressLineTwo', value: address.addressLineTwo });
    handleChange({ path: 'shippingAddress.postalCode', value: address.postalCode });
    handleChange({ path: 'shippingAddress.city', value: address.city });
    handleChange({ path: 'shippingAddress.country', value: address.country });

    setSelectedAddress(address);
  };

  const onUpdateAddress = (update: FieldUpdate) => {
    handleChange(update);
    setSelectedAddress(undefined);
  };

  const { totalPrice, grandTotal, discountedTotal } = calculatePrices(
    kits,
    [...suppModules, ...suppBoxes],
    fields.discount || 0,
    fields.deliveryCost || 0,
  );

  const customerAddress = data?.getCustomer?.addresses?.[0];

  return (
    <>
      <PageHeader title={formatMessage(quoteMessages.newQuote)} back="/customers" />
      <div className="add-edit-form quote-detail">
        <Grid container spacing={3}>
          <Loadable loading={loading}>
            <General customer={data?.getCustomer?.name || ''} />
          </Loadable>
          <Loadable loading={addressesLoading && loading}>
            {addressesData?.getConfirmedAddresses && customerAddress && (
              <ShippingAddress
                addresses={[
                  customerAddress,
                  ...addressesData?.getConfirmedAddresses.filter(
                    a =>
                      getDisplayAddress(a, locale) !== getDisplayAddress(customerAddress, locale),
                  ),
                ]}
                selectedAddress={selectedAddress}
                onSelectAddress={onSelectAddress}
                fields={fields}
                onChange={onUpdateAddress}
                errors={errors}
              />
            )}
          </Loadable>
          <Kits
            kits={kits}
            onOpenKit={onOpenKit}
            onDelete={onDeleteKit}
            onSelect={() => setKitsPanelOpen(true)}
            disableSelect={loading}
            onCopyKit={onCopyKit}
          />
          <Grid item xs={12} lg={6}>
            <Grid container spacing={3}>
              <Modules
                quoteItems={suppModules}
                disableSelect={loading}
                onSelect={() => setModulePanelOpen(true)}
              />
              <Boxes
                quoteItems={suppBoxes}
                disableSelect={loading}
                onSelect={() => setBoxPanelOpen(true)}
              />
              <Loadable loading={loading}>
                <DeliveryDiscount fields={fields} onChange={handleChange} errors={errors} />
              </Loadable>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={3}>
              <Price
                totalPrice={totalPrice}
                grandTotal={grandTotal}
                discountedTotal={discountedTotal}
                discount={fields?.discount || 0}
                deliveryCost={fields?.deliveryCost || 0}
                kits={kits}
                modules={suppModules}
                boxes={suppBoxes}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
      <FormActions
        onCreateUpdate={() => setOpenSaveDialog(true)}
        onCancel={() => setOpenCancelDialog(true)}
        create
        disabled={!kits.length && !suppModules.length && !suppBoxes.length}
        loading={isSaving}
      />

      {data?.getCustomer?.distributor?.id != null && (
        <>
          <KitsPanel
            kit={editingKit}
            companyId={data.getCustomer.distributor.id}
            open={isKitsPanelOpen}
            onClose={onCloseKitPanel}
            onCreate={onCreateKit}
            onUpdate={onUpdateKit}
          />
          <ModulesPanel
            companyId={data.getCustomer.distributor.id}
            quoteItems={suppModules}
            open={isModulePanelOpen}
            onConfirm={val => setSuppModules(val)}
            onClose={() => setModulePanelOpen(false)}
          />
          <BoxesPanel
            companyId={data.getCustomer.distributor.id}
            quoteItems={suppBoxes}
            open={isBoxPanelOpen}
            onConfirm={val => setSuppBoxes(val)}
            onClose={() => setBoxPanelOpen(false)}
          />
        </>
      )}
      <ConfirmDialog
        open={openCancelDialog}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => onCancel()}
        title={formatMessage(quoteMessages.discardTitle)}
        content={formatMessage(quoteMessages.quoteDiscardContent)}
      />

      <ConfirmDialog
        open={openSaveDialog}
        onCancel={() => setOpenSaveDialog(false)}
        onConfirm={() => onSave()}
        title={formatMessage(quoteMessages.quoteCreateTitle)}
        content={formatMessage(quoteMessages.quoteCreateContent)}
      />
    </>
  );
};

export default CreateQuote;
