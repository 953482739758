import { defineMessages } from 'react-intl';

export default defineMessages({
  customer: {
    id: 'customer',
    defaultMessage: 'Customer',
  },
  newCustomer: {
    id: 'new_customer',
    defaultMessage: 'New Customer',
  },
  reminderOnMonday: {
    id: 'reminder_on_monday',
    defaultMessage: 'Reminders are sent first Monday of the period.',
  },
  additionalInfoHelperText: {
    id: 'additional_info_helper_text',
    defaultMessage: 'Info that will be printed on the label for each kit, eg company doctor',
  },
  primaryLabelLanguage: {
    id: 'primary_label_language',
    defaultMessage: 'Label language (primary)',
  },
  secondaryLabelLanguage: {
    id: 'secondary_label_language',
    defaultMessage: 'Label language (secondary)',
  },
});
