import React, { FC, useState, useEffect } from 'react';
import dompurify from 'dompurify';
import classNames from 'classnames';
import { Paper, Dialog, DialogTitle, DialogContent, DialogActions, Grid } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useIntl } from 'react-intl';
import { InputProps } from '../../types/form.types';
import Button from '../Button';
import Field from '../form/Field';
import { ModuleIcon } from '../../types/graphql-generated.types';
import { productMessages, commonMessages } from '../../messages';

type Icon = Pick<ModuleIcon, 'id' | 'svg'>;

type StickerSelectFieldProps = InputProps & {
  icons?: Icon[];
};

const StickerSelectField: FC<StickerSelectFieldProps> = ({
  name,
  value,
  error,
  icons,
  onChange = () => {},
}) => {
  const { formatMessage } = useIntl();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<number | null>(value as number);

  useEffect(() => {
    if (value != null) {
      setSelected(value as number);
    }
  }, [setSelected, value]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setSelected(value as number);
  };
  const handleSelect = () => {
    setOpen(false);

    if (selected != null) {
      onChange({
        path: name,
        value: selected,
      });
    }
  };

  let selectedIcon: Icon | undefined;

  if (selected != null && icons) {
    selectedIcon = icons.find(i => i.id === value);
  }

  const iconClass = (id: number) =>
    classNames({
      'sticker-dialog-icon': true,
      selected: id === selected,
    });

  const inputClass = classNames({
    'sticker-select-container': true,
    error: !!error,
  });

  return (
    <>
      <Field className={inputClass}>
        {({ Error }) => (
          <>
            <Paper onClick={handleOpen} elevation={0} variant="outlined">
              {selectedIcon && (
                <span
                  className="sticker-select-icon"
                  dangerouslySetInnerHTML={{ __html: dompurify.sanitize(selectedIcon.svg || '') }}
                />
              )}
              <span className="sticker-select-label">
                {formatMessage(
                  selectedIcon ? productMessages.stickerSelected : productMessages.stickerEmpty,
                )}
              </span>
              <ArrowForwardIosIcon className="sticker-select-arrow" />
            </Paper>
            {error && <Error errors={error} />}
          </>
        )}
      </Field>
      <Dialog className="dialog sticker-dialog" onClose={handleClose} open={open}>
        <DialogTitle>{formatMessage(productMessages.stickerEmpty)}</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            {icons?.map(icon => (
              <Grid item key={icon.id!} xs={4}>
                <div onClick={() => setSelected(icon.id!)} className={iconClass(icon.id!)}>
                  <div dangerouslySetInnerHTML={{ __html: dompurify.sanitize(icon.svg || '') }} />
                </div>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            className="button-outlined"
            onClick={handleClose}
            text={formatMessage(commonMessages.cancel)}
          />
          <Button
            className="button-filled"
            disabled={selected == null}
            onClick={handleSelect}
            text={formatMessage(commonMessages.select)}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default StickerSelectField;
