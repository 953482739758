import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { Box, makeStyles, createStyles, Grid, Typography } from '@material-ui/core';
import { InventoryItem, Module } from '../../types/graphql-generated.types';
import { ProductIcon } from '../products';
import { inventoryMessages } from '../../messages';
import { formatDate } from '../../helpers/date';
import { getModuleName } from '../../helpers/module';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      height: '56px',
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      ...theme.mixins.card(0.16),
    },
    content: {
      width: '100%',
      boxSizing: 'border-box',
      padding: theme.spacing(1),
    },
    red: {
      color: theme.palette.primary.main,
    },
    icon: {
      width: '48px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    info: {
      maxWidth: 'calc(100% - 48px)',
    },
  }),
);

interface Props {
  item: InventoryItem;
  ordered?: boolean;
}

const InventoryItemCard: FC<Props> = props => {
  const { item, ordered } = props;
  const { formatMessage } = useIntl();
  const classes = useStyles(props);

  const module = item.product as Module;

  const batchNumber = item.batch?.lotNumber || item.batchId;
  const isUnknownBatch = !item.batch?.lotNumber;

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <Grid container spacing={1}>
          <Grid item className={classes.icon}>
            <ProductIcon rgb={module.rgb} svg={module.icon?.svg} />
          </Grid>
          <Grid container alignItems="center" item xs className={classes.info}>
            <Grid container justify="space-between" spacing={1}>
              <Grid item xs={12}>
                <Typography variant="subtitle1" noWrap>{`${module.moduleId} - ${getModuleName(
                  module,
                )}`}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container justify="space-between">
                  {ordered && item.lastReorderQuote?.createdAt && (
                    <Grid item xs={12}>
                      <Typography variant="body2" noWrap>{`${formatMessage(
                        inventoryMessages.ordered,
                      )}: ${formatDate(item.lastReorderQuote?.createdAt)}`}</Typography>
                    </Grid>
                  )}
                  {!ordered && (
                    <>
                      <Grid item xs={5}>
                        {batchNumber && (
                          <Typography
                            variant="body2"
                            className={classNames({
                              [classes.red]: isUnknownBatch,
                            })}
                            noWrap
                          >{`${formatMessage(
                            inventoryMessages.batch,
                          )}: ${batchNumber}`}</Typography>
                        )}
                      </Grid>
                      <Grid item xs={7}>
                        {item.batch && (
                          <Typography variant="body2" align="right" noWrap>{`${formatMessage(
                            inventoryMessages.expires,
                          )}: ${formatDate(item.batch.expiryDate)}`}</Typography>
                        )}
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default InventoryItemCard;
