import { defineMessages } from 'react-intl';

export default defineMessages({
  enterKitId: {
    id: 'enter_kit_id',
    defaultMessage: 'Please enter your kit’s ID',
  },
  kitIdDescription: {
    id: 'kit_id_description',
    defaultMessage: 'Your kit ID can be found on the sticker on the ModulAid box',
  },
  statusAvailable: {
    id: 'status_available',
    defaultMessage: 'Available',
  },
  statusUsed: {
    id: 'status_used_expired',
    defaultMessage: 'Used or Expired',
  },
  actionUsed: {
    id: 'action_used',
    defaultMessage: 'Used',
  },
  actionReordered: {
    id: 'action_reordered',
    defaultMessage: 'Reordered',
  },
  actionRestocked: {
    id: 'action_restocked',
    defaultMessage: 'Restocked',
  },
  actionVerified: {
    id: 'action_verified',
    defaultMessage: 'Inventory verified by',
  },
  statusOrdered: {
    id: 'status_ordered',
    defaultMessage: 'Ordered',
  },
  batch: {
    id: 'inventory_item_batch',
    defaultMessage: 'batch',
  },
  expires: {
    id: 'inventory_item_expires',
    defaultMessage: 'expires',
  },
  ordered: {
    id: 'inventory_item_ordered',
    defaultMessage: 'ordered',
  },
  noBatchDescription: {
    id: 'inventory_item_no_batch_description',
    defaultMessage:
      'Module batch number not found. This number is used to track the expiration date of the module. Please find it on the module’s sticker and enter it below.',
  },
  confirmUseSummaryDescription: {
    id: 'inventory_item_use_summary_description',
    defaultMessage:
      'Why was this module used? Any summary of the event you write here will be kept in the kit’s history.',
  },
  confirmUseSummary: {
    id: 'inventory_item_use_summary',
    defaultMessage: 'Summary',
  },
  confirmVerifySummary: {
    id: 'inventory_verify_name',
    defaultMessage: 'Name',
  },
  confirmUseIsEmpty: {
    id: 'inventory_item_use_is_empty',
    defaultMessage: 'Is this module empty and thus ready to be reordered later on?',
  },
  use: {
    id: 'inventory_item_use',
    defaultMessage: 'Use',
  },
  restockDescription: {
    id: 'inventory_restock_description',
    defaultMessage: 'Please enter the batch number of the new module you are adding to the kit.',
  },
  verifyDescription: {
    id: 'inventory_verify_description',
    defaultMessage: 'Please confirm that you have verified the inventory of this kit.',
  },
  verifyNameDescription: {
    id: 'inventory_verify_description_name',
    defaultMessage: 'Please enter the name of the person that has verified this kit',
  },
  restock: {
    id: 'inventory_restock',
    defaultMessage: 'Restock',
  },
  reorder: {
    id: 'inventory_reorder',
    defaultMessage: 'Reorder',
  },
  verify: {
    id: 'inventory_verify',
    defaultMessage: 'Verify',
  },
  verifyInventory: {
    id: 'inventory_verify_inventory',
    defaultMessage: 'Verify inventory',
  },
  orderConfirmedTitle: {
    id: 'inventory_order_confirmed_title',
    defaultMessage: 'Order confirmed',
  },
  orderConfirmedDescription: {
    id: 'inventory_order_confirmed_description',
    defaultMessage: 'Your order was successfully placed.',
  },
  backToKit: {
    id: 'inventory_back_to_kit',
    defaultMessage: 'Back to kit',
  },
  inventory: {
    id: 'inventory',
    defaultMessage: 'Inventory',
  },
  labels: {
    id: 'kit_labels',
    defaultMessage: 'Labels',
  },
  noLabels: {
    id: 'kit_labels_empty',
    defaultMessage: 'No labels',
  },
  labelsHelperText: {
    id: 'kit_labels_description',
    defaultMessage: 'Group kits together in your inventory via labels',
  },
  contents: {
    id: 'kit_contents',
    defaultMessage: 'Contents',
  },
  history: {
    id: 'kit_history',
    defaultMessage: 'History',
  },
  lastActivity: {
    id: 'kit_last_activity',
    defaultMessage: 'Last activity',
  },
  editLocationTitle: {
    id: 'kit_edit_location_title',
    defaultMessage: 'Edit location',
  },
});
