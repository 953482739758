import React from 'react';
import { Grid, Box, makeStyles, createStyles } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { TableHeader, TableCell, TableRow } from '../../table';
import { ReactComponent as IconProspectOutlined } from '../../../assets/icons/icons-prospect-outlined.svg';
import { ReactComponent as IconProspectFilled } from '../../../assets/icons/icons-prospect-filled.svg';
import { ReactComponent as DeliveryIcon } from '../../../assets/icons/icons-delivery.svg';
import { CompaniesListProps } from '../../../types/user.types';
import { userMessages, labelMessages } from '../../../messages';

const useStyles = makeStyles(theme =>
  createStyles({
    emptyRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.text.secondary,
    },
    tableBox: {
      width: '100%',
    },
    iconCell: {
      display: 'flex',
      alignItems: 'center',
    },
    title: {
      '&:first-letter': {
        textTransform: 'capitalize',
      },
    },
  }),
);

const CompanyTable: React.FC<CompaniesListProps> = props => {
  const { user } = props;
  const { formatMessage } = useIntl();
  const classes = useStyles(props);

  if (!user?.companies || !user?.companies.length) {
    return (
      <Grid container>
        <Box className={classes.tableBox}>
          <TableRow>
            <TableCell
              classes={{ root: classes.emptyRow }}
              body
              xs={12}
              label={formatMessage(userMessages.noCompanies)}
            />
          </TableRow>
        </Box>
      </Grid>
    );
  }
  return (
    <>
      {user?.companies?.map(company => {
        return (
          <Grid container>
            <Box className={classes.tableBox}>
              <TableRow>
                <TableCell xs={2} className={classes.iconCell}>
                  {company?.__typename === 'Distributor' ? (
                    <DeliveryIcon />
                  ) : (
                    <IconProspectFilled />
                  )}
                </TableCell>
                <TableCell primary body xs={10} label={company?.name} />
              </TableRow>
            </Box>
          </Grid>
        );
      })}
    </>
  );
};

const Companies: React.FC<CompaniesListProps> = props => {
  const { user } = props;
  const { formatMessage } = useIntl();
  const classes = useStyles(props);

  return (
    <Grid item xs={12}>
      <div className="form-section">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className="form-section-title">
              <div className="section-icon">
                <IconProspectOutlined />
              </div>
              <p className={classes.title}>{formatMessage(userMessages.companies)}</p>
            </div>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Box className={classes.tableBox}>
                <TableHeader>
                  {() => (
                    <>
                      <TableCell xs={2} label={formatMessage(labelMessages.type)} />
                      <TableCell xs={10} label={formatMessage(labelMessages.name)} />
                    </>
                  )}
                </TableHeader>
              </Box>
            </Grid>

            <CompanyTable user={user} />
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};
export default Companies;
