import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Tabs, Tab, Box, makeStyles, createStyles, Grid, Container } from '@material-ui/core';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import classNames from 'classnames';
import ExtensionIcon from '@material-ui/icons/Extension';
import {
  GetInventoryForKitQuery,
  Box as BoxType,
  InventoryItem,
  InventoryItemUpdate,
} from '../../types/graphql-generated.types';
import { inventoryMessages } from '../../messages';
import { Info, InventorySection, Header } from '../../components/inventory/large';
import History from './History';
import { useAuth0 } from '../../providers/AuthProvider';
import BackToOverview from '../../components/BackToOverview';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      paddingTop: '150px',
    },
  }),
);

interface InventoryOverviewProps {
  inventory: Exclude<GetInventoryForKitQuery['getInventoryForKit'], null>;
  available: InventoryItem[];
  usedOrExpired: InventoryItem[];
  ordered: InventoryItem[];
  tab: 'contents' | 'history';
  page: number;

  setUpdateBatchItem: (item: InventoryItem) => void;
  setUseItem: (item: InventoryItem) => void;
  setConfirmUseItem: (item: InventoryItem) => void;
  setRestockItem: (item: InventoryItem) => void;
  setVerifyInventory: (item: boolean) => void;
  setTab: (tab: 'contents' | 'history') => void;
  setPage: (page: number) => void;

  onReorder: () => void;
  onUpdateKit: (update: InventoryItemUpdate) => Promise<boolean>;

  isSavingLabels: boolean;
}

const InventoryOverview: FC<InventoryOverviewProps> = props => {
  const { isAuthenticated } = useAuth0();
  const {
    tab,
    setTab,
    page,
    setPage,
    inventory,
    available,
    usedOrExpired,
    ordered,
    setUpdateBatchItem,
    setUseItem,
    setRestockItem,
    setVerifyInventory,
    onReorder,
    onUpdateKit,
    isSavingLabels,
  } = props;
  const classes = useStyles(props);

  const { formatMessage } = useIntl();

  const onClickItem = (item: InventoryItem) => {
    if (item.batch) {
      setUseItem(item);
    } else {
      setUpdateBatchItem(item);
    }
  };

  return (
    <>
      <Header />
      <Container classes={{ root: classNames('container', classes.container) }}>
        {isAuthenticated && <BackToOverview back="/inventory" />}
        <Box className="add-edit-form">
          <Grid container spacing={3}>
            <Info
              customerId={inventory.customer?.id}
              labels={inventory.labels}
              location={inventory.location}
              reference={inventory.uid}
              box={(inventory.product as BoxType).name}
              onUpdateKit={onUpdateKit}
              isSavingLabels={isSavingLabels}
            />
            {isAuthenticated && (
              <Grid item xs={12}>
                <Tabs
                  value={tab}
                  onChange={(_, value) => setTab(value)}
                  indicatorColor="primary"
                  textColor="secondary"
                >
                  <Tab value="contents" label={formatMessage(inventoryMessages.contents)} />
                  <Tab value="history" label={formatMessage(inventoryMessages.history)} />
                </Tabs>
              </Grid>
            )}
            {tab === 'contents' ? (
              <>
                <InventorySection
                  items={available}
                  icon={<ExtensionIcon />}
                  type="available"
                  title={formatMessage(inventoryMessages.statusAvailable)}
                  onClick={onClickItem}
                  onVerify={() => setVerifyInventory(true)}
                />
                {!!usedOrExpired.length && (
                  <InventorySection
                    items={usedOrExpired}
                    type="used"
                    icon={<NotInterestedIcon />}
                    title={formatMessage(inventoryMessages.statusUsed)}
                    onClick={item => setRestockItem(item)}
                    onReorder={() => onReorder()}
                  />
                )}
                {!!ordered.length && (
                  <InventorySection
                    items={ordered}
                    type="reordered"
                    icon={<LocalShippingIcon />}
                    title={formatMessage(inventoryMessages.statusOrdered)}
                    onClick={item => setRestockItem(item)}
                  />
                )}
              </>
            ) : (
              <Grid item xs={12}>
                <Box className="form-section">
                  <History
                    page={page}
                    setPage={setPage}
                    id={inventory.id!}
                    customerId={inventory.customer?.id}
                    large
                  />
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default InventoryOverview;
