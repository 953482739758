import { useState, useCallback } from 'react';
import { useImmer } from 'use-immer';
import set from 'set-value';
import get from 'get-value';
import { Errors, GraphQLError } from '../types/form.types';
import formatErrors from '../helpers/error-format';
import { FieldUpdate } from '../helpers/form';
import { errorMessages } from '../messages';
import history from '../lib/history';

/**
 * Custom hook to handle the Create and update form
 * @param mutation mutation that is used to create/update
 */
const useForm = <T>(mutation: any, initialFields: T, create: boolean, id?: number) => {
  const [errors, setErrors] = useImmer<Errors>({});
  const [disabled, setDisabled] = useState(true);
  const [fields, setFields] = useImmer<T>(initialFields);

  const handleChange = ({ path, value, errorPath }: FieldUpdate): void => {
    setFields(draft => {
      set(draft as object, path, value as any);
    });

    setErrors(draft => {
      set(draft, errorPath || path, undefined);
    });

    setDisabled(false);
  };

  const handleCreateUpdate = async (
    path?: string,
    options: Record<string, any> = {},
    serializer: (fields: T) => T = f => f,
  ): Promise<boolean> => {
    const variables = {
      ...(!create && { id }),
      ...options,
      type: serializer(fields),
    };

    try {
      await mutation({ variables });

      if (path) {
        history.push(path);
      }

      return true;
    } catch (error) {
      const code: string = get(error, 'graphQLErrors.0.extensions.code');
      const graphQLErrors: GraphQLError[] = get(error, 'graphQLErrors.0.extensions.invalidArgs');

      setErrors(() => {
        const errorMapping = graphQLErrors ? formatErrors(graphQLErrors) : {};

        if (Object.keys(errorMessages).includes(code)) {
          errorMapping.additionalErrors = {
            [code]: code,
            context: undefined,
          };
        }

        return errorMapping;
      });

      return false;
    }
  };

  const updateFields = useCallback(
    (updatedFields: T) => {
      setFields(() => updatedFields);
    },
    [setFields],
  );

  const updateField = useCallback(
    (path, value) => {
      setFields(draft => set(draft as object, path, value as any));
    },
    [setFields],
  );

  return {
    fields,
    errors,
    disabled,
    handleChange,
    handleCreateUpdate,
    updateFields,
    updateField,
  };
};

export default useForm;
