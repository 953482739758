import React from 'react';
import ReactPaginate from 'react-paginate';
import classNames from 'classnames';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { setPaginationQuery } from '../helpers/query';
import { PaginatedOptions } from '../types/overview.types';

interface Pagination {
  selected: number;
}

interface Props {
  options: Pick<PaginatedOptions, 'page' | 'pageCount'>;
  className?: string;
  small?: boolean;
  onPageSelect?: (selected: number) => void;
}

const Pagination: React.FC<Props> = ({ options, className, onPageSelect, small }) => {
  if (options.pageCount == null || options.pageCount <= 1) {
    return null;
  }

  const onChange = (data: Pagination) => {
    if (onPageSelect) {
      onPageSelect(data.selected);
    } else {
      setPaginationQuery(data.selected);
    }
  };

  return (
    <div className={classNames(className, 'pagination-wrapper')}>
      <ReactPaginate
        forcePage={options.page ?? 0} // force page with state, so it doesn't reset on rerender
        pageCount={options.pageCount}
        onPageChange={onChange}
        pageRangeDisplayed={1}
        marginPagesDisplayed={small ? 1 : 2}
        previousLabel={<ArrowBackIosIcon />}
        nextLabel={<ArrowForwardIosIcon />}
        containerClassName="pagination-container"
        activeClassName="active"
        disableInitialCallback={false}
      />
    </div>
  );
};

export default Pagination;
