import React, { FC } from 'react';
import dompurify from 'dompurify';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

interface ProductIconProps {
  className?: string;
  rgb?: string | null;
  svg?: string | null;
}

export const PRODUCT_ICON_SIDE = 36;

const useStyles = makeStyles({
  root: {
    height: `${PRODUCT_ICON_SIDE}px`,
    width: `${PRODUCT_ICON_SIDE}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& svg path': {
      fill: 'white',

      '&[stroke]': {
        stroke: 'white',
      },
    },
  },
});

const ProductIcon: FC<ProductIconProps> = props => {
  const { className, rgb, svg } = props;
  const classes = useStyles(props);
  return (
    <span
      className={clsx(classes.root, className)}
      style={{ backgroundColor: `#${rgb || ''}` }}
      dangerouslySetInnerHTML={{
        __html: dompurify.sanitize(String(svg || '')),
      }}
    />
  );
};

export default ProductIcon;
