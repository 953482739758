export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  DateTime: any,
  Date: any,
};

export type Address = {
   __typename?: 'Address',
  id?: Maybe<Scalars['Int']>,
  addressLineOne?: Maybe<Scalars['String']>,
  addressLineTwo?: Maybe<Scalars['String']>,
  postalCode?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type AddressInputCreate = {
  addressLineOne?: Maybe<Scalars['String']>,
  addressLineTwo?: Maybe<Scalars['String']>,
  postalCode?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
};

export type AddressInputUpdate = {
  id: Scalars['Int'],
  addressLineOne?: Maybe<Scalars['String']>,
  addressLineTwo?: Maybe<Scalars['String']>,
  postalCode?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
};

export type Batch = {
   __typename?: 'Batch',
  id?: Maybe<Scalars['Int']>,
  lotNumber?: Maybe<Scalars['Int']>,
  expiryDate?: Maybe<Scalars['Date']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type BatchFilter = {
  lotNumber?: Maybe<IntFilter>,
};

export type BatchInputCreate = {
  module?: Maybe<Scalars['Int']>,
  lotNumber?: Maybe<Scalars['Int']>,
  expiryDate?: Maybe<Scalars['Date']>,
};

export type BatchInputUpdate = {
  module?: Maybe<Scalars['Int']>,
  lotNumber?: Maybe<Scalars['Int']>,
  expiryDate?: Maybe<Scalars['Date']>,
};

export type BooleanFilter = {
  _filtertype?: Maybe<FilterType>,
  eq?: Maybe<Array<Scalars['Boolean']>>,
};

export type Box = {
   __typename?: 'Box',
  id?: Maybe<Scalars['Int']>,
  name?: Maybe<Scalars['String']>,
  productCode?: Maybe<Scalars['Int']>,
  recommendedPrice?: Maybe<Scalars['Float']>,
  volume?: Maybe<Scalars['Int']>,
  active?: Maybe<Scalars['Boolean']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  productInfo?: Maybe<Array<Maybe<ProductInfo>>>,
};

export type BoxFilter = {
  active?: Maybe<BooleanFilter>,
};

export type BoxInputCreate = {
  name: Scalars['String'],
  productCode?: Maybe<Scalars['Int']>,
  recommendedPrice?: Maybe<Scalars['Float']>,
  volume?: Maybe<Scalars['Int']>,
  active: Scalars['Boolean'],
};

export type BoxInputUpdate = {
  name?: Maybe<Scalars['String']>,
  productCode?: Maybe<Scalars['Int']>,
  recommendedPrice?: Maybe<Scalars['Float']>,
  volume?: Maybe<Scalars['Int']>,
  active?: Maybe<Scalars['Boolean']>,
};

export type Cmyk = {
   __typename?: 'CMYK',
  C?: Maybe<Scalars['Int']>,
  M?: Maybe<Scalars['Int']>,
  Y?: Maybe<Scalars['Int']>,
  K?: Maybe<Scalars['Int']>,
};

export type CmykInput = {
  C?: Maybe<Scalars['Int']>,
  M?: Maybe<Scalars['Int']>,
  Y?: Maybe<Scalars['Int']>,
  K?: Maybe<Scalars['Int']>,
};

export type Company = Distributor | Customer;

export type Customer = {
   __typename?: 'Customer',
  id?: Maybe<Scalars['Int']>,
  name?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  logo?: Maybe<Scalars['String']>,
  vat?: Maybe<Scalars['String']>,
  addresses?: Maybe<Array<Maybe<Address>>>,
  status?: Maybe<CustomerStatus>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  sector?: Maybe<Sector>,
  numberOfEmployees?: Maybe<Scalars['Int']>,
  language?: Maybe<Language>,
  primaryLabelLanguage?: Maybe<KitLabelLanguage>,
  secondaryLabelLanguage?: Maybe<KitLabelLanguage>,
  reminderFrequency?: Maybe<ReminderFrequency>,
  additionalInfo?: Maybe<Scalars['String']>,
  distributor?: Maybe<Distributor>,
};

export type CustomerDistributorFilter = {
  name?: Maybe<StringFilter>,
};

export type CustomerFilter = {
  AND?: Maybe<Array<CustomerFilter>>,
  OR?: Maybe<Array<CustomerFilter>>,
  name?: Maybe<StringFilter>,
  distributor?: Maybe<CustomerDistributorFilter>,
};

export type CustomerInputCreate = {
  name: Scalars['String'],
  email?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  vat?: Maybe<Scalars['String']>,
  addresses: Array<AddressInputCreate>,
  sector?: Maybe<Sector>,
  numberOfEmployees?: Maybe<Scalars['Int']>,
  language?: Maybe<Language>,
  reminderFrequency?: Maybe<ReminderFrequency>,
  additionalInfo?: Maybe<Scalars['String']>,
  distributor?: Maybe<Scalars['Int']>,
  primaryLabelLanguage?: Maybe<KitLabelLanguage>,
  secondaryLabelLanguage?: Maybe<KitLabelLanguage>,
};

export type CustomerInputUpdate = {
  name?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  vat?: Maybe<Scalars['String']>,
  addresses?: Maybe<Array<AddressInputUpdate>>,
  sector?: Maybe<Sector>,
  numberOfEmployees?: Maybe<Scalars['Int']>,
  language?: Maybe<Language>,
  reminderFrequency?: Maybe<ReminderFrequency>,
  additionalInfo?: Maybe<Scalars['String']>,
  distributor?: Maybe<Scalars['Int']>,
  primaryLabelLanguage?: Maybe<KitLabelLanguage>,
  secondaryLabelLanguage?: Maybe<KitLabelLanguage>,
};

export enum CustomerStatus {
  Customer = 'CUSTOMER',
  Prospect = 'PROSPECT'
}



export type Distributor = {
   __typename?: 'Distributor',
  id?: Maybe<Scalars['Int']>,
  name?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  logo?: Maybe<Scalars['String']>,
  vat?: Maybe<Scalars['String']>,
  defaultDeliveryCost?: Maybe<Scalars['Float']>,
  addresses?: Maybe<Array<Maybe<Address>>>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type DistributorInputCreate = {
  name: Scalars['String'],
  email?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  vat?: Maybe<Scalars['String']>,
  addresses: Array<AddressInputCreate>,
  defaultDeliveryCost?: Maybe<Scalars['Float']>,
};

export type DistributorInputUpdate = {
  name?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  vat?: Maybe<Scalars['String']>,
  addresses?: Maybe<Array<AddressInputUpdate>>,
  defaultDeliveryCost?: Maybe<Scalars['Float']>,
};

export enum Errors {
  RestockExpiredBatchError = 'RESTOCK_EXPIRED_BATCH_ERROR',
  NothingToReorderError = 'NOTHING_TO_REORDER_ERROR',
  UserAlreadyExistsError = 'USER_ALREADY_EXISTS_ERROR',
  UserAccessLevelError = 'USER_ACCESS_LEVEL_ERROR'
}

export enum FilterType {
  String = 'STRING',
  Boolean = 'BOOLEAN',
  Int = 'INT'
}

export enum HistoryActions {
  Use = 'USE',
  Restock = 'RESTOCK',
  Reorder = 'REORDER',
  Verify = 'VERIFY'
}

export type HistoryEntry = {
   __typename?: 'HistoryEntry',
  id?: Maybe<Scalars['Int']>,
  action?: Maybe<HistoryActions>,
  inventoryItem?: Maybe<InventoryItem>,
  summary?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  batch?: Maybe<Batch>,
};

export type IntFilter = {
  _filtertype?: Maybe<FilterType>,
  eq?: Maybe<Array<Scalars['Int']>>,
};

export type InventoryCustomerFilter = {
  name?: Maybe<StringFilter>,
};

export type InventoryDistributorFilter = {
  name?: Maybe<StringFilter>,
};

export type InventoryFilter = {
  AND?: Maybe<Array<InventoryFilter>>,
  OR?: Maybe<Array<InventoryFilter>>,
  location?: Maybe<StringFilter>,
  customer?: Maybe<InventoryCustomerFilter>,
  distributor?: Maybe<InventoryDistributorFilter>,
  labels?: Maybe<InventoryLabelsFilter>,
};

export type InventoryItem = {
   __typename?: 'InventoryItem',
  id?: Maybe<Scalars['Int']>,
  uid?: Maybe<Scalars['String']>,
  product?: Maybe<Product>,
  customer?: Maybe<Customer>,
  status?: Maybe<InventoryItemStatus>,
  children?: Maybe<Array<InventoryItem>>,
  labels?: Maybe<Array<Label>>,
  location?: Maybe<Scalars['String']>,
  lastReorderQuote?: Maybe<Quote>,
  lastKitUpdate?: Maybe<Scalars['DateTime']>,
  orderItem?: Maybe<OrderItem>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  batch?: Maybe<Batch>,
  batchId?: Maybe<Scalars['Int']>,
};

export enum InventoryItemStatus {
  Available = 'AVAILABLE',
  Used = 'USED',
  Reordered = 'REORDERED',
  Verified = 'VERIFIED'
}

export type InventoryItemUpdate = {
  batchId?: Maybe<Scalars['Int']>,
  status?: Maybe<InventoryItemStatus>,
  labels?: Maybe<UpdateLabelsBelongsToMany>,
  location?: Maybe<Scalars['String']>,
};

export type InventoryLabelsFilter = {
  id?: Maybe<IntFilter>,
};

export enum KitLabelLanguage {
  En = 'EN',
  Fr = 'FR',
  Nl = 'NL',
  De = 'DE',
  Es = 'ES',
  Ro = 'RO',
  Tr = 'TR',
  Pl = 'PL',
  Pt = 'PT',
  Ar = 'AR'
}

export type Label = {
   __typename?: 'Label',
  id?: Maybe<Scalars['Int']>,
  name?: Maybe<Scalars['String']>,
  customer?: Maybe<Customer>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type LabelInput = {
  id?: Maybe<Scalars['Int']>,
  name?: Maybe<Scalars['String']>,
};

export enum Language {
  Nl = 'NL',
  Fr = 'FR',
  En = 'EN'
}

export type Module = {
   __typename?: 'Module',
  id?: Maybe<Scalars['Int']>,
  recommendedPrice?: Maybe<Scalars['Float']>,
  volume?: Maybe<Scalars['Int']>,
  active?: Maybe<Scalars['Boolean']>,
  nameEN?: Maybe<Scalars['String']>,
  nameNL?: Maybe<Scalars['String']>,
  nameFR?: Maybe<Scalars['String']>,
  nameDE?: Maybe<Scalars['String']>,
  nameES?: Maybe<Scalars['String']>,
  namePL?: Maybe<Scalars['String']>,
  namePT?: Maybe<Scalars['String']>,
  nameRO?: Maybe<Scalars['String']>,
  nameTR?: Maybe<Scalars['String']>,
  nameAR?: Maybe<Scalars['String']>,
  moduleId?: Maybe<Scalars['String']>,
  moduleSubId?: Maybe<Scalars['String']>,
  productCode?: Maybe<Scalars['Int']>,
  cmyk?: Maybe<Cmyk>,
  rgb?: Maybe<Scalars['String']>,
  expirable?: Maybe<Scalars['Boolean']>,
  icon?: Maybe<ModuleIcon>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  productInfo?: Maybe<Array<Maybe<ProductInfo>>>,
};

export type ModuleFilter = {
  active?: Maybe<BooleanFilter>,
};

export type ModuleIcon = {
   __typename?: 'ModuleIcon',
  id?: Maybe<Scalars['Int']>,
  name?: Maybe<Scalars['String']>,
  svg?: Maybe<Scalars['String']>,
};

export type ModuleInputCreate = {
  recommendedPrice?: Maybe<Scalars['Float']>,
  volume?: Maybe<Scalars['Int']>,
  active: Scalars['Boolean'],
  nameEN: Scalars['String'],
  nameNL: Scalars['String'],
  nameFR: Scalars['String'],
  nameDE: Scalars['String'],
  nameES: Scalars['String'],
  namePL: Scalars['String'],
  namePT: Scalars['String'],
  nameRO: Scalars['String'],
  nameTR: Scalars['String'],
  nameAR: Scalars['String'],
  moduleId: Scalars['String'],
  moduleSubId?: Maybe<Scalars['String']>,
  productCode?: Maybe<Scalars['Int']>,
  cmyk: CmykInput,
  rgb: Scalars['String'],
  expirable: Scalars['Boolean'],
  icon?: Maybe<Scalars['Int']>,
};

export type ModuleInputUpdate = {
  recommendedPrice?: Maybe<Scalars['Float']>,
  volume?: Maybe<Scalars['Int']>,
  active?: Maybe<Scalars['Boolean']>,
  nameEN?: Maybe<Scalars['String']>,
  nameNL?: Maybe<Scalars['String']>,
  nameFR?: Maybe<Scalars['String']>,
  nameDE?: Maybe<Scalars['String']>,
  nameES?: Maybe<Scalars['String']>,
  namePL?: Maybe<Scalars['String']>,
  namePT?: Maybe<Scalars['String']>,
  nameRO?: Maybe<Scalars['String']>,
  nameTR?: Maybe<Scalars['String']>,
  nameAR?: Maybe<Scalars['String']>,
  moduleId?: Maybe<Scalars['String']>,
  moduleSubId?: Maybe<Scalars['String']>,
  productCode?: Maybe<Scalars['Int']>,
  cmyk?: Maybe<CmykInput>,
  rgb?: Maybe<Scalars['String']>,
  expirable?: Maybe<Scalars['Boolean']>,
  icon?: Maybe<Scalars['Int']>,
};

export type Mutation = {
   __typename?: 'Mutation',
  createDistributor?: Maybe<Distributor>,
  updateDistributor?: Maybe<Distributor>,
  createCustomer?: Maybe<Customer>,
  updateCustomer?: Maybe<Customer>,
  createModule?: Maybe<Module>,
  updateModule?: Maybe<Module>,
  createBox?: Maybe<Box>,
  updateBox?: Maybe<Box>,
  createBatch?: Maybe<Batch>,
  updateBatch?: Maybe<Batch>,
  createProductInfo?: Maybe<ProductInfo>,
  updateProductInfo?: Maybe<ProductInfo>,
  createQuote?: Maybe<Quote>,
  updateQuote?: Maybe<Quote>,
  createUser?: Maybe<User>,
  updateUser?: Maybe<User>,
  updateInventoryItem?: Maybe<InventoryItem>,
  reorderInventory?: Maybe<Scalars['Boolean']>,
  updateKit?: Maybe<InventoryItem>,
};


export type MutationCreateDistributorArgs = {
  distributor: DistributorInputCreate
};


export type MutationUpdateDistributorArgs = {
  id: Scalars['Int'],
  distributor: DistributorInputUpdate
};


export type MutationCreateCustomerArgs = {
  customer: CustomerInputCreate,
  companyId?: Maybe<Scalars['Int']>
};


export type MutationUpdateCustomerArgs = {
  id: Scalars['Int'],
  customer: CustomerInputUpdate,
  customerId?: Maybe<Scalars['Int']>
};


export type MutationCreateModuleArgs = {
  module: ModuleInputCreate
};


export type MutationUpdateModuleArgs = {
  id: Scalars['Int'],
  module: ModuleInputUpdate
};


export type MutationCreateBoxArgs = {
  box: BoxInputCreate
};


export type MutationUpdateBoxArgs = {
  id: Scalars['Int'],
  box: BoxInputUpdate
};


export type MutationCreateBatchArgs = {
  batch: BatchInputCreate
};


export type MutationUpdateBatchArgs = {
  id: Scalars['Int'],
  batch: BatchInputUpdate
};


export type MutationCreateProductInfoArgs = {
  productInfo: ProductInfoInputCreate,
  companyId: Scalars['Int']
};


export type MutationUpdateProductInfoArgs = {
  id: Scalars['Int'],
  productInfo: ProductInfoInputUpdate,
  companyId: Scalars['Int']
};


export type MutationCreateQuoteArgs = {
  quote: QuoteInputCreate,
  customerId: Scalars['Int'],
  companyId?: Maybe<Scalars['Int']>
};


export type MutationUpdateQuoteArgs = {
  id: Scalars['Int'],
  quote?: Maybe<QuoteInputUpdate>,
  companyId?: Maybe<Scalars['Int']>,
  customerId?: Maybe<Scalars['Int']>
};


export type MutationCreateUserArgs = {
  user: UserInputCreate,
  companyId?: Maybe<Scalars['Int']>
};


export type MutationUpdateUserArgs = {
  id: Scalars['Int'],
  user: UserInputUpdate
};


export type MutationUpdateInventoryItemArgs = {
  id: Scalars['Int'],
  inventoryItem: InventoryItemUpdate,
  summary?: Maybe<Scalars['String']>,
  isPartial?: Maybe<Scalars['Boolean']>
};


export type MutationReorderInventoryArgs = {
  id: Scalars['Int']
};


export type MutationUpdateKitArgs = {
  id: Scalars['Int'],
  inventoryItem: InventoryItemUpdate
};

export type Order = {
   __typename?: 'Order',
  id?: Maybe<Scalars['Int']>,
  reference?: Maybe<Scalars['String']>,
  customer?: Maybe<Customer>,
  discount?: Maybe<Scalars['Float']>,
  deliveryCost?: Maybe<Scalars['Float']>,
  subTotalPrice?: Maybe<Scalars['Float']>,
  discountPrice?: Maybe<Scalars['Float']>,
  totalPrice?: Maybe<Scalars['Float']>,
  createdBy?: Maybe<User>,
  quote?: Maybe<Quote>,
  orderItems?: Maybe<Array<OrderItem>>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  shippingAddress?: Maybe<Address>,
  returnAddress?: Maybe<Address>,
};

export type OrderCreatedByFilter = {
  firstName_lastName?: Maybe<StringFilter>,
};

export type OrderCustomerFilter = {
  name?: Maybe<StringFilter>,
};

export type OrderDistributorFilter = {
  name?: Maybe<StringFilter>,
};

export type OrderFilter = {
  AND?: Maybe<Array<OrderFilter>>,
  OR?: Maybe<Array<OrderFilter>>,
  customer?: Maybe<OrderCustomerFilter>,
  distributor?: Maybe<OrderDistributorFilter>,
  createdBy?: Maybe<OrderCreatedByFilter>,
};

export type OrderItem = {
   __typename?: 'OrderItem',
  id?: Maybe<Scalars['Int']>,
  unitPrice?: Maybe<Scalars['Float']>,
  quantity?: Maybe<Scalars['Int']>,
  totalPrice?: Maybe<Scalars['Float']>,
  product?: Maybe<Product>,
  children?: Maybe<Array<OrderItem>>,
  location?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type PagedBoxes = {
   __typename?: 'PagedBoxes',
  items?: Maybe<Array<Box>>,
  pageInfo?: Maybe<PageInfo>,
};

export type PagedCustomers = {
   __typename?: 'PagedCustomers',
  items?: Maybe<Array<Customer>>,
  pageInfo?: Maybe<PageInfo>,
};

export type PagedDistributors = {
   __typename?: 'PagedDistributors',
  items?: Maybe<Array<Distributor>>,
  pageInfo?: Maybe<PageInfo>,
};

export type PagedHistoryEntries = {
   __typename?: 'PagedHistoryEntries',
  items?: Maybe<Array<HistoryEntry>>,
  pageInfo?: Maybe<PageInfo>,
};

export type PagedInventories = {
   __typename?: 'PagedInventories',
  items?: Maybe<Array<InventoryItem>>,
  pageInfo?: Maybe<PageInfo>,
};

export type PagedModules = {
   __typename?: 'PagedModules',
  items?: Maybe<Array<Module>>,
  pageInfo?: Maybe<PageInfo>,
};

export type PagedOrders = {
   __typename?: 'PagedOrders',
  items?: Maybe<Array<Order>>,
  pageInfo?: Maybe<PageInfo>,
};

export type PagedQuotes = {
   __typename?: 'PagedQuotes',
  items?: Maybe<Array<Quote>>,
  pageInfo?: Maybe<PageInfo>,
};

export type PagedUsers = {
   __typename?: 'PagedUsers',
  items?: Maybe<Array<User>>,
  pageInfo?: Maybe<PageInfo>,
};

export type PageInfo = {
   __typename?: 'PageInfo',
  itemCount?: Maybe<Scalars['Int']>,
  total?: Maybe<Scalars['Int']>,
  pageCount?: Maybe<Scalars['Int']>,
};

export type Product = Box | Module;

export type ProductInfo = {
   __typename?: 'ProductInfo',
  id?: Maybe<Scalars['Int']>,
  articleId?: Maybe<Scalars['String']>,
  price?: Maybe<Scalars['Float']>,
  product?: Maybe<Product>,
};

export type ProductInfoInputCreate = {
  articleId: Scalars['String'],
  price?: Maybe<Scalars['Float']>,
  product?: Maybe<Scalars['Int']>,
};

export type ProductInfoInputUpdate = {
  articleId?: Maybe<Scalars['String']>,
  price?: Maybe<Scalars['Float']>,
};

export type ProductRelationInput = {
  id?: Maybe<Scalars['Int']>,
};

export type Query = {
   __typename?: 'Query',
  hello: Scalars['String'],
  getUsers: Array<User>,
  getCustomers: Array<Customer>,
  getCustomer?: Maybe<Customer>,
  getPagedCustomers: PagedCustomers,
  getDistributors: Array<Distributor>,
  getPagedDistributors: PagedDistributors,
  getDistributor?: Maybe<Distributor>,
  getModules: Array<Module>,
  getPagedModules: PagedModules,
  getModule?: Maybe<Module>,
  getModuleIcons: Array<ModuleIcon>,
  getBoxes: Array<Box>,
  getPagedBoxes: PagedBoxes,
  getBox?: Maybe<Box>,
  getBatches: Array<Batch>,
  getBatch?: Maybe<Batch>,
  getProductInfo?: Maybe<ProductInfo>,
  getUser?: Maybe<User>,
  getUserByExternalId?: Maybe<User>,
  getPagedUsers: PagedUsers,
  getPagedQuotes?: Maybe<PagedQuotes>,
  getQuote?: Maybe<Quote>,
  getConfirmedAddresses: Array<Address>,
  getPagedOrders?: Maybe<PagedOrders>,
  getOrder?: Maybe<Order>,
  getInventoryForKit?: Maybe<InventoryItem>,
  getPagedHistoryForKit?: Maybe<PagedHistoryEntries>,
  getPagedInventories?: Maybe<PagedInventories>,
  getLabels: Array<Label>,
};


export type QueryGetCustomerArgs = {
  id: Scalars['Int'],
  companyId?: Maybe<Scalars['Int']>
};


export type QueryGetPagedCustomersArgs = {
  limit: Scalars['Int'],
  offset: Scalars['Int'],
  sortColumn?: Maybe<Scalars['String']>,
  sortDirection?: Maybe<SortDirection>,
  companyId?: Maybe<Scalars['Int']>,
  filter?: Maybe<CustomerFilter>
};


export type QueryGetPagedDistributorsArgs = {
  limit: Scalars['Int'],
  offset: Scalars['Int'],
  sortColumn?: Maybe<Scalars['String']>,
  sortDirection?: Maybe<SortDirection>
};


export type QueryGetDistributorArgs = {
  id: Scalars['Int']
};


export type QueryGetModulesArgs = {
  sortColumn?: Maybe<Scalars['String']>,
  sortDirection?: Maybe<SortDirection>,
  companyId?: Maybe<Scalars['Int']>,
  filter?: Maybe<ModuleFilter>
};


export type QueryGetPagedModulesArgs = {
  limit: Scalars['Int'],
  offset: Scalars['Int'],
  sortColumn?: Maybe<Scalars['String']>,
  sortDirection?: Maybe<SortDirection>,
  companyId?: Maybe<Scalars['Int']>
};


export type QueryGetModuleArgs = {
  id: Scalars['Int'],
  companyId?: Maybe<Scalars['Int']>
};


export type QueryGetBoxesArgs = {
  sortColumn?: Maybe<Scalars['String']>,
  sortDirection?: Maybe<SortDirection>,
  companyId?: Maybe<Scalars['Int']>,
  filter?: Maybe<BoxFilter>
};


export type QueryGetPagedBoxesArgs = {
  limit: Scalars['Int'],
  offset: Scalars['Int'],
  sortColumn?: Maybe<Scalars['String']>,
  sortDirection?: Maybe<SortDirection>,
  companyId?: Maybe<Scalars['Int']>
};


export type QueryGetBoxArgs = {
  id: Scalars['Int'],
  companyId?: Maybe<Scalars['Int']>
};


export type QueryGetBatchesArgs = {
  moduleId: Scalars['Int'],
  sortColumn?: Maybe<Scalars['String']>,
  sortDirection?: Maybe<SortDirection>,
  filter?: Maybe<BatchFilter>
};


export type QueryGetBatchArgs = {
  id: Scalars['Int']
};


export type QueryGetProductInfoArgs = {
  id: Scalars['Int'],
  companyId: Scalars['Int']
};


export type QueryGetUserArgs = {
  id?: Maybe<Scalars['Int']>,
  companyId?: Maybe<Scalars['Int']>
};


export type QueryGetPagedUsersArgs = {
  limit: Scalars['Int'],
  offset: Scalars['Int'],
  sortColumn?: Maybe<Scalars['String']>,
  sortDirection?: Maybe<SortDirection>,
  companyId?: Maybe<Scalars['Int']>
};


export type QueryGetPagedQuotesArgs = {
  limit: Scalars['Int'],
  offset: Scalars['Int'],
  sortColumn?: Maybe<Scalars['String']>,
  sortDirection?: Maybe<SortDirection>,
  companyId?: Maybe<Scalars['Int']>,
  customerId?: Maybe<Scalars['Int']>,
  filter?: Maybe<QuoteFilter>
};


export type QueryGetQuoteArgs = {
  id: Scalars['Int'],
  companyId?: Maybe<Scalars['Int']>,
  customerId?: Maybe<Scalars['Int']>
};


export type QueryGetConfirmedAddressesArgs = {
  customerId: Scalars['Int']
};


export type QueryGetPagedOrdersArgs = {
  limit: Scalars['Int'],
  offset: Scalars['Int'],
  sortColumn?: Maybe<Scalars['String']>,
  sortDirection?: Maybe<SortDirection>,
  companyId?: Maybe<Scalars['Int']>,
  customerId?: Maybe<Scalars['Int']>,
  filter?: Maybe<OrderFilter>
};


export type QueryGetOrderArgs = {
  id: Scalars['Int'],
  companyId?: Maybe<Scalars['Int']>,
  customerId?: Maybe<Scalars['Int']>
};


export type QueryGetInventoryForKitArgs = {
  id: Scalars['String']
};


export type QueryGetPagedHistoryForKitArgs = {
  limit: Scalars['Int'],
  offset: Scalars['Int'],
  id: Scalars['Int'],
  customerId?: Maybe<Scalars['Int']>
};


export type QueryGetPagedInventoriesArgs = {
  limit: Scalars['Int'],
  offset: Scalars['Int'],
  sortColumn?: Maybe<Scalars['String']>,
  sortDirection?: Maybe<SortDirection>,
  filter?: Maybe<InventoryFilter>,
  companyId?: Maybe<Scalars['Int']>,
  customerId?: Maybe<Scalars['Int']>
};


export type QueryGetLabelsArgs = {
  customerId?: Maybe<Scalars['Int']>,
  companyId?: Maybe<Scalars['Int']>
};

export type Quote = {
   __typename?: 'Quote',
  id?: Maybe<Scalars['Int']>,
  reference?: Maybe<Scalars['String']>,
  customer?: Maybe<Customer>,
  status?: Maybe<QuoteStatus>,
  discount?: Maybe<Scalars['Float']>,
  deliveryCost?: Maybe<Scalars['Float']>,
  subTotalPrice?: Maybe<Scalars['Float']>,
  discountPrice?: Maybe<Scalars['Float']>,
  totalPrice?: Maybe<Scalars['Float']>,
  createdBy?: Maybe<User>,
  quoteItems?: Maybe<Array<QuoteItem>>,
  reorderLocation?: Maybe<Scalars['String']>,
  shippingAddress?: Maybe<Address>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type QuoteCreatedByFilter = {
  firstName_lastName?: Maybe<StringFilter>,
};

export type QuoteCustomerFilter = {
  name?: Maybe<StringFilter>,
};

export type QuoteDistributorFilter = {
  name?: Maybe<StringFilter>,
};

export type QuoteFilter = {
  AND?: Maybe<Array<QuoteFilter>>,
  OR?: Maybe<Array<QuoteFilter>>,
  status?: Maybe<StringFilter>,
  customer?: Maybe<QuoteCustomerFilter>,
  distributor?: Maybe<QuoteDistributorFilter>,
  createdBy?: Maybe<QuoteCreatedByFilter>,
};

export type QuoteInputCreate = {
  discount?: Maybe<Scalars['Float']>,
  deliveryCost?: Maybe<Scalars['Float']>,
  quoteItems: Array<QuoteItemInputCreate>,
  shippingAddress?: Maybe<AddressInputCreate>,
};

export type QuoteInputUpdate = {
  status?: Maybe<QuoteStatus>,
  quoteItems?: Maybe<UpdateQuoteItemsHasMany>,
  shippingAddress?: Maybe<AddressInputCreate>,
};

export type QuoteItem = {
   __typename?: 'QuoteItem',
  id?: Maybe<Scalars['Int']>,
  unitPrice?: Maybe<Scalars['Float']>,
  quantity?: Maybe<Scalars['Int']>,
  totalPrice?: Maybe<Scalars['Float']>,
  product?: Maybe<Product>,
  children?: Maybe<Array<QuoteItem>>,
  location?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type QuoteItemInputCreate = {
  quantity?: Maybe<Scalars['Int']>,
  product?: Maybe<ProductRelationInput>,
  children?: Maybe<Array<QuoteItemInputCreate>>,
  location?: Maybe<Scalars['String']>,
};

export type QuoteItemInputUpdate = {
  id: Scalars['Int'],
  location?: Maybe<Scalars['String']>,
};

export enum QuoteStatus {
  Accepted = 'ACCEPTED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export enum ReminderFrequency {
  None = 'NONE',
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Biannual = 'BIANNUAL',
  Yearly = 'YEARLY'
}

export enum Sector {
  Construction = 'CONSTRUCTION',
  ChemistryRubberPlastic = 'CHEMISTRY_RUBBER_PLASTIC',
  VariousServices = 'VARIOUS_SERVICES',
  ServicesToPersons = 'SERVICES_TO_PERSONS',
  EnergyWaterWaste = 'ENERGY_WATER_WASTE',
  FinancialServices = 'FINANCIAL_SERVICES',
  Healthcare = 'HEALTHCARE',
  GraphicIndustry = 'GRAPHIC_INDUSTRY',
  CateringTourism = 'CATERING_TOURISM',
  WoodFurniture = 'WOOD_FURNITURE',
  Ict = 'ICT',
  SocialServices = 'SOCIAL_SERVICES',
  Metal = 'METAL',
  Education = 'EDUCATION',
  RecreationCultureSport = 'RECREATION_CULTURE_SPORT',
  PublicAuthorities = 'PUBLIC_AUTHORITIES',
  OtherIndustry = 'OTHER_INDUSTRY',
  OtherServices = 'OTHER_SERVICES',
  PrimarySector = 'PRIMARY_SECTOR',
  ProductionBuildingMaterials = 'PRODUCTION_BUILDING_MATERIALS',
  TextileClothingFootwear = 'TEXTILE_CLOTHING_FOOTWEAR',
  TransportLogistics = 'TRANSPORT_LOGISTICS',
  EmploymentJobPlacement = 'EMPLOYMENT_JOB_PLACEMENT',
  BusinessServices = 'BUSINESS_SERVICES'
}

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StringFilter = {
  _filtertype?: Maybe<FilterType>,
  eq?: Maybe<Array<Scalars['String']>>,
  contains?: Maybe<Array<Scalars['String']>>,
};

export type UpdateLabelsBelongsToMany = {
  replace?: Maybe<Array<LabelInput>>,
};

export type UpdateQuoteItemsHasMany = {
  update?: Maybe<Array<QuoteItemInputUpdate>>,
};

export type User = {
   __typename?: 'User',
  id?: Maybe<Scalars['Int']>,
  firstName?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  externalId?: Maybe<Scalars['String']>,
  companies?: Maybe<Array<Maybe<Company>>>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type UserInputCreate = {
  firstName?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
};

export type UserInputUpdate = {
  firstName?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
};

export type GetBatchesQueryVariables = {
  moduleId: Scalars['Int'],
  sortColumn?: Maybe<Scalars['String']>,
  sortDirection?: Maybe<SortDirection>,
  filter?: Maybe<BatchFilter>
};


export type GetBatchesQuery = (
  { __typename?: 'Query' }
  & { getBatches: Array<(
    { __typename?: 'Batch' }
    & Pick<Batch, 'lotNumber' | 'expiryDate' | 'id'>
  )> }
);

export type CreateBatchMutationVariables = {
  type: BatchInputCreate
};


export type CreateBatchMutation = (
  { __typename?: 'Mutation' }
  & { createBatch: Maybe<(
    { __typename?: 'Batch' }
    & Pick<Batch, 'id'>
  )> }
);

export type UpdateBatchMutationVariables = {
  id: Scalars['Int'],
  type: BatchInputUpdate
};


export type UpdateBatchMutation = (
  { __typename?: 'Mutation' }
  & { updateBatch: Maybe<(
    { __typename?: 'Batch' }
    & Pick<Batch, 'id'>
  )> }
);

export type BoxItemFragment = (
  { __typename: 'Box' }
  & Pick<Box, 'id' | 'name' | 'volume' | 'active'>
);

export type GetBoxesQueryVariables = {
  companyId: Scalars['Int'],
  sortColumn?: Maybe<Scalars['String']>,
  sortDirection?: Maybe<SortDirection>,
  filter?: Maybe<BoxFilter>
};


export type GetBoxesQuery = (
  { __typename?: 'Query' }
  & { getBoxes: Array<(
    { __typename?: 'Box' }
    & { productInfo: Maybe<Array<Maybe<(
      { __typename?: 'ProductInfo' }
      & Pick<ProductInfo, 'articleId' | 'id' | 'price'>
    )>>> }
    & BoxItemFragment
  )> }
);

export type GetPagedBoxesQueryVariables = {
  offset: Scalars['Int'],
  limit: Scalars['Int'],
  sortColumn?: Maybe<Scalars['String']>,
  sortDirection?: Maybe<SortDirection>
};


export type GetPagedBoxesQuery = (
  { __typename?: 'Query' }
  & { getPagedBoxes: (
    { __typename?: 'PagedBoxes' }
    & { items: Maybe<Array<(
      { __typename?: 'Box' }
      & Pick<Box, 'recommendedPrice'>
      & BoxItemFragment
    )>>, pageInfo: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'itemCount' | 'pageCount' | 'total'>
    )> }
  ) }
);

export type GetPagedBoxesDistributorQueryVariables = {
  companyId: Scalars['Int'],
  offset: Scalars['Int'],
  limit: Scalars['Int'],
  sortColumn?: Maybe<Scalars['String']>,
  sortDirection?: Maybe<SortDirection>
};


export type GetPagedBoxesDistributorQuery = (
  { __typename?: 'Query' }
  & { getPagedBoxes: (
    { __typename?: 'PagedBoxes' }
    & { items: Maybe<Array<(
      { __typename?: 'Box' }
      & { productInfo: Maybe<Array<Maybe<(
        { __typename?: 'ProductInfo' }
        & Pick<ProductInfo, 'articleId' | 'id' | 'price'>
      )>>> }
      & BoxItemFragment
    )>>, pageInfo: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'itemCount' | 'pageCount' | 'total'>
    )> }
  ) }
);

export type GetBoxQueryVariables = {
  id: Scalars['Int'],
  companyId?: Maybe<Scalars['Int']>
};


export type GetBoxQuery = (
  { __typename?: 'Query' }
  & { getBox: Maybe<(
    { __typename?: 'Box' }
    & Pick<Box, 'id' | 'name' | 'recommendedPrice' | 'volume' | 'active' | 'productCode'>
  )> }
);

export type CreateBoxMutationVariables = {
  type: BoxInputCreate
};


export type CreateBoxMutation = (
  { __typename?: 'Mutation' }
  & { createBox: Maybe<(
    { __typename?: 'Box' }
    & Pick<Box, 'id'>
  )> }
);

export type UpdateBoxMutationVariables = {
  id: Scalars['Int'],
  type: BoxInputUpdate
};


export type UpdateBoxMutation = (
  { __typename?: 'Mutation' }
  & { updateBox: Maybe<(
    { __typename?: 'Box' }
    & Pick<Box, 'id'>
  )> }
);

export type GetCustomerQueryVariables = {
  id: Scalars['Int'],
  companyId?: Maybe<Scalars['Int']>
};


export type GetCustomerQuery = (
  { __typename?: 'Query' }
  & { getCustomer: Maybe<(
    { __typename?: 'Customer' }
    & Pick<Customer, 'id' | 'name' | 'email' | 'phone' | 'vat' | 'sector' | 'numberOfEmployees' | 'reminderFrequency' | 'additionalInfo' | 'language' | 'primaryLabelLanguage' | 'secondaryLabelLanguage'>
    & { distributor: Maybe<(
      { __typename?: 'Distributor' }
      & Pick<Distributor, 'id' | 'name'>
    )>, addresses: Maybe<Array<Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'addressLineOne' | 'addressLineTwo' | 'postalCode' | 'city' | 'country'>
    )>>> }
  )> }
);

export type GetCustomerForQuoteQueryVariables = {
  id: Scalars['Int'],
  companyId?: Maybe<Scalars['Int']>
};


export type GetCustomerForQuoteQuery = (
  { __typename?: 'Query' }
  & { getCustomer: Maybe<(
    { __typename?: 'Customer' }
    & Pick<Customer, 'id' | 'name'>
    & { distributor: Maybe<(
      { __typename?: 'Distributor' }
      & Pick<Distributor, 'id' | 'defaultDeliveryCost'>
    )>, addresses: Maybe<Array<Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'addressLineOne' | 'addressLineTwo' | 'postalCode' | 'city' | 'country'>
    )>>> }
  )> }
);

export type GetPagedCustomersQueryVariables = {
  offset: Scalars['Int'],
  limit: Scalars['Int'],
  sortColumn?: Maybe<Scalars['String']>,
  sortDirection?: Maybe<SortDirection>,
  companyId?: Maybe<Scalars['Int']>,
  filter?: Maybe<CustomerFilter>
};


export type GetPagedCustomersQuery = (
  { __typename?: 'Query' }
  & { getPagedCustomers: (
    { __typename?: 'PagedCustomers' }
    & { items: Maybe<Array<(
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'status' | 'name'>
      & { distributor: Maybe<(
        { __typename?: 'Distributor' }
        & Pick<Distributor, 'id' | 'name'>
      )> }
    )>>, pageInfo: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'itemCount' | 'pageCount' | 'total'>
    )> }
  ) }
);

export type CreateCustomerMutationVariables = {
  type: CustomerInputCreate,
  companyId?: Maybe<Scalars['Int']>
};


export type CreateCustomerMutation = (
  { __typename?: 'Mutation' }
  & { createCustomer: Maybe<(
    { __typename?: 'Customer' }
    & Pick<Customer, 'name' | 'email' | 'phone' | 'vat'>
    & { addresses: Maybe<Array<Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'addressLineOne' | 'addressLineTwo' | 'postalCode' | 'city' | 'country'>
    )>>> }
  )> }
);

export type UpdateCustomerMutationVariables = {
  id: Scalars['Int'],
  type: CustomerInputUpdate,
  customerId?: Maybe<Scalars['Int']>
};


export type UpdateCustomerMutation = (
  { __typename?: 'Mutation' }
  & { updateCustomer: Maybe<(
    { __typename?: 'Customer' }
    & Pick<Customer, 'name' | 'email' | 'phone' | 'vat'>
    & { addresses: Maybe<Array<Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'addressLineOne' | 'addressLineTwo' | 'postalCode' | 'city' | 'country'>
    )>>> }
  )> }
);

export type GetDistributorsQueryVariables = {};


export type GetDistributorsQuery = (
  { __typename?: 'Query' }
  & { getDistributors: Array<(
    { __typename?: 'Distributor' }
    & Pick<Distributor, 'id' | 'name'>
  )> }
);

export type GetPagedDistributorsQueryVariables = {
  offset: Scalars['Int'],
  limit: Scalars['Int'],
  sortColumn?: Maybe<Scalars['String']>,
  sortDirection?: Maybe<SortDirection>
};


export type GetPagedDistributorsQuery = (
  { __typename?: 'Query' }
  & { getPagedDistributors: (
    { __typename?: 'PagedDistributors' }
    & { items: Maybe<Array<(
      { __typename?: 'Distributor' }
      & Pick<Distributor, 'id' | 'name'>
    )>>, pageInfo: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'itemCount' | 'pageCount' | 'total'>
    )> }
  ) }
);

export type GetDistributorQueryVariables = {
  id: Scalars['Int']
};


export type GetDistributorQuery = (
  { __typename?: 'Query' }
  & { getDistributor: Maybe<(
    { __typename?: 'Distributor' }
    & Pick<Distributor, 'id' | 'name' | 'email' | 'phone' | 'vat' | 'defaultDeliveryCost'>
    & { addresses: Maybe<Array<Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'addressLineOne' | 'addressLineTwo' | 'postalCode' | 'city' | 'country'>
    )>>> }
  )> }
);

export type CreateDistributorMutationVariables = {
  type: DistributorInputCreate
};


export type CreateDistributorMutation = (
  { __typename?: 'Mutation' }
  & { createDistributor: Maybe<(
    { __typename?: 'Distributor' }
    & Pick<Distributor, 'name' | 'email' | 'phone' | 'vat'>
    & { addresses: Maybe<Array<Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'addressLineOne' | 'addressLineTwo' | 'postalCode' | 'city' | 'country'>
    )>>> }
  )> }
);

export type UpdateDistributorMutationVariables = {
  id: Scalars['Int'],
  type: DistributorInputUpdate
};


export type UpdateDistributorMutation = (
  { __typename?: 'Mutation' }
  & { updateDistributor: Maybe<(
    { __typename?: 'Distributor' }
    & Pick<Distributor, 'name' | 'email' | 'phone' | 'vat'>
    & { addresses: Maybe<Array<Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'addressLineOne' | 'addressLineTwo' | 'postalCode' | 'city' | 'country'>
    )>>> }
  )> }
);

export type GetInventoryForKitQueryVariables = {
  id: Scalars['String']
};


export type GetInventoryForKitQuery = (
  { __typename?: 'Query' }
  & { getInventoryForKit: Maybe<(
    { __typename?: 'InventoryItem' }
    & Pick<InventoryItem, 'id' | 'location' | 'uid'>
    & { product: Maybe<(
      { __typename?: 'Box' }
      & Pick<Box, 'id' | 'name'>
    ) | { __typename?: 'Module' }>, labels: Maybe<Array<(
      { __typename?: 'Label' }
      & Pick<Label, 'id' | 'name'>
    )>>, customer: Maybe<(
      { __typename?: 'Customer' }
      & Pick<Customer, 'id'>
    )>, children: Maybe<Array<(
      { __typename?: 'InventoryItem' }
      & Pick<InventoryItem, 'id' | 'status' | 'batchId'>
      & { batch: Maybe<(
        { __typename?: 'Batch' }
        & Pick<Batch, 'id' | 'lotNumber' | 'expiryDate'>
      )>, lastReorderQuote: Maybe<(
        { __typename?: 'Quote' }
        & Pick<Quote, 'id' | 'createdAt'>
      )>, product: Maybe<{ __typename?: 'Box' } | (
        { __typename?: 'Module' }
        & Pick<Module, 'id' | 'moduleId' | 'nameEN' | 'rgb'>
        & { icon: Maybe<(
          { __typename?: 'ModuleIcon' }
          & Pick<ModuleIcon, 'id' | 'svg'>
        )> }
      )> }
    )>> }
  )> }
);

export type GetPagedInventoriesQueryVariables = {
  companyId?: Maybe<Scalars['Int']>,
  customerId?: Maybe<Scalars['Int']>,
  offset: Scalars['Int'],
  limit: Scalars['Int'],
  sortColumn?: Maybe<Scalars['String']>,
  sortDirection?: Maybe<SortDirection>,
  filter?: Maybe<InventoryFilter>
};


export type GetPagedInventoriesQuery = (
  { __typename?: 'Query' }
  & { getPagedInventories: Maybe<(
    { __typename?: 'PagedInventories' }
    & { pageInfo: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'itemCount' | 'pageCount' | 'total'>
    )>, items: Maybe<Array<(
      { __typename?: 'InventoryItem' }
      & Pick<InventoryItem, 'id' | 'uid' | 'location' | 'lastKitUpdate' | 'createdAt'>
      & { customer: Maybe<(
        { __typename?: 'Customer' }
        & Pick<Customer, 'id' | 'name'>
        & { distributor: Maybe<(
          { __typename?: 'Distributor' }
          & Pick<Distributor, 'id' | 'name'>
        )> }
      )>, children: Maybe<Array<(
        { __typename?: 'InventoryItem' }
        & Pick<InventoryItem, 'id'>
        & { product: Maybe<{ __typename?: 'Box' } | (
          { __typename?: 'Module' }
          & Pick<Module, 'id' | 'moduleId' | 'nameEN' | 'rgb'>
          & { icon: Maybe<(
            { __typename?: 'ModuleIcon' }
            & Pick<ModuleIcon, 'id' | 'svg'>
          )> }
        )> }
      )>> }
    )>> }
  )> }
);

export type SearchInventoryQueryVariables = {
  id: Scalars['String']
};


export type SearchInventoryQuery = (
  { __typename?: 'Query' }
  & { getInventoryForKit: Maybe<(
    { __typename?: 'InventoryItem' }
    & Pick<InventoryItem, 'id' | 'uid'>
  )> }
);

export type SearchBatchesQueryVariables = {
  moduleId: Scalars['Int'],
  filter: BatchFilter
};


export type SearchBatchesQuery = (
  { __typename?: 'Query' }
  & { getBatches: Array<(
    { __typename?: 'Batch' }
    & Pick<Batch, 'id' | 'lotNumber' | 'expiryDate'>
  )> }
);

export type UpdateInventoryItemMutationVariables = {
  id: Scalars['Int'],
  inventoryItem: InventoryItemUpdate,
  summary?: Maybe<Scalars['String']>,
  isPartial?: Maybe<Scalars['Boolean']>
};


export type UpdateInventoryItemMutation = (
  { __typename?: 'Mutation' }
  & { updateInventoryItem: Maybe<(
    { __typename?: 'InventoryItem' }
    & Pick<InventoryItem, 'id'>
    & { batch: Maybe<(
      { __typename?: 'Batch' }
      & Pick<Batch, 'id' | 'lotNumber' | 'expiryDate'>
    )> }
  )> }
);

export type UpdateKitMutationVariables = {
  id: Scalars['Int'],
  inventoryItem: InventoryItemUpdate
};


export type UpdateKitMutation = (
  { __typename?: 'Mutation' }
  & { updateKit: Maybe<(
    { __typename?: 'InventoryItem' }
    & Pick<InventoryItem, 'id'>
  )> }
);

export type ReorderInventoryMutationVariables = {
  id: Scalars['Int']
};


export type ReorderInventoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'reorderInventory'>
);

export type GetPagedHistoryForKitQueryVariables = {
  offset: Scalars['Int'],
  limit: Scalars['Int'],
  id: Scalars['Int'],
  customerId?: Maybe<Scalars['Int']>
};


export type GetPagedHistoryForKitQuery = (
  { __typename?: 'Query' }
  & { getPagedHistoryForKit: Maybe<(
    { __typename?: 'PagedHistoryEntries' }
    & { pageInfo: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'itemCount' | 'pageCount' | 'total'>
    )>, items: Maybe<Array<(
      { __typename?: 'HistoryEntry' }
      & Pick<HistoryEntry, 'id' | 'action' | 'summary' | 'createdAt'>
      & { inventoryItem: Maybe<(
        { __typename?: 'InventoryItem' }
        & Pick<InventoryItem, 'id'>
        & { product: Maybe<{ __typename?: 'Box' } | (
          { __typename?: 'Module' }
          & Pick<Module, 'id' | 'moduleId' | 'nameEN' | 'rgb'>
          & { icon: Maybe<(
            { __typename?: 'ModuleIcon' }
            & Pick<ModuleIcon, 'id' | 'svg'>
          )> }
        )> }
      )> }
    )>> }
  )> }
);

export type GetLabelsForInputQueryVariables = {
  customerId: Scalars['Int']
};


export type GetLabelsForInputQuery = (
  { __typename?: 'Query' }
  & { getLabels: Array<(
    { __typename?: 'Label' }
    & Pick<Label, 'id' | 'name'>
  )> }
);

export type GetLabelsForFilterQueryVariables = {
  customerId?: Maybe<Scalars['Int']>,
  companyId?: Maybe<Scalars['Int']>
};


export type GetLabelsForFilterQuery = (
  { __typename?: 'Query' }
  & { getLabels: Array<(
    { __typename?: 'Label' }
    & Pick<Label, 'id' | 'name'>
  )> }
);

export type ModuleItemFragment = (
  { __typename: 'Module' }
  & Pick<Module, 'id' | 'moduleId' | 'moduleSubId' | 'nameEN' | 'active' | 'rgb'>
  & { icon: Maybe<(
    { __typename?: 'ModuleIcon' }
    & Pick<ModuleIcon, 'id' | 'name' | 'svg'>
  )> }
);

export type GetModulesQueryVariables = {
  companyId: Scalars['Int'],
  sortColumn?: Maybe<Scalars['String']>,
  sortDirection?: Maybe<SortDirection>,
  filter?: Maybe<ModuleFilter>
};


export type GetModulesQuery = (
  { __typename?: 'Query' }
  & { getModules: Array<(
    { __typename?: 'Module' }
    & Pick<Module, 'volume'>
    & { productInfo: Maybe<Array<Maybe<(
      { __typename?: 'ProductInfo' }
      & Pick<ProductInfo, 'articleId' | 'id' | 'price'>
    )>>> }
    & ModuleItemFragment
  )> }
);

export type GetPagedModulesQueryVariables = {
  offset: Scalars['Int'],
  limit: Scalars['Int'],
  sortColumn?: Maybe<Scalars['String']>,
  sortDirection?: Maybe<SortDirection>
};


export type GetPagedModulesQuery = (
  { __typename?: 'Query' }
  & { getPagedModules: (
    { __typename?: 'PagedModules' }
    & { items: Maybe<Array<(
      { __typename?: 'Module' }
      & Pick<Module, 'recommendedPrice'>
      & ModuleItemFragment
    )>>, pageInfo: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'itemCount' | 'pageCount' | 'total'>
    )> }
  ) }
);

export type GetPagedModulesDistributorQueryVariables = {
  companyId: Scalars['Int'],
  offset: Scalars['Int'],
  limit: Scalars['Int'],
  sortColumn?: Maybe<Scalars['String']>,
  sortDirection?: Maybe<SortDirection>
};


export type GetPagedModulesDistributorQuery = (
  { __typename?: 'Query' }
  & { getPagedModules: (
    { __typename?: 'PagedModules' }
    & { items: Maybe<Array<(
      { __typename?: 'Module' }
      & { productInfo: Maybe<Array<Maybe<(
        { __typename?: 'ProductInfo' }
        & Pick<ProductInfo, 'articleId' | 'id' | 'price'>
      )>>> }
      & ModuleItemFragment
    )>>, pageInfo: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'itemCount' | 'pageCount' | 'total'>
    )> }
  ) }
);

export type GetModuleQueryVariables = {
  id: Scalars['Int'],
  companyId?: Maybe<Scalars['Int']>
};


export type GetModuleQuery = (
  { __typename?: 'Query' }
  & { getModule: Maybe<(
    { __typename?: 'Module' }
    & Pick<Module, 'id' | 'expirable' | 'active' | 'moduleId' | 'moduleSubId' | 'nameAR' | 'nameDE' | 'nameEN' | 'nameES' | 'nameFR' | 'nameNL' | 'namePL' | 'namePT' | 'nameRO' | 'nameTR' | 'productCode' | 'recommendedPrice' | 'rgb' | 'volume'>
    & { cmyk: Maybe<(
      { __typename?: 'CMYK' }
      & Pick<Cmyk, 'C' | 'K' | 'M' | 'Y'>
    )>, icon: Maybe<(
      { __typename?: 'ModuleIcon' }
      & Pick<ModuleIcon, 'id'>
    )> }
  )> }
);

export type CreateModuleMutationVariables = {
  type: ModuleInputCreate
};


export type CreateModuleMutation = (
  { __typename?: 'Mutation' }
  & { createModule: Maybe<(
    { __typename?: 'Module' }
    & Pick<Module, 'id'>
  )> }
);

export type UpdateModuleMutationVariables = {
  id: Scalars['Int'],
  type: ModuleInputUpdate
};


export type UpdateModuleMutation = (
  { __typename?: 'Mutation' }
  & { updateModule: Maybe<(
    { __typename?: 'Module' }
    & Pick<Module, 'id'>
  )> }
);

export type GetModuleIconsQueryVariables = {};


export type GetModuleIconsQuery = (
  { __typename?: 'Query' }
  & { getModuleIcons: Array<(
    { __typename?: 'ModuleIcon' }
    & Pick<ModuleIcon, 'id' | 'svg'>
  )> }
);

export type GetOrderQueryVariables = {
  id: Scalars['Int'],
  companyId?: Maybe<Scalars['Int']>,
  customerId?: Maybe<Scalars['Int']>
};


export type GetOrderQuery = (
  { __typename?: 'Query' }
  & { getOrder: Maybe<(
    { __typename?: 'Order' }
    & Pick<Order, 'createdAt' | 'deliveryCost' | 'discount' | 'discountPrice' | 'id' | 'reference' | 'subTotalPrice' | 'totalPrice'>
    & { createdBy: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    )>, quote: Maybe<(
      { __typename?: 'Quote' }
      & Pick<Quote, 'id' | 'reorderLocation'>
    )>, customer: Maybe<(
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'name' | 'vat'>
      & { distributor: Maybe<(
        { __typename?: 'Distributor' }
        & Pick<Distributor, 'id' | 'vat' | 'name'>
      )> }
    )>, shippingAddress: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'addressLineOne' | 'addressLineTwo' | 'postalCode' | 'city' | 'country'>
    )>, orderItems: Maybe<Array<(
      { __typename?: 'OrderItem' }
      & Pick<OrderItem, 'createdAt' | 'id' | 'location' | 'quantity' | 'totalPrice' | 'unitPrice'>
      & { product: Maybe<(
        { __typename?: 'Box' }
        & { productInfo: Maybe<Array<Maybe<(
          { __typename?: 'ProductInfo' }
          & ProductInfoItemFragment
        )>>> }
        & BoxItemFragment
      ) | (
        { __typename?: 'Module' }
        & { productInfo: Maybe<Array<Maybe<(
          { __typename?: 'ProductInfo' }
          & ProductInfoItemFragment
        )>>> }
        & ModuleItemFragment
      )>, children: Maybe<Array<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'location' | 'quantity' | 'totalPrice' | 'unitPrice'>
        & { product: Maybe<{ __typename?: 'Box' } | (
          { __typename?: 'Module' }
          & { productInfo: Maybe<Array<Maybe<(
            { __typename?: 'ProductInfo' }
            & ProductInfoItemFragment
          )>>> }
          & ModuleItemFragment
        )> }
      )>> }
    )>> }
  )> }
);

export type GetPagedOrdersQueryVariables = {
  companyId?: Maybe<Scalars['Int']>,
  customerId?: Maybe<Scalars['Int']>,
  offset: Scalars['Int'],
  limit: Scalars['Int'],
  sortColumn?: Maybe<Scalars['String']>,
  sortDirection?: Maybe<SortDirection>,
  filter?: Maybe<OrderFilter>
};


export type GetPagedOrdersQuery = (
  { __typename?: 'Query' }
  & { getPagedOrders: Maybe<(
    { __typename?: 'PagedOrders' }
    & { pageInfo: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'itemCount' | 'pageCount' | 'total'>
    )>, items: Maybe<Array<(
      { __typename?: 'Order' }
      & Pick<Order, 'createdAt' | 'totalPrice' | 'id'>
      & { createdBy: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName'>
      )>, customer: Maybe<(
        { __typename?: 'Customer' }
        & Pick<Customer, 'id' | 'name'>
        & { distributor: Maybe<(
          { __typename?: 'Distributor' }
          & Pick<Distributor, 'id' | 'name'>
        )> }
      )> }
    )>> }
  )> }
);

export type ProductInfoItemFragment = (
  { __typename: 'ProductInfo' }
  & Pick<ProductInfo, 'id' | 'articleId' | 'price'>
);

export type GetProductInfoQueryVariables = {
  companyId: Scalars['Int'],
  id: Scalars['Int']
};


export type GetProductInfoQuery = (
  { __typename?: 'Query' }
  & { getProductInfo: Maybe<(
    { __typename?: 'ProductInfo' }
    & { product: Maybe<(
      { __typename: 'Box' }
      & Pick<Box, 'id' | 'name' | 'active' | 'recommendedPrice' | 'volume' | 'productCode'>
    ) | (
      { __typename: 'Module' }
      & Pick<Module, 'id' | 'nameEN' | 'productCode' | 'volume' | 'moduleSubId' | 'moduleId' | 'expirable' | 'active' | 'recommendedPrice'>
    )> }
    & ProductInfoItemFragment
  )> }
);

export type CreateProductInfoMutationVariables = {
  companyId: Scalars['Int'],
  type: ProductInfoInputCreate
};


export type CreateProductInfoMutation = (
  { __typename?: 'Mutation' }
  & { createProductInfo: Maybe<(
    { __typename?: 'ProductInfo' }
    & Pick<ProductInfo, 'id'>
  )> }
);

export type UpdateProductInfoMutationVariables = {
  id: Scalars['Int'],
  companyId: Scalars['Int'],
  type: ProductInfoInputUpdate
};


export type UpdateProductInfoMutation = (
  { __typename?: 'Mutation' }
  & { updateProductInfo: Maybe<(
    { __typename?: 'ProductInfo' }
    & Pick<ProductInfo, 'id'>
  )> }
);

export type GetQuoteQueryVariables = {
  id: Scalars['Int'],
  companyId?: Maybe<Scalars['Int']>,
  customerId?: Maybe<Scalars['Int']>
};


export type GetQuoteQuery = (
  { __typename?: 'Query' }
  & { getQuote: Maybe<(
    { __typename?: 'Quote' }
    & Pick<Quote, 'createdAt' | 'deliveryCost' | 'discount' | 'discountPrice' | 'id' | 'status' | 'reorderLocation' | 'reference' | 'subTotalPrice' | 'totalPrice'>
    & { createdBy: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    )>, shippingAddress: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'addressLineOne' | 'addressLineTwo' | 'postalCode' | 'city' | 'country'>
    )>, customer: Maybe<(
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'name' | 'vat'>
      & { addresses: Maybe<Array<Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'addressLineOne' | 'addressLineTwo' | 'postalCode' | 'city' | 'country'>
      )>>>, distributor: Maybe<(
        { __typename?: 'Distributor' }
        & Pick<Distributor, 'id' | 'vat' | 'name'>
      )> }
    )>, quoteItems: Maybe<Array<(
      { __typename?: 'QuoteItem' }
      & Pick<QuoteItem, 'createdAt' | 'id' | 'location' | 'quantity' | 'totalPrice' | 'unitPrice'>
      & { product: Maybe<(
        { __typename?: 'Box' }
        & { productInfo: Maybe<Array<Maybe<(
          { __typename?: 'ProductInfo' }
          & ProductInfoItemFragment
        )>>> }
        & BoxItemFragment
      ) | (
        { __typename?: 'Module' }
        & { productInfo: Maybe<Array<Maybe<(
          { __typename?: 'ProductInfo' }
          & ProductInfoItemFragment
        )>>> }
        & ModuleItemFragment
      )>, children: Maybe<Array<(
        { __typename?: 'QuoteItem' }
        & Pick<QuoteItem, 'id' | 'location' | 'quantity' | 'totalPrice' | 'unitPrice'>
        & { product: Maybe<{ __typename?: 'Box' } | (
          { __typename?: 'Module' }
          & { productInfo: Maybe<Array<Maybe<(
            { __typename?: 'ProductInfo' }
            & ProductInfoItemFragment
          )>>> }
          & ModuleItemFragment
        )> }
      )>> }
    )>> }
  )> }
);

export type GetPagedQuotesQueryVariables = {
  companyId?: Maybe<Scalars['Int']>,
  customerId?: Maybe<Scalars['Int']>,
  offset: Scalars['Int'],
  limit: Scalars['Int'],
  sortColumn?: Maybe<Scalars['String']>,
  sortDirection?: Maybe<SortDirection>,
  filter?: Maybe<QuoteFilter>
};


export type GetPagedQuotesQuery = (
  { __typename?: 'Query' }
  & { getPagedQuotes: Maybe<(
    { __typename?: 'PagedQuotes' }
    & { pageInfo: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'itemCount' | 'pageCount' | 'total'>
    )>, items: Maybe<Array<(
      { __typename?: 'Quote' }
      & Pick<Quote, 'createdAt' | 'totalPrice' | 'status' | 'id'>
      & { createdBy: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName'>
      )>, customer: Maybe<(
        { __typename?: 'Customer' }
        & Pick<Customer, 'id' | 'name'>
        & { distributor: Maybe<(
          { __typename?: 'Distributor' }
          & Pick<Distributor, 'id' | 'name'>
        )> }
      )> }
    )>> }
  )> }
);

export type GetConfirmedAddressesQueryVariables = {
  customerId: Scalars['Int']
};


export type GetConfirmedAddressesQuery = (
  { __typename?: 'Query' }
  & { getConfirmedAddresses: Array<(
    { __typename?: 'Address' }
    & Pick<Address, 'addressLineOne' | 'addressLineTwo' | 'postalCode' | 'city' | 'country'>
  )> }
);

export type CreateQuoteMutationVariables = {
  customerId: Scalars['Int'],
  companyId: Scalars['Int'],
  type: QuoteInputCreate
};


export type CreateQuoteMutation = (
  { __typename?: 'Mutation' }
  & { createQuote: Maybe<(
    { __typename?: 'Quote' }
    & Pick<Quote, 'id'>
  )> }
);

export type UpdateQuoteMutationVariables = {
  id: Scalars['Int'],
  companyId?: Maybe<Scalars['Int']>,
  customerId?: Maybe<Scalars['Int']>,
  type: QuoteInputUpdate
};


export type UpdateQuoteMutation = (
  { __typename?: 'Mutation' }
  & { updateQuote: Maybe<(
    { __typename?: 'Quote' }
    & Pick<Quote, 'id'>
  )> }
);

export type GetUserByExternalIdQueryVariables = {};


export type GetUserByExternalIdQuery = (
  { __typename?: 'Query' }
  & { getUserByExternalId: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { companies: Maybe<Array<Maybe<(
      { __typename: 'Distributor' }
      & Pick<Distributor, 'id' | 'email' | 'name'>
    ) | (
      { __typename: 'Customer' }
      & Pick<Customer, 'id' | 'email' | 'name'>
    )>>> }
  )> }
);

export type GetUserQueryVariables = {
  id?: Maybe<Scalars['Int']>,
  companyId?: Maybe<Scalars['Int']>
};


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { getUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
    & { companies: Maybe<Array<Maybe<(
      { __typename: 'Distributor' }
      & Pick<Distributor, 'name'>
    ) | (
      { __typename: 'Customer' }
      & Pick<Customer, 'name'>
    )>>> }
  )> }
);

export type GetPagedUsersQueryVariables = {
  offset: Scalars['Int'],
  limit: Scalars['Int'],
  sortColumn?: Maybe<Scalars['String']>,
  sortDirection?: Maybe<SortDirection>,
  companyId?: Maybe<Scalars['Int']>
};


export type GetPagedUsersQuery = (
  { __typename?: 'Query' }
  & { getPagedUsers: (
    { __typename?: 'PagedUsers' }
    & { items: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
      & { companies: Maybe<Array<Maybe<(
        { __typename: 'Distributor' }
        & Pick<Distributor, 'name'>
      ) | (
        { __typename: 'Customer' }
        & Pick<Customer, 'name'>
      )>>> }
    )>>, pageInfo: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'itemCount' | 'pageCount' | 'total'>
    )> }
  ) }
);

export type CreateUserMutationVariables = {
  companyId: Scalars['Int'],
  type: UserInputCreate
};


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )> }
);

export type UpdateUserMutationVariables = {
  id: Scalars['Int'],
  type: UserInputUpdate
};


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
  )> }
);
