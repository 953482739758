import { gql } from 'apollo-boost';

export const GET_BATCHES = gql`
  query getBatches(
    $moduleId: Int!
    $sortColumn: String
    $sortDirection: SortDirection
    $filter: BatchFilter
  ) {
    getBatches(
      moduleId: $moduleId
      sortColumn: $sortColumn
      sortDirection: $sortDirection
      filter: $filter
    ) {
      lotNumber
      expiryDate
      id
    }
  }
`;

export const CREATE_BATCH = gql`
  mutation createBatch($type: BatchInputCreate!) {
    createBatch(batch: $type) {
      id
    }
  }
`;

export const UPDATE_BATCH = gql`
  mutation updateBatch($id: Int!, $type: BatchInputUpdate!) {
    updateBatch(id: $id, batch: $type) {
      id
    }
  }
`;
