import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Box, makeStyles, createStyles, Grid, Container } from '@material-ui/core';
import classNames from 'classnames';
import ExtensionIcon from '@material-ui/icons/Extension';
import {
  GetInventoryForKitQuery,
  InventoryItem,
  Box as BoxType,
} from '../../types/graphql-generated.types';
import { inventoryMessages } from '../../messages';
import { PageHeader } from '../../components';
import { InventorySection, Header } from '../../components/inventory/large';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      paddingTop: '100px',
    },
  }),
);

interface Props {
  inventory: Exclude<GetInventoryForKitQuery['getInventoryForKit'], null>;
  reorderItems: InventoryItem[];
}

const ReorderInventory: FC<Props> = props => {
  const { inventory, reorderItems } = props;
  const classes = useStyles(props);

  const { formatMessage } = useIntl();

  return (
    <>
      <Header />
      <Container classes={{ root: classNames('container', classes.container) }}>
        <PageHeader
          title={formatMessage(inventoryMessages.reorder)}
          back={`/inventory/${inventory.uid}`}
        />
        <Box className="add-edit-form">
          <Grid container spacing={3}>
            <InventorySection
              items={reorderItems}
              icon={<ExtensionIcon />}
              title={inventory.location || ''}
              subtitle={`${inventory.uid} - ${(inventory.product as BoxType).name}`}
              type="empty"
            />
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default ReorderInventory;
