import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { Grid, Typography } from '@material-ui/core';
import { useQuery } from 'react-apollo';
import Panel from '../../Panel';
import { quoteMessages, commonMessages } from '../../../messages';
import {
  GetBoxesQuery,
  QuoteItem,
  GetBoxesQueryVariables,
} from '../../../types/graphql-generated.types';
import { GET_BOXES } from '../../../graphql/queries/box';
import Button from '../../Button';
import { formatPrice } from '../../../helpers/format';
import ConfirmDialog from '../ConfirmDialog';
import { useProductPanel, usePanelSort, useProductPanelStyles } from '../hooks';
import BoxesTable from './BoxesTable';

interface BoxesPanelProps {
  open: boolean;
  companyId: number;
  onClose: () => void;
  onConfirm: (quoteItems: QuoteItem[]) => void;
  quoteItems: QuoteItem[];
}

const BoxesPanel: FC<BoxesPanelProps> = props => {
  const { companyId, quoteItems, open, onClose, onConfirm } = props;
  const { formatMessage } = useIntl();

  const [openDialog, setOpenDialog] = useState(false);

  const originalTotalAmount = quoteItems.reduce((acc, item) => acc + (item?.quantity || 0), 0);

  const classes = useProductPanelStyles(props);

  const { column, direction, onChangeSort } = usePanelSort('box.name');
  const {
    getQuoteItems,
    updateQuoteItems,
    resetQuoteItems,
    getQuantity,
    totalAmount,
    totalPrice,
  } = useProductPanel(quoteItems);

  const { loading, data } = useQuery<GetBoxesQuery, GetBoxesQueryVariables>(GET_BOXES, {
    variables: {
      companyId,
      sortDirection: direction,
      sortColumn: column,
      filter: {
        active: { eq: [true] },
      },
    },
  });

  const onCancel = (isConfirmed?: boolean) => {
    if (isConfirmed || totalAmount === 0 || totalAmount === originalTotalAmount) {
      setOpenDialog(false);
      onClose();
      resetQuoteItems();
    } else {
      setOpenDialog(true);
    }
  };

  const onConfirmSelection = () => {
    onConfirm(getQuoteItems());
    onClose();
  };

  return (
    <>
      <Panel onClose={() => onCancel()} open={open} classes={{ root: classes.panel }}>
        <Typography className={classes.section} variant="h2">
          {formatMessage(quoteMessages.selectSuppBoxes)}
        </Typography>
        <BoxesTable
          column={column}
          direction={direction}
          onChangeSort={onChangeSort}
          onChangeAmount={updateQuoteItems}
          getQuantity={getQuantity}
          boxes={data?.getBoxes}
          loading={loading}
        />
        <Grid className={classes.total} container justify="flex-end">
          <span className={classes.totalDescription}>
            {formatMessage(quoteMessages.boxesFor, { itemCount: totalAmount })}
          </span>
          <span className={classes.totalPrice}>{formatPrice(totalPrice)}</span>
        </Grid>
        <Grid className={classes.actions} container justify="flex-end">
          <Button
            text={formatMessage(commonMessages.cancel)}
            className="button-outlined"
            onClick={() => onCancel()}
          />
          <Button
            disabled={!quoteItems.length && totalAmount < 1}
            text={formatMessage(commonMessages.confirm)}
            className="button-filled"
            onClick={onConfirmSelection}
          />
        </Grid>
      </Panel>
      <ConfirmDialog
        open={openDialog}
        onCancel={() => setOpenDialog(false)}
        onConfirm={() => onCancel(true)}
        title={formatMessage(quoteMessages.discardTitle)}
        content={formatMessage(quoteMessages.boxesDiscardContent)}
      />
    </>
  );
};

export default BoxesPanel;
