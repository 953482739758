import React, { FC, useState } from 'react';
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  useTheme,
} from '@material-ui/core';
import EditOutLinedIcon from '@material-ui/icons/EditOutlined';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { inventoryMessages, commonMessages, quoteMessages } from '../../../messages';
import { OutlinedTextField } from '../../inputs';
import Button from '../../Button';
import { useDialogStyles } from '../hooks';
import { FieldUpdate } from '../../../helpers/form';

interface Props {
  location: string;
  onClose: () => void;
  onUpdate: (location: string) => void;
}

const UpdateLocationDialog: FC<Props> = props => {
  const { onClose, onUpdate, location: prevLocation } = props;
  const classes = useDialogStyles(props);
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const iconStyle = {
    backgroundColor: theme.palette.secondary.light,
    padding: '5px',
    borderRadius: '22px',
    display: 'flex',
  };

  const [location, setLocation] = useState(prevLocation);
  const [loading, setLoading] = useState(false);

  const onChangeLocation = ({ value }: FieldUpdate) => setLocation(value as string);

  const onBlurLocation = () => setLocation(location.trim());

  const onSave = () => {
    setLoading(true);
    onUpdate(location);
  };

  return (
    <Dialog fullWidth open onClose={onClose} classes={{ paper: classes.root }}>
      <DialogTitle className={classNames(classes.paddingH, classes.paddingV)}>
        <Grid container alignItems="center">
          <Grid item className={classes.icon}>
            <EditOutLinedIcon style={iconStyle} fontSize="large" />
          </Grid>
          <Grid item xs className={classes.name}>
            <Typography align="left" variant="h3" noWrap>
              {formatMessage(inventoryMessages.editLocationTitle)}
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classNames(classes.paddingH, classes.gutter)}>
        <OutlinedTextField
          className={classes.gutter}
          name="location"
          labelText={formatMessage(quoteMessages.location)}
          value={location}
          maxLength={50}
          onChange={onChangeLocation}
          onBlur={onBlurLocation}
        />
      </DialogContent>
      <DialogActions className={classNames(classes.paddingV, classes.paddingH, classes.actions)}>
        <Button
          className="button-outlined"
          onClick={onClose}
          text={formatMessage(commonMessages.cancel)}
        />
        <Button
          className="button-filled"
          onClick={onSave}
          text={formatMessage(commonMessages.save)}
          loading={loading}
          disabled={location == null || location.trim().length === 0 || location === prevLocation}
        />
      </DialogActions>
    </Dialog>
  );
};

export default UpdateLocationDialog;
