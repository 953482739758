import React, { useRef, useState, MouseEvent, forwardRef, Ref, RefObject } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  useMediaQuery,
  useTheme,
  Drawer,
  Divider,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import CloseOutlined from '@material-ui/icons/CloseOutlined';
import { show } from 'cookie-though';
import { GetUserByExternalIdQuery, Distributor, Customer } from '../types/graphql-generated.types';
import { ReactComponent as MenuIcon } from '../assets/icons-menu.svg';
import { Auth0User } from '../providers/AuthProvider';
import { useUser, Role } from '../providers/UserProvider';
import messages from '../messages/common';
import { ReactComponent as Logo } from '../assets/modulaid-logo.svg';
import HeaderLinks from '../components/header/HeaderLinks';
import history from '../lib/history';

interface ProfileNavigationProps {
  className?: string;
  user?: Auth0User;
  companies: Exclude<GetUserByExternalIdQuery['getUserByExternalId'], null>['companies'];
  currentCompany:
    | ({ __typename: 'Distributor' } & Pick<Distributor, 'id' | 'email' | 'name'>)
    | ({ __typename: 'Customer' } & Pick<Customer, 'id' | 'email' | 'name'>)
    | null
    | undefined;
  onLogout: () => void;
  handleToggle: () => void;
  handleProfileClick: () => void;
  handlePrivacyClick: () => void;
  ref?: RefObject<HTMLUListElement>;
}

const ProfileNavigation: React.ComponentType<ProfileNavigationProps> = forwardRef(
  (
    {
      className,
      user,
      companies,
      currentCompany,
      onLogout,
      handleToggle,
      handleProfileClick,
      handlePrivacyClick,
    },
    ref?: Ref<HTMLUListElement>,
  ) => {
    const { formatMessage } = useIntl();
    const { selectCompany, role } = useUser();
    const companyToggle = () => {
      selectCompany(undefined);
      handleToggle();
    };

    const parseUserName = (givenUser: Auth0User): string => {
      const name = (givenUser.nickname || givenUser.name || '').split('@')[0];
      const nameParts = name.split('.');
      const capitalizedNameParts = nameParts.map(n => n.charAt(0).toUpperCase() + n.slice(1));
      return ` - ${capitalizedNameParts.join(' ')}`;
    };

    return (
      <ul className={className} ref={ref}>
        {user && (
          <li>
            <div
              onClick={handleProfileClick}
              onKeyDown={handleProfileClick}
              role="button"
              tabIndex={0}
            >
              <strong>{formatMessage(messages.profile)}</strong>
              {user ? parseUserName(user) : ''}
            </div>
          </li>
        )}

        {/*
        TODO: Company detail link in this menu is disabled until the
        actual page is ready.
        Note: replace dummy company name with actual company name
        <li>
          <strong>{formatMessage(messages.company)}</strong> - Vandelay Industries
        </li>
        */}
        {companies && companies.length > 1 && (
          <li>
            <div onClick={companyToggle} onKeyDown={companyToggle} role="link" tabIndex={0}>
              <strong>
                {role === Role.Distributor
                  ? formatMessage(messages.changeDistributor)
                  : formatMessage(messages.changeCustomer)}
              </strong>
              {currentCompany && currentCompany.name && `- ${currentCompany.name}`}
            </div>
          </li>
        )}
        <li>
          <div
            onClick={() => {
              show();
              handleToggle();
            }}
            onKeyDown={() => {
              show();
              handleToggle();
            }}
            role="button"
            tabIndex={0}
          >
            <strong>{formatMessage(messages.manageCookies)}</strong>
          </div>
        </li>
        <li>
          <div
            onClick={handlePrivacyClick}
            onKeyDown={handlePrivacyClick}
            role="button"
            tabIndex={0}
          >
            <strong>{formatMessage(messages.privacyAndCookiePolicy)}</strong>
          </div>
        </li>
        <li className="logout-btn">
          <div onClick={onLogout} onKeyDown={onLogout} role="button" tabIndex={0}>
            <strong>{formatMessage(messages.logout)}</strong>
          </div>
        </li>
      </ul>
    );
  },
);
interface HeaderProps {
  user?: Auth0User;
  loading: boolean;
  onLogout: () => void;
}

const Header: React.FC<HeaderProps> = ({ user, loading, onLogout }) => {
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up('md'));
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const { companies, companyId } = useUser();
  const currentCompany = companies?.find(c => c && c.id === companyId);

  const handleClose = (e: MouseEvent<Document>): void => {
    if (anchorRef.current && anchorRef!.current.contains(e.target as Node)) {
      return;
    }

    setUserMenuOpen(false);
  };

  const handleToggle = (): void => {
    setUserMenuOpen(prevOpen => !prevOpen);
  };

  const handleProfileClick = (): void => {
    history.push('/profile');
    setUserMenuOpen(false);
  };

  const handlePrivacyClick = (): void => {
    history.push('/privacy');
    setUserMenuOpen(false);
  };

  const showNav = !loading && user;
  if (!showNav) return null;

  if (!isLarge) {
    return (
      <>
        <MenuIcon
          className="mobile-menu-button"
          onClick={() => {
            setShowMobileMenu(!showMobileMenu);
          }}
        />
        <Drawer open={showMobileMenu} onClose={() => setShowMobileMenu(false)}>
          <div className="header-drawer">
            <div className="header-drawer-top">
              <Logo className="header-logo" />
              <CloseOutlined
                className="mobile-menu-close-icon"
                onClick={() => {
                  setShowMobileMenu(false);
                }}
              />
              <HeaderLinks
                onClick={() => {
                  setShowMobileMenu(false);
                }}
              />
            </div>

            <div className="header-drawer-bottom">
              <Divider />
              <ProfileNavigation
                user={user}
                companies={companies}
                onLogout={onLogout}
                currentCompany={currentCompany}
                handleToggle={handleToggle}
                handleProfileClick={handleProfileClick}
                handlePrivacyClick={handlePrivacyClick}
              />
            </div>
          </div>
        </Drawer>
      </>
    );
  }

  return (
    <>
      <header className="main-header" ref={anchorRef}>
        <div className="main-header-wrapper">
          <div className="header-left">
            <NavLink to="/" className="header-logo-link">
              <Logo className="header-logo" />
            </NavLink>
            <HeaderLinks />
          </div>
          <div className="header-right">
            <hr className="divider" />
            <div
              className="user"
              onClick={handleToggle}
              onKeyPress={handleToggle}
              aria-haspopup="true"
              aria-controls={userMenuOpen ? 'menu-list-grow' : undefined}
              role="button"
              tabIndex={0}
            >
              <span className="avatar">
                {user?.given_name
                  ? user.given_name!.charAt(0)
                  : user?.nickname?.charAt(0).toUpperCase()}
              </span>
            </div>
            <Popper
              open={userMenuOpen}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal={false}
              placement="top-end"
            >
              {({ TransitionProps }) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <Grow {...TransitionProps} style={{ transformOrigin: 'bottom-end' }}>
                  <Paper id="menu-popper" classes={{ root: 'popper', elevation1: 'popper-shadow' }}>
                    <ClickAwayListener onClickAway={handleClose}>
                      <ProfileNavigation
                        className="popper-list"
                        user={user}
                        companies={companies}
                        currentCompany={currentCompany}
                        onLogout={onLogout}
                        handleToggle={handleToggle}
                        handleProfileClick={handleProfileClick}
                        handlePrivacyClick={handlePrivacyClick}
                      />
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
