import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useIntl } from 'react-intl';
import { Box } from '@material-ui/core';
import { PageHeader, Pagination, Loadable, OverviewSearch } from '../../components';
import { useOptions, usePageView, useImpersonator } from '../../hooks';
import { setSortableQuery } from '../../helpers/query';
import {
  quoteMessages,
  commonMessages,
  labelMessages,
  inventoryMessages,
  productMessages,
} from '../../messages';
import {
  GetPagedInventoriesQuery,
  SortDirection,
  GetPagedInventoriesQueryVariables,
} from '../../types/graphql-generated.types';
import { useUser, Role } from '../../providers/UserProvider';
import { TableHeader, TableCell, TableRow, EmptyRow } from '../../components/table';
import { getNextDirection } from '../../helpers/sort';
import history from '../../lib/history';
import {
  GET_PAGED_INVENTORIES,
  createSearch,
  createLabelsFilter,
} from '../../graphql/queries/inventory';
import { ProductIconList } from '../../components/products';
import { getModulesFromInventory } from '../../helpers/inventory';
import { LabelsFilter } from '../../components/inventory';
import { ALL_ID } from '../../components/inventory/LabelsFilter';
import { formatDate } from '../../helpers/date';

const Inventories: React.FC = () => {
  usePageView('inventory_overview');

  const { formatMessage } = useIntl();

  const { isImpersonating, getFakeVariables, fakeCompanyName, isRole } = useImpersonator();
  const { whenRole, getContextVariables } = useUser();

  const isAdmin = isRole(Role.Admin);
  const isDistributor = isRole(Role.Distributor);
  const isCustomer = isRole(Role.Customer);

  const { options, changeParams, resetPagination } = useOptions({
    page: 0,
    offset: 0,
    pageCount: 1,
    limit: 20,
    sortColumn: 'inventory_item.location',
    sortDirection: SortDirection.Asc,
  });

  const { pageCount, page, ...rest } = options;

  const [search, setSearch] = useState<string | undefined>();
  const [labels, setLabels] = useState<number[]>([ALL_ID]);

  const labelsFilter = labels[0] === ALL_ID ? {} : createLabelsFilter(labels);
  const searchFilter = search ? createSearch(isRole, search) : {};

  const { loading, data } = useQuery<GetPagedInventoriesQuery, GetPagedInventoriesQueryVariables>(
    GET_PAGED_INVENTORIES,
    {
      variables: {
        ...(isImpersonating ? getFakeVariables() : getContextVariables()),
        ...rest,
        filter: {
          ...searchFilter,
          ...labelsFilter,
        },
      },
      onCompleted(result) {
        if (result?.getPagedInventories?.pageInfo?.pageCount) {
          changeParams('pageCount', result.getPagedInventories.pageInfo.pageCount);
        }
      },
    },
  );

  const onChangeSort = (key: string) => {
    setSortableQuery(key, getNextDirection(key, options.sortColumn, options.sortDirection));
  };

  const onSearch = (value: string | undefined) => {
    resetPagination();
    setSearch(value);
  };

  const isEmpty = !data?.getPagedInventories?.items?.length;

  return (
    <div>
      <PageHeader
        title={`${formatMessage(inventoryMessages.inventory)}${
          isImpersonating ? ` (${fakeCompanyName})` : ''
        }`}
      >
        <OverviewSearch onSearch={onSearch} onClear={() => onSearch(undefined)} />
      </PageHeader>
      {isCustomer && <LabelsFilter selected={labels} onUpdateSelected={setLabels} />}
      <Box>
        <TableHeader
          sortOptions={{
            column: options.sortColumn,
            direction: options.sortDirection,
            onChange: onChangeSort,
          }}
        >
          {({ Sortable }) => (
            <>
              {isAdmin && (
                <TableCell xs={2}>
                  <Sortable name="distributor.name">
                    {formatMessage(labelMessages.distributor)}
                  </Sortable>
                </TableCell>
              )}
              {(isDistributor || isAdmin) && (
                <TableCell
                  xs={whenRole({
                    [Role.Default]: 2,
                    [Role.Distributor]: 3,
                  })}
                >
                  <Sortable name="customer.name">{formatMessage(commonMessages.customer)}</Sortable>
                </TableCell>
              )}
              <TableCell xs={3}>
                <Sortable name="inventory_item.location">
                  {formatMessage(quoteMessages.location)}
                </Sortable>
              </TableCell>
              <TableCell
                xs={whenRole({
                  [Role.Default]: 2,
                  [Role.Distributor]: 2,
                  [Role.Customer]: 3,
                })}
              >
                {formatMessage(inventoryMessages.lastActivity)}
              </TableCell>
              <TableCell
                xs={whenRole({
                  [Role.Default]: 3,
                  [Role.Distributor]: 4,
                  [Role.Customer]: 6,
                })}
              >
                {formatMessage(productMessages.modules)}
              </TableCell>
            </>
          )}
        </TableHeader>
        <Loadable loading={loading}>
          {isEmpty ? (
            <EmptyRow />
          ) : (
            data?.getPagedInventories?.items?.map(inventory => (
              <TableRow
                key={inventory.id!}
                onClick={() => history.push(`/inventory/${inventory.uid}`)}
              >
                {isAdmin && <TableCell body xs={2} label={inventory.customer?.distributor?.name} />}
                {(isAdmin || isDistributor) && (
                  <TableCell
                    body
                    xs={whenRole({
                      [Role.Default]: 2,
                      [Role.Distributor]: 3,
                    })}
                    label={inventory.customer?.name}
                  />
                )}
                <TableCell body primary xs={3} label={inventory.location} />
                <TableCell
                  body
                  xs={whenRole({
                    [Role.Default]: 2,
                    [Role.Distributor]: 2,
                    [Role.Customer]: 3,
                  })}
                  label={formatDate(inventory.lastKitUpdate || inventory.createdAt)}
                />
                <TableCell
                  body
                  xs={whenRole({
                    [Role.Default]: 3,
                    [Role.Distributor]: 4,
                    [Role.Customer]: 6,
                  })}
                >
                  <ProductIconList modules={getModulesFromInventory(inventory)} />
                </TableCell>
              </TableRow>
            ))
          )}

          <Pagination options={options} />
        </Loadable>
      </Box>
    </div>
  );
};

export default Inventories;
