import React, { FC } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import classNames from 'classnames';
import { TableCell } from '../table';
import { Module } from '../../types/graphql-generated.types';
import ProductIcon from './ProductIcon';

interface ProductIdCellProps {
  module: Module;
}

export const createProductIdCellStyles = (theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: '20px',
      minWidth: '150px',
      maxWidth: '14%',
      flexBasis: '14%',
      display: 'flex',

      '& > span': {
        display: 'flex',
      },

      '& > span:first-of-type': {
        marginRight: '10px',

        '&.selected': {
          marginRight: '0',
        },
      },
    },
    body: {
      justifyContent: 'space-between',

      '& > span:first-of-type': {
        color: theme.palette.text.primary,
        fontWeight: 600,
        marginRight: 0,
      },

      '& > span:first-of-type, > span:nth-of-type(2)': {
        display: 'inline-block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  });

const useStyles = makeStyles(theme => createProductIdCellStyles(theme));

const ProductIdCell: FC<ProductIdCellProps> = props => {
  const { module } = props;
  const classes = useStyles(props);

  const className = classNames(classes.root, classes.body);

  return (
    <TableCell ellipsis={false} body classes={{ root: className }} xs={3}>
      <span>{module?.moduleId}</span>
      <span>{module?.moduleSubId}</span>
      <ProductIcon rgb={module?.rgb} svg={module?.icon?.svg} />
    </TableCell>
  );
};

export default ProductIdCell;
