import React, { ChangeEvent } from 'react';
import { Select } from '@material-ui/core';
import Field from '../form/Field';
import { useLabel } from '../../hooks';
import { InputProps } from '../../types/form.types';
import { getUpdate } from '../../helpers/form';

type SelectValue = string | number | boolean | Record<string, any> | null | undefined;

const OutlinedSelectField: React.FC<Omit<InputProps, 'value'> & { value: SelectValue }> = ({
  onChange = () => {},
  ...props
}) => {
  const { labelWidth, labelRef } = useLabel();
  return (
    <Field className="outlined-select-container">
      {({ Label, Error, HelperText }) => (
        <>
          {props.labelText && (
            <Label error={!!props.error} label={props.labelText} labelRef={labelRef} />
          )}
          <Select
            error={!!props.error}
            native
            labelWidth={labelWidth}
            value={props.value != null ? props.value : ''}
            onChange={e => onChange(getUpdate(e as ChangeEvent<HTMLInputElement>))}
            name={props.name}
            disabled={props.distributorDisabled || props.disabled}
          >
            {props.children}
          </Select>
          {props.error && <Error errors={props.error} />}
          {props.helperText && <HelperText text={props.helperText} />}
        </>
      )}
    </Field>
  );
};

export default OutlinedSelectField;
