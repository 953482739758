/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, useRef, RefObject } from 'react';
import { OutlinedInput, CircularProgress } from '@material-ui/core';
import { Autocomplete, createFilterOptions, RenderInputParams } from '@material-ui/lab';
import ClearIcon from '@material-ui/icons/Clear';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import Field from '../form/Field';
import { useLabel } from '../../hooks';
import { InputProps } from '../../types/form.types';
import { FieldUpdate } from '../../helpers/form';

export type Option = Record<string, any>;

interface Props {
  value: Option[];
  loading: boolean;
  noOptionsLabel: string;
  optionKey: string;
  options: Option[];
  onChange: (update: FieldUpdate, inputRef: RefObject<HTMLInputElement>) => void;
}

type InputPropsWithRef = RenderInputParams['inputProps'] & { ref: RefObject<HTMLInputElement> };

const filterOptions = createFilterOptions<Option>({ trim: true });

const AutocompleteTagField: FC<Omit<InputProps, 'value' | 'onChange'> & Props> = props => {
  const {
    value,
    disabled,
    loading,
    name,
    optionKey,
    options,
    noOptionsLabel,
    onChange = () => {},
  } = props;

  const { labelWidth, labelRef } = useLabel();
  const inputRef = useRef<HTMLInputElement | null>(null);

  return (
    <Field>
      {({ Label, Error, HelperText }) => (
        <>
          {props.labelText && (
            <Label labelRef={labelRef} label={props.labelText} error={!!props.error} />
          )}
          <Autocomplete
            freeSolo
            multiple
            fullWidth
            disableClearable
            autoHighlight
            filterSelectedOptions
            disabled={disabled}
            loading={loading}
            value={value}
            id="autocomplete-tag-field"
            filterOptions={filterOptions}
            options={options}
            noOptionsText={noOptionsLabel}
            getOptionSelected={(option, val) => option[optionKey] === val[optionKey]}
            getOptionLabel={option => option[optionKey]}
            onChange={(event, val, reason) => {
              let updated = val;

              if (reason === 'create-option') {
                // Split tags (Option[]) and newly created option (string)
                const tags = val.slice(0, val.length - 1);
                const option = val[val.length - 1].trim();

                // Disallow an empty string or an already existing tag
                if (option === '' || tags.find(t => t[optionKey] === option)) {
                  return;
                }

                // Transform new string to Option and add to tags
                updated = tags.concat({ [optionKey]: option });
              }

              // Prevent default to stop the page from navigating Back in Firefox
              if (reason === 'remove-option') {
                event.preventDefault();
              }

              onChange({ path: name, value: updated }, inputRef);
            }}
            ChipProps={{
              variant: 'outlined',
              color: 'secondary',
              deleteIcon: <ClearIcon />,
            }}
            renderInput={params => {
              const { ref } = params.inputProps as InputPropsWithRef;
              if (ref.current && !inputRef.current) {
                inputRef.current = ref.current;
              }

              return (
                <OutlinedInput
                  {...params.InputProps}
                  fullWidth
                  disabled={disabled}
                  name={name}
                  inputProps={params.inputProps}
                  labelWidth={labelWidth}
                  endAdornment={
                    <>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  }
                />
              );
            }}
            renderOption={(option, { inputValue }) => {
              const matches = match(option[optionKey], inputValue);
              const parts = parse(option[optionKey], matches);

              return (
                <div>
                  {parts.map((part, index) => (
                    <span
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      style={{
                        textDecoration: part.highlight ? 'underline' : 'none',
                        fontWeight: part.highlight ? 600 : 400,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                </div>
              );
            }}
          />
          {props.error && <Error errors={props.error} />}
          {props.helperText && <HelperText text={props.helperText} />}
        </>
      )}
    </Field>
  );
};

export default AutocompleteTagField;
