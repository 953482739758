import React from 'react';
import { Grid } from '@material-ui/core';
import { useMutation, useQuery } from 'react-apollo';
import { useParams } from 'react-router';
import PageHeader from '../../../components/PageHeader';
import FormActions from '../../../components/FormActions';
import { usePageView, useForm } from '../../../hooks';
import { Details, Translations, Sticker } from '../../../components/products/modules/sections';
import { UPDATE_MODULE, GET_MODULE, GET_MODULE_ICONS } from '../../../graphql/queries/module';
import {
  GetModuleIconsQuery,
  GetModuleQuery,
  ModuleInputUpdate,
} from '../../../types/graphql-generated.types';
import { getModuleFields } from '../../../constants/form-constants';
import { BatchesOverview } from './batches';
import history from '../../../lib/history';
import { Loadable } from '../../../components';

const initialFields = getModuleFields();

const EditModule = () => {
  usePageView('product_module_detail');

  const { id } = useParams();
  const moduleId = parseInt(id!, 10);

  const [update, { loading: isSaving }] = useMutation(UPDATE_MODULE);
  const { fields, handleChange, handleCreateUpdate, errors, disabled, updateFields } = useForm<
    ModuleInputUpdate
  >(update, initialFields, false, moduleId);

  const { data: iconData } = useQuery<GetModuleIconsQuery>(GET_MODULE_ICONS);

  const { loading } = useQuery<GetModuleQuery>(GET_MODULE, {
    variables: {
      id: moduleId,
    },
    onCompleted(result) {
      if (result.getModule) {
        const { id: _, ...module } = result.getModule as ModuleInputUpdate & { id: number };
        module.icon = result.getModule.icon?.id;
        updateFields(module);
      } else {
        history.push('/products/modules');
      }
    },
  });

  return (
    <Loadable loading={loading}>
      <PageHeader title={fields.nameEN || ''} back="/products" />
      <div className="add-edit-form module-form">
        <Grid container spacing={3}>
          <Details fields={fields} errors={errors} onChange={handleChange} />
          <Translations fields={fields} errors={errors} onChange={handleChange} />
          <Sticker
            icons={iconData?.getModuleIcons}
            fields={fields}
            errors={errors}
            onChange={handleChange}
          />
        </Grid>
      </div>
      <BatchesOverview isExpirable={!!fields.expirable} />
      <FormActions
        onCreateUpdate={() => handleCreateUpdate('/products')}
        disabled={disabled}
        loading={isSaving}
      />
    </Loadable>
  );
};

export default EditModule;
