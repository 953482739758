import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import AssignmentIcon from '@material-ui/icons/AssignmentOutlined';
import { Grid } from '@material-ui/core';
import { commonMessages, quoteMessages } from '../../../messages';
import { OutlinedTextField } from '../../inputs';

interface GeneralQuoteSectionProps {
  customer?: string;
  createdBy?: string;
  createdAt?: string;
}

const General: FC<GeneralQuoteSectionProps> = ({ customer, createdBy, createdAt }) => {
  const { formatMessage } = useIntl();

  return (
    <Grid item xs={12}>
      <div className="form-section">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className="form-section-title">
              <div className="section-icon">
                <AssignmentIcon />
              </div>
              <p>{formatMessage(commonMessages.general)}</p>
            </div>
          </Grid>
          {createdBy && (
            <Grid item xs={6}>
              <OutlinedTextField
                disabled
                name="createdBy"
                labelText={formatMessage(quoteMessages.createdBy)}
                value={createdBy}
              />
            </Grid>
          )}
          {createdAt && (
            <Grid item xs={6}>
              <OutlinedTextField
                disabled
                name="createdAt"
                labelText={formatMessage(quoteMessages.createdAt)}
                value={createdAt}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <OutlinedTextField
              disabled
              name="customer"
              labelText={formatMessage(commonMessages.customer)}
              value={customer}
            />
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

export default General;
