import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Tabs,
  Tab,
  Box,
  makeStyles,
  createStyles,
  Typography,
  Grid,
  useTheme,
  useMediaQuery,
  ButtonBase,
} from '@material-ui/core';
import { useMutation } from 'react-apollo';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { ApolloQueryResult } from 'apollo-boost';
import { ReactComponent as Logo } from '../../assets/modulaid-logo.svg';
import {
  GetInventoryForKitQuery,
  Box as BoxType,
  InventoryItemStatus,
  InventoryItem,
  UpdateKitMutation,
  UpdateKitMutationVariables,
  InventoryItemUpdate,
} from '../../types/graphql-generated.types';
import { inventoryMessages } from '../../messages';
import { getStatus, sortInventoryItemsFn } from '../../helpers/inventory';
import {
  InventorySection,
  UpdateBatchDialog,
  UseItemDialog,
  ConfirmUseItemDialog,
  RestockItemDialog,
  UpdateLocationDialog,
  LabelsInput,
} from '../../components/inventory';
import OverviewLarge from './OverviewLarge';
import History from './History';
import { BackToOverview, Button } from '../../components';
import history from '../../lib/history';
import { UPDATE_KIT } from '../../graphql/queries/inventory';
import { useAuth0 } from '../../providers/AuthProvider';
import VerifyInventoryDialog from '../../components/inventory/dialogs/VerifyInventoryDialog';

const useStyles = makeStyles(theme =>
  createStyles({
    header: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '26px',
    },
    logo: {
      margin: '0 20px 0 12px',
      width: '32px',
      height: '32px',
    },
    headerText: {
      fontSize: '20px',
    },
    kit: {
      display: 'flex',
      flexDirection: 'column',
      padding: '9px 12px 16px',
      ...theme.mixins.card(),
    },
    kitHeader: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(4),
    },
    kitHeaderLogo: {
      marginRight: '14px',
      backgroundColor: theme.palette.secondary.light,
      padding: '8px 9px',
      borderRadius: '22px',
      display: 'flex',

      '& path': {
        fill: theme.palette.secondary.dark,
      },
    },
    kitHeaderSubtitle: {
      color: theme.palette.text.secondary,
      fontSize: '12px',
      marginTop: '2px',
      paddingLeft: '1px',
      fontWeight: 600,
    },
    kitLocation: {
      display: 'inline-flex',
      wordBreak: 'break-all',
    },
    kitLocationEdit: {
      fill: theme.palette.secondary.dark,
      marginLeft: theme.spacing(1),
      lineHeight: 1.2,
    },
    statusTitle: {
      marginBottom: '8px',
    },
    tabs: {
      marginTop: '16px',
      marginBottom: '24px',
    },
    tabsBorder: {
      borderBottom: `solid 1px ${theme.palette.divider}`,
    },
    itemContainer: {
      marginBottom: '8px',
    },
    divider: {
      margin: '8px 0 16px 0',
      color: theme.palette.divider,
    },
    item: {
      width: 'calc(100% - 8px)',
    },
    reorderButton: {
      marginTop: theme.spacing(1),
    },
  }),
);

interface InventoryOverviewProps {
  inventory: Exclude<GetInventoryForKitQuery['getInventoryForKit'], null>;
  refetch: () => Promise<ApolloQueryResult<GetInventoryForKitQuery>>;
}

const InventoryOverview: FC<InventoryOverviewProps> = props => {
  const { isAuthenticated } = useAuth0();
  const { inventory, refetch } = props;
  const classes = useStyles(props);
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up('md'));

  const { formatMessage } = useIntl();

  const [updateKit, { loading }] = useMutation<UpdateKitMutation, UpdateKitMutationVariables>(
    UPDATE_KIT,
  );

  const [tab, setTab] = useState<'contents' | 'history'>('contents');
  const [historyPage, setHistoryPage] = useState(0);

  // Modals
  const [updateBatchItem, setUpdateBatchItem] = useState<InventoryItem | undefined>();
  const [useItem, setUseItem] = useState<InventoryItem | undefined>();
  const [confirmUseItem, setConfirmUseItem] = useState<InventoryItem | undefined>();
  const [restockItem, setRestockItem] = useState<InventoryItem | undefined>();
  const [location, setLocation] = useState<string | undefined>();
  const [verifyInventory, setVerifyInventory] = useState(false);

  const [available, usedOrExpired, ordered] = (inventory.children || [])
    .sort(sortInventoryItemsFn)
    .reduce<[InventoryItem[], InventoryItem[], InventoryItem[]]>(
      (acc, item) => {
        switch (getStatus(item)) {
          case InventoryItemStatus.Available:
            return [[...acc[0], item], acc[1], acc[2]];
          case InventoryItemStatus.Used:
            return [acc[0], [...acc[1], item], acc[2]];
          case InventoryItemStatus.Reordered:
            return [acc[0], acc[1], [...acc[2], item]];
          default:
            return acc;
        }
      },
      [[], [], []],
    );

  const onClickItem = (item: InventoryItem) => {
    if (item.batch) {
      setUseItem(item);
    } else {
      setUpdateBatchItem(item);
    }
  };

  const onReorder = () => history.push(`/inventory/${inventory.uid}/reorder`);

  const onUpdateKit = async (update: InventoryItemUpdate): Promise<boolean> => {
    let error;

    try {
      await updateKit({
        variables: {
          id: inventory.id!,
          inventoryItem: update,
        },
      });
    } catch (err) {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.error('Error updating kit: ', err);
      }
      error = err;
    } finally {
      refetch();
    }

    return !!error;
  };

  const onChangeTab = (newTab: 'contents' | 'history') => {
    setHistoryPage(0);
    setTab(newTab);
  };

  return (
    <>
      {isLarge ? (
        <OverviewLarge
          inventory={inventory}
          available={available}
          usedOrExpired={usedOrExpired}
          ordered={ordered}
          setUpdateBatchItem={setUpdateBatchItem}
          setUseItem={setUseItem}
          setConfirmUseItem={setConfirmUseItem}
          setRestockItem={setRestockItem}
          setVerifyInventory={setVerifyInventory}
          onReorder={onReorder}
          onUpdateKit={onUpdateKit}
          isSavingLabels={loading}
          tab={tab}
          setTab={onChangeTab}
          page={historyPage}
          setPage={setHistoryPage}
        />
      ) : (
        <>
          <Box className={classes.header}>
            <Logo className={classes.logo} />
            <Typography className={classes.headerText} variant="h1">
              ModulAid
            </Typography>
          </Box>
          {isAuthenticated && <BackToOverview back="/inventory" />}
          <Box className={classes.kit}>
            <Box className={classes.kitHeader}>
              <Box className={classes.kitHeaderLogo}>
                <WorkOutlineIcon />
              </Box>
              <Box>
                <Typography className={classes.kitLocation} variant="h3">
                  {inventory.location}
                  {isAuthenticated && (
                    <ButtonBase
                      disableRipple
                      component="div"
                      onClick={() => {
                        if (inventory.location) {
                          setLocation(inventory.location);
                        }
                      }}
                    >
                      <EditOutlinedIcon className={classes.kitLocationEdit} />
                    </ButtonBase>
                  )}
                </Typography>

                <Typography className={classes.kitHeaderSubtitle}>{`${inventory.uid} - ${
                  (inventory.product as BoxType).name
                }`}</Typography>
              </Box>
            </Box>
            <Box>
              <LabelsInput
                onChange={l => onUpdateKit({ labels: { replace: l } })}
                disabled={!isAuthenticated || loading}
                customerId={inventory.customer?.id}
                labels={inventory.labels || []}
                isSaving={loading}
              />
            </Box>
            <Box className={classes.tabs}>
              {isAuthenticated && (
                <Tabs
                  classes={{ root: classes.tabsBorder }}
                  variant="fullWidth"
                  value={tab}
                  onChange={(_, value) => onChangeTab(value)}
                  indicatorColor="primary"
                  textColor="secondary"
                >
                  <Tab value="contents" label={formatMessage(inventoryMessages.contents)} />
                  <Tab value="history" label={formatMessage(inventoryMessages.history)} />
                </Tabs>
              )}
            </Box>
            {tab === 'contents' ? (
              <>
                <InventorySection
                  divider={false}
                  items={available}
                  title={formatMessage(inventoryMessages.statusAvailable)}
                  onClick={onClickItem}
                />
                <Grid className={classes.reorderButton} container justify="flex-end">
                  <Grid item>
                    <Button
                      className="button-filled"
                      onClick={() => setVerifyInventory(true)}
                      text={formatMessage(inventoryMessages.verifyInventory)}
                    />
                  </Grid>
                </Grid>
                {!!usedOrExpired.length && (
                  <>
                    <InventorySection
                      items={usedOrExpired}
                      title={formatMessage(inventoryMessages.statusUsed)}
                      onClick={item => setRestockItem(item)}
                    />
                    <Grid className={classes.reorderButton} container justify="flex-end">
                      <Grid item>
                        <Button
                          className="button-filled"
                          onClick={onReorder}
                          text={formatMessage(inventoryMessages.reorder)}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
                {!!ordered.length && (
                  <InventorySection
                    items={ordered}
                    title={formatMessage(inventoryMessages.statusOrdered)}
                    ordered
                    onClick={item => setRestockItem(item)}
                  />
                )}
              </>
            ) : (
              <History
                page={historyPage}
                setPage={setHistoryPage}
                id={inventory.id!}
                customerId={inventory.customer?.id}
              />
            )}
          </Box>
        </>
      )}
      {!!updateBatchItem && (
        <UpdateBatchDialog
          item={updateBatchItem}
          onClose={() => setUpdateBatchItem(undefined)}
          onSuccess={item => {
            setUpdateBatchItem(undefined);
            setUseItem(item);
          }}
        />
      )}
      {!!useItem && (
        <UseItemDialog
          item={useItem}
          onClose={() => setUseItem(undefined)}
          onUse={item => {
            setUseItem(undefined);
            setConfirmUseItem(item);
          }}
        />
      )}
      {!!confirmUseItem && (
        <ConfirmUseItemDialog
          item={confirmUseItem}
          onClose={() => setConfirmUseItem(undefined)}
          onSuccess={() => {
            setConfirmUseItem(undefined);
            refetch();
          }}
        />
      )}
      {!!restockItem && (
        <RestockItemDialog
          item={restockItem}
          onClose={() => setRestockItem(undefined)}
          onSuccess={() => {
            setRestockItem(undefined);
            refetch();
          }}
        />
      )}
      {!!verifyInventory && (
        <VerifyInventoryDialog
          inventoryId={inventory.id!}
          onClose={() => setVerifyInventory(false)}
          onSuccess={() => {
            setVerifyInventory(false);
            refetch();
          }}
        />
      )}

      {!!location && (
        <UpdateLocationDialog
          location={location}
          onClose={() => setLocation(undefined)}
          onUpdate={async (loc: string) => {
            await onUpdateKit({ location: loc });
            setLocation(undefined);
            refetch();
          }}
        />
      )}
    </>
  );
};

export default InventoryOverview;
