import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { Grid } from '@material-ui/core';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { quoteMessages } from '../../../messages';
import { OutlinedNumberField } from '../../inputs';
import { QuoteInputCreate } from '../../../types/graphql-generated.types';
import { SectionProps, Constraint } from '../../../types/form.types';
import { DEFAULT_PRICE_MASK, DEFAULT_PERCENTAGE_MASK } from '../../../constants/number-mask';

const priceMask = createNumberMask({ ...DEFAULT_PRICE_MASK });
const percentMask = createNumberMask({ ...DEFAULT_PERCENTAGE_MASK });

type DeliveryDiscountProps = Partial<SectionProps<Partial<QuoteInputCreate>>> & {
  disable?: boolean;
};

const DeliveryDiscount: FC<DeliveryDiscountProps> = ({ disable, fields, errors, onChange }) => {
  const { formatMessage } = useIntl();

  return (
    <Grid item xs={12}>
      <div className="form-section">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className="form-section-title">
              <div className="section-icon">
                <LocalShippingIcon />
              </div>
              <p>{formatMessage(quoteMessages.deliveryAndDiscount)}</p>
            </div>
          </Grid>
          <Grid item xs={12}>
            <OutlinedNumberField
              disabled={disable}
              name="discount"
              labelText={formatMessage(quoteMessages.discount)}
              endAdornment="%"
              value={fields?.discount}
              mask={percentMask}
              onChange={onChange}
              error={errors?.discount as Constraint}
            />
          </Grid>
          <Grid item xs={12}>
            <OutlinedNumberField
              disabled={disable}
              name="deliveryCost"
              labelText={formatMessage(quoteMessages.deliveryCost)}
              endAdornment="€"
              value={fields?.deliveryCost}
              mask={priceMask}
              onChange={onChange}
              error={errors?.deliveryCost as Constraint}
            />
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

export default DeliveryDiscount;
