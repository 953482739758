import { gql } from 'apollo-boost';
import { moduleFragments } from './module';
import { boxFragments } from './box';
import { productInfoFragments } from './product-info';
import { OrderFilter } from '../../types/graphql-generated.types';
import { Role } from '../../providers/UserProvider';

export const createSearch = (isRole: (role: Role) => boolean, value: string): OrderFilter => {
  const filters: OrderFilter[] = [{ createdBy: { firstName_lastName: { contains: [value] } } }];

  if (isRole(Role.Admin) || isRole(Role.Distributor)) {
    filters.push({ customer: { name: { contains: [value] } } });
  }

  if (isRole(Role.Admin)) {
    filters.push({ distributor: { name: { contains: [value] } } });
  }

  return { OR: filters };
};

export const GET_ORDER = gql`
  query getOrder($id: Int!, $companyId: Int, $customerId: Int) {
    getOrder(id: $id, companyId: $companyId, customerId: $customerId) {
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
      deliveryCost
      discount
      discountPrice
      id
      quote {
        id
        reorderLocation
      }
      customer {
        id
        name
        vat
        distributor {
          id
          vat
          name
        }
      }
      shippingAddress {
        addressLineOne
        addressLineTwo
        postalCode
        city
        country
      }
      reference
      subTotalPrice
      totalPrice
      orderItems {
        createdAt
        id
        location
        product {
          ... on Box {
            ...BoxItem
            productInfo {
              ...ProductInfoItem
            }
          }
          ... on Module {
            ...ModuleItem
            productInfo {
              ...ProductInfoItem
            }
          }
        }
        children {
          id
          location
          product {
            ... on Module {
              ...ModuleItem
              productInfo {
                ...ProductInfoItem
              }
            }
          }
          quantity
          totalPrice
          unitPrice
        }
        quantity
        totalPrice
        unitPrice
      }
    }
  }
  ${moduleFragments.Item}
  ${boxFragments.Item}
  ${productInfoFragments.Item}
`;

export const GET_PAGED_ORDERS = gql`
  query getPagedOrders(
    $companyId: Int
    $customerId: Int
    $offset: Int!
    $limit: Int!
    $sortColumn: String
    $sortDirection: SortDirection
    $filter: OrderFilter
  ) {
    getPagedOrders(
      limit: $limit
      offset: $offset
      companyId: $companyId
      customerId: $customerId
      sortColumn: $sortColumn
      sortDirection: $sortDirection
      filter: $filter
    ) {
      pageInfo {
        itemCount
        pageCount
        total
      }
      items {
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
        customer {
          id
          name
          distributor {
            id
            name
          }
        }
        totalPrice
        id
      }
    }
  }
`;
