import firebase from 'firebase/app';
import { CookiePreferences } from 'cookie-though/dist/types/types.d';
import history from '../lib/history';
import firebaseConfig from '../constants/firebase';
import 'firebase/analytics';

export const initializeFirebase = () => firebase.initializeApp(firebaseConfig);
let isFirebaseAnalyticsEnabled = false;

export const setPageView = (title: string) => {
  history.listen(({ pathname }) => {
    if (!isFirebaseAnalyticsEnabled) {
      return;
    }

    firebase.analytics().setCurrentScreen(pathname);
    firebase.analytics().logEvent('page_view', {
      page_path: pathname,
      page_title: title,
    });
  });
};

export const isAnalyticsEnabled = ({ cookieOptions }: CookiePreferences) => {
  const { isEnabled: isAnalyticsCookieEnabled } = cookieOptions.find(
    ({ id }) => id === 'analytics',
  )!;
  const isProductionEnvironment = process.env.REACT_APP_SENTRY_ENV === 'production';

  return isAnalyticsCookieEnabled && isProductionEnvironment;
};

export const enableAnalytics = () => {
  isFirebaseAnalyticsEnabled = true;
  firebase.analytics();
  firebase.analytics().setAnalyticsCollectionEnabled(isFirebaseAnalyticsEnabled);
};

export const disableAnalytics = () => {
  isFirebaseAnalyticsEnabled = false;
  firebase.analytics().setAnalyticsCollectionEnabled(isFirebaseAnalyticsEnabled);
};
