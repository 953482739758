import React, { FC, ReactNode } from 'react';
import { Switch, Route, RouteComponentProps, Redirect, Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import { Tabs, Tab } from '@material-ui/core';
import get from 'get-value';
import { ModulesOverview } from './modules';
import { BoxesOverview } from './boxes';
import { PageHeader, Button } from '../../components';
import commonMessages from '../../messages/common';
import messages from '../../messages/products';
import history from '../../lib/history';
import { useUser } from '../../providers/UserProvider';
import { takeKeys } from '../../helpers/query';
import { IMPERSONATOR_KEYS } from '../../hooks/impersonator';

interface ProductsProps extends RouteComponentProps {
  children?: ReactNode;
}

const productTabs = {
  modules: {
    value: 'modules',
    label: messages.modules,
    addLabel: messages.addModule,
  },
  boxes: {
    value: 'boxes',
    label: messages.boxes,
    addLabel: messages.addBox,
  },
};

const Products: FC<ProductsProps> = props => {
  const {
    match: { url },
    location,
  } = props;

  const { formatMessage } = useIntl();
  const { isAdmin } = useUser();

  const selectedTab = location.pathname.split('/').pop() || productTabs.modules.value;

  const getAddButton = (): ReactNode => {
    if (!get(productTabs, selectedTab) || !isAdmin) {
      return null;
    }

    const { value, addLabel } = productTabs[selectedTab as keyof typeof productTabs];

    return (
      <Button
        className="button-filled-icon"
        text={formatMessage(addLabel)}
        onClick={() => {
          history.push(`${url}/${value}/add`);
        }}
        icon={<AddCircleOutlineIcon />}
      />
    );
  };

  const impersonatorQPs = takeKeys(location.search, IMPERSONATOR_KEYS);

  return (
    <div className="products">
      <PageHeader
        title={
          isAdmin ? formatMessage(commonMessages.products) : formatMessage(commonMessages.prices)
        }
      />
      <div className="content">
        <div className="subheader">
          <Tabs
            indicatorColor="primary"
            textColor="secondary"
            value={get(productTabs, selectedTab) ? selectedTab : false}
          >
            <Tab
              value={productTabs.modules.value}
              label={formatMessage(productTabs.modules.label)}
              component={Link}
              to={{
                pathname: `${url}/${productTabs.modules.value}`,
                search: impersonatorQPs,
              }}
            />
            <Tab
              value={productTabs.boxes.value}
              label={formatMessage(productTabs.boxes.label)}
              component={Link}
              to={{
                pathname: `${url}/${productTabs.boxes.value}`,
                search: impersonatorQPs,
              }}
            />
          </Tabs>
          {getAddButton()}
        </div>
        <div className="subcontent">
          <Switch>
            <Redirect
              exact
              from={url}
              to={{
                pathname: `${url}/${productTabs.modules.value}`,
                search: impersonatorQPs,
              }}
            />
            <Route path={`${url}/${productTabs.modules.value}`} component={ModulesOverview} />
            <Route path={`${url}/${productTabs.boxes.value}`} component={BoxesOverview} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default Products;
