import React from 'react';
import { Grid } from '@material-ui/core';
import get from 'get-value';
import { useIntl } from 'react-intl';
import i18nCountries from 'i18n-iso-countries';
import { ReactComponent as AddressIcon } from '../../../assets/icons/icons-location.svg';
import { OutlinedTextField, OutlinedSelectField } from '../../inputs';
import { CompanySectionProps, AddressSection } from '../../../types/company.types';
import { commonMessages, labelMessages } from '../../../messages';
import { countries } from '../../../helpers/country';

const Address: React.FC<CompanySectionProps<AddressSection>> = ({ fields, onChange, errors }) => {
  const { formatMessage, locale } = useIntl();
  return (
    <Grid item xs={12}>
      <div className="form-section">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className="form-section-title">
              <div className="section-icon">
                <AddressIcon />
              </div>
              <p>{formatMessage(commonMessages.address)}</p>
            </div>
          </Grid>
          <Grid item xs={6}>
            <OutlinedTextField
              name="addresses.0.addressLineOne"
              labelText={formatMessage(labelMessages.addressLineOne)}
              value={get(fields, 'addresses.0.addressLineOne')}
              onChange={onChange}
              maxLength={255}
              error={get(errors, 'addresses.0.addressLineOne')}
            />
          </Grid>
          <Grid item xs={6}>
            <OutlinedTextField
              name="addresses.0.addressLineTwo"
              labelText={`${formatMessage(labelMessages.addressLineTwo)} (${formatMessage(
                labelMessages.optional,
              )})`}
              value={get(fields, 'addresses.0.addressLineTwo')}
              onChange={onChange}
              maxLength={255}
            />
          </Grid>
          <Grid item xs={4}>
            <OutlinedTextField
              name="addresses.0.postalCode"
              labelText={formatMessage(labelMessages.postalCode)}
              value={get(fields, 'addresses.0.postalCode')}
              onChange={onChange}
              error={get(errors, 'addresses.0.postalCode')}
              maxLength={20}
            />
          </Grid>
          <Grid item xs={4}>
            <OutlinedTextField
              name="addresses.0.city"
              labelText={formatMessage(labelMessages.city)}
              value={get(fields, 'addresses.0.city')}
              onChange={onChange}
              maxLength={255}
              error={get(errors, 'addresses.0.city')}
            />
          </Grid>
          <Grid item xs={4}>
            <OutlinedSelectField
              name="addresses.0.country"
              labelText={formatMessage(labelMessages.country)}
              value={get(fields, 'addresses.0.country')}
              onChange={onChange}
              error={get(errors, 'addresses.0.country')}
            >
              <option />
              {countries.map(country => (
                <option key={country.id} value={country.id}>
                  {i18nCountries.getName(country.id, locale)}
                </option>
              ))}
            </OutlinedSelectField>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};
export default Address;
