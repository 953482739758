import { gql } from 'apollo-boost';

export const boxFragments = {
  Item: gql`
    fragment BoxItem on Box {
      __typename
      id
      name
      volume
      active
    }
  `,
};

export const GET_BOXES = gql`
  query getBoxes(
    $companyId: Int!
    $sortColumn: String
    $sortDirection: SortDirection
    $filter: BoxFilter
  ) {
    getBoxes(
      companyId: $companyId
      sortColumn: $sortColumn
      sortDirection: $sortDirection
      filter: $filter
    ) {
      ...BoxItem
      productInfo {
        articleId
        id
        price
      }
    }
  }
  ${boxFragments.Item}
`;

export const GET_PAGED_BOXES = gql`
  query getPagedBoxes(
    $offset: Int!
    $limit: Int!
    $sortColumn: String
    $sortDirection: SortDirection
  ) {
    getPagedBoxes(
      offset: $offset
      limit: $limit
      sortColumn: $sortColumn
      sortDirection: $sortDirection
    ) {
      items {
        ...BoxItem
        recommendedPrice
      }
      pageInfo {
        itemCount
        pageCount
        total
      }
    }
  }
  ${boxFragments.Item}
`;

export const GET_PAGED_BOXES_DISTRIBUTOR = gql`
  query getPagedBoxesDistributor(
    $companyId: Int!
    $offset: Int!
    $limit: Int!
    $sortColumn: String
    $sortDirection: SortDirection
  ) {
    getPagedBoxes(
      companyId: $companyId
      offset: $offset
      limit: $limit
      sortColumn: $sortColumn
      sortDirection: $sortDirection
    ) {
      items {
        ...BoxItem
        productInfo {
          articleId
          id
          price
        }
      }
      pageInfo {
        itemCount
        pageCount
        total
      }
    }
  }
  ${boxFragments.Item}
`;

export const GET_BOX = gql`
  query getBox($id: Int!, $companyId: Int) {
    getBox(id: $id, companyId: $companyId) {
      id
      name
      recommendedPrice
      volume
      active
      productCode
    }
  }
`;

export const CREATE_BOX = gql`
  mutation createBox($type: BoxInputCreate!) {
    createBox(box: $type) {
      id
    }
  }
`;

export const UPDATE_BOX = gql`
  mutation updateBox($id: Int!, $type: BoxInputUpdate!) {
    updateBox(id: $id, box: $type) {
      id
    }
  }
`;
