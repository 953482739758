import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useIntl } from 'react-intl';
import { Box, GridSize } from '@material-ui/core';
import { PageHeader, Pagination, Loadable, OverviewSearch } from '../../components';
import { GET_PAGED_ORDERS, createSearch } from '../../graphql/queries/order';
import { useOptions, usePageView, useImpersonator } from '../../hooks';
import { setSortableQuery } from '../../helpers/query';
import { quoteMessages, commonMessages, productMessages, labelMessages } from '../../messages';
import {
  GetPagedOrdersQuery,
  SortDirection,
  GetPagedOrdersQueryVariables,
} from '../../types/graphql-generated.types';
import { useUser, Role } from '../../providers/UserProvider';
import { TableHeader, TableCell, TableRow, EmptyRow } from '../../components/table';
import { getNextDirection } from '../../helpers/sort';
import { formatDate } from '../../helpers/date';
import { formatPrice } from '../../helpers/format';
import history from '../../lib/history';

const OrdersOverview: React.FC = () => {
  usePageView('orders_overview');

  const { formatMessage } = useIntl();

  const { isImpersonating, getFakeVariables, fakeCompanyName, isRole } = useImpersonator();
  const { getContextVariables } = useUser();

  const isAdmin = isRole(Role.Admin);
  const isDistributor = isRole(Role.Distributor);

  const { options, changeParams, resetPagination } = useOptions({
    page: 0,
    offset: 0,
    pageCount: 1,
    limit: 20,
    sortColumn: 'order.createdAt',
    sortDirection: SortDirection.Desc,
  });

  const { pageCount, page, ...rest } = options;

  const [search, setSearch] = useState<string | undefined>();

  const { loading, data } = useQuery<GetPagedOrdersQuery, GetPagedOrdersQueryVariables>(
    GET_PAGED_ORDERS,
    {
      variables: {
        ...(isImpersonating ? getFakeVariables() : getContextVariables()),
        ...rest,
        ...(search && { filter: createSearch(isRole, search) }),
      },
      onCompleted(result) {
        if (result?.getPagedOrders?.pageInfo?.pageCount) {
          changeParams('pageCount', result.getPagedOrders.pageInfo.pageCount);
        }
      },
    },
  );

  const onSearch = (value: string | undefined) => {
    resetPagination();
    setSearch(value);
  };

  const onChangeSort = (key: string) => {
    setSortableQuery(key, getNextDirection(key, options.sortColumn, options.sortDirection));
  };

  let createdByWidth: GridSize;

  if (isAdmin) {
    createdByWidth = 2;
  } else if (isDistributor) {
    createdByWidth = 4;
  } else {
    createdByWidth = 7;
  }

  const isEmpty = !data?.getPagedOrders?.items?.length;

  return (
    <div>
      <PageHeader
        title={`${formatMessage(commonMessages.orders)}${
          isImpersonating ? ` (${fakeCompanyName})` : ''
        }`}
      >
        <OverviewSearch onSearch={onSearch} onClear={() => onSearch(undefined)} />
      </PageHeader>
      <Box>
        <TableHeader
          sortOptions={{
            column: options.sortColumn,
            direction: options.sortDirection,
            onChange: onChangeSort,
          }}
        >
          {({ Sortable }) => (
            <>
              {isAdmin && (
                <TableCell xs={2}>
                  <Sortable name="distributor.name">
                    {formatMessage(labelMessages.distributor)}
                  </Sortable>
                </TableCell>
              )}
              {(isAdmin || isDistributor) && (
                <TableCell xs={isAdmin ? 2 : 3}>
                  <Sortable name="customer.name">{formatMessage(commonMessages.customer)}</Sortable>
                </TableCell>
              )}
              <TableCell xs={createdByWidth}>
                <Sortable name="created_by.lastName">
                  {formatMessage(quoteMessages.createdBy)}
                </Sortable>
              </TableCell>
              <TableCell xs={3}>
                <Sortable name="order.createdAt">{formatMessage(quoteMessages.createdAt)}</Sortable>
              </TableCell>
              <TableCell xs={2}>
                <Sortable name="order.totalPrice">{formatMessage(productMessages.price)}</Sortable>
              </TableCell>
            </>
          )}
        </TableHeader>
        <Loadable loading={loading}>
          {isEmpty ? (
            <EmptyRow />
          ) : (
            data?.getPagedOrders?.items?.map(order => (
              <TableRow key={order.id!} onClick={() => history.push(`/orders/${order.id!}`)}>
                {isAdmin && <TableCell body xs={2} label={order.customer?.distributor?.name} />}
                {(isAdmin || isDistributor) && (
                  <TableCell body primary xs={isAdmin ? 2 : 3} label={order.customer?.name} />
                )}
                <TableCell
                  body
                  xs={createdByWidth}
                  label={`${order.createdBy?.firstName || ''} ${order.createdBy?.lastName || ''}`}
                />
                <TableCell
                  body
                  xs={3}
                  label={order.createdAt ? formatDate(order.createdAt) : '-'}
                />
                <TableCell body xs={2} label={formatPrice(order.totalPrice)} />
              </TableRow>
            ))
          )}

          <Pagination options={options} />
        </Loadable>
      </Box>
    </div>
  );
};

export default OrdersOverview;
