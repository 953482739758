import React from 'react';
import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import { ReactComponent as Logo } from '../../assets/modulaid-logo.svg';
import { useUser, Role } from '../../providers/UserProvider';
import messages from '../../messages/common';
import { TableCell, TableRow } from '../../components/table';

const CompanySelector = () => {
  const { formatMessage } = useIntl();
  const { companies, role, selectCompany } = useUser();

  return (
    <div className="add-edit-form">
      <Grid container justify="center" alignItems="center">
        <Grid item md={4} sm={6} xs={8}>
          <div className="form-section company-selector">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div className="form-section-title">
                  <Logo className="header-logo" />
                  <h3>
                    {role === Role.Distributor
                      ? formatMessage(messages.selectDistributor)
                      : formatMessage(messages.selectCustomer)}
                  </h3>
                </div>
                <p>
                  {role === Role.Distributor
                    ? formatMessage(messages.multipleDistributorAccountExplanation)
                    : formatMessage(messages.multipleCustomerAccountExplanation)}
                </p>
              </Grid>
              {companies?.map(company => (
                <TableRow
                  key={company?.id!}
                  onClick={() => {
                    if (company?.id) {
                      selectCompany(company.id);
                    }
                  }}
                >
                  <TableCell body xs={12} label={`${company?.name}`} />
                </TableRow>
              ))}
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default CompanySelector;
