import React, { MouseEvent } from 'react';
import { NavLink } from 'react-router-dom';
import { useIntl } from 'react-intl';
import qs from 'query-string';
import { quoteMessages, commonMessages, inventoryMessages, userMessages } from '../../messages';
import { useUser } from '../../providers/UserProvider';
import { SortDirection } from '../../types/graphql-generated.types';

interface Props {
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
}

const HeaderLinks: React.FC<Props> = ({ onClick }) => {
  const { isAdmin, isDistributor, isCustomer } = useUser();

  const { formatMessage } = useIntl();
  return (
    <div className="header-links">
      {isAdmin && (
        <NavLink
          exact
          isActive={(_match, location) => location.pathname.includes('distributor')}
          to={{ pathname: '/distributors', search: `?page=1&sort=${SortDirection.Asc}` }}
          activeClassName="selected"
          onClick={onClick}
        >
          {formatMessage(commonMessages.distributors)}
        </NavLink>
      )}
      {(isAdmin || isDistributor) && (
        <NavLink
          exact
          isActive={(_match, location) => location.pathname.includes('customer')}
          to={{
            pathname: '/customers',
            search: `?column=customer.name&page=1&sort=${SortDirection.Asc}`,
          }}
          activeClassName="selected"
          onClick={onClick}
        >
          {formatMessage(commonMessages.customers)}
        </NavLink>
      )}
      {(isAdmin || isDistributor) && (
        <NavLink
          isActive={(_match, location) => location.pathname.includes('products')}
          to="/products"
          activeClassName="selected"
          onClick={onClick}
        >
          {isAdmin && formatMessage(commonMessages.products)}
          {isDistributor && formatMessage(commonMessages.prices)}
        </NavLink>
      )}
      {(isAdmin || isDistributor || isCustomer) && (
        <NavLink
          isActive={(_match, location) => location.pathname.includes('quotes')}
          to={{
            pathname: '/quotes',
            search: qs.stringify({ page: 1, sort: SortDirection.Desc, column: 'quote.createdAt' }),
          }}
          activeClassName="selected"
          onClick={onClick}
        >
          {formatMessage(quoteMessages.quotes)}
        </NavLink>
      )}
      {(isAdmin || isDistributor || isCustomer) && (
        <NavLink
          isActive={(_match, location) => location.pathname.includes('orders')}
          to={{
            pathname: '/orders',
            search: qs.stringify({ page: 1, sort: SortDirection.Desc, column: 'order.createdAt' }),
          }}
          activeClassName="selected"
          onClick={onClick}
        >
          {formatMessage(commonMessages.orders)}
        </NavLink>
      )}
      {(isAdmin || isDistributor || isCustomer) && (
        <NavLink
          isActive={(_match, location) => location.pathname.includes('inventory')}
          to={{
            pathname: '/inventory',
            search: qs.stringify({
              page: 1,
              sort: SortDirection.Asc,
              column: 'inventory_item.location',
            }),
          }}
          activeClassName="selected"
          onClick={onClick}
        >
          {formatMessage(inventoryMessages.inventory)}
        </NavLink>
      )}
      {(isAdmin || isDistributor || isCustomer) && (
        <NavLink
          isActive={(_match, location) => location.pathname.includes('users')}
          to={{
            pathname: '/users',
            search: qs.stringify({ page: 1, sort: SortDirection.Desc, column: 'user.lastName' }),
          }}
          activeClassName="selected"
          onClick={onClick}
        >
          {formatMessage(userMessages.users)}
        </NavLink>
      )}
    </div>
  );
};

export default HeaderLinks;
