import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { PageHeader, Button, Pagination, SortableTableHeader, Loadable } from '../../components';
import history from '../../lib/history';
import { GET_PAGED_DISTRIBUTORS } from '../../graphql/queries/distributor';
import { DistributorInterface } from '../../types/distributor.types';
import { useOptions, usePageView } from '../../hooks';
import messages from '../../messages/common';
import { setSortableQuery } from '../../helpers/query';
import { labelMessages } from '../../messages';
import { SortDirection } from '../../types/graphql-generated.types';

const Distributors: React.FC = () => {
  usePageView('distributors_overview');

  const { formatMessage } = useIntl();
  const { options, changeParams } = useOptions({
    page: 0,
    offset: 0,
    pageCount: 1,
    limit: 20,
    sortColumn: 'name',
    sortDirection: SortDirection.Asc,
  });

  // ES7 syntax to omit properties from options state
  const { pageCount, page, ...noPage } = options;

  const { loading, data } = useQuery<DistributorInterface>(GET_PAGED_DISTRIBUTORS, {
    variables: noPage,
    onCompleted(result) {
      if (result?.getPagedDistributors?.pageInfo?.pageCount) {
        changeParams('pageCount', result.getPagedDistributors.pageInfo.pageCount);
      }
    },
  });

  const onChangeSortColumn = (type: string) => {
    let nextDir = SortDirection.Asc;

    if (options.sortColumn === type) {
      nextDir =
        options.sortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc;
    }

    setSortableQuery(type, nextDir);
  };

  const onClickAdd = () => {
    history.push('/distributors/add');
  };

  return (
    <div className="distributors">
      <PageHeader title={formatMessage(messages.distributors)}>
        <Button
          className="button-filled-icon"
          text={formatMessage(messages.addNew)}
          onClick={onClickAdd}
          icon={<AddCircleOutlineIcon />}
        />
      </PageHeader>
      <div className="table">
        <Grid className="table-header" container>
          <Grid item xs>
            <SortableTableHeader
              name="name"
              current={options.sortColumn}
              direction={options.sortDirection}
              onChange={onChangeSortColumn}
            >
              {formatMessage(labelMessages.name)}
            </SortableTableHeader>
          </Grid>
        </Grid>

        <Loadable loading={loading}>
          {data?.getPagedDistributors.items.map(distributor => (
            <Link
              key={distributor.id}
              to={`/distributor/${distributor.id}`}
              className="table-row-link"
            >
              <Grid className="table-row" container>
                <Grid item xs className="table-row-primary">
                  <span>{distributor.name}</span>
                </Grid>
              </Grid>
            </Link>
          ))}
        </Loadable>

        <Pagination options={options} />
      </div>
    </div>
  );
};

export default Distributors;
