import React, { FC } from 'react';
import {
  Container,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { Button } from '..';
import { commonMessages } from '../../messages';

const useStyles = makeStyles({
  content: {
    textAlign: 'center',
    marginBottom: '40px',
  },
});

interface ConfirmDialogProps {
  open: boolean;
  title: string;
  content: string;
  loading?: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const ConfirmDialog: FC<ConfirmDialogProps> = props => {
  const { content, title, open, onCancel, onConfirm, loading } = props;

  const { formatMessage } = useIntl();
  const classes = useStyles(props);

  return (
    <Dialog onClose={onCancel} open={open}>
      <DialogTitle disableTypography>
        <Typography variant="h3">{title}</Typography>
      </DialogTitle>
      <DialogContent>
        <Container className={classes.content}>{content}</Container>
      </DialogContent>
      <DialogActions>
        <Button
          className="button-outlined"
          onClick={onCancel}
          text={formatMessage(commonMessages.cancel)}
          loading={loading}
        />
        <Button
          className="button-filled"
          onClick={onConfirm}
          text={formatMessage(commonMessages.ok)}
          loading={loading}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
