import React, { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import { PageHeader } from '../../components';
import { commonMessages } from '../../messages';

interface Props extends RouteComponentProps {
  children?: ReactNode;
}

const CookiePolicy: FC<Props> = () => {
  const { formatMessage } = useIntl();

  return (
    <div className="cookie-policy">
      <PageHeader title={formatMessage(commonMessages.cookiePolicy)} />
      <div className="content">
        <h2>Wat zijn cookies?</h2>
        <p>
          Bij het bezoek aan websites worden er kleine tekstbestanden opgeslagen op de harde schijf
          van de computer van de gebruiker. Deze kleine tekstbestanden zijn cookies. Deze cookies
          worden opgeslagen opdat bij een volgend bezoek deze gebruiker wordt herkend. Cookies zijn
          louter een unieke identificatie in de vorm van een tekstbestand.
          <br /> Cookies hebben een houdbaarheidsdatum, hetgeen betekent dat deze cookies niet
          onbeperkt blijven bestaan. De houdbaarheidsdatum varieert afhankelijk van het type van
          cookie en kan variëren van de duurtijd van een websitebezoek tot een specifieke datum in
          de toekomst waarop ze niet langer werkzaam zijn.
          <br /> Session Cookies zijn de cookies welke automatisch verwijderd worden bij het
          afsluiten van de browser door de gebruiker. In andere gevallen worden de cookies langer
          bewaard en kunnen deze ook bij een volgend bezoek aan dezelfde website worden aangewend,
          dit betreffen “persistent cookies”.
        </p>
        <h2>Hoe gebruikt Pharmatwork cookies?</h2>
        <p>
          Pharmatwork maakt gebruik van cookies om de navigatie via haar website te
          vergemakkelijken, om veiligheidsredenen en om een grotere efficiëntie te bereiken bij het
          gebruik van de website. De gebruikte cookies door Pharmatwork zijn enkel en alleen
          gekoppeld aan een anonieme gebruiker en zijn of haar apparaat. De cookies bevatten geen
          verwijzingen die ons in staat stellen om de gebruiker te identificeren noch om informatie
          of cookies op het apparaat van de gebruiker te achterhalen.{' '}
        </p>
        <h2>Welke soorten cookies gebruikt Pharmatwork?</h2>
        <p>
          Pharmatwork maakt gebruik van cookies voor analytische doeleinden.
          <br /> Deze cookies worden enkel verzameld op anonieme basis teneinde te analyseren hoe
          gebruikers de website aanwenden en om statistieken op te stellen. Er worden, zoals eerder
          gesteld, geen persoonsgegevens verzameld via deze cookies.
        </p>
        <h2>Analytische cookies</h2>
        <p>
          De website van Pharmatwork maakt gebruik van Google Analytics cookies, een
          webanalyse-service die wordt aangeboden door Google voor statistische doeleinden. Deze
          cookies worden overgebracht naar Google en worden door deze laatste opgeslagen op hun
          servers in de Verenigde Staten. Google mag deze informatie onder geen beding verschaffen
          aan derden, tenzij hiertoe een wettelijke verplichting bestaat.
        </p>
        <h2>Cookies verwijderen en uitschakelen</h2>
        <p>
          De instellingen van de meeste internetbrowsers zorgen ervoor dat cookies automatisch
          worden geaccepteerd. Via de browserinstellingen op de computer van de gebruiker kan de
          gebruiker echter reeds geplaatste cookies verwijderen en het plaatsen van nieuwe cookies
          weigeren. De wijze waarop verschilt per browser. De gebruiker kan indien nodig hiervoor de
          hulpfunctie van de browser raadplegen.
        </p>
      </div>
    </div>
  );
};

export default CookiePolicy;
