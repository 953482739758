import React, { FC, useState } from 'react';
import { useQuery } from 'react-apollo';
import { useMeasure } from 'react-use';
import { makeStyles, createStyles } from '@material-ui/core';
import { GET_PAGED_HISTORY } from '../../graphql/queries/inventory';
import {
  GetPagedHistoryForKitQuery,
  GetPagedHistoryForKitQueryVariables,
  HistoryEntry as Entry,
} from '../../types/graphql-generated.types';
import { Loadable, Pagination } from '../../components';
import { HistoryEntry } from '../../components/inventory';

interface HistoryProps {
  id: number;
  page: number;
  setPage: (page: number) => void;
  large?: boolean;
  customerId?: number | null;
}

const LIMIT = 10;

const useStyles = makeStyles(() =>
  createStyles({
    pagination: {
      justifyContent: 'center',
    },
  }),
);

const History: FC<HistoryProps> = ({ id, large, page, customerId, setPage }) => {
  const classes = useStyles();

  // Using this to give the loadable component the height
  // of the previous page to prevent the page from scrolling
  // to the top as there might be no scrollbar when the loading
  // indicator is showing
  const [ref, { height }] = useMeasure<HTMLDivElement>();

  const [pageCount, setPageCount] = useState(1);

  const { loading, data } = useQuery<
    GetPagedHistoryForKitQuery,
    GetPagedHistoryForKitQueryVariables
  >(GET_PAGED_HISTORY, {
    variables: {
      id,
      customerId,
      limit: LIMIT,
      offset: page * LIMIT,
    },
    onCompleted(result) {
      if (result?.getPagedHistoryForKit?.pageInfo?.pageCount != null) {
        setPageCount(result.getPagedHistoryForKit.pageInfo.pageCount);
      }
    },
  });

  const entries = data?.getPagedHistoryForKit?.items;

  return (
    <>
      <Loadable style={large ? { height } : {}} loading={loading}>
        <div ref={ref}>
          {entries?.length
            ? entries.map((entry: Entry, idx: number) => (
                <HistoryEntry
                  key={entry.id!}
                  first={idx === 0}
                  last={idx === entries.length - 1}
                  entry={entry}
                />
              ))
            : null}
        </div>
      </Loadable>
      <Pagination
        className={!large ? classes.pagination : undefined}
        small={!large}
        options={{ page, pageCount }}
        onPageSelect={selected => setPage(selected)}
      />
    </>
  );
};

export default History;
