import { defineMessages } from 'react-intl';

export default defineMessages({
  ok: {
    id: 'ok',
    defaultMessage: 'OK',
  },
  continue: {
    id: 'continue',
    defaultMessage: 'Continue',
  },
  add: {
    id: 'add',
    defaultMessage: 'Add',
  },
  distributors: {
    id: 'distributors',
    defaultMessage: 'Distributors',
  },
  customers: {
    id: 'customers',
    defaultMessage: 'Customers',
  },
  customer: {
    id: 'customer',
    defaultMessage: 'Customer',
  },
  prospect: {
    id: 'prospect',
    defaultMessage: 'Prospect',
  },
  products: {
    id: 'products',
    defaultMessage: 'Products',
  },
  prices: {
    id: 'prices',
    defaultMessage: 'Prices',
  },
  orders: {
    id: 'orders',
    defaultMessage: 'Orders',
  },
  users: {
    id: 'users',
    defaultMessage: 'Users',
  },
  general: {
    id: 'general',
    defaultMessage: 'General',
  },
  info: {
    id: 'info',
    defaultMessage: 'Info',
  },
  address: {
    id: 'address',
    defaultMessage: 'Address',
  },
  contact: {
    id: 'contact',
    defaultMessage: 'Contact',
  },
  costs: {
    id: 'costs',
    defaultMessage: 'Costs',
  },
  logout: {
    id: 'logout',
    defaultMessage: 'Logout',
  },
  create: {
    id: 'create',
    defaultMessage: 'Create',
  },
  save: {
    id: 'save',
    defaultMessage: 'Save',
  },
  cancel: {
    id: 'cancel',
    defaultMessage: 'Cancel',
  },
  select: {
    id: 'select',
    defaultMessage: 'Select',
  },
  addNew: {
    id: 'add_new',
    defaultMessage: 'Add new',
  },
  backToOverview: {
    id: 'back_to_overview',
    defaultMessage: 'Back to overview',
  },
  confirm: {
    id: 'confirm',
    defaultMessage: 'Confirm',
  },
  amount: {
    id: 'amount',
    defaultMessage: 'Amount',
  },
  printAsPDF: {
    id: 'print_pdf',
    defaultMessage: 'Print as PDF',
  },
  links: {
    id: 'links',
    defaultMessage: 'Links',
  },
  customerLinks: {
    id: 'links_customer',
    defaultMessage: 'Navigate to a filtered list of this customer’s associated items.',
  },
  distributorLinks: {
    id: 'links_distributor',
    defaultMessage: 'Navigate to a filtered list of this distributor’s associated items.',
  },
  viewQuotes: {
    id: 'links_view_quotes',
    defaultMessage: 'View Quotes',
  },
  viewOrders: {
    id: 'links_view_orders',
    defaultMessage: 'View Orders',
  },
  viewInventory: {
    id: 'links_view_inventory',
    defaultMessage: 'View Inventory',
  },
  viewProducts: {
    id: 'links_view_products',
    defaultMessage: 'View Products',
  },
  viewUsers: {
    id: 'links_view_users',
    defaultMessage: 'View Users',
  },
  search: {
    id: 'search',
    defaultMessage: 'Search',
  },
  noItemsFound: {
    id: 'no_items_found',
    defaultMessage: 'No items found',
  },
  profile: {
    id: 'profile',
    defaultMessage: 'Profile',
  },
  company: {
    id: 'company',
    defaultMessage: 'Company',
  },
  changeDistributor: {
    id: 'changeDistributor',
    defaultMessage: 'Switch distributor',
  },
  changeCustomer: {
    id: 'changeCustomer',
    defaultMessage: 'Switch customer',
  },
  selectDistributor: {
    id: 'selectDistributor',
    defaultMessage: 'Distributor selection',
  },
  selectCustomer: {
    id: 'selectCustomer',
    defaultMessage: 'Customer selection',
  },
  multipleDistributorAccountExplanation: {
    id: 'multipleDistributorAccountExplanation',
    defaultMessage: 'Your account has access to multiple distributors. Please select one below.',
  },
  multipleCustomerAccountExplanation: {
    id: 'multipleCustomerAccountExplanation',
    defaultMessage: 'Your account has access to multiple customers. Please select one below.',
  },
  filterAll: {
    id: 'filter_al',
    defaultMessage: 'All',
  },
  myProfile: {
    id: 'myProfile',
    defaultMessage: 'My Profile',
  },
  privacy: {
    id: 'privacy',
    defaultMessage: 'Privacy statement',
  },
  cookiePolicy: {
    id: 'cookiePolicy',
    defaultMessage: 'Cookie policy',
  },
  privacyAndCookiePolicy: {
    id: 'privacyAndCookiePolicy',
    defaultMessage: 'Privacy & Cookie Policy',
  },
  manageCookies: {
    id: 'manageCookies',
    defaultMessage: 'Manage Cookies',
  },
});
