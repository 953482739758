import { makeStyles, createStyles } from '@material-ui/core';

const useProductPanelStyles = makeStyles(theme =>
  createStyles({
    panel: {
      width: '95%',
      [theme.breakpoints.up('md')]: {
        width: '90%',
      },
      [theme.breakpoints.up('lg')]: {
        width: '70%',
      },
      [theme.breakpoints.up('xl')]: {
        width: '60%',
      },
    },
    table: {
      width: '92%',
    },
    container: {
      display: 'flex',
    },
    section: {
      marginBottom: '35px',
    },
    amount: {
      minWidth: '90px',
      width: '8%',
      marginLeft: 'auto',
    },
    total: {
      marginTop: '35px',
      alignItems: 'flex-end',
    },
    totalDescription: {
      lineHeight: '11px',
      fontSize: '14px',
      marginLeft: '8px',
      color: theme.palette.secondary.main,
    },
    totalPrice: {
      lineHeight: '15px',
      marginLeft: '10px',
      fontSize: '20px',
      color: theme.palette.secondary.dark,
      fontWeight: 600,
    },
    actions: {
      marginTop: '35px',

      '& > button': {
        padding: '0 20px',
      },
    },
  }),
);

export default useProductPanelStyles;
