import BigNumber from 'bignumber.js';
import {
  Module,
  QuoteItem,
  Box,
  Product,
  QuoteItemInputCreate,
  Quote,
  Order,
  OrderItem,
  QuoteItemInputUpdate,
} from '../types/graphql-generated.types';
import { convertIconSvgToPng } from './svg';
import { baseSort } from './sort';

export type Kit = [string, QuoteItem];
export type OrderKit = [string, OrderItem];

export const sortKitsFn = (a: Kit, b: Kit): number => {
  const A = (a[1].location || '').toUpperCase();
  const B = (b[1].location || '').toUpperCase();
  return baseSort(A, B);
};

export const sortModuleQuoteItemsFn = (a: QuoteItem, b: QuoteItem): number => {
  const A = (a.product as Module)?.nameEN || '';
  const B = (b.product as Module)?.nameEN || '';

  return baseSort(A, B);
};

export const sortBoxQuoteItemsFn = (a: QuoteItem, b: QuoteItem): number => {
  const A = (a.product as Box)?.name || '';
  const B = (b.product as Box)?.name || '';

  return baseSort(A, B);
};

export const calculateTotalPrice = (amount: number, price: number): number => {
  return new BigNumber(price)
    .times(amount)
    .decimalPlaces(2)
    .toNumber();
};

export const removeKitFromList = (kits: Kit[], kit: Kit): Kit[] =>
  kits.filter(([id]) => id !== kit[0]);

export const calculateTotalOfQuoteItems = (quoteItems: QuoteItem[]): number =>
  quoteItems.reduce((sum, item) => {
    const totalPrice = item.totalPrice || 0;
    return sum + totalPrice;
  }, 0);

export const calculateTotalOfKit = (kit: QuoteItem) =>
  (kit.children || []).reduce((sum, module) => sum + (module.totalPrice || 0), kit.totalPrice || 0);

export const calculateDiscount = (total: number, discount: number): number => {
  return new BigNumber(total)
    .minus(new BigNumber(total).times(new BigNumber(discount).dividedBy(100)))
    .decimalPlaces(2)
    .toNumber();
};

export const getVolume = (product: Product): number => {
  const { volume } = product;
  return volume || 0;
};

export const getModulesFromKit = (kit: Kit): Module[] => {
  const children = kit[1].children || [];

  return children
    .map(quoteItem => quoteItem.product)
    .filter((product?: Product | null): product is Module => product != null)
    .sort((a: Module, b: Module): number => {
      const A = a.nameEN || '';
      const B = b.nameEN || '';

      return baseSort(A, B);
    });
};

export const getBoxFromKit = (kit: Kit): Box | null => (kit[1].product as Box) || null;

export const createQuoteItemFromProduct = (
  product: Box | Module,
  amount: number,
): QuoteItem | null => {
  const price = product?.productInfo?.[0]?.price;

  if (price == null) {
    return null;
  }

  return {
    product,
    quantity: amount,
    unitPrice: price,
    totalPrice: calculateTotalPrice(amount, price),
  };
};

export const serializeQuoteItem = (quoteItem: QuoteItem): QuoteItemInputCreate => ({
  quantity: quoteItem.quantity,
  product: { id: quoteItem.product?.id },
});

export const serializeQuoteItems = (quoteItems: QuoteItem[]): QuoteItemInputCreate[] =>
  quoteItems.map(serializeQuoteItem);

export const serializeKits = (kits: Kit[]): QuoteItemInputCreate[] =>
  kits.map(kit => ({
    ...serializeQuoteItem(kit[1]),
    location: kit[1].location,
    children: serializeQuoteItems(kit[1].children || []),
  }));

export const serializeUpdatedKits = (kits: Kit[]): QuoteItemInputUpdate[] =>
  kits.map(kit => ({ id: parseInt(kit[0], 10), location: kit[1].location }));

export const deserializeQuoteItems = (quote: Quote) => {
  const { quoteItems } = quote;
  const items = quoteItems || [];
  return {
    kits: items.filter(q => q.children?.length).map(q => [String(q.id!), q]) as Kit[],
    modules: items.filter(q => !q.children?.length && (q.product as Module).moduleId),
    boxes: items.filter(q => !q.children?.length && !(q.product as Module).moduleId),
  };
};

export const deserializeOrderItems = (order: Order) => {
  const { orderItems } = order;
  const items = orderItems || [];
  return {
    kits: items.filter(q => q.children?.length).map(q => [String(q.id!), q]) as OrderKit[],
    modules: items.filter(q => !q.children?.length && (q.product as Module).moduleId),
    boxes: items.filter(q => !q.children?.length && !(q.product as Module).moduleId),
  };
};

export const getCreatedBy = (quote: Quote) =>
  `${quote.createdBy?.firstName || ''} ${quote.createdBy?.lastName || ''}`;

export const getFileName = (quoteOrder: Quote | Order) => {
  const { reference } = quoteOrder;

  // eslint-disable-next-line no-underscore-dangle
  const kind = quoteOrder.__typename === 'Quote' ? 'quote' : 'order';

  if (reference) {
    return `${kind}-${reference}.pdf`;
  }

  return `${kind}.pdf`;
};

export const generateDataURLs = async (modules: Module[]): Promise<Record<number, string>> => {
  const svgDataUrls: Record<number, string> = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const module of modules) {
    // eslint-disable-next-line no-await-in-loop
    const dataURL = await convertIconSvgToPng(
      module
        .icon!.svg!.replace(/fill="([a-zA-Z0-9#]+)"/gi, 'fill="#FFFFFF"')
        .replace(/stroke="([a-zA-Z0-9#]+)"/gi, 'stroke="#FFFFFF"')
        .replace(/width="(\d+)(px)?"/, '')
        .replace(/height="(\d+)(px)?"/, '')
        .replace(/viewBox="0 0 24 24"/, ''),
      module.rgb!,
    );
    svgDataUrls[module.id!] = dataURL;
  }

  return svgDataUrls;
};
