import React, { FC } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useIntl } from 'react-intl';
import { Grid, Box } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import { ReactComponent as IconProspectFilled } from '../../assets/icons/icons-prospect-filled.svg';
import { ReactComponent as DeliveryIcon } from '../../assets/icons/icons-delivery.svg';
import { PageHeader, Loadable, Pagination, Button } from '../../components';
import { GET_USERS } from '../../graphql/queries/user';
import { setSortableQuery } from '../../helpers/query';
import { getNextDirection } from '../../helpers/sort';
import { useUser, Role } from '../../providers/UserProvider';
import messages from '../../messages/common';
import { labelMessages } from '../../messages';
import { useOptions, usePageView, useImpersonator } from '../../hooks';
import { TableHeader, TableCell, TableRow, EmptyRow } from '../../components/table';
import history from '../../lib/history';
import {
  User,
  SortDirection,
  GetPagedUsersQuery,
  GetPagedUsersQueryVariables,
} from '../../types/graphql-generated.types';

const Users: React.FC = () => {
  usePageView('users_overview');

  const { formatMessage } = useIntl();
  const { companyId, companies } = useUser();
  const { isImpersonating, getFakeVariables, fakeCompanyName, isRole } = useImpersonator();

  const isAdmin = isRole(Role.Admin);

  const { options, changeParams } = useOptions({
    page: 0,
    offset: 0,
    pageCount: 1,
    limit: 20,
    sortColumn: 'user.lastName',
    sortDirection: SortDirection.Asc,
  });

  const { pageCount, page, ...rest } = options;

  const fakeVariables = getFakeVariables();
  let type = 'distributor';
  if (isImpersonating && fakeVariables.customerId) {
    fakeVariables.companyId = fakeVariables.customerId;
    type = 'customer';
  }
  const { loading, data } = useQuery<GetPagedUsersQuery, GetPagedUsersQueryVariables>(GET_USERS, {
    variables: {
      ...(isImpersonating ? fakeVariables : { companyId }),
      ...rest,
    },
    onCompleted(result) {
      if (result?.getPagedUsers?.pageInfo?.pageCount != null) {
        changeParams('pageCount', result.getPagedUsers.pageInfo.pageCount);
      }
    },
  });

  const onChangeSort = (key: string) => {
    setSortableQuery(key, getNextDirection(key, options.sortColumn, options.sortDirection));
  };

  const isEmpty = !data?.getPagedUsers?.items?.length;

  const getUserTypeIcon = (user: User) => {
    const userCompanies = user.companies;
    if (userCompanies && userCompanies.length) {
      const hasDistributor = !!userCompanies.find(company => company?.__typename === 'Distributor');
      if (hasDistributor) {
        return <DeliveryIcon />;
      }
      return <IconProspectFilled />;
    }
    return '';
  };

  const onClickAdd = () => {
    if (isImpersonating) {
      history.push(
        `/users/invite?company-name=${fakeCompanyName}&${type}=${fakeVariables.companyId}`,
      );
    } else {
      history.push('/users/invite');
    }
  };

  const getHeader = (): string => {
    let suffix = '';
    if (!isAdmin) {
      const companyName = isImpersonating
        ? fakeCompanyName
        : companies?.find(c => c?.id === companyId)?.name;
      suffix = ` - ${companyName}`;
    }
    return `${formatMessage(messages.users)}${suffix}`;
  };

  return (
    <div className="users">
      <PageHeader title={getHeader()}>
        {((isAdmin && isImpersonating) || !isAdmin) && (
          <Grid container alignItems="center">
            <Button
              className="button-filled-icon"
              text={formatMessage(messages.addNew)}
              onClick={onClickAdd}
              icon={<AddCircleOutlineIcon />}
            />
          </Grid>
        )}
      </PageHeader>

      <Box>
        <TableHeader
          sortOptions={{
            column: options.sortColumn,
            direction: options.sortDirection,
            onChange: onChangeSort,
          }}
        >
          {({ Sortable }) => (
            <>
              {isAdmin && <TableCell xs={1} label={formatMessage(labelMessages.type)} />}
              <TableCell xs={3}>
                <Sortable name="user.firstName">{formatMessage(labelMessages.firstName)}</Sortable>
              </TableCell>
              <TableCell xs={3}>
                <Sortable name="user.lastName">{formatMessage(labelMessages.lastName)}</Sortable>
              </TableCell>
              <TableCell xs={5}>
                <Sortable name="user.email">{formatMessage(labelMessages.email)}</Sortable>
              </TableCell>
            </>
          )}
        </TableHeader>

        <Loadable loading={loading}>
          {isEmpty ? (
            <EmptyRow />
          ) : (
            data?.getPagedUsers?.items?.map(user => (
              <TableRow key={user.id!} onClick={() => history.push(`/users/${user.id}`)}>
                {isAdmin && (
                  <TableCell body xs={1}>
                    {getUserTypeIcon(user)}
                  </TableCell>
                )}
                <TableCell body xs={3} label={user.firstName} />
                <TableCell body xs={3} label={user.lastName} />
                <TableCell body xs={5} label={user.email} />
              </TableRow>
            ))
          )}

          <Pagination options={options} />
        </Loadable>
      </Box>
    </div>
  );
};

export default Users;
