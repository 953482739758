import { startOfToday } from 'date-fns';
import {
  InventoryItem,
  InventoryItemStatus,
  Module,
  Product,
} from '../types/graphql-generated.types';
import { parseDate } from './date';
import { baseSort } from './sort';

export function hasItemExpired(item: InventoryItem): boolean {
  const expiresAt = item.batch?.expiryDate as string;

  if (expiresAt) {
    return startOfToday() > parseDate(expiresAt);
  }

  return false;
}

export function getStatus(item: InventoryItem): InventoryItemStatus {
  if (item.status === InventoryItemStatus.Available) {
    return hasItemExpired(item) ? InventoryItemStatus.Used : InventoryItemStatus.Available;
  }

  return item.status || InventoryItemStatus.Available;
}

export function sortInventoryItemsFn(a: InventoryItem, b: InventoryItem): number {
  return baseSort((a.product as Module)?.moduleId || '', (b.product as Module)?.moduleId || '');
}

export function getReorder(items: InventoryItem[]): InventoryItem[] {
  return items.filter(i => getStatus(i) === InventoryItemStatus.Used).sort(sortInventoryItemsFn);
}

export const getModulesFromInventory = (inventory: InventoryItem): Module[] => {
  const children = inventory.children || [];

  return children
    .map(item => item.product)
    .filter((product?: Product | null): product is Module => product != null)
    .filter((product: Product, index: number, array: Product[]) => {
      return index === array.findIndex(p => p.id === product.id);
    })
    .sort((a: Module, b: Module): number => {
      const A = a.nameEN || '';
      const B = b.nameEN || '';

      return baseSort(A, B);
    });
};
