import { ApolloClient } from 'apollo-client';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { getOperationDefinition } from 'apollo-utilities';
import omitDeep from 'omit-deep-lodash';
import auth from './auth';
import introspectionQueryResultData from '../types/introspection-results';
import getGuestToken from './guest_token';

const PUBLIC_QUERIES = [
  'getInventoryForKit',
  'searchInventory',
  'searchBatches',
  'updateInventoryItem',
  'reorderInventory',
  'getLabelsForInput',
  'updateKit',
];

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const cleanTypenameLink = new ApolloLink((operation, forward) => {
  const keysToOmit = ['__typename'];

  const def = getOperationDefinition(operation.query);
  if (def && def.operation === 'mutation') {
    // eslint-disable-next-line no-param-reassign
    operation.variables = omitDeep(operation.variables, keysToOmit);
  }
  return forward ? forward(operation) : null;
});

const errorHandler = onError(({ graphQLErrors, networkError }) => {
  if (process.env.NODE_ENV === 'development') {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) =>
        // eslint-disable-next-line no-console
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
      );
    }

    if (networkError) {
      // eslint-disable-next-line no-console
      console.log(`[Network error]: ${networkError}`);
    }
  }
});

const setToken = setContext(async ({ operationName }, { headers }) => {
  let bearerToken: string | undefined;

  if (PUBLIC_QUERIES.includes(operationName || '')) {
    bearerToken = await getGuestToken();
  } else {
    bearerToken = await auth.client!.getTokenSilently();
  }

  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${bearerToken || ''}`,
    },
  };
});

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_URI,
  credentials: 'same-origin',
});

const client = new ApolloClient({
  link: ApolloLink.from([errorHandler, setToken, cleanTypenameLink, httpLink]),
  cache: new InMemoryCache({
    fragmentMatcher,
  }),
});

client.defaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
  },
  query: {
    fetchPolicy: 'network-only',
  },
};

export default client;
