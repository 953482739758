import dompurify from 'dompurify';
import canvg from 'canvg';

export async function convertIconSvgToPng(svg: string, backgroundColor?: string): Promise<string> {
  const purified = dompurify.sanitize(svg);

  const canvas = document.getElementById('icon-canvas') as HTMLCanvasElement | null;

  if (canvas) {
    const ctx = canvas.getContext('2d');

    if (ctx) {
      if (backgroundColor) {
        ctx.fillStyle = `#${backgroundColor}`;
        ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      }

      const instance = canvg.fromString(ctx, purified, {
        offsetX: 6,
        offsetY: 6,
        ignoreClear: true,
      });
      await instance.render();
      const dataURL = canvas.toDataURL('image/png', 1);
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      ctx.beginPath();
      return dataURL;
    }
  }

  return '';
}
