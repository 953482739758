import React, { FC, useState } from 'react';
import { Container, Box, makeStyles, createStyles, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useLazyQuery } from 'react-apollo';
import { ReactComponent as Logo } from '../../assets/modulaid-logo.svg';
import { inventoryMessages, commonMessages } from '../../messages';
import { OutlinedTextField } from '../../components/inputs';
import { SEARCH_INVENTORY } from '../../graphql/queries/inventory';
import { SearchInventoryQuery } from '../../types/graphql-generated.types';
import { Button } from '../../components';
import history from '../../lib/history';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '26px',
    },
    logo: {
      margin: '0 20px 0 12px',
      width: '46px',
      height: '46px',
    },
    headerText: {
      fontSize: '34px',
    },
    card: {
      padding: '32px 16px 16px 32px',
      maxWidth: '344px',
      height: '207px',
      display: 'flex',
      flexDirection: 'column',
      ...theme.mixins.card(),
    },
    prompt: {
      color: theme.palette.text.hint,
      fontSize: '16px',
      marginBottom: '20px',
    },
    input: {
      maxWidth: '328px',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 'auto',
    },
  }),
);

const Landing: FC = () => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const [kitId, setKitId] = useState('');
  const [notFound, setNotFound] = useState(false);

  const [searchInventory, { loading }] = useLazyQuery<SearchInventoryQuery>(SEARCH_INVENTORY, {
    onCompleted(result) {
      if (result.getInventoryForKit?.uid) {
        history.push(`/inventory/${result.getInventoryForKit.uid}`);
      } else {
        setNotFound(true);
      }
    },
  });

  return (
    <Container className={classes.root}>
      <Box className={classes.header}>
        <Logo className={classes.logo} />
        <Typography className={classes.headerText} variant="h1">
          ModulAid
        </Typography>
      </Box>
      <Box className={classes.card}>
        <Typography className={classes.prompt}>
          {formatMessage(inventoryMessages.enterKitId)}
        </Typography>
        <OutlinedTextField
          className={classes.input}
          name="kitId"
          labelText="Kit ID"
          helperText={formatMessage(inventoryMessages.kitIdDescription)}
          value={kitId}
          error={notFound ? { notFound: '' } : undefined}
          onChange={({ value }) => {
            setNotFound(false);
            setKitId(value as string);
          }}
        />
        <Box className={classes.buttonContainer}>
          <Button
            text={formatMessage(commonMessages.continue)}
            className="button-filled"
            onClick={() => searchInventory({ variables: { id: kitId } })}
            loading={loading}
            disabled={kitId === ''}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default Landing;
