import React, { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { Grid, makeStyles, createStyles, Box } from '@material-ui/core';
import { labelMessages, inventoryMessages } from '../../../messages';
import Button from '../../Button';
import { Module, InventoryItem } from '../../../types/graphql-generated.types';
import { TableHeader, TableCell, TableRow } from '../../table';
import { ProductIcon } from '../../products';
import { getModuleName } from '../../../helpers/module';

import { formatDate } from '../../../helpers/date';

const capitalize = (s: string): string => s.charAt(0).toUpperCase() + s.slice(1);

const useStyles = makeStyles(theme =>
  createStyles({
    button: {
      '& span': {
        display: 'inline-block',
        marginRight: '15px',
      },
    },
    icon: {
      justifyContent: 'center',
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    title: {
      color: theme.palette.secondary.main,
      lineHeight: 1,
    },
    subtitle: {
      color: theme.palette.text.secondary,
      fontSize: '14px',
      paddingLeft: '10px',
      fontWeight: 600,
      lineHeight: 1.2,
    },
  }),
);

type Section = 'available' | 'used' | 'reordered' | 'empty';

interface Props {
  items: InventoryItem[];
  title: string;
  subtitle?: string;
  icon: ReactNode;
  type: Section;
  onClick?: (item: InventoryItem) => void;
  onReorder?: () => void;
  onVerify?: () => void;
}

const InventorySection: FC<Props> = ({
  items,
  title,
  subtitle,
  icon,
  type,
  onClick,
  onReorder = () => {},
  onVerify = () => {},
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const renderAdditionalHeaders = () => {
    if (type === 'empty') {
      return null;
    }

    if (type !== 'reordered') {
      return (
        <>
          <TableCell xs={3} label={capitalize(formatMessage(inventoryMessages.batch))} />
          <TableCell xs={3} label={capitalize(formatMessage(inventoryMessages.expires))} />
        </>
      );
    }

    return <TableCell xs={3} label={capitalize(formatMessage(inventoryMessages.ordered))} />;
  };

  const renderAdditionalColumns = (item: InventoryItem) => {
    if (type === 'empty') {
      return null;
    }

    if (type !== 'reordered') {
      return (
        <>
          <TableCell body xs={3} label={item.batch?.lotNumber || item.batchId || '-'} />
          <TableCell
            body
            xs={3}
            label={item.batch?.expiryDate ? formatDate(item.batch?.expiryDate) : '-'}
          />
        </>
      );
    }

    return (
      <TableCell
        body
        xs={3}
        label={
          item.lastReorderQuote?.createdAt ? formatDate(item.lastReorderQuote?.createdAt) : '-'
        }
      />
    );
  };

  return (
    <Grid item xs={12}>
      <div className="form-section">
        <Grid container>
          <Grid item xs={12}>
            <div className="form-section-title">
              <div className="section-icon">{icon}</div>
              <Box className={classes.titleContainer}>
                <p className={classes.title}>{title}</p>
                {subtitle && <span className={classes.subtitle}>{subtitle}</span>}
              </Box>
              {type === 'used' && (
                <Button
                  className="button-filled"
                  text={formatMessage(inventoryMessages.reorder)}
                  onClick={() => onReorder()}
                />
              )}
              {type === 'available' && (
                <Button
                  className="button-filled"
                  text={formatMessage(inventoryMessages.verifyInventory)}
                  onClick={() => onVerify()}
                />
              )}
            </div>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <TableHeader>
                {() => (
                  <>
                    <TableCell classes={{ root: classes.icon }} xs={1} />
                    <TableCell
                      xs={type !== 'reordered' ? 5 : 8}
                      label={formatMessage(labelMessages.name)}
                    />
                    {renderAdditionalHeaders()}
                  </>
                )}
              </TableHeader>
            </Grid>
            {items.map((item, idx) => {
              const module = item.product as Module;
              return (
                // eslint-disable-next-line react/no-array-index-key
                <Grid container key={`${type}-${module.id!}-${idx}`}>
                  <TableRow onClick={onClick ? () => onClick(item) : undefined}>
                    <TableCell classes={{ root: classes.icon }} body ellipsis={false} xs={1}>
                      <ProductIcon rgb={module.rgb} svg={module.icon?.svg} />
                    </TableCell>
                    <TableCell
                      primary
                      body
                      xs={type !== 'reordered' ? 5 : 8}
                      label={getModuleName(module)}
                    />
                    {renderAdditionalColumns(item)}
                  </TableRow>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

export default InventorySection;
