import React, { FC, useState, KeyboardEvent } from 'react';
import { useQuery } from 'react-apollo';
import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import { useParams } from 'react-router-dom';
import { usePageView, useOptions } from '../../../../hooks';
import { GET_BATCHES } from '../../../../graphql/queries/batch';
import { PageHeader, Button, SortableTableHeader, Loadable } from '../../../../components';
import messages from '../../../../messages/products';
import { setSortableQuery } from '../../../../helpers/query';
import { formatDate, DEFAULT_FORMAT } from '../../../../helpers/date';
import { GetBatchesQuery, Batch, SortDirection } from '../../../../types/graphql-generated.types';
import CreateBatch from './Create';
import EditBatch from './Edit';

interface BatchesProps {
  isExpirable: boolean;
}

const Batches: FC<BatchesProps> = ({ isExpirable }) => {
  usePageView('products_boxes_overview');
  const { formatMessage } = useIntl();

  // BatchDialog
  const [open, setOpen] = useState(false);
  const [editingBatch, setEditingBatch] = useState<Batch | null>(null);

  // Overview
  const { id } = useParams();
  const { options } = useOptions({
    sortColumn: 'batch.lotNumber',
    sortDirection: SortDirection.Asc,
  });

  const { loading, data, refetch } = useQuery<GetBatchesQuery>(GET_BATCHES, {
    variables: { ...options, moduleId: parseInt(id!, 10) },
  });

  const onChangeSortColumn = (type: string) => {
    let nextDir = SortDirection.Asc;

    if (options.sortColumn === type) {
      nextDir =
        options.sortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc;
    }

    setSortableQuery(type, nextDir);
  };

  const onClickBatch = (batch: Batch) => {
    setOpen(true);
    setEditingBatch(batch);
  };

  const onCancel = () => {
    setOpen(false);
    setEditingBatch(null);
  };

  const onConfirm = () => {
    refetch();
    onCancel();
  };

  const onKeyPressRow = (event: KeyboardEvent<HTMLSpanElement>, batch: Batch) => {
    if (event.keyCode === 13) {
      onClickBatch(batch);
    }
  };

  const TableHeader: FC<{ name: string; children: string }> = ({ name, children }) => (
    <SortableTableHeader
      name={name}
      current={options.sortColumn}
      direction={options.sortDirection}
      onChange={onChangeSortColumn}
    >
      {children}
    </SortableTableHeader>
  );

  return (
    <>
      <PageHeader isSub title={formatMessage(messages.batches)}>
        <Button
          className="button-outlined-icon"
          text={formatMessage(messages.addBatch)}
          onClick={() => {
            setOpen(true);
          }}
          icon={<AddCircleOutlineIcon />}
        />
      </PageHeader>
      <div className="table batches">
        <Grid className="table-header" container>
          <Grid item xs={4}>
            <TableHeader name="batch.lotNumber">{formatMessage(messages.nr)}</TableHeader>
          </Grid>
          {isExpirable && (
            <Grid item xs={6}>
              <TableHeader name="batch.expiryDate">
                {formatMessage(messages.expiryDate)}
              </TableHeader>
            </Grid>
          )}
        </Grid>

        <Loadable loading={loading}>
          {data?.getBatches?.map(batch => (
            <Grid className="table-row-container" key={batch.id!} container>
              <span
                role="button"
                tabIndex={0}
                onKeyPress={e => onKeyPressRow(e, batch)}
                onClick={() => onClickBatch(batch)}
                className="table-row-link"
              >
                <Grid className="table-row" container>
                  <Grid className="table-row-primary" item xs={4}>
                    <span>{batch.lotNumber}</span>
                  </Grid>
                  {isExpirable && (
                    <Grid item xs={6}>
                      <span>
                        {batch.expiryDate && formatDate(batch.expiryDate, DEFAULT_FORMAT)}
                      </span>
                    </Grid>
                  )}
                </Grid>
              </span>
            </Grid>
          ))}
        </Loadable>
      </div>
      {open && !editingBatch && (
        <CreateBatch
          moduleId={parseInt(id!, 10)}
          isExpirable={isExpirable}
          isOpen={open}
          onCancel={onCancel}
          onConfirm={onConfirm}
        />
      )}
      {open && editingBatch && (
        <EditBatch
          batch={editingBatch}
          isExpirable={isExpirable}
          isOpen={open}
          onCancel={onCancel}
          onConfirm={onConfirm}
        />
      )}
    </>
  );
};

export default Batches;
