import { defineMessages } from 'react-intl';

export default defineMessages({
  products: {
    id: 'products',
    defaultMessage: 'Products',
  },
  addModule: {
    id: 'modules_add',
    defaultMessage: 'Add new module',
  },
  modules: {
    id: 'modules',
    defaultMessage: 'Modules',
  },
  module: {
    id: 'module',
    defaultMessage: 'Module',
  },
  newModule: {
    id: 'new_module',
    defaultMessage: 'New Module',
  },
  moduleDetails: {
    id: 'module_details',
    defaultMessage: 'Module details',
  },
  moduleSticker: {
    id: 'module_sticker',
    defaultMessage: 'Sticker',
  },
  moduleTranslations: {
    id: 'translations',
    defaultMessage: 'Translations',
  },
  name: {
    id: 'name',
    defaultMessage: 'Name',
  },
  nameENDescription: {
    id: 'name_en_description',
    defaultMessage: 'English translation of the name',
  },
  recommended: {
    id: 'recommended',
    defaultMessage: 'Recommended',
  },
  recommendedPrice: {
    id: 'recommended_price',
    defaultMessage: 'Recommended Price',
  },
  articleId: {
    id: 'article_id',
    defaultMessage: 'Article ID',
  },
  articleIdDescription: {
    id: 'article_id_description',
    defaultMessage: 'Distributors Article ID',
  },
  price: {
    id: 'price',
    defaultMessage: 'Price',
  },
  status: {
    id: 'status',
    defaultMessage: 'Status',
  },
  statusActive: {
    id: 'status_active',
    defaultMessage: 'Active',
  },
  statusInactive: {
    id: 'status_inactive',
    defaultMessage: 'Inactive',
  },
  volumeCm3: {
    id: 'volume_cm3',
    defaultMessage: 'Volume cc',
  },
  nameEN: {
    id: 'name_en',
    defaultMessage: 'Name (EN)',
  },
  moduleId: {
    id: 'module_id',
    defaultMessage: 'Module ID',
  },
  moduleSubId: {
    id: 'module_sub_id',
    defaultMessage: 'Module SubID',
  },
  productCode: {
    id: 'product_code',
    defaultMessage: 'Product Code',
  },
  expirable: {
    id: 'expirable',
    defaultMessage: 'Expirable',
  },
  boxes: {
    id: 'boxes',
    defaultMessage: 'Boxes',
  },
  box: {
    id: 'box',
    defaultMessage: 'Box',
  },
  addBox: {
    id: 'boxes_add',
    defaultMessage: 'Add new box',
  },
  newBox: {
    id: 'boxes_new',
    defaultMessage: 'New Box',
  },
  boxDetails: {
    id: 'box_details',
    defaultMessage: 'Box Details',
  },
  stickerSelected: {
    id: 'sticker_selected',
    defaultMessage: 'Icon on sticker',
  },
  stickerEmpty: {
    id: 'sticker_empty',
    defaultMessage: 'Select icon on sticker',
  },
  batches: {
    id: 'batches',
    defaultMessage: 'Batches',
  },
  addBatch: {
    id: 'batches_add',
    defaultMessage: 'Add new batch',
  },
  editBatch: {
    id: 'batches_edit',
    defaultMessage: 'Edit batch',
  },
  nr: {
    id: 'nr',
    defaultMessage: 'Batch Nr',
  },
  expiryDate: {
    id: 'expiryDate',
    defaultMessage: 'Expiry date',
  },
});
