import React, { FC } from 'react';
import { GridProps, Grid, makeStyles } from '@material-ui/core';
import { SortableTableHeader } from '..';
import { SortDirection } from '../../types/graphql-generated.types';

const useStyles = makeStyles({
  root: {
    margin: '0 0 17px 0',
    paddingLeft: '16px',

    '& > .MuiGrid-item:first-of-type': {
      paddingLeft: 0,
    },
  },
});

type TableHeaderProps = GridProps & {
  sortOptions?: {
    column: string;
    direction: SortDirection;
    onChange: (name: string) => void;
  };
  children: (props: { Sortable: FC<{ name: string; children: string }> }) => any;
};

const TableHeader: FC<TableHeaderProps> = props => {
  const { sortOptions, children, ...gridProps } = props;
  const classes = useStyles(props);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Grid container className={classes.root} {...gridProps}>
      {children({
        Sortable: ({ name, children: label }) =>
          sortOptions ? (
            <SortableTableHeader
              name={name}
              current={sortOptions.column}
              direction={sortOptions.direction}
              onChange={sortOptions.onChange}
            >
              {label}
            </SortableTableHeader>
          ) : (
            <>{label}</>
          ),
      })}
    </Grid>
  );
};

export default TableHeader;
