import { gql } from 'apollo-boost';

export const moduleFragments = {
  Item: gql`
    fragment ModuleItem on Module {
      __typename
      id
      moduleId
      moduleSubId
      nameEN
      active
      rgb
      icon {
        id
        name
        svg
      }
    }
  `,
};

export const GET_MODULES = gql`
  query getModules(
    $companyId: Int!
    $sortColumn: String
    $sortDirection: SortDirection
    $filter: ModuleFilter
  ) {
    getModules(
      companyId: $companyId
      sortColumn: $sortColumn
      sortDirection: $sortDirection
      filter: $filter
    ) {
      ...ModuleItem
      volume
      productInfo {
        articleId
        id
        price
      }
    }
  }
  ${moduleFragments.Item}
`;

// TODO: use locale as param for translated name
export const GET_PAGED_MODULES = gql`
  query getPagedModules(
    $offset: Int!
    $limit: Int!
    $sortColumn: String
    $sortDirection: SortDirection
  ) {
    getPagedModules(
      offset: $offset
      limit: $limit
      sortColumn: $sortColumn
      sortDirection: $sortDirection
    ) {
      items {
        ...ModuleItem
        recommendedPrice
      }
      pageInfo {
        itemCount
        pageCount
        total
      }
    }
  }
  ${moduleFragments.Item}
`;

export const GET_PAGED_MODULES_DISTRIBUTOR = gql`
  query getPagedModulesDistributor(
    $companyId: Int!
    $offset: Int!
    $limit: Int!
    $sortColumn: String
    $sortDirection: SortDirection
  ) {
    getPagedModules(
      companyId: $companyId
      offset: $offset
      limit: $limit
      sortColumn: $sortColumn
      sortDirection: $sortDirection
    ) {
      items {
        ...ModuleItem
        productInfo {
          articleId
          id
          price
        }
      }
      pageInfo {
        itemCount
        pageCount
        total
      }
    }
  }
  ${moduleFragments.Item}
`;

export const GET_MODULE = gql`
  query getModule($id: Int!, $companyId: Int) {
    getModule(id: $id, companyId: $companyId) {
      id
      expirable
      active
      cmyk {
        C
        K
        M
        Y
      }
      moduleId
      moduleSubId
      nameAR
      nameDE
      nameEN
      nameES
      nameFR
      nameNL
      namePL
      namePT
      nameRO
      nameTR
      productCode
      recommendedPrice
      rgb
      volume
      icon {
        id
      }
    }
  }
`;

export const CREATE_MODULE = gql`
  mutation createModule($type: ModuleInputCreate!) {
    createModule(module: $type) {
      id
    }
  }
`;

export const UPDATE_MODULE = gql`
  mutation updateModule($id: Int!, $type: ModuleInputUpdate!) {
    updateModule(id: $id, module: $type) {
      id
    }
  }
`;

export const GET_MODULE_ICONS = gql`
  query getModuleIcons {
    getModuleIcons {
      id
      svg
    }
  }
`;
