import { defineMessages } from 'react-intl';

export default defineMessages({
  user: {
    id: 'user',
    defaultMessage: 'User',
  },
  users: {
    id: 'users',
    defaultMessage: 'Users',
  },
  companies: {
    id: 'companies',
    defaultMessage: 'companies',
  },
  newUser: {
    id: 'new_user',
    defaultMessage: 'Add user',
  },
  noCompanies: {
    id: 'no_companies',
    defaultMessage: 'This user is not linked to any company',
  },
  createLabel: {
    id: 'invite',
    defaultMessage: 'Invite',
  },
});
