import React, { useEffect, ComponentType } from 'react';
import { Route, withRouter, RouteComponentProps, Redirect } from 'react-router-dom';
import { useAuth0 } from '../providers/AuthProvider';
import { Role, useUser } from '../providers/UserProvider';

interface PrivateRouteProps extends RouteComponentProps {
  component: ComponentType<RouteComponentProps<any>> | ComponentType<any>;
  path: string;
  roles?: Role[];
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, path, roles = [], ...rest } = props;
  const { isAuthenticated, loginWithRedirect, loading } = useAuth0();
  const { role } = useUser();

  useEffect(() => {
    const fn = async () => {
      if (!isAuthenticated) {
        await loginWithRedirect({
          appState: { targetUrl: path },
        });
      }
    };
    fn();
  }, [loading, isAuthenticated, loginWithRedirect, path]);

  const render = (renderProps: RouteComponentProps<any>) => {
    if (isAuthenticated && (!roles.length || roles.includes(role))) {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Component {...renderProps} />;
    }

    if (isAuthenticated) {
      return <Redirect to="/" />;
    }

    return null;
  };

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Route exact path={path} render={render} {...rest} />;
};

export default withRouter(PrivateRoute);
