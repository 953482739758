import React from 'react';
import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import { useMutation, useQuery } from 'react-apollo';
import PageHeader from '../../../components/PageHeader';
import FormActions from '../../../components/FormActions';
import { usePageView, useForm } from '../../../hooks';
import messages from '../../../messages/products';
import { Details, Translations, Sticker } from '../../../components/products/modules/sections';
import { CREATE_MODULE, GET_MODULE_ICONS } from '../../../graphql/queries/module';
import { GetModuleIconsQuery, ModuleInputUpdate } from '../../../types/graphql-generated.types';
import { getModuleFields } from '../../../constants/form-constants';
import { Loadable } from '../../../components';

const initialFields = getModuleFields();

const CreateModule = () => {
  usePageView('product_new_module');

  const { formatMessage } = useIntl();
  const { loading, data } = useQuery<GetModuleIconsQuery>(GET_MODULE_ICONS);

  const [createModule] = useMutation(CREATE_MODULE);
  const { fields, handleChange, handleCreateUpdate, errors, disabled } = useForm<ModuleInputUpdate>(
    createModule,
    initialFields,
    true,
  );

  return (
    <>
      <PageHeader title={formatMessage(messages.newModule)} back="/products" />
      <div className="add-edit-form module-form">
        <Grid container spacing={3}>
          <Details fields={fields} errors={errors} onChange={handleChange} />
          <Translations fields={fields} errors={errors} onChange={handleChange} />
          <Loadable loading={loading}>
            <Sticker
              icons={data?.getModuleIcons}
              fields={fields}
              errors={errors}
              onChange={handleChange}
            />
          </Loadable>
        </Grid>
      </div>
      <FormActions
        onCreateUpdate={() => handleCreateUpdate('/products')}
        create
        disabled={disabled}
      />
    </>
  );
};

export default CreateModule;
