import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import AssignmentIcon from '@material-ui/icons/AssignmentOutlined';
import classNames from 'classnames';
import { Grid, makeStyles, createStyles, Box } from '@material-ui/core';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import Button from '../../Button';
import { commonMessages, quoteMessages, productMessages } from '../../../messages';
import { OutlinedTextField } from '../../inputs';
import { InventoryItemUpdate, Label } from '../../../types/graphql-generated.types';
import { LabelsInput } from '..';
import { FieldUpdate } from '../../../helpers/form';
import { useAuth0 } from '../../../providers/AuthProvider';

const useStyles = makeStyles(theme =>
  createStyles({
    location: {
      display: 'flex',
      alignItems: 'center',
    },
    locationInput: {
      flexGrow: 1,
    },
    locationButton: {
      marginLeft: theme.spacing(),
      padding: `26px ${theme.spacing()}px`,
    },
    locationButtonBorderless: {
      marginLeft: theme.spacing(),
      padding: '26px 10px',
      height: '56px',
    },
  }),
);

interface Props {
  customerId?: number | null;
  labels?: Label[] | null;
  location?: string | null;
  reference?: string | null;
  box?: string | null;
  isSavingLabels: boolean;
  onUpdateKit: (update: InventoryItemUpdate) => Promise<boolean>;
}

const Info: FC<Props> = props => {
  const {
    customerId,
    labels,
    reference,
    box,
    isSavingLabels,
    onUpdateKit,
    location: prevLocation,
  } = props;
  const { isAuthenticated } = useAuth0();
  const { formatMessage } = useIntl();
  const classes = useStyles(props);

  const [isSaving, setIsSaving] = useState(false);
  const [location, setLocation] = useState(prevLocation);
  const [isEditingLocation, setIsEditingLocation] = useState(false);

  const onCancel = () => {
    setIsEditingLocation(false);
    setLocation(prevLocation);
  };

  const onChangeLocation = ({ value }: FieldUpdate) => setLocation(value as string);
  const onBlurLocation = () => setLocation(location?.trim());

  const onSaveLocation = async () => {
    setIsSaving(true);
    await onUpdateKit({ location });
    setIsSaving(false);
    setIsEditingLocation(false);
  };

  return (
    <Grid item xs={12}>
      <div className="form-section">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <div className="form-section-title">
              <div className="section-icon">
                <AssignmentIcon />
              </div>
              <p>{formatMessage(commonMessages.info)}</p>
            </div>
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.location}>
              <OutlinedTextField
                disabled={!isEditingLocation}
                className={classes.locationInput}
                name="location"
                labelText={formatMessage(quoteMessages.location)}
                value={location}
                onChange={onChangeLocation}
                onBlur={onBlurLocation}
                maxLength={50}
              />
              {isEditingLocation ? (
                <>
                  <Button
                    className={classNames(classes.locationButtonBorderless, 'button-filled')}
                    icon={<CheckOutlinedIcon />}
                    disabled={!location || !location.length || location === prevLocation}
                    onClick={onSaveLocation}
                    loading={isSaving}
                  />
                  <Button
                    className={classNames(classes.locationButton, 'button-outlined')}
                    icon={<ClearOutlinedIcon />}
                    onClick={onCancel}
                  />
                </>
              ) : (
                isAuthenticated && (
                  <Button
                    className={classNames(classes.locationButton, 'button-filled')}
                    icon={<EditOutlinedIcon />}
                    onClick={() => setIsEditingLocation(true)}
                  />
                )
              )}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <OutlinedTextField
              disabled
              name="box"
              labelText={formatMessage(productMessages.box)}
              value={box}
            />
          </Grid>
          <Grid item xs={6}>
            <OutlinedTextField
              disabled
              name="reference"
              labelText={formatMessage(quoteMessages.reference)}
              value={reference}
            />
          </Grid>
          <Grid item xs={6}>
            <LabelsInput
              onChange={l => onUpdateKit({ labels: { replace: l } })}
              disabled={!isAuthenticated}
              isSaving={isSavingLabels}
              customerId={customerId}
              labels={labels || []}
            />
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

export default Info;
