import React, { FC } from 'react';
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  useTheme,
} from '@material-ui/core';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { inventoryMessages } from '../../../messages';
import Button from '../../Button';
import { useDialogStyles } from '../hooks';

interface Props {
  onClose: () => void;
}

const OrderConfirmedDialog: FC<Props> = props => {
  const { onClose } = props;
  const classes = useDialogStyles(props);
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const iconStyle = {
    backgroundColor: theme.palette.secondary.light,
    padding: '5px',
    borderRadius: '22px',
    display: 'flex',
  };

  return (
    <Dialog fullWidth open onClose={onClose} classes={{ paper: classes.root }}>
      <DialogTitle className={classNames(classes.paddingH, classes.paddingV)}>
        <Grid container alignItems="center">
          <Grid item className={classes.icon}>
            <CheckOutlinedIcon style={iconStyle} fontSize="large" />
          </Grid>
          <Grid item xs className={classes.name}>
            <Typography align="left" variant="h3" noWrap>
              {formatMessage(inventoryMessages.orderConfirmedTitle)}
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.paddingH}>
        <Typography className={classes.description}>
          {formatMessage(inventoryMessages.orderConfirmedDescription)}
        </Typography>
      </DialogContent>
      <DialogActions className={classNames(classes.paddingH, classes.paddingV, classes.actions)}>
        <Button
          className="button-filled"
          onClick={() => onClose()}
          text={formatMessage(inventoryMessages.backToKit)}
        />
      </DialogActions>
    </Dialog>
  );
};

export default OrderConfirmedDialog;
