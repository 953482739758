import { defineMessages } from 'react-intl';

export default defineMessages({
  quote: {
    id: 'quote',
    defaultMessage: 'Quote',
  },
  quotes: {
    id: 'quotes',
    defaultMessage: 'Quotes',
  },
  createQuote: {
    id: 'create_quote',
    defaultMessage: 'Create Quote',
  },
  newQuote: {
    id: 'new_quote',
    defaultMessage: 'New Quote',
  },
  general: {
    id: 'general',
    defaultMessage: 'General',
  },
  location: {
    id: 'location',
    defaultMessage: 'Location',
  },
  locationInfo: {
    id: 'quote_location_info',
    defaultMessage: "Location will be shown on the kit's info label (max 30 chars).",
  },
  kits: {
    id: 'kits',
    defaultMessage: 'Kits',
  },
  deliveryAndDiscount: {
    id: 'delivery_discount',
    defaultMessage: 'Delivery & Discount',
  },
  noKits: {
    id: 'quote_kits_empty',
    defaultMessage: 'No kits added',
  },
  noModules: {
    id: 'quote_modules_empty',
    defaultMessage: 'No modules selected',
  },
  noBoxes: {
    id: 'quote_boxes_empty',
    defaultMessage: 'No boxes selected',
  },
  total: {
    id: 'total',
    defaultMessage: 'Total',
  },
  discount: {
    id: 'discount',
    defaultMessage: 'Discount',
  },
  discountedTotal: {
    id: 'discounted_total',
    defaultMessage: 'Discounted total',
  },
  deliveryCost: {
    id: 'delivery_cost',
    defaultMessage: 'Delivery cost',
  },
  grandTotal: {
    id: 'grand_total',
    defaultMessage: 'Grand Total',
  },
  selectSuppModules: {
    id: 'quote_modules_select',
    defaultMessage: 'Select modules',
  },
  modulesFor: {
    id: 'quote_modules_for',
    defaultMessage: '{itemCount, plural, one {# module for} other {# modules for}}',
  },
  discardTitle: {
    id: 'quote_discard_title',
    defaultMessage: 'Changes will be lost',
  },
  modulesDiscardContent: {
    id: 'quote_discard_modules_content',
    defaultMessage:
      'Your current module selection will not be saved. Any changes you made will be lost. Discard changes?',
  },
  boxesDiscardContent: {
    id: 'quote_discard_boxes_content',
    defaultMessage:
      'Your current box selection will not be saved. Any changes you made will be lost. Discard changes?',
  },
  selectSuppBoxes: {
    id: 'quote_boxes_select',
    defaultMessage: 'Select boxes',
  },
  boxesFor: {
    id: 'quote_boxes_for',
    defaultMessage: '{itemCount, plural, one {# box for} other {# boxes for}}',
  },
  addKit: {
    id: 'quote_kits_add',
    defaultMessage: 'Add new kit',
  },
  editKit: {
    id: 'quote_kits_edit',
    defaultMessage: 'Edit kit',
  },
  boxAt: {
    id: 'quote_kits_box_at',
    defaultMessage: 'box {name} at',
  },
  modulesAt: {
    id: 'quote_kits_modules_at',
    defaultMessage: '{itemCount, plural, one {# module at} other {# modules at}}',
  },
  kitDiscardContent: {
    id: 'quote_discard_kit_content',
    defaultMessage:
      'This kit will not be saved, and your current configuration will be lost. Discard changes?',
  },
  deleteKitTitle: {
    id: 'quote_delete_kit_title',
    defaultMessage: 'Deleting kit',
  },
  deleteKit: {
    id: 'quote_delete_kit_content',
    defaultMessage:
      'The kit will be permanently deleted. This action cannot be reverted. Delete kit?',
  },
  quoteDiscardContent: {
    id: 'quote_discard_content',
    defaultMessage: 'All changes will be lost. Are you sure?',
  },
  quoteCreateTitle: {
    id: 'quote_created_dialog_title',
    defaultMessage: 'Creating quote',
  },
  quoteCreateContent: {
    id: 'quote_create_dialog_content',
    defaultMessage: 'Once created, a quote can no longer be modified. Create quote?',
  },
  acceptQuoteTitle: {
    id: 'quote_accept_dialog_title',
    defaultMessage: 'Accepting quote',
  },
  acceptQuoteContent: {
    id: 'quote_accept_dialog_content',
    defaultMessage: 'Are you sure you want to accept this quote?',
  },
  rejectQuoteTitle: {
    id: 'quote_reject_dialog_title',
    defaultMessage: 'Rejecting quote',
  },
  rejectQuoteContent: {
    id: 'quote_reject_dialog_content',
    defaultMessage: 'Are you sure you want to reject this quote?',
  },
  createdBy: {
    id: 'quote_created_by',
    defaultMessage: 'Created by',
  },
  createdAt: {
    id: 'quote_created_at',
    defaultMessage: 'Created at',
  },
  accepted: {
    id: 'quote_status_accepted',
    defaultMessage: 'Accepted',
  },
  pending: {
    id: 'quote_status_pending',
    defaultMessage: 'Pending',
  },
  rejected: {
    id: 'quote_status_rejected',
    defaultMessage: 'Rejected',
  },
  accept: {
    id: 'quote_status_accept',
    defaultMessage: 'Accept',
  },
  reject: {
    id: 'quote_status_reject',
    defaultMessage: 'Reject',
  },
  reference: {
    id: 'quote_reference',
    defaultMessage: 'Reference',
  },
  deliveryAddress: {
    id: 'quote_delivery_address',
    defaultMessage: 'Delivery address',
  },
  vatExcluded: {
    id: 'vat_excluded',
    defaultMessage: 'VAT excluded',
  },
  reorderFor: {
    id: 'quote_reorder_for',
    defaultMessage: 'Reorder for {location}',
  },
  saveChanges: {
    id: 'quote_save_changes',
    defaultMessage: 'Save changes',
  },
  copyKit: {
    id: 'quote_copy_kit',
    defaultMessage: 'Copy kit',
  },
  copyKitDescription: {
    id: 'quote_copy_kit_description',
    defaultMessage: 'How many copies do you wish to create?',
  },
  copyKitInputHelper: {
    id: 'quote_copy_kit_input_helper',
    defaultMessage: 'Maximum {maxCopies} copies',
  },
  showAllKits: {
    id: 'quote_price_show_all_kits',
    defaultMessage: 'Show all kits',
  },
  showLessKits: {
    id: 'quote_price_show_less_kits',
    defaultMessage: 'Show less kits',
  },
  shippingAddress: {
    id: 'shipping_address',
    defaultMessage: 'Shipping Address',
  },
  availableShippingAddresses: {
    id: 'shipping_addresses_available',
    defaultMessage: 'Available shipping addresses',
  },
});
