import React from 'react';
import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import { useMutation, useQuery } from 'react-apollo';
import { useParams } from 'react-router-dom';
import { PageHeader, FormActions, Loadable } from '../../components';
import { useUser } from '../../providers/UserProvider';
import { General, Companies } from '../../components/users/section';
import { useForm, usePageView } from '../../hooks';
import { GET_USER, UPDATE_USER } from '../../graphql/queries/user';
import { userFields } from '../../constants/form-constants';
import { UserForm } from '../../types/user.types';
import history from '../../lib/history';
import messages from '../../messages/common';

const Detail = () => {
  usePageView('user_detail');

  const { formatMessage } = useIntl();

  const isProfile = history.location.pathname === '/profile';
  const { userId, isAdmin } = useUser();

  const { id } = useParams();
  const parsedId = isProfile ? userId : parseInt(id!, 10);

  const [updateUser, { loading: isSaving }] = useMutation<UserForm>(UPDATE_USER);
  const { fields, errors, disabled, handleChange, handleCreateUpdate, updateFields } = useForm(
    updateUser,
    userFields,
    false,
    parsedId,
  );

  const { loading } = useQuery(GET_USER, {
    variables: { id: parsedId },
    onCompleted(result) {
      if (result.getUser) {
        const { id: _, ...noId } = result.getUser;
        updateFields(noId);
      } else {
        history.push('/users');
      }
    },
  });

  const serializer = (f: UserForm) => {
    const { email, firstName, lastName } = f;
    return { email, firstName, lastName };
  };

  const firstName = fields.firstName || '';
  const lastName = fields.lastName || '';

  return (
    <Loadable loading={loading}>
      <PageHeader
        title={isProfile ? formatMessage(messages.myProfile) : `${firstName} ${lastName}`}
        back={isProfile ? undefined : '/users'}
      />
      <div className="add-edit-form">
        <Grid container spacing={3}>
          <General fields={fields} onChange={handleChange} errors={errors} isUpdate />
        </Grid>

        {isAdmin && (
          <Grid container spacing={3}>
            <Companies user={fields} />
          </Grid>
        )}
      </div>

      <FormActions
        onCreateUpdate={() => handleCreateUpdate('/users', { userId: parsedId }, serializer)}
        disabled={disabled}
        loading={isSaving}
      />
    </Loadable>
  );
};

export default Detail;
