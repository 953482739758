import React from 'react';
import { Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { Constraint } from '../../../types/form.types';
import { OutlinedTextField } from '../../inputs';
import { ReactComponent as ContactIcon } from '../../../assets/icons/icons-contact.svg';
import { UserSectionProps, GeneralSection } from '../../../types/user.types';
import { commonMessages, labelMessages } from '../../../messages';

const General: React.FC<UserSectionProps<GeneralSection>> = ({
  onChange,
  fields,
  errors,
  isUpdate,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Grid item xs={12}>
      <div className="form-section">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className="form-section-title">
              <div className="section-icon">
                <ContactIcon />
              </div>
              <p>{formatMessage(commonMessages.general)}</p>
            </div>
          </Grid>

          <Grid item xs={6}>
            <OutlinedTextField
              name="firstName"
              labelText={formatMessage(labelMessages.firstName)}
              maxLength={255}
              value={fields?.firstName}
              onChange={onChange}
              error={errors?.firstName as Constraint}
            />
          </Grid>

          <Grid item xs={6}>
            <OutlinedTextField
              name="lastName"
              labelText={formatMessage(labelMessages.lastName)}
              maxLength={255}
              value={fields?.lastName}
              onChange={onChange}
              error={errors?.lastName as Constraint}
            />
          </Grid>

          <Grid item xs={6}>
            <OutlinedTextField
              name="email"
              labelText={formatMessage(labelMessages.email)}
              maxLength={255}
              value={fields.email}
              onChange={onChange}
              error={(errors?.additionalErrors || errors?.email) as Constraint}
              disabled={isUpdate}
            />
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};
export default General;
