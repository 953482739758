import React, { FC } from 'react';
import { makeStyles, createStyles, Typography, Divider, Grid, ButtonBase } from '@material-ui/core';
import InventoryItemCard from './InventoryItemCard';
import { InventoryItem } from '../../types/graphql-generated.types';

const useStyles = makeStyles(theme =>
  createStyles({
    statusTitle: {
      marginBottom: '8px',
    },
    itemContainer: {
      marginBottom: '8px',
    },
    divider: {
      margin: '8px 0 16px 0',
      color: theme.palette.divider,
    },
    item: {
      width: 'calc(100% - 8px)',
    },
    clickable: {
      width: '100%',
      display: 'block',
    },
  }),
);

interface Props {
  items: InventoryItem[];
  title: string;
  ordered?: boolean;
  divider?: boolean;
  onClick: (item: InventoryItem) => void;
}

const InventorySection: FC<Props> = props => {
  const { items, ordered, title, onClick, divider = true } = props;
  const classes = useStyles(props);

  return (
    <>
      {divider && <Divider className={classes.divider} />}
      <Typography className={classes.statusTitle} variant="h4">
        {title}
      </Typography>
      <Grid container>
        {items.map(
          i =>
            i.product && (
              <Grid
                key={i.id!}
                className={classes.itemContainer}
                container
                justify="flex-end"
                item
                xs={12}
              >
                <Grid item className={classes.item}>
                  <ButtonBase
                    className={classes.clickable}
                    disableRipple
                    component="div"
                    onClick={() => onClick(i)}
                  >
                    <InventoryItemCard key={i.id!} item={i} ordered={ordered} />
                  </ButtonBase>
                </Grid>
              </Grid>
            ),
        )}
      </Grid>
    </>
  );
};

export default InventorySection;
