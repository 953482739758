import React, { FC } from 'react';
import classNames from 'classnames';
import { Box, makeStyles, createStyles, Typography } from '@material-ui/core';
import { ReactComponent as Logo } from '../../../assets/modulaid-logo.svg';

const useStyles = makeStyles(theme =>
  createStyles({
    header: {
      padding: `0 ${theme.spacing(3)}px`,
      boxSizing: 'border-box',
    },
    headerText: {
      fontSize: '36px',
      fontWeight: 'bold',
    },
    headerLogo: {
      width: '44px',
      height: '44px',
      marginRight: theme.spacing(2),
    },
  }),
);

const Header: FC = props => {
  const classes = useStyles(props);

  return (
    <Box className={classNames('main-header', classes.header)}>
      <Logo className={classes.headerLogo} />
      <Typography className={classes.headerText} variant="h1">
        ModulAid
      </Typography>
    </Box>
  );
};

export default Header;
