import React, { FC, useRef, useState, useEffect, RefObject } from 'react';
import { useIntl } from 'react-intl';
import { useQuery } from 'react-apollo';
import xorBy from 'lodash.xorby';
import {
  Label,
  GetLabelsForInputQuery,
  GetLabelsForInputQueryVariables,
} from '../../types/graphql-generated.types';
import { inventoryMessages } from '../../messages';
import { AutocompleteTagField } from '../inputs';
import { GET_LABELS_FOR_INPUT } from '../../graphql/queries/label';
import { Option } from '../inputs/AutocompleteTagField';

interface Props {
  labels: Label[];
  customerId?: number | null;
  disabled: boolean;
  isSaving: boolean;
  onChange: (labels: Label[]) => Promise<boolean>;
}

const LabelsInput: FC<Props> = props => {
  const { labels, customerId, onChange, disabled, isSaving } = props;
  const { formatMessage } = useIntl();

  const previousLabels = useRef(labels);
  const [inputLabels, setInputLabels] = useState(labels);

  const { data, loading, refetch } = useQuery<
    GetLabelsForInputQuery,
    GetLabelsForInputQueryVariables
  >(GET_LABELS_FOR_INPUT, {
    variables: { customerId: customerId! },
    skip: customerId == null,
  });

  const onChangeLabels = async (changes: Label[], ref: RefObject<HTMLInputElement>) => {
    setInputLabels(changes);
    const error = await onChange(changes);

    // Update autocomplete options
    refetch();

    // Refocus input field because we disable on onChange
    ref.current?.focus();

    // Reset inputLabels on error (back to saved labels)
    if (error) {
      setInputLabels(labels);
    }
  };

  useEffect(() => {
    // We need to update the inputLabels with any newly saved
    // labels to make sure that on subsequent changes we
    // send any previously created labels to the API and not
    // the "draft" label without ID.
    if (xorBy(previousLabels.current, labels, 'id').length) {
      // Maintain the same order as in the input
      const sortedLabels = [...labels].sort(
        (a, b) =>
          inputLabels.findIndex(l => l.name === a.name) -
          inputLabels.findIndex(l => l.name === b.name),
      );
      previousLabels.current = sortedLabels;
      setInputLabels(sortedLabels);
      refetch();
    }
  }, [labels, refetch, inputLabels]);

  return (
    <AutocompleteTagField
      name="labels"
      loading={isSaving || loading}
      disabled={isSaving || disabled}
      options={(data?.getLabels as Option[] | undefined) ?? []}
      value={inputLabels}
      onChange={({ value }, ref) => onChangeLabels(value as Label[], ref)}
      noOptionsLabel={formatMessage(inventoryMessages.noLabels)}
      optionKey="name"
      labelText={formatMessage(inventoryMessages.labels)}
      helperText={formatMessage(inventoryMessages.labelsHelperText)}
    />
  );
};

export default LabelsInput;
