import React from 'react';
import { init, getPreferences, onPreferencesChanged } from 'cookie-though';
import Header from './Header';
import { Auth0User } from '../providers/AuthProvider';
import { useUser, Role } from '../providers/UserProvider';
import { CompanySelector } from '../views/profile';
import { DEFAULT_COOKIE_THOUGH_CONFIG } from '../constants/cookie-though';
import {
  initializeFirebase,
  isAnalyticsEnabled,
  disableAnalytics,
  enableAnalytics,
} from '../initializers/analytics';

interface Props {
  user?: Auth0User;
  loading: boolean;
  onLogout: () => void;
}

initializeFirebase();

init(DEFAULT_COOKIE_THOUGH_CONFIG);
if (isAnalyticsEnabled(getPreferences())) {
  enableAnalytics();
}

onPreferencesChanged(cookiePreferences => {
  if (isAnalyticsEnabled(cookiePreferences)) {
    return enableAnalytics();
  }

  return disableAnalytics();
});

const CompanyContainer: React.FC<Props> = ({ user, onLogout, loading, children }) => {
  const { companyId, role, isAuthenticated } = useUser();

  if (isAuthenticated && !companyId && role !== Role.Admin) {
    return <CompanySelector />;
  }

  const handleLogout = () => {
    onLogout();
  };

  return (
    <>
      <Header user={user} onLogout={handleLogout} loading={loading} />
      {children}
    </>
  );
};

export default CompanyContainer;
