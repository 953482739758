import React, { FC } from 'react';
import { Switch, Route, RouteComponentProps, Redirect } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import { Container, makeStyles, createStyles } from '@material-ui/core';
import Overview from './Overview';
import { GET_INVENTORY } from '../../graphql/queries/inventory';
import { GetInventoryForKitQuery } from '../../types/graphql-generated.types';
import history from '../../lib/history';
import { Loadable, ScrollToTop } from '../../components';
import Reorder from './Reorder';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      padding: '16px 8px',

      [theme.breakpoints.up('md')]: {
        padding: 0,
      },
    },
  }),
);

interface Params {
  id: string;
}

const Inventory: FC<RouteComponentProps<Params>> = ({ match }) => {
  const { url, params } = match;
  const classes = useStyles();

  const { data, loading, refetch } = useQuery<GetInventoryForKitQuery>(GET_INVENTORY, {
    variables: { id: params.id },
    onCompleted(result) {
      if (!result.getInventoryForKit) {
        history.push('/');
      }
    },
  });

  return (
    <Container className={classes.root}>
      <ScrollToTop />
      <Loadable loading={loading}>
        {data?.getInventoryForKit && (
          <Switch>
            <Route path={`${url}/reorder`}>
              <Reorder inventory={data.getInventoryForKit} refetch={refetch} />
            </Route>
            <Route exact path={`${url}`}>
              <Overview inventory={data.getInventoryForKit!} refetch={refetch} />
            </Route>
            <Redirect to="/" />
          </Switch>
        )}
      </Loadable>
    </Container>
  );
};

export default Inventory;
