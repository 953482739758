import React, { FC } from 'react';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import { makeStyles, createStyles, Box, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { QuoteStatus as Status } from '../../types/graphql-generated.types';
import { quoteMessages } from '../../messages';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.text.secondary,
    },
    icon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      height: '22px',
      width: '22px',
      marginRight: '10px',
    },
    [Status.Accepted]: {
      backgroundColor: theme.palette.statusBackground.light,

      '& svg': {
        fill: theme.palette.status.light,
      },
    },
    [Status.Pending]: {
      backgroundColor: theme.palette.statusBackground.main,

      '& svg': {
        fill: theme.palette.status.main,
      },
    },
    [Status.Rejected]: {
      backgroundColor: theme.palette.statusBackground.dark,

      '& svg': {
        fill: theme.palette.status.dark,
      },
    },
  }),
);

interface QuoteStatusProps {
  status: Status;
}

const QuoteStatus: FC<QuoteStatusProps> = props => {
  const { status } = props;

  const classes = useStyles(props);
  const { formatMessage } = useIntl();

  const StatusMap = {
    [Status.Accepted]: {
      Icon: CheckCircleOutlineIcon,
      message: formatMessage(quoteMessages.accepted),
      className: classes[Status.Accepted],
    },
    [Status.Pending]: {
      Icon: AvTimerIcon,
      message: formatMessage(quoteMessages.pending),
      className: classes[Status.Pending],
    },
    [Status.Rejected]: {
      Icon: NotInterestedIcon,
      message: formatMessage(quoteMessages.rejected),
      className: classes[Status.Rejected],
    },
  };

  const { Icon, message, className } = StatusMap[status];

  return (
    <Box className={classes.root}>
      <Box className={classNames(classes.icon, className)}>
        <Icon fontSize="small" />
      </Box>
      <Typography noWrap>{message}</Typography>
    </Box>
  );
};

export default QuoteStatus;
