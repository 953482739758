import React from 'react';

import { Redirect } from 'react-router-dom';
import { Role, useUser } from '../providers/UserProvider';

const HomeRoute = () => {
  const { whenRole } = useUser();

  const redirectTo = whenRole({
    [Role.Distributor]: '/customers',
    [Role.Customer]: '/quotes',
    [Role.Default]: '/distributors',
  });

  return <Redirect exact from="/" to={redirectTo} />;
};

export default HomeRoute;
