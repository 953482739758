import { defineMessages } from 'react-intl';

export default defineMessages({
  nl: {
    id: 'lang_nl',
    defaultMessage: 'Dutch (NL)',
  },
  fr: {
    id: 'lang_fr',
    defaultMessage: 'French (FR)',
  },
  de: {
    id: 'lang_de',
    defaultMessage: 'German (DE)',
  },
  es: {
    id: 'lang_es',
    defaultMessage: 'Spanish (ES)',
  },
  pl: {
    id: 'lang_pl',
    defaultMessage: 'Polish (PL)',
  },
  pt: {
    id: 'lang_pt',
    defaultMessage: 'Portuguese (PT)',
  },
  ro: {
    id: 'lang_ro',
    defaultMessage: 'Romanian (RO)',
  },
  tr: {
    id: 'lang_tr',
    defaultMessage: 'Turkish (TR)',
  },
  ar: {
    id: 'lang_ar',
    defaultMessage: 'Arabic (AR)',
  },
});
