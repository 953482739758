import React from 'react';
import { Grid } from '@material-ui/core';
import { useMutation, useQuery } from 'react-apollo';
import { useParams } from 'react-router';
import PageHeader from '../../../components/PageHeader';
import FormActions from '../../../components/FormActions';
import { usePageView, useForm } from '../../../hooks';
import { GET_BOX, UPDATE_BOX } from '../../../graphql/queries/box';
import { GetBoxQuery, BoxInputUpdate } from '../../../types/graphql-generated.types';
import { Details } from '../../../components/products/boxes/sections';
import { getBoxFields } from '../../../constants/form-constants';
import { Loadable } from '../../../components';

const initialFields = getBoxFields();

const EditBox = () => {
  usePageView('product_box_detail');

  const { id } = useParams();
  const boxId = parseInt(id!, 10);

  const [updateBox, { loading: isSaving }] = useMutation(UPDATE_BOX);
  const { fields, handleChange, errors, handleCreateUpdate, disabled, updateFields } = useForm<
    BoxInputUpdate
  >(updateBox, initialFields, false, boxId);

  const { loading } = useQuery<GetBoxQuery>(GET_BOX, {
    variables: {
      id: parseInt(id!, 10),
    },
    onCompleted(result) {
      if (result.getBox) {
        const { id: _, ...box } = result.getBox as BoxInputUpdate & { id: number };
        updateFields(box);
      }
    },
  });

  return (
    <Loadable loading={loading}>
      <PageHeader title={fields?.name || ''} back="/products/boxes" />
      <div className="add-edit-form box-form">
        <Grid container spacing={3}>
          <Details fields={fields} errors={errors} onChange={handleChange} />
        </Grid>
      </div>
      <FormActions
        onCreateUpdate={() => handleCreateUpdate('/products/boxes')}
        disabled={disabled}
        loading={isSaving}
      />
    </Loadable>
  );
};

export default EditBox;
