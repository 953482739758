import React from 'react';
import { DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { InputProps } from '../../types/form.types';
import Field from '../form/Field';
import { DEFAULT_FORMAT, formatDateISO, formatDate } from '../../helpers/date';

type OutlinedDateFieldProps = InputProps & {
  disablePast?: boolean;
};

const OutlinedDateField: React.FC<OutlinedDateFieldProps> = ({
  name,
  value,
  labelText,
  error,
  helperText,
  disablePast,
  onChange = () => {},
}) => {
  const onChangeDate = (date: MaterialUiPickersDate) => {
    if (date) {
      onChange({
        path: name,
        value: formatDateISO(date, {
          representation: 'date',
        }),
      });
    }
  };

  const formatLabel = (date: MaterialUiPickersDate) => formatDate(date, DEFAULT_FORMAT);

  return (
    <Field className="outlined-date-container">
      {({ Error, HelperText }) => (
        <>
          <DatePicker
            autoOk
            color="primary"
            labelFunc={formatLabel}
            className="outlined-date-field"
            disablePast={disablePast}
            label={labelText || ''}
            error={!!error}
            variant="inline"
            inputVariant="outlined"
            value={value as string}
            onChange={onChangeDate}
            InputProps={{
              classes: {
                root: 'input-outlined',
                focused: 'input-focused',
                notchedOutline: 'input-notchedOutline',
                input: 'input-text',
                error: 'input-error',
              },
            }}
          />
          {error && <Error errors={error} />}
          {helperText && <HelperText text={helperText} />}
        </>
      )}
    </Field>
  );
};

export default OutlinedDateField;
