import React, { ReactNode } from 'react';
import classNames from 'classnames';
import BackToOverview from './BackToOverview';

type PageHeaderProps = {
  title: string;
  children?: ReactNode;
  back?: string;
  subtitle?: string;
  isSub?: boolean;
};

const PageHeader = ({ title, children, back, subtitle, isSub }: PageHeaderProps) => {
  const headerClass = classNames({
    'page-header': true,
    'page-sub-header': isSub,
  });

  return (
    <div className={headerClass}>
      {back ? <BackToOverview back={back} /> : null}
      <div className="page-header-wrapper">
        <div className="page-header-title">
          <h1>
            {title} <span>{subtitle}</span>
          </h1>
        </div>
        {children && <div className="page-header-actions">{children}</div>}
      </div>
    </div>
  );
};

export default PageHeader;
