/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, { ReactNode, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import countries from 'i18n-iso-countries';

interface Props {
  children: ReactNode;
}

const AVAILABLE_LANGS = ['en', 'nl', 'fr'];
const DEFAULT_LANG = 'en';

const LocaleProvider: React.FC<Props> = ({ children }) => {
  const [messages, setMessages] = useState();

  const browserLang = navigator.language.split('-')[0];
  const lang = AVAILABLE_LANGS.includes(browserLang) ? browserLang : DEFAULT_LANG;

  useEffect(() => {
    const getLocale = async (locale: string) => {
      const { messages: update } = await import(`../locales/${locale}.ts`);

      countries.registerLocale(require(`i18n-iso-countries/langs/${locale}.json`));

      setMessages(update);
    };

    getLocale(lang);
  }, [lang]);

  return messages ? (
    <IntlProvider key={lang} locale={lang} messages={messages}>
      {children}
    </IntlProvider>
  ) : (
    <div />
  );
};

export default LocaleProvider;
