import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';
import LanguageIcon from '@material-ui/icons/Language';
import { SectionProps, Constraint } from '../../../../types/form.types';
import { ModuleInputUpdate } from '../../../../types/graphql-generated.types';
import messages from '../../../../messages/products';
import languages from '../../../../messages/languages';
import { OutlinedTextField } from '../../../inputs';

const Translations: FC<SectionProps<ModuleInputUpdate>> = ({ fields, onChange, errors }) => {
  const { formatMessage } = useIntl();

  return (
    <Grid item xs={12}>
      <div className="form-section">
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <div className="form-section-title">
              <div className="section-icon">
                <LanguageIcon />
              </div>
              <p>{formatMessage(messages.moduleTranslations)}</p>
            </div>
          </Grid>
          <Grid item xs={4}>
            <OutlinedTextField
              name="nameNL"
              labelText={formatMessage(languages.nl)}
              value={fields?.nameNL}
              onChange={onChange}
              error={errors?.nameNL as Constraint}
            />
          </Grid>
          <Grid item xs={4}>
            <OutlinedTextField
              name="nameFR"
              labelText={formatMessage(languages.fr)}
              value={fields?.nameFR}
              onChange={onChange}
              error={errors?.nameFR as Constraint}
            />
          </Grid>
          <Grid item xs={4}>
            <OutlinedTextField
              name="nameDE"
              labelText={formatMessage(languages.de)}
              value={fields?.nameDE}
              onChange={onChange}
              error={errors?.nameDE as Constraint}
            />
          </Grid>
          <Grid item xs={4}>
            <OutlinedTextField
              name="nameES"
              labelText={formatMessage(languages.es)}
              value={fields?.nameES}
              onChange={onChange}
              error={errors?.nameES as Constraint}
            />
          </Grid>
          <Grid item xs={4}>
            <OutlinedTextField
              name="namePL"
              labelText={formatMessage(languages.pl)}
              value={fields?.namePL}
              onChange={onChange}
              error={errors?.namePL as Constraint}
            />
          </Grid>
          <Grid item xs={4}>
            <OutlinedTextField
              name="namePT"
              labelText={formatMessage(languages.pt)}
              value={fields?.namePT}
              onChange={onChange}
              error={errors?.namePT as Constraint}
            />
          </Grid>
          <Grid item xs={4}>
            <OutlinedTextField
              name="nameRO"
              labelText={formatMessage(languages.ro)}
              value={fields?.nameRO}
              onChange={onChange}
              error={errors?.nameRO as Constraint}
            />
          </Grid>
          <Grid item xs={4}>
            <OutlinedTextField
              name="nameTR"
              labelText={formatMessage(languages.tr)}
              value={fields?.nameTR}
              onChange={onChange}
              error={errors?.nameTR as Constraint}
            />
          </Grid>
          <Grid item xs={4}>
            <OutlinedTextField
              name="nameAR"
              labelText={formatMessage(languages.ar)}
              value={fields?.nameAR}
              onChange={onChange}
              error={errors?.nameAR as Constraint}
            />
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

export default Translations;
