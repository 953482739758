import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Grid, makeStyles, createStyles, Box, ButtonBase } from '@material-ui/core';
import { TableHeader, TableCell, TableRow } from '../../table';
import Button from '../../Button';
import { quoteMessages, productMessages, commonMessages } from '../../../messages';
import { formatPrice } from '../../../helpers/format';
import {
  getBoxFromKit,
  getModulesFromKit,
  Kit,
  calculateTotalOfKit,
  sortKitsFn,
} from '../../../helpers/quote';
import { ProductIconList } from '../../products';
import ConfirmDialog from '../ConfirmDialog';
import CopyKitDialog from '../CopyKitDialog';
import Pagination from '../../Pagination';

const useStyles = makeStyles(theme =>
  createStyles({
    emptyRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.text.secondary,
    },
    root: {
      flexWrap: 'nowrap',
    },
    table: {
      flexGrow: 1,
    },
    actionContainer: {
      minWidth: '40px',
      marginLeft: '8px',
    },
    actionRow: {
      height: 'calc(100% - 8px)',
      margin: 0,
      padding: 0,
    },
    action: {
      padding: 0,
      display: 'flex',
      justifyContent: 'center',
    },
    actionButton: {
      width: '100%',
      height: '100%',

      '& svg path': {
        transition: 'fill 0.2s ease-in',
        fill: theme.palette.text.secondary,
      },

      '&:hover svg path': {
        fill: theme.palette.primary.dark,
      },
    },
  }),
);

const KIT_LIMIT = 10;

interface KitsProps {
  kits: Kit[];
  disableSelect?: boolean;
  disable?: boolean;
  onSelect?: () => void;
  onOpenKit?: (kit: Kit) => void;
  onDelete?: (kit: Kit) => void;
  onCopyKit?: (kit: Kit, quantity: number) => void;
}

const noOp = () => {};

const Kits: FC<KitsProps> = props => {
  const {
    onSelect = noOp,
    onDelete = noOp,
    onOpenKit,
    disableSelect,
    kits,
    disable,
    onCopyKit,
  } = props;

  const classes = useStyles();
  const { formatMessage } = useIntl();

  const [deletingKit, setDeletingKit] = useState<Kit>();
  const [copyingKit, setCopyingKit] = useState<Kit>();

  const [page, setPage] = useState(0);
  const pageCount = kits.length > 0 ? Math.ceil(kits.length / KIT_LIMIT) : 0;

  const onConfirmDelete = () => {
    if (deletingKit) {
      onDelete(deletingKit);
      setDeletingKit(undefined);
    }
  };

  const sortedKits = [...kits].sort(sortKitsFn);
  const displayKits =
    kits.length > KIT_LIMIT
      ? sortedKits.slice(page * KIT_LIMIT, page * KIT_LIMIT + KIT_LIMIT)
      : kits;

  if (displayKits.length === 0 && page !== 0) {
    setPage(page - 1);
  }

  return (
    <>
      <Grid item xs={12}>
        <div className="form-section">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className="form-section-title">
                <div className="section-icon">
                  <DashboardIcon />
                </div>
                <p>{formatMessage(quoteMessages.kits)}</p>
                {!disable && (
                  <Button
                    className="button-outlined-icon"
                    text={formatMessage(commonMessages.add)}
                    onClick={onSelect}
                    disabled={disableSelect}
                    icon={<AddCircleOutlineIcon />}
                  />
                )}
              </div>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Box className={classes.table}>
                  <TableHeader>
                    {() => (
                      <>
                        <TableCell xs={3} label={formatMessage(quoteMessages.location)} />
                        <TableCell xs={5} label={formatMessage(productMessages.modules)} />
                        <TableCell xs={2} label={formatMessage(productMessages.box)} />
                        <TableCell xs={2} label={formatMessage(productMessages.price)} />
                      </>
                    )}
                  </TableHeader>
                </Box>
                {!disable && (
                  <>
                    <Box className={classes.actionContainer} />
                    <Box className={classes.actionContainer} />
                  </>
                )}
              </Grid>
              {kits.length ? (
                <>
                  {displayKits.map(kit => (
                    <Grid container className={classes.root} key={kit[0]}>
                      <Box className={classes.table}>
                        <TableRow
                          onClick={disable && !onOpenKit ? undefined : () => onOpenKit?.(kit)}
                        >
                          <TableCell primary body xs={3} label={kit[1].location} />
                          <TableCell body xs={5}>
                            <ProductIconList modules={getModulesFromKit(kit)} />
                          </TableCell>
                          <TableCell body xs={2} label={getBoxFromKit(kit)?.name} />
                          <TableCell body xs={2} label={formatPrice(calculateTotalOfKit(kit[1]))} />
                        </TableRow>
                      </Box>
                      {!disable && (
                        <>
                          <Box className={classes.actionContainer}>
                            <TableRow classes={{ root: classes.actionRow }}>
                              <TableCell body xs classes={{ root: classes.action }}>
                                <ButtonBase
                                  disableRipple
                                  component="div"
                                  className={classes.actionButton}
                                  onClick={() => setCopyingKit(kit)}
                                >
                                  <FileCopyIcon />
                                </ButtonBase>
                              </TableCell>
                            </TableRow>
                          </Box>
                          <Box className={classes.actionContainer}>
                            <TableRow classes={{ root: classes.actionRow }}>
                              <TableCell body xs classes={{ root: classes.action }}>
                                <ButtonBase
                                  disableRipple
                                  component="div"
                                  className={classes.actionButton}
                                  onClick={() => setDeletingKit(kit)}
                                >
                                  <DeleteIcon />
                                </ButtonBase>
                              </TableCell>
                            </TableRow>
                          </Box>
                        </>
                      )}
                    </Grid>
                  ))}
                  {pageCount > 1 && (
                    <Pagination
                      onPageSelect={selected => setPage(selected)}
                      options={{ page, pageCount }}
                    />
                  )}
                </>
              ) : (
                <TableRow>
                  <TableCell
                    classes={{ root: classes.emptyRow }}
                    body
                    xs={12}
                    label={formatMessage(quoteMessages.noKits)}
                  />
                </TableRow>
              )}
            </Grid>
          </Grid>
        </div>
      </Grid>
      <ConfirmDialog
        open={!!deletingKit}
        onCancel={() => setDeletingKit(undefined)}
        onConfirm={onConfirmDelete}
        title={formatMessage(quoteMessages.deleteKitTitle)}
        content={formatMessage(quoteMessages.deleteKit)}
      />
      <CopyKitDialog
        open={!!copyingKit}
        onCancel={() => setCopyingKit(undefined)}
        onConfirm={quantity => {
          if (copyingKit && onCopyKit) {
            onCopyKit(copyingKit, quantity);
            setCopyingKit(undefined);
          }
        }}
      />
    </>
  );
};

export default Kits;
