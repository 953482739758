import React, { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { PageHeader } from '../../components';
import { commonMessages } from '../../messages';

interface Props extends RouteComponentProps {
  children?: ReactNode;
}

const Privacy: FC<Props> = () => {
  const { formatMessage } = useIntl();

  return (
    <div className="privacy">
      <PageHeader title={formatMessage(commonMessages.privacy)} />
      <div className="content">
        <p>
          U kan onze <Link to={'/cookie-policy'}>cookie policy</Link> hier vinden
        </p>
        <p>
          Wanneer in deze privacyverklaring verwezen wordt naar Pharmatwork, wordt verwezen naar de
          PHARMATWORK BV, met maatschappelijke zetel te Bagattenstraat 173, 9000 Gent, RPR Gent
          afdeling Gent en met als ondernemingsnummer 0728.708.045, alsook naar elke overeenkomstig
          artikel 1:20 van het Wetboek van Vennootschappen en Verenigingen met Pharmatwork verbonden
          vennootschap.
        </p>
        <p>
          Wanneer u beroep doet op de diensten Pharmatwork, vertrouwt u haar uw gegevens toe. Deze
          privacyverklaring wil u duidelijk maken welke gegevens door Pharmatwork worden verzameld,
          waarom Pharmatwork deze gegevens worden verzameld en wat Pharmatwork hiermee doet. Hierbij
          wil Pharmatwork er u tevens op wijzen dat Parmacar steeds te uwer beschikking staat indien
          u aanpassingen wenst in de gegevens waarover Pharmatwork beschikt.
        </p>
        <p>In deze privacyverklaring wordt uiteen gezet:</p>
        <ul>
          <li>Welke gegevens worden door Pharmatwork verzameld?</li>
          <li>Hoe worden uw gegevens gebruikt?</li>
          <li>Hoelang worden uw gegevens bewaard?</li>
          <li>Hoe u uw gegevens kunt laten aanpassen;</li>
          <li>Wanneer deze privacyverklaring van toepassing is;</li>
          <li>
            Hoe de naleving van deze privacyverklaring wordt gecontroleerd en hoe wordt omgegaan met
            klachten omtrent deze privacyverklaring;
          </li>
          <li>Hoe wijzigingen aan deze privacyverklaring worden gecommuniceerd;</li>
        </ul>
        <h2>1. Welke gegevens worden door Pharmatwork verzameld?</h2>
        <p>
          Uw gegevens worden voornamelijk verzameld voor het vaststellen van basisinformatie (zoals
          uw naam, uw adres, telefoonnummer, geslacht, geboortedatum, e-mailadres en de taal die u
          spreekt) maar eveneens voor meer complexe zaken (zoals de ondernemingen waarbij u
          betrokken bent, de functies die u heeft binnen deze ondernemingen, etc.), die Pharmatwork
          nodig heeft voor het verstrekken van diensten waarvoor u op haar beroep doet.
        </p>
        <p>
          Pharmatwork heeft deze gegevens nodig voor de volgende doeleinden, en verzamelt deze
          gegevens op de volgende manieren:
        </p>
        <p>
          <strong>Gegevens die Pharmatwork verzamelt middels de website</strong> www.modulaid.eu De
          meeste informatie is op of via de website beschikbaar zonder dat persoonsgegevens moeten
          worden verstrekt. In bepaalde gevallen kan u evenwel persoonlijke informatie worden
          gevraagd. Dit zal u per geval gevraagd worden. Dit kan bijvoorbeeld voorkomen bij het
          gebruik van de toepassing “contactformulier”. Pharmatwork verzamelt echter geen
          persoonsgegevens met betrekking tot gevoelige categorieën, op basis van ras, religie,
          seksuele geaardheid of gezondheid.
        </p>
        <h2>2. Hoe gebruikt Pharmatwork deze gegevens?</h2>
        <p>Pharmatwork hecht veel belang aan de eerbiediging van uw persoonlijke levenssfeer.</p>
        <p>
          De informatie die wordt verzameld overeenkomstig hetgeen hoger wordt beschreven, wordt
          behandeld in overeenstemming met de bepalingen van de Algemene Verordening
          Gegevensbescherming (AVG beter bekend onder zijn Engels afkorting GDPR; voluit de
          Verordening (EU) 2016/679 van het Europees Parlement en de Raad van 27 april 2016
          betreffende de bescherming van natuurlijke personen in verband met de verwerking van
          persoonsgegevens en betreffende het vrije verkeer van die gegevens en tot intrekking van
          Richtlijn 95/46/EG).
        </p>
        <p>
          Pharmatwork verzamelt en verwerkt de gevraagde persoonsgegevens om de diensten te leveren
          waarvoor u haar tussenkomst verzocht en meer in het bijzonder voor volgende doeleinden:
        </p>
        <ul>
          <li>opmaak en uitvoering van de met u gesloten overeenkomst;</li>
          <li>
            om u de mogelijkheid te bieden om informatie op de website te plaatsen en uit te
            wisselen, en om contact te kunnen opnemen met andere gebruikers als de website daar de
            mogelijkheid toe biedt;
          </li>
          <li>
            om de mogelijkheid te kunnen bieden dat bezoekers en geïnteresseerden contact kunnen
            opnemen met Pharmatwork;
          </li>
          <li>
            om webstatistieken op te stellen bijvoorbeeld over het aantal bezoekers van de
            verschillende rubrieken van de website;
          </li>
          <li>om u de diensten en advertenties op onze website algemeen te kunnen aanbieden;</li>
          <li>
            voor marketingdoeleinden om u gericht te kunnen informeren van veranderingen binnen de
            wetgeving en andere onderwerpen welke voor u relevant kunnen zijn, om u relevante
            e-mails en post, nieuwsbrieven, gebruikersinformatie, serviceberichten, of andere
            elektronische boodschappen te kunnen sturen, om u te informeren over haar diensten,
            bijvoorbeeld om u op de hoogte te stellen van aankomende wijzigingen of verbeteringen;{' '}
          </li>
          <li>
            voor verdere marketingdoeleinden waarbij de gegevens aan bedrijven en/of derden kunnen
            worden doorgegeven zodat zij interessante en relevante product- en/of dienstaanbiedingen
            aan u kunnen richten; (zie verder)
          </li>
          <li>
            om onze websites en technologieën te analyseren, onderhouden, beveiligen, en
            optimaliseren;
          </li>
          <li>om te voldoen aan de wetten en regelgevingen waar Pharmatwork onderhevig aan is;</li>
          <li>bij beslechting van eventuele geschillen;</li>
          <li>voor administratieve doeleinden;</li>
        </ul>
        <p>
          Pharmatwork vraagt uw toestemming voordat zij uw gegevens voor andere doeleinden verwerkt
          dan die in deze privacyverklaring worden beschreven.
        </p>
        <p>
          Pharmatwork verwerkt de door u verstrekte persoonlijke gegevens op haar servers.
          Pharmatwork kan de persoonlijke gegevens verwerken op een server die zich buiten het land
          bevindt waar u woont, doch verzekert zich ervan dat de verwerker in overeenstemming is met
          de bepalingen van de GDPR.
        </p>
        <p>
          <strong>
            Pharmatwork deelt persoonlijke gegevens <u>niet</u> met bedrijven, organisaties en
            individuen buiten haar eigen onderneming, behalve in een van de volgende omstandigheden:
          </strong>
        </p>
        <ul>
          <li>
            <strong>Met uw toestemming:</strong>
            <p>
              Pharmatwork kan persoonlijke gegevens delen met bedrijven, organisaties of individuen
              buiten Pharmatwork wanneer u haar toestemming heeft gegeven dit te doen. Pharmatwork
              heeft uw uitdrukkelijke toestemming nodig voor het delen van gevoelige persoonlijke
              gegevens. U kunt deze toestemming op elk moment intrekken.
            </p>
          </li>
          <li>
            <strong>Met externe partners:</strong>
            <p>
              Als één van de programma’s van Pharmatwork wordt beheerd door een externe
              partner/softwareleverancier, kan deze externe partner toegang hebben tot uw
              persoonsgegevens (waaronder uw e-mailadres en andere gegevens) in functie van beheer
              en onderhoud van dit programma. Deze externe partner kan mogelijk het volgende doen:
            </p>
            <ul>
              <li>
                uw gegevens openen of bewaren die zijn opgeslagen als onderdeel van uw profiel;
              </li>
              <li>
                uw gegevens ontvangen om te voldoen aan de van toepassing zijnde wet- en
                regelgeving, wettelijke procedures of verzoeken van overheidsinstanties;
              </li>
              <li>de mogelijkheden beperken om gegevens te verwijderen of aan te passen;</li>
            </ul>
            <p>
              Pharmatwork gaat enkel in zee met externe partners die gegarandeerd voldoen aan de
              GDPR- wetgeving.
            </p>
          </li>
          <li>
            <strong>Om juridische redenen:</strong>
            <p>
              Pharmatwork deelt persoonlijke gegevens met bedrijven, instanties of individuen
              (bijvoorbeeld de fiscus) buiten Pharmatwork als zij te goeder trouw van mening is dat
              toegang, gebruik, behoud of openbaarmaking van de gegevens redelijkerwijs nodig is om:
            </p>
            <ul>
              <li>
                te voldoen aan de geldende wetgeving, wettelijke procedures of uitvoerbare verzoeken
                van overheidsinstanties;
              </li>
              <li>
                de op de onderlinge overeenkomst van toepassing zijnde voorwaarden af te dwingen,
                waaronder het onderzoeken van mogelijke schendingen van deze overeenkomst;
              </li>
              <li>
                fraude en technische of beveiligingsproblemen op te sporen, te voorkomen of
                anderszins op te lossen;
              </li>
              <li>
                de rechten, eigendom of veiligheid van Pharmatwork, haar cliënteel of het publiek te
                beschermen, zoals vereist of toegestaan volgens de wet.
              </li>
            </ul>
          </li>
        </ul>
        <p>
          Als Pharmatwork betrokken is bij een fusie, overname of verkoop van activa, blijft zij de
          vertrouwelijkheid van persoonlijke gegevens waarborgen en stelt ze haar cliënteel op de
          hoogte voordat persoonlijke gegevens worden overgedragen of onderworpen aan een ander
          privacy beleid.
        </p>
        <p>
          Pharmatwork doet haar uiterste best om zichzelf en haar cliënteel te beschermen tegen
          ongeoorloofde toegang tot of ongeoorloofde aanpassing, openbaarmaking of vernietiging van
          gegevens die in haar bezit zijn. In het bijzonder is het volgende van toepassing:
        </p>
        <ul>
          <li>
            Pharmatwork beoordeelt periodiek en op systematische wijze haar handelswijze met
            betrekking tot verzameling, opslag en verwerking van gegevens, en de bescherming tegen
            ongeoorloofde toegang tot systemen;
          </li>
          <li>
            Pharmatwork beperkt de toegang tot persoonlijke gegevens tot medewerkers van
            Pharmatwork, contractanten en agenten die deze gegevens moeten kennen om ze voor
            Pharmatwork te verwerken. Deze personen zijn onderworpen aan strenge contractuele en/of
            deontologische vertrouwelijkheidsverplichtingen en kunnen worden bestraft of ontslagen
            als ze niet voldoen aan deze verplichtingen.
          </li>
        </ul>
        <ul>
          <li>
            <strong>
              <u>Hoelang worden uw gegevens bewaard?</u>
            </strong>
          </li>
          <p>
            Pharmatwork bewaart uw persoonsgegevens net zolang als nodig voor de doeleinden zoals
            omschreven onder punt 2. Daarna kunnen uw persoonsgegevens bewaard blijven voor
            archiefdoeleinden.
          </p>
          <li>
            <strong>
              <u>Hoe kunt u uw gegevens laten aanpassen?</u>
            </strong>
          </li>
          <p>
            Pharmatwork streeft ernaar duidelijk te zijn over de gegevens die ze verzamelt, zodat u
            weloverwogen beslissingen kunt nemen over hoe uw gegevens worden verwerkt. U kunt
            bijvoorbeeld via e-mail naar info@modulaid.eu een aanvraag indienen om uw gegevens te
            wijzigen, op te vragen of te wissen.
            <br /> Ook kun u uw toestemming tot de verwerking van uw persoonsgegevens voor
            promotionele doeleinden, persoonlijke reclame of andere (re)marketing doeleinden geven
            of intrekken.
            <br /> Via deze weg is het ook mogelijk om een datalek te melden.
            <br /> Ingeval u zoals hierboven voorzien aangeeft uw persoonsgegevens te willen
            wijzigen, op te vragen of te wissen, zal Pharmatwork proberen deze gegevens snel bij te
            werken, ter beschikking te stellen of te verwijderen, tenzij Pharmatwork die gegevens
            moet bewaren voor legitieme zakelijke of juridische doeleinden. Wanneer u uw
            persoonlijke gegevens bijwerkt, kan Pharmatwork u vragen uw identiteit te verifiëren
            voordat zij uw verzoek kan verwerken.
          </p>
          <p>
            Pharmatwork kan verzoeken weigeren die op onredelijke wijze worden herhaald, die
            onevenredige technische inspanningen vereisen (zoals het ontwikkelen van een nieuw
            systeem of het aanbrengen van fundamentele wijzigingen in een bestaande handelswijze),
            de privacy van anderen in gevaar brengen of zeer onpraktisch zouden zijn (zoals
            verzoeken met betrekking tot gegevens die zich op back-upsystemen bevinden).
            <br /> Indien bepaalde wetgeving Pharmatwork verplicht tot het bewaren van uw gegevens,
            kan er op dergelijk verzoek evenmin worden ingegaan. Pharmatwork zal zich er in
            dergelijk geval wel toe engageren de toegang tot uw gegevens intern zo veel mogelijk te
            beperken.
          </p>
          <p>
            Pharmatwork streeft ernaar haar databanken te handhaven op een manier die gegevens
            beschermt tegen onopzettelijke of kwaadwillige vernietiging of openbaarmaking. Daarom is
            het mogelijk dat Pharmatwork, nadat uw gegevens uit haar databanken werden verwijderd,
            resterende kopieën niet onmiddellijk van haar actieve servers verwijderd worden en
            gegevens niet uit haar back-upsystemen verwijderd worden.
          </p>
          <li>
            <strong>
              <u>Wanneer is deze Privacyverklaring van toepassing? </u>
            </strong>
          </li>
          <p>
            Deze privacyverklaring is van toepassing op alle diensten die worden aangeboden door
            Pharmatwork.
          </p>
          <li>
            <strong>
              <u>
                Hoe wordt de nalevering van deze privacyverklaring gecontroleerd en hoe wordt
                omgegaan met klachten omtrent deze privacyverklaring?{' '}
              </u>
            </strong>
          </li>
          <p>
            Regelmatig controleert Pharmatwork of ze voldoet aan deze privacyverklaring. Ook wanneer
            Pharmatwork formele klachten ontvangt, zal zij contact opnemen met de persoon die de
            klacht heeft ingediend om vervolgacties te bespreken.
          </p>
          <li>
            <strong>
              <u>Hoe worden wijzigingen aan deze privacyverklaring gecommuniceerd? </u>
            </strong>
          </li>
          <p>
            Deze privacyverklaring kan worden gewijzigd. Pharmatwork zal deze wijzigingen op deze
            pagina aangeven.
          </p>
        </ul>
      </div>
    </div>
  );
};

export default Privacy;
