import React, { FC, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Grid, Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import { ReactComponent as IconProspectFilled } from '../../assets/icons/icons-prospect-filled.svg';
import { ReactComponent as IconAddQuote } from '../../assets/icons/icons-create-offer.svg';
import {
  PageHeader,
  Button,
  Pagination,
  Loadable,
  SortableTableHeader,
  OverviewSearch,
} from '../../components';
import history from '../../lib/history';
import { GET_CUSTOMERS, createSearch } from '../../graphql/queries/customer';
import { useOptions, usePageView } from '../../hooks';
import messages from '../../messages/common';
import { labelMessages, quoteMessages } from '../../messages';
import { setSortableQuery } from '../../helpers/query';
import { useUser } from '../../providers/UserProvider';
import { EmptyRow } from '../../components/table';
import {
  SortDirection,
  GetPagedCustomersQuery,
  GetPagedCustomersQueryVariables,
} from '../../types/graphql-generated.types';

const Customers: React.FC = () => {
  usePageView('customers_overview');

  const { isCustomer, isAdmin, companyId } = useUser();
  const { formatMessage } = useIntl();
  const { options, changeParams, resetPagination } = useOptions({
    page: 0,
    offset: 0,
    pageCount: 1,
    limit: 20,
    sortColumn: 'customer.name',
    sortDirection: SortDirection.Asc,
  });

  const { pageCount, page, ...noPage } = options;

  const [search, setSearch] = useState<string | undefined>();

  const { loading, data } = useQuery<GetPagedCustomersQuery, GetPagedCustomersQueryVariables>(
    GET_CUSTOMERS,
    {
      variables: {
        ...(isAdmin ? noPage : { ...noPage, companyId }),
        ...(search && { filter: createSearch(isAdmin, search) }),
      },
      onCompleted(result) {
        if (result?.getPagedCustomers?.pageInfo?.pageCount != null) {
          changeParams('pageCount', result.getPagedCustomers.pageInfo.pageCount);
        }
      },
    },
  );

  const onSearch = (value: string | undefined) => {
    resetPagination();
    setSearch(value);
  };

  const onChangeSortColumn = (type: string) => {
    let nextDir = SortDirection.Asc;

    if (options.sortColumn === type) {
      nextDir =
        options.sortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc;
    }

    setSortableQuery(type, nextDir);
  };

  const onClickAdd = () => {
    history.push('/customers/add');
  };

  const TableHeader: FC<{ name: string; children: string }> = ({ name, children }) => (
    <SortableTableHeader
      name={name}
      current={options.sortColumn}
      direction={options.sortDirection}
      onChange={onChangeSortColumn}
    >
      {children}
    </SortableTableHeader>
  );

  const linkClassName = classNames('table-row-link', {
    'table-row-link-action': !isCustomer,
  });

  const headerClassName = classNames('table-header', {
    'table-row-link-action': !isCustomer,
  });

  const isEmpty = !data?.getPagedCustomers?.items?.length;

  return (
    <div className="customers">
      <PageHeader title={formatMessage(messages.customers)}>
        <Grid container alignItems="center">
          <OverviewSearch onSearch={onSearch} onClear={() => onSearch(undefined)} />
          <Button
            className="button-filled-icon"
            text={formatMessage(messages.addNew)}
            onClick={onClickAdd}
            icon={<AddCircleOutlineIcon />}
          />
        </Grid>
      </PageHeader>
      <div className="table">
        <Grid className={headerClassName} container>
          <Grid item xs={1} container justify="center" className="table-header-status">
            <span>{formatMessage(labelMessages.status)}</span>
          </Grid>
          <Grid item xs={4}>
            <TableHeader name="customer.name">{formatMessage(labelMessages.name)}</TableHeader>
          </Grid>
          {isAdmin && (
            <Grid item xs>
              <TableHeader name="distributor.name">
                {formatMessage(labelMessages.distributor)}
              </TableHeader>
            </Grid>
          )}
        </Grid>

        <Loadable loading={loading}>
          {isEmpty ? (
            <EmptyRow />
          ) : (
            data?.getPagedCustomers.items?.map(customer => (
              <Grid className="table-row-container" key={customer.id!} container>
                <Link to={`/customer/${customer.id}`} className={linkClassName}>
                  <Grid className="table-row" container>
                    <Grid item xs={1} container justify="center" className="table-row-prospect">
                      <IconProspectFilled />
                    </Grid>
                    <Grid item xs={4} className="table-row-primary">
                      <span>{customer.name}</span>
                    </Grid>
                    {isAdmin && (
                      <Grid item xs>
                        <span>{customer.distributor?.name}</span>
                      </Grid>
                    )}
                  </Grid>
                </Link>
                {!isCustomer && (
                  <Link to={`/customer/${customer.id}/quote`} className="table-row-action">
                    <Tooltip title={formatMessage(quoteMessages.createQuote)} placement="top" arrow>
                      <Grid className="table-row">
                        <IconAddQuote />
                      </Grid>
                    </Tooltip>
                  </Link>
                )}
              </Grid>
            ))
          )}
        </Loadable>

        <Pagination options={options} />
      </div>
    </div>
  );
};

export default Customers;
