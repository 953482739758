import React, { FC, useState } from 'react';
import { Typography, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { useMutation } from 'react-apollo';
import {
  Module,
  UpdateInventoryItemMutation,
  InventoryItemStatus,
} from '../../../types/graphql-generated.types';
import { inventoryMessages, commonMessages } from '../../../messages';
import Button from '../../Button';
import { UPDATE_INVENTORY_ITEM } from '../../../graphql/queries/inventory';
import ModuleHeader from '../ModuleHeader';
import { useDialogStyles } from '../hooks';
import { useAuth0 } from '../../../providers/AuthProvider';
import OutlinedTextField from '../../inputs/OutlinedTextField';

interface Props {
  inventoryId: number;
  onClose: () => void;
  onSuccess: () => void;
}

const VerifyInventoryDialog: FC<Props> = props => {
  const { inventoryId, onClose, onSuccess } = props;
  const [summary, setSummary] = useState('');
  const classes = useDialogStyles(props);
  const { formatMessage } = useIntl();
  const { user } = useAuth0();

  const [updateInventoryItem, { loading }] = useMutation<UpdateInventoryItemMutation>(
    UPDATE_INVENTORY_ITEM,
  );

  const onVerify = async () => {
    try {
      await updateInventoryItem({
        variables: {
          id: inventoryId,
          inventoryItem: { status: InventoryItemStatus.Verified },
          summary: user ? user.email : summary.trim(),
        },
      });
      onSuccess();
    } catch (err) {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.error('Error verifying inventory: ', err);
      }
    }
  };

  const module: Module = {
    rgb: '279B36',
    icon: {
      svg: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <path fill="#3D464C" fill-rule="evenodd" d="M8 0C3.584 0 0 3.584 0 8s3.584 8 8 8 8-3.584 8-8-3.584-8-8-8zm.8 12H7.2V7.2h1.6V12zm0-6.4H7.2V4h1.6v1.6z"/>
</svg>`,
    },
    nameEN: 'Verify inventory',
  };

  return (
    <Dialog fullWidth open onClose={onClose} classes={{ paper: classes.root }}>
      <DialogTitle className={classNames(classes.paddingH, classes.paddingV)}>
        <ModuleHeader module={module} />
      </DialogTitle>
      <DialogContent className={classNames(classes.paddingH, classes.gutter)}>
        <Typography className={classes.description}>
          {formatMessage(
            user ? inventoryMessages.verifyDescription : inventoryMessages.verifyNameDescription,
          )}
        </Typography>
        {!user && (
          <OutlinedTextField
            className={classes.gutter}
            name="name"
            labelText={formatMessage(inventoryMessages.confirmVerifySummary)}
            value={summary}
            maxLength={50}
            onChange={({ value }) => setSummary(value as string)}
          />
        )}
      </DialogContent>
      <DialogActions className={classNames(classes.paddingH, classes.paddingV, classes.actions)}>
        <Button
          className="button-outlined"
          onClick={onClose}
          text={formatMessage(commonMessages.cancel)}
        />
        <Button
          className="button-filled"
          onClick={() => onVerify()}
          text={formatMessage(inventoryMessages.verify)}
          loading={loading}
          disabled={summary.trim().length === 0 && !user}
        />
      </DialogActions>
    </Dialog>
  );
};

export default VerifyInventoryDialog;
