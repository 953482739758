import { SortDirection } from '../types/graphql-generated.types';

export const baseSort = (A: string, B: string): number => {
  if (A < B) {
    return -1;
  }

  if (A > B) {
    return 1;
  }

  return 0;
};

export const getNextDirection = (
  key: string,
  column: string,
  direction: SortDirection,
): SortDirection => {
  let nextDir = SortDirection.Asc;

  if (column === key) {
    nextDir = direction === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc;
  }

  return nextDir;
};
