import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { makeStyles, createStyles, Box } from '@material-ui/core';
import { commonMessages } from '../../messages';
import TableRow from './TableRow';
import TableCell from './TableCell';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      opacity: 0.7,
    },
    emptyRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.text.secondary,
    },
  }),
);

const EmptyRow: FC = () => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  return (
    <Box className={classes.root}>
      <TableRow>
        <TableCell
          classes={{ root: classes.emptyRow }}
          body
          xs={12}
          label={formatMessage(commonMessages.noItemsFound)}
        />
      </TableRow>
    </Box>
  );
};

export default EmptyRow;
