import { AddressInterface } from '../types/address.types';
import { CompanyForm, CustomerForm } from '../types/company.types';
import { UserForm } from '../types/user.types';
import {
  BoxInputUpdate,
  ModuleInputUpdate,
  ProductInfoInputUpdate,
} from '../types/graphql-generated.types';

export const address: AddressInterface = {
  addressLineOne: '',
  addressLineTwo: '',
  city: '',
  country: '',
  postalCode: '',
};

export const companyFields: CompanyForm = {
  name: '',
  vat: '',
  addresses: [address],
  email: '',
  phone: '',
  defaultDeliveryCost: 0,
};

export const customerFields: CustomerForm = {
  name: '',
  vat: '',
  addresses: [address],
  email: '',
  phone: '',
  sector: null,
  numberOfEmployees: null,
  additionalInfo: '',
  language: null,
  primaryLabelLanguage: null,
  secondaryLabelLanguage: null,
  distributor: null,
};

export const userFields: UserForm = {
  firstName: '',
  lastName: '',
  email: '',
};

export const getModuleFields = (): ModuleInputUpdate => ({
  active: true,
  expirable: true,
  nameEN: '',
  nameNL: '',
  nameFR: '',
  nameDE: '',
  nameES: '',
  namePL: '',
  namePT: '',
  nameRO: '',
  nameTR: '',
  nameAR: '',
  moduleId: '',
  rgb: '000',
  icon: null,
  cmyk: {
    C: null,
    M: null,
    Y: null,
    K: null,
  },
});

export const getBoxFields = (): BoxInputUpdate => ({
  name: '',
  active: true,
});

export const getProductInfoFields = (): ProductInfoInputUpdate => ({
  articleId: '',
});
