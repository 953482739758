import { gql } from 'apollo-boost';

export const GET_DISTRIBUTORS = gql`
  query getDistributors {
    getDistributors {
      id
      name
    }
  }
`;

export const GET_PAGED_DISTRIBUTORS = gql`
  query getPagedDistributors(
    $offset: Int!
    $limit: Int!
    $sortColumn: String
    $sortDirection: SortDirection
  ) {
    getPagedDistributors(
      offset: $offset
      limit: $limit
      sortColumn: $sortColumn
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
      }
      pageInfo {
        itemCount
        pageCount
        total
      }
    }
  }
`;

export const GET_DISTRIBUTOR = gql`
  query getDistributor($id: Int!) {
    getDistributor(id: $id) {
      id
      name
      email
      phone
      vat
      defaultDeliveryCost
      addresses {
        id
        addressLineOne
        addressLineTwo
        postalCode
        city
        country
      }
    }
  }
`;

export const CREATE_DISTRIBUTOR = gql`
  mutation createDistributor($type: DistributorInputCreate!) {
    createDistributor(distributor: $type) {
      name
      email
      phone
      vat
      addresses {
        addressLineOne
        addressLineTwo
        postalCode
        city
        country
      }
    }
  }
`;

export const UPDATE_DISTRIBUTOR = gql`
  mutation updateDistributor($id: Int!, $type: DistributorInputUpdate!) {
    updateDistributor(id: $id, distributor: $type) {
      name
      email
      phone
      vat
      addresses {
        id
        addressLineOne
        addressLineTwo
        postalCode
        city
        country
      }
    }
  }
`;
