import React, { useContext, createContext, useState, useEffect } from 'react';
import { Auth } from '../lib/auth';

export interface Auth0User extends Omit<IdToken, '__raw'> {}

interface Auth0Context {
  user?: Auth0User;
  isAuthenticated: boolean;
  loading: boolean;
  loginWithRedirect(o?: RedirectLoginOptions): Promise<void>;
  logout(o?: LogoutOptions): void;
}

interface Auth0ProviderOptions {
  children: React.ReactElement;
  auth: Auth;
  waitForAuth: Promise<void>;
}

export const Auth0Context = createContext<Auth0Context | null>(null);
export const useAuth0 = () => useContext(Auth0Context)!;
export const Auth0Provider: React.FC<Auth0ProviderOptions> = ({ children, auth, waitForAuth }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<Auth0User>();

  useEffect(() => {
    const init = async () => {
      await waitForAuth;
      setIsAuthenticated(auth.isAuthenticated);
      setUser(auth.user);
      setLoading(auth.loading);
    };

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waitForAuth]); // disabled as we don't want to watch the class properties

  const loginWithRedirect = (options?: RedirectLoginOptions) =>
    auth.client!.loginWithRedirect(options);

  const logout = (options?: LogoutOptions) => auth.client!.logout(options);

  const value = {
    loginWithRedirect,
    logout,
    isAuthenticated,
    user,
    loading,
  };

  return <Auth0Context.Provider value={value}>{children}</Auth0Context.Provider>;
};
