import { ChangeEvent } from 'react';

export interface FieldUpdate {
  path: string;
  value: unknown;
  errorPath?: string;
}

const commaToDot = (s: string) => s.replace(',', '.');
const dotToComma = (s: string) => s.replace('.', ',');

interface UpdateOptions {
  isCheckbox?: boolean;
}

export function getUpdate(
  event: ChangeEvent<HTMLInputElement>,
  { isCheckbox = false }: UpdateOptions = {},
): FieldUpdate {
  return {
    path: event.target.name,
    value: isCheckbox ? event.target.checked : event.target.value,
  };
}

export function floatToString(number: number | undefined | null): string | undefined | null {
  if (number != null) {
    return dotToComma(`${number}`);
  }

  return number;
}

export function toNumber(fieldUpdate: FieldUpdate): FieldUpdate {
  const value =
    fieldUpdate.value == null || fieldUpdate.value === ''
      ? null
      : parseFloat(commaToDot(fieldUpdate.value as string));

  return {
    value,
    path: fieldUpdate.path,
  };
}
