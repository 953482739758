import React from 'react';
import { Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { ReactComponent as InfoIcon } from '../../../assets/icons/icons-info.svg';
import OutlinedTextField from '../../inputs/OutlinedTextField';
import { Constraint } from '../../../types/form.types';
import {
  CompanySectionProps,
  InfoSection,
  Sector,
  Language,
  KitLabelLanguage,
} from '../../../types/company.types';
import OutlinedSelectField from '../../inputs/OutlinedSelectField';
import { toNumber } from '../../../helpers/form';
import { commonMessages, labelMessages, customerMessages } from '../../../messages';
import NumberMask from '../../inputs/NumberMask';

const Info: React.FC<CompanySectionProps<InfoSection>> = ({ onChange, fields, errors }) => {
  const { formatMessage } = useIntl();
  return (
    <Grid item xs={12}>
      <div className="form-section">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className="form-section-title">
              <div className="section-icon">
                <InfoIcon />
              </div>
              <p>{formatMessage(commonMessages.info)}</p>
            </div>
          </Grid>
          <Grid item xs={6}>
            <OutlinedSelectField
              name="sector"
              labelText={`${formatMessage(labelMessages.sector)} (${formatMessage(
                labelMessages.optional,
              )})`}
              value={fields?.sector}
              onChange={onChange}
              error={errors?.sector as Constraint}
            >
              <option />
              {(Object.keys(Sector) as (keyof typeof Sector)[]).map(sector => (
                <option key={sector} value={sector}>
                  {Sector[sector]}
                </option>
              ))}
            </OutlinedSelectField>
          </Grid>
          <Grid item xs={6}>
            <OutlinedTextField
              name="numberOfEmployees"
              labelText={`${formatMessage(labelMessages.numberOfEmployees)} (${formatMessage(
                labelMessages.optional,
              )})`}
              value={fields?.numberOfEmployees}
              onChange={u => onChange(toNumber(u))}
              inputComponent={NumberMask as any}
              error={errors?.numberOfEmployees as Constraint}
            />
          </Grid>
          <Grid item xs={6}>
            <OutlinedSelectField
              name="language"
              labelText={formatMessage(labelMessages.language)}
              value={fields?.language}
              onChange={onChange}
              error={errors?.language as Constraint}
            >
              <option disabled />
              {(Object.keys(Language) as (keyof typeof Language)[]).map(language => (
                <option key={language} value={language}>
                  {Language[language]}
                </option>
              ))}
            </OutlinedSelectField>
          </Grid>
          <Grid item xs={12}>
            <OutlinedTextField
              name="additionalInfo"
              labelText={`${formatMessage(labelMessages.additionalInfo)} (${formatMessage(
                labelMessages.optional,
              )})`}
              value={fields.additionalInfo}
              maxLength={140}
              helperText={formatMessage(customerMessages.additionalInfoHelperText)}
              onChange={onChange}
              error={errors?.additionalInfo as Constraint}
            />
          </Grid>
          <Grid item xs={6}>
            <OutlinedSelectField
              name="primaryLabelLanguage"
              labelText={formatMessage(customerMessages.primaryLabelLanguage)}
              value={fields?.primaryLabelLanguage}
              onChange={onChange}
              error={errors?.primaryLabelLanguage as Constraint}
            >
              <option disabled />
              {(Object.keys(KitLabelLanguage) as (keyof typeof KitLabelLanguage)[]).map(
                language => (
                  <option key={language} value={language}>
                    {KitLabelLanguage[language]}
                  </option>
                ),
              )}
            </OutlinedSelectField>
          </Grid>
          <Grid item xs={6}>
            <OutlinedSelectField
              name="secondaryLabelLanguage"
              labelText={formatMessage(customerMessages.secondaryLabelLanguage)}
              value={fields?.secondaryLabelLanguage}
              onChange={onChange}
              error={errors?.secondaryLabelLanguage as Constraint}
            >
              <option disabled />
              {(Object.keys(KitLabelLanguage) as (keyof typeof KitLabelLanguage)[]).map(
                language => (
                  <option key={language} value={language}>
                    {KitLabelLanguage[language]}
                  </option>
                ),
              )}
            </OutlinedSelectField>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};
export default Info;
