import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { Box, makeStyles, createStyles, Grid, Typography } from '@material-ui/core';
import { HistoryActions, HistoryEntry, Module } from '../../types/graphql-generated.types';
import { ProductIcon } from '../products';
import { inventoryMessages } from '../../messages';
import { formatDate } from '../../helpers/date';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
    },
    content: {
      width: '100%',
    },
    action: {
      color: theme.palette.text.secondary,
    },
    iconWrapper: {
      width: '50px',
      display: 'flex',
      flexDirection: 'column',
    },
    icon: {
      borderRadius: '24px',
    },
    firstIcon: {
      marginTop: '14px',
    },
    info: {
      maxWidth: 'calc(100% - 50px)',
      padding: theme.spacing(),
    },
    summary: {
      fontSize: '14px',
    },
    title: {
      marginBottom: theme.spacing(2),
    },
    line: {
      borderRight: `solid 2px ${theme.palette.divider}`,
      width: '17px',
    },
    lineAbove: {
      height: '14px',
    },
    lineBelow: {
      flex: 1,
    },
  }),
);

interface Props {
  entry: HistoryEntry;
  first: boolean;
  last: boolean;
}

const InventoryItemCard: FC<Props> = props => {
  const { entry, first, last } = props;
  const { formatMessage } = useIntl();
  const classes = useStyles(props);

  const module = entry.inventoryItem?.product as Module;

  let action;

  switch (entry.action) {
    case HistoryActions.Reorder:
      action = formatMessage(inventoryMessages.actionReordered);
      break;
    case HistoryActions.Restock:
      action = formatMessage(inventoryMessages.actionRestocked);
      break;
    case HistoryActions.Verify:
      action = formatMessage(inventoryMessages.actionVerified);
      break;
    default:
      action = formatMessage(inventoryMessages.actionUsed);
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <Grid container>
          <Grid item className={classes.iconWrapper}>
            {!first && <Box className={classNames(classes.line, classes.lineAbove)} />}
            {entry.action !== HistoryActions.Verify ? (
              <ProductIcon
                className={classNames(classes.icon, { [classes.firstIcon]: first })}
                rgb={module.rgb}
                svg={module.icon?.svg}
              />
            ) : (
              <ProductIcon
                className={classNames(classes.icon, { [classes.firstIcon]: first })}
                rgb="279B36"
                svg={`<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <path fill="#3D464C" fill-rule="evenodd" d="M8 0C3.584 0 0 3.584 0 8s3.584 8 8 8 8-3.584 8-8-3.584-8-8-8zm.8 12H7.2V7.2h1.6V12zm0-6.4H7.2V4h1.6v1.6z"/>
</svg>`}
              />
            )}
            {!last && <Box className={classNames(classes.line, classes.lineBelow)} />}
          </Grid>
          <Grid item container direction="column" className={classes.info}>
            <Grid item>
              <Typography component="span" variant="body2" noWrap>
                {formatDate(entry.createdAt)}
              </Typography>
            </Grid>
            <Grid item className={classes.title}>
              <Typography component="span" variant="subtitle1" className={classes.action}>
                {action}:
              </Typography>{' '}
              <Typography component="span" variant="subtitle1" noWrap>
                {module.nameEN}
                {entry.action === HistoryActions.Verify && entry.summary}
              </Typography>
            </Grid>
            {entry.summary && entry.action !== HistoryActions.Verify && (
              <Grid item>
                <Typography variant="body2" className={classes.summary}>
                  {entry.summary}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default InventoryItemCard;
