import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';
import ExtensionIcon from '@material-ui/icons/Extension';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { SectionProps, Constraint } from '../../../../types/form.types';
import { ModuleInputUpdate } from '../../../../types/graphql-generated.types';
import { productMessages, labelMessages } from '../../../../messages';
import { OutlinedTextField, OutlinedNumberField, CheckboxField } from '../../../inputs';
import { DEFAULT_INTEGER_MASK, DEFAULT_PRICE_MASK } from '../../../../constants/number-mask';

const decimalMask = createNumberMask({ ...DEFAULT_PRICE_MASK });
const numberMask = createNumberMask({ ...DEFAULT_INTEGER_MASK });

const Detail: FC<SectionProps<ModuleInputUpdate>> = ({ fields, onChange, errors }) => {
  const { formatMessage } = useIntl();

  const moduleSubIdLabel = `${formatMessage(productMessages.moduleSubId)} (${formatMessage(
    labelMessages.optional,
  )})`;

  return (
    <Grid item xs={12}>
      <div className="form-section">
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <div className="form-section-title">
              <div className="section-icon">
                <ExtensionIcon />
              </div>
              <p>{formatMessage(productMessages.moduleDetails)}</p>
            </div>
          </Grid>
          <Grid item xs={8}>
            <OutlinedTextField
              name="nameEN"
              labelText={formatMessage(productMessages.nameEN)}
              value={fields?.nameEN}
              onChange={onChange}
              error={errors?.nameEN as Constraint}
              helperText={formatMessage(productMessages.nameENDescription)}
            />
          </Grid>
          <Grid item xs={4} className="module-form-active">
            <CheckboxField
              name="active"
              labelText={formatMessage(productMessages.statusActive)}
              value={!!fields?.active}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={4}>
            <OutlinedTextField
              name="moduleId"
              labelText={formatMessage(productMessages.moduleId)}
              value={fields?.moduleId}
              onChange={onChange}
              error={errors?.moduleId as Constraint}
            />
          </Grid>
          <Grid item xs={4}>
            <OutlinedTextField
              name="moduleSubId"
              labelText={moduleSubIdLabel}
              value={fields?.moduleSubId}
              onChange={onChange}
              error={errors?.moduleSubId as Constraint}
            />
          </Grid>
          <Grid item xs={4}>
            <OutlinedNumberField
              name="productCode"
              labelText={formatMessage(productMessages.productCode)}
              mask={numberMask}
              value={fields?.productCode}
              onChange={onChange}
              error={errors?.productCode as Constraint}
            />
          </Grid>
          <Grid item xs={4}>
            <OutlinedNumberField
              name="volume"
              labelText={formatMessage(productMessages.volumeCm3)}
              endAdornment="cc"
              mask={numberMask}
              value={fields?.volume}
              onChange={onChange}
              error={errors?.volume as Constraint}
            />
          </Grid>
          <Grid item xs={4}>
            <OutlinedNumberField
              name="recommendedPrice"
              labelText={formatMessage(productMessages.recommendedPrice)}
              startAdornment="€"
              value={fields?.recommendedPrice}
              mask={decimalMask}
              onChange={onChange}
              error={errors?.recommendedPrice as Constraint}
            />
          </Grid>
          <Grid item xs={4}>
            <CheckboxField
              name="expirable"
              labelText={formatMessage(productMessages.expirable)}
              value={!!fields?.expirable}
              onChange={onChange}
            />
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

export default Detail;
