import { useEffect, useCallback } from 'react';
import qs from 'query-string';
import { useImmer } from 'use-immer';
import history from '../lib/history';
import { PaginatedOptions, Options } from '../types/overview.types';
import { Query } from '../types/query.types';
import { setSortableQuery, setPaginationQuery } from '../helpers/query';

type ChangeParamsFn = (
  key: keyof Options | keyof PaginatedOptions,
  value: Options[keyof Options] | PaginatedOptions[keyof PaginatedOptions],
) => void;
type ResetPaginationFn = () => void;

function useOptions(
  initialOptions: PaginatedOptions,
): { options: PaginatedOptions; changeParams: ChangeParamsFn; resetPagination: ResetPaginationFn };
function useOptions(
  initialOptions: Options,
): { options: Options; changeParams: ChangeParamsFn; resetPagination: ResetPaginationFn };
function useOptions(initialOptions: PaginatedOptions | Options) {
  const query: Query = qs.parse(history.location.search, {
    parseNumbers: true,
  });

  const [options, setOptions] = useImmer<typeof initialOptions>(initialOptions);

  useEffect(() => {
    if (!query.column || !query.page || !query.sort) {
      setSortableQuery(options.sortColumn, options.sortDirection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { limit } = options as PaginatedOptions;

  useEffect(() => {
    setOptions(draft => {
      if (query.page) {
        // subtract by 1 because index starts at 0
        const pageNumber = query.page! - 1;
        (draft as PaginatedOptions).page = pageNumber;
        (draft as PaginatedOptions).offset =
          pageNumber * ((options as PaginatedOptions).limit || 20);
      }

      if (query.sort) {
        draft.sortDirection = query.sort;
      }

      if (query.column) {
        draft.sortColumn = query.column;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setOptions, query.sort, query.column, query.page, limit]);

  const changeParams = useCallback(
    (
      key: keyof Options | keyof PaginatedOptions,
      value: Options[keyof Options] | PaginatedOptions[keyof PaginatedOptions],
    ): void => {
      setOptions(draft => {
        // @ts-ignore no clue why this is complaining...
        draft[key] = value;
      });
    },
    [setOptions],
  );

  const resetPagination = () => {
    setPaginationQuery(0);
    changeParams('offset', 0);
  };

  return { options, changeParams, resetPagination };
}

export default useOptions;
