import React, { FC } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { TableRow, TableHeader, TableCell } from '../../table';
import { productMessages, commonMessages } from '../../../messages';
import { Box as BoxType, SortDirection } from '../../../types/graphql-generated.types';
import Loadable from '../../Loadable';
import { formatPrice } from '../../../helpers/format';
import { NumberStepInput } from '../../inputs';

const useStyles = makeStyles({
  amountTable: {
    width: '92%',
  },
  singleTable: {
    width: '100%',
  },
  container: {
    display: 'flex',
  },
  amount: {
    minWidth: '90px',
    width: '8%',
    marginLeft: 'auto',
  },
});

interface BoxesTableProps {
  column: string;
  direction: SortDirection;
  onChangeSort: (key: string) => void;
  getQuantity: (id: number) => number;
  onChangeAmount?: (box: BoxType, amount: number) => void;
  onSelect?: (box: BoxType) => void;
  boxes?: BoxType[];
  loading: boolean;
  variant?: 'amount' | 'single';
  minVolume?: number;
}

const BoxesTable: FC<BoxesTableProps> = props => {
  const {
    variant = 'amount',
    minVolume = 0,
    boxes,
    loading,
    column,
    direction,
    onChangeSort,
    getQuantity,
    onChangeAmount = () => {},
    onSelect = () => {},
  } = props;
  const { formatMessage } = useIntl();
  const classes = useStyles(props);

  const isAmountVariant = variant === 'amount';
  const tableClassName = classNames({
    [classes.amountTable]: isAmountVariant,
    [classes.singleTable]: !isAmountVariant,
  });

  return (
    <>
      <Box className={classes.container}>
        <Box className={tableClassName}>
          <TableHeader sortOptions={{ column, direction, onChange: onChangeSort }}>
            {({ Sortable }) => (
              <>
                <TableCell xs={3}>
                  <Sortable name="box.name">{formatMessage(productMessages.name)}</Sortable>
                </TableCell>
                <TableCell xs={3}>
                  <Sortable name="productInfo.articleId">
                    {formatMessage(productMessages.articleId)}
                  </Sortable>
                </TableCell>
                <TableCell xs={3}>
                  <Sortable name="box.volume">{formatMessage(productMessages.volumeCm3)}</Sortable>
                </TableCell>
                <TableCell xs={3}>
                  <Sortable name="productInfo.price">
                    {formatMessage(productMessages.price)}
                  </Sortable>
                </TableCell>
              </>
            )}
          </TableHeader>
        </Box>
        {isAmountVariant && (
          <Box className={classes.amount}>
            <TableHeader>
              {() => <TableCell ellipsis={false} xs label={formatMessage(commonMessages.amount)} />}
            </TableHeader>
          </Box>
        )}
      </Box>
      <Loadable loading={loading}>
        {boxes?.map(box => (
          <Box key={box.id!} className={classes.container}>
            <Box className={tableClassName}>
              <TableRow
                disabled={!isAmountVariant && (box.volume || 0) < minVolume}
                onClick={!isAmountVariant ? () => onSelect(box) : undefined}
                active={getQuantity(box.id!) > 0}
              >
                <TableCell body primary xs={3} label={box.name} />
                <TableCell body xs={3} label={box.productInfo?.[0]?.articleId} />
                <TableCell body xs={3} label={box.volume} />
                <TableCell body xs={3} label={formatPrice(box.productInfo?.[0]?.price)} />
              </TableRow>
            </Box>
            {isAmountVariant && (
              <Box className={classes.amount}>
                <TableRow>
                  <TableCell body xs>
                    <NumberStepInput
                      disabled={box.productInfo?.[0]?.price == null}
                      value={getQuantity(box.id!)}
                      onChange={(amount: number) => onChangeAmount(box, amount)}
                    />
                  </TableCell>
                </TableRow>
              </Box>
            )}
          </Box>
        ))}
      </Loadable>
    </>
  );
};

export default BoxesTable;
