import { defineMessages } from 'react-intl';

export default defineMessages({
  distributor: {
    id: 'distributor',
    defaultMessage: 'Distributor',
  },
  optional: {
    id: 'optional',
    defaultMessage: 'optional',
  },
  name: {
    id: 'name',
    defaultMessage: 'Name',
  },
  firstName: {
    id: 'first_name',
    defaultMessage: 'First name',
  },
  lastName: {
    id: 'last_name',
    defaultMessage: 'Last name',
  },
  type: {
    id: 'type',
    defaultMessage: 'type',
  },
  status: {
    id: 'status',
    defaultMessage: 'Status',
  },
  vat: {
    id: 'vat',
    defaultMessage: 'VAT',
  },
  sector: {
    id: 'sector',
    defaultMessage: 'Sector',
  },
  numberOfEmployees: {
    id: 'number_of_employees',
    defaultMessage: 'Number of employees',
  },
  reminderFrequency: {
    id: 'reminder_frequency',
    defaultMessage: 'Reminder frequency',
  },
  language: {
    id: 'language',
    defaultMessage: 'Language',
  },
  additionalInfo: {
    id: 'additional_info',
    defaultMessage: 'Additional kit label info',
  },
  addressLineOne: {
    id: 'address_line_one',
    defaultMessage: 'Line 1',
  },
  addressLineTwo: {
    id: 'address_line_two',
    defaultMessage: 'Line 2',
  },
  postalCode: {
    id: 'postal_code',
    defaultMessage: 'Postal code',
  },
  city: {
    id: 'city',
    defaultMessage: 'City',
  },
  country: {
    id: 'country',
    defaultMessage: 'Country',
  },
  email: {
    id: 'email',
    defaultMessage: 'Email',
  },
  phoneNumber: {
    id: 'phone_number',
    defaultMessage: 'Tel Nr',
  },
  defaultDeliveryCost: {
    id: 'default_delivery_cost',
    defaultMessage: 'Default delivery cost',
  },
});
