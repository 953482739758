import React, { FC, useState } from 'react';
import {
  Box,
  makeStyles,
  createStyles,
  Typography,
  ButtonBase,
  Grid,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useIntl } from 'react-intl';
import { useMutation } from 'react-apollo';
import { ApolloQueryResult } from 'apollo-boost';
import {
  GetInventoryForKitQuery,
  Box as BoxType,
  ReorderInventoryMutation,
} from '../../types/graphql-generated.types';
import { inventoryMessages, orderMessages } from '../../messages';
import history from '../../lib/history';
import { FormActions } from '../../components';
import { ReorderItemCard } from '../../components/inventory';
import { getReorder } from '../../helpers/inventory';
import OrderConfirmedDialog from '../../components/inventory/dialogs/OrderConfirmedDialog';
import { REORDER_INVENTORY } from '../../graphql/queries/inventory';
import ReorderLarge from './ReorderLarge';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      height: '100%',
      marginBottom: '80px',
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '26px',
    },
    logo: {
      margin: '0 20px 0 12px',

      '& svg': {
        width: '32px',
        height: '32px',
        color: theme.palette.secondary.main,
      },
    },
    headerText: {
      fontSize: '20px',
    },
    kit: {
      display: 'flex',
      flexDirection: 'column',
      padding: '9px 12px 16px',
      ...theme.mixins.card(),
    },
    kitHeader: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '31px',
    },
    kitHeaderLogo: {
      marginRight: '14px',
      backgroundColor: theme.palette.secondary.light,
      padding: '8px 9px',
      borderRadius: '22px',
      display: 'flex',

      '& path': {
        fill: theme.palette.secondary.dark,
      },
    },
    kitHeaderSubtitle: {
      color: theme.palette.text.secondary,
      fontSize: '12px',
      marginTop: '2px',
      paddingLeft: '1px',
      fontWeight: 600,
    },
    item: {
      marginBottom: theme.spacing(1),
    },
  }),
);

interface InventoryReorderProps {
  inventory: Exclude<GetInventoryForKitQuery['getInventoryForKit'], null>;
  refetch: () => Promise<ApolloQueryResult<GetInventoryForKitQuery>>;
}

const InventoryReorder: FC<InventoryReorderProps> = props => {
  const { inventory, refetch } = props;
  const classes = useStyles(props);
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up('md'));
  const { formatMessage } = useIntl();

  const [showConfirmed, setShowConfirmed] = useState(false);
  const [reorderInventory] = useMutation<ReorderInventoryMutation>(REORDER_INVENTORY);

  const goBack = () => history.push(`/inventory/${inventory.uid}`);

  const reorderItems = getReorder(inventory.children || []);

  if (!reorderItems.length) {
    goBack();
  }

  const onOrder = async () => {
    try {
      await reorderInventory({ variables: { id: inventory.id } });
      setShowConfirmed(true);
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  };

  return (
    <>
      {isLarge ? (
        <ReorderLarge inventory={inventory} reorderItems={reorderItems} />
      ) : (
        <Box className={classes.root}>
          <Box className={classes.header}>
            <ButtonBase className={classes.logo} disableRipple onClick={goBack}>
              <ArrowBackIcon />
            </ButtonBase>
            <Typography className={classes.headerText} variant="h1">
              {formatMessage(inventoryMessages.reorder)}
            </Typography>
          </Box>
          <Box className={classes.kit}>
            <Box className={classes.kitHeader}>
              <Box className={classes.kitHeaderLogo}>
                <WorkOutlineIcon />
              </Box>
              <Box>
                <Typography variant="h3">{inventory.location}</Typography>
                <Typography className={classes.kitHeaderSubtitle}>{`${inventory.uid} - ${
                  (inventory.product as BoxType).name
                }`}</Typography>
              </Box>
            </Box>
            <Grid container>
              {reorderItems.map(i => (
                <Grid className={classes.item} key={i.id!} item xs={12}>
                  <ReorderItemCard item={i} />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      )}
      <FormActions
        onCreateUpdate={() => onOrder()}
        onCancel={() => goBack()}
        createLabel={formatMessage(orderMessages.order)}
        disabled={false}
        loading={false}
      />
      {showConfirmed && (
        <OrderConfirmedDialog
          onClose={() => {
            setShowConfirmed(false);
            refetch();
            goBack();
          }}
        />
      )}
    </>
  );
};

export default InventoryReorder;
