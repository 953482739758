import React, { FC, useState, useEffect } from 'react';
import { Grid, Typography, Box, makeStyles, createStyles } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useQuery } from 'react-apollo';
import { quoteMessages, commonMessages, productMessages } from '../../../messages';
import Panel from '../../Panel';
import Button from '../../Button';
import { OutlinedTextField } from '../../inputs';
import { useProductPanel, usePanelSort, useProductPanelStyles } from '../hooks';
import { ModulesTable, BoxesTable } from '.';
import { GET_BOXES } from '../../../graphql/queries/box';
import {
  GetBoxesQuery,
  GetModulesQuery,
  Box as BoxType,
  QuoteItem,
  Module,
  GetModulesQueryVariables,
  GetBoxesQueryVariables,
} from '../../../types/graphql-generated.types';
import { GET_MODULES } from '../../../graphql/queries/module';
import { formatPrice } from '../../../helpers/format';
import { getVolume, Kit } from '../../../helpers/quote';
import { FieldUpdate } from '../../../helpers/form';
import { Constraint } from '../../../types/form.types';
import ConfirmDialog from '../ConfirmDialog';
import { scrollToElement } from '../../../helpers/scroll';

const useStyles = makeStyles(theme =>
  createStyles({
    totalSubPrice: {
      fontSize: '16px',
      fontWeight: 600,
      color: theme.palette.secondary.main,
      lineHeight: '12px',
      marginLeft: '8px',
    },
  }),
);

interface KitsPanelProps {
  kit?: Kit;
  open: boolean;
  companyId: number;
  onlyLocation?: boolean;
  onClose: () => void;
  onCreate?: (quoteItem: QuoteItem) => void;
  onUpdate: (kit: Kit) => void;
}

const restoreKit = (kit: QuoteItem) => {
  return {
    location: kit.location || '',
    modules: kit.children || [],
    box: [
      {
        product: kit.product,
        quantity: kit.quantity,
        totalPrice: kit.product?.productInfo?.[0]?.price,
      },
    ],
  };
};

const KitsPanel: FC<KitsPanelProps> = props => {
  const { kit, open, onClose, onCreate, onUpdate, companyId, onlyLocation } = props;

  const isUpdate = !!kit;

  const classes = useProductPanelStyles(props);
  const localClasses = useStyles(props);
  const { formatMessage } = useIntl();

  const [openDialog, setOpenDialog] = useState(false);
  const [dirty, setDirty] = useState(false);

  const [location, setLocation] = useState('');
  const [locationError, setLocationError] = useState<Partial<Constraint>>();

  const { initQuoteItems: initModules, ...modulesPanel } = useProductPanel([]);
  const modulesSort = usePanelSort('module.moduleId');
  const modulesQuery = useQuery<GetModulesQuery, GetModulesQueryVariables>(GET_MODULES, {
    skip: onlyLocation,
    variables: {
      companyId,
      sortDirection: modulesSort.direction,
      sortColumn: modulesSort.column,
      filter: {
        active: { eq: [true] },
      },
    },
  });

  const [isAutoSetBox, setIsAutoSetBox] = useState(true);
  const { initQuoteItems: initBoxes, ...boxesPanel } = useProductPanel([]);
  const boxesSort = usePanelSort('box.volume');

  const boxesQuery = useQuery<GetBoxesQuery, GetBoxesQueryVariables>(GET_BOXES, {
    skip: onlyLocation,
    variables: {
      companyId,
      sortDirection: boxesSort.direction,
      sortColumn: boxesSort.column,
      filter: {
        active: { eq: [true] },
      },
    },
  });

  useEffect(() => {
    if (kit) {
      const restored = restoreKit(kit[1]);
      initModules(restored.modules);
      initBoxes(restored.box);
      setLocation(restored.location);
      setLocationError(undefined);
      setIsAutoSetBox(false);
      setDirty(false);
    }
  }, [setLocation, setLocationError, kit, initModules, initBoxes]);

  const boxQuoteItems = boxesPanel.getQuoteItems();
  const selectedQuoteItem = boxQuoteItems.length ? boxQuoteItems[0] : null;
  const selectedBox = selectedQuoteItem?.product as BoxType | undefined;

  const isBoxTooSmall = selectedBox && getVolume(selectedBox) < modulesPanel.totalVolume;
  const isBoxTooBigAndAutoSet =
    selectedBox && getVolume(selectedBox) >= modulesPanel.totalVolume && isAutoSetBox;

  if (boxesQuery.data?.getBoxes && (!selectedBox || isBoxTooSmall || isBoxTooBigAndAutoSet)) {
    // Find first box that is big enough to fit all the modules
    const box = [...boxesQuery.data.getBoxes]
      .sort((a, b) => getVolume(a) - getVolume(b))
      .find(b => getVolume(b) >= modulesPanel.totalVolume);

    // Auto-set that box if it's different from the currently selected box
    if (box && box.id !== selectedBox?.id) {
      setIsAutoSetBox(true);
      boxesPanel.setToSingleQuoteItem(box);
      // If no box is found and we have 1 already selected it means no box is big enough
    } else if (!box && selectedBox) {
      boxesPanel.resetQuoteItems();
    }
  }

  const onSelectBox = (box: BoxType) => {
    setIsAutoSetBox(false);
    boxesPanel.setToSingleQuoteItem(box);
    setDirty(true);
  };

  const onSelectedModules = (module: Module, amount: number) => {
    modulesPanel.updateQuoteItems(module, amount);
    setDirty(true);
  };

  const onChangeLocation = ({ value }: FieldUpdate) => {
    setLocation(value as string);

    if (locationError) {
      setLocationError(undefined);
    }

    if (!dirty) {
      setDirty(true);
    }
  };

  const onBlurLocation = () => setLocation(location.trim());

  const reset = () => {
    setDirty(false);
    setLocation('');
    setLocationError(undefined);
    setIsAutoSetBox(true);
    modulesPanel.resetQuoteItems();
    boxesPanel.resetQuoteItems();
  };

  const onCancel = (isConfirmed?: boolean) => {
    if (isConfirmed || !dirty) {
      setOpenDialog(false);
      onClose();
      reset();
    } else {
      setOpenDialog(true);
    }
  };

  const onCreateKit = () => {
    if (!location || location.trim() === '') {
      setLocationError({
        isNotEmpty: '',
      });

      scrollToElement('#location');
    } else {
      const boxQuoteItem = boxesPanel.getQuoteItems()[0];
      const kitToSave: QuoteItem = {
        location: location.trim(),
        product: boxQuoteItem?.product,
        quantity: boxQuoteItem?.quantity,
        totalPrice: boxesPanel.totalPrice,
        children: modulesPanel.getQuoteItems(),
      };

      if (isUpdate && kit) {
        onUpdate([kit[0], kitToSave]);
      } else if (onCreate) {
        onCreate(kitToSave);
      }

      onClose();
      reset();
    }
  };

  return (
    <>
      <Panel onClose={() => onCancel()} open={open} classes={{ root: classes.panel }}>
        <Typography className={classes.section} variant="h2">
          {formatMessage(isUpdate ? quoteMessages.editKit : quoteMessages.addKit)}
        </Typography>
        <Grid container className={classes.section}>
          <Grid item xs={10} md={6}>
            <OutlinedTextField
              inputProps={{ id: 'location' }}
              name="location"
              labelText={formatMessage(quoteMessages.location)}
              helperText={formatMessage(quoteMessages.locationInfo)}
              value={location}
              onChange={onChangeLocation}
              error={locationError}
              maxLength={50}
              onBlur={onBlurLocation}
            />
          </Grid>
        </Grid>
        {!onlyLocation && (
          <>
            <Typography className={classes.section} variant="h3">
              {formatMessage(productMessages.modules)}
            </Typography>
            <Box className={classes.section}>
              <ModulesTable
                column={modulesSort.column}
                direction={modulesSort.direction}
                onChangeSort={modulesSort.onChangeSort}
                onChangeAmount={onSelectedModules}
                getQuantity={modulesPanel.getQuantity}
                modules={modulesQuery.data?.getModules}
                loading={modulesQuery.loading}
              />
            </Box>
            <Typography className={classes.section} variant="h3">
              {formatMessage(productMessages.boxes)}
            </Typography>
            <Box className={classes.section}>
              <BoxesTable
                variant="single"
                column={boxesSort.column}
                direction={boxesSort.direction}
                onChangeSort={boxesSort.onChangeSort}
                onSelect={onSelectBox}
                getQuantity={boxesPanel.getQuantity}
                boxes={boxesQuery.data?.getBoxes}
                loading={boxesQuery.loading}
                minVolume={modulesPanel.totalVolume}
              />
            </Box>
            <Grid className={classes.total} container justify="flex-end">
              <span className={classes.totalDescription}>
                {formatMessage(quoteMessages.modulesAt, { itemCount: modulesPanel.totalAmount })}
              </span>
              <span className={localClasses.totalSubPrice}>
                {formatPrice(modulesPanel.totalPrice)}
              </span>
              {selectedBox && (
                <>
                  <span className={classes.totalDescription}>{' + '}</span>
                  <span className={classes.totalDescription}>
                    {formatMessage(quoteMessages.boxAt, { name: selectedBox?.name })}
                  </span>
                  <span className={localClasses.totalSubPrice}>
                    {formatPrice(boxesPanel.totalPrice)}
                  </span>
                </>
              )}
              <span className={classes.totalDescription}>{' = '}</span>
              <span className={classes.totalPrice}>
                {formatPrice(modulesPanel.totalPrice + boxesPanel.totalPrice)}
              </span>
            </Grid>
          </>
        )}
        <Grid className={classes.actions} container justify="flex-end">
          <Button
            onClick={() => onCancel()}
            text={formatMessage(commonMessages.cancel)}
            className="button-outlined"
          />
          <Button
            disabled={!selectedBox || !modulesPanel.totalAmount}
            onClick={onCreateKit}
            text={formatMessage(isUpdate ? commonMessages.save : commonMessages.add)}
            className="button-filled"
          />
        </Grid>
      </Panel>
      <ConfirmDialog
        open={openDialog}
        onCancel={() => setOpenDialog(false)}
        onConfirm={() => onCancel(true)}
        title={formatMessage(quoteMessages.discardTitle)}
        content={formatMessage(quoteMessages.kitDiscardContent)}
      />
    </>
  );
};

export default KitsPanel;
