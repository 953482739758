import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';
import ExtensionIcon from '@material-ui/icons/Extension';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { SectionProps, Constraint } from '../../../../types/form.types';
import { productMessages } from '../../../../messages';
import { OutlinedTextField, OutlinedNumberField, CheckboxField } from '../../../inputs';
import { ProductInfoInputUpdate, Box } from '../../../../types/graphql-generated.types';
import { DEFAULT_INTEGER_MASK, DEFAULT_PRICE_MASK } from '../../../../constants/number-mask';
import { formatPrice } from '../../../../helpers/format';

const decimalMask = createNumberMask({ ...DEFAULT_PRICE_MASK });
const numberMask = createNumberMask({ ...DEFAULT_INTEGER_MASK });

type DetailsDistributorProps = SectionProps<ProductInfoInputUpdate> & {
  box?: Box | null;
};

const Details: FC<DetailsDistributorProps> = ({ fields, box, onChange, errors }) => {
  const { formatMessage } = useIntl();

  return (
    <Grid item xs={12}>
      <div className="form-section">
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <div className="form-section-title">
              <div className="section-icon">
                <ExtensionIcon />
              </div>
              <p>{formatMessage(productMessages.boxDetails)}</p>
            </div>
          </Grid>
          <Grid item xs={6}>
            <OutlinedTextField
              disabled
              name="name"
              labelText={formatMessage(productMessages.name)}
              value={box?.name}
            />
          </Grid>
          <Grid item xs={4}>
            <OutlinedNumberField
              disabled
              name="productCode"
              labelText={formatMessage(productMessages.productCode)}
              mask={numberMask}
              value={box?.productCode}
            />
          </Grid>
          <Grid item xs={2} className="box-form-active">
            <CheckboxField
              disabled
              name="active"
              labelText={formatMessage(productMessages.statusActive)}
              value={!!box?.active}
            />
          </Grid>
          <Grid item xs={4}>
            <OutlinedTextField
              name="articleId"
              labelText={formatMessage(productMessages.articleId)}
              value={fields?.articleId}
              onChange={onChange}
              error={errors?.articleId as Constraint}
              helperText={formatMessage(productMessages.articleIdDescription)}
            />
          </Grid>
          <Grid item xs={4}>
            <OutlinedNumberField
              name="price"
              labelText={formatMessage(productMessages.price)}
              startAdornment="€"
              value={fields?.price}
              mask={decimalMask}
              onChange={onChange}
              error={errors?.price as Constraint}
              helperText={`${formatMessage(productMessages.recommended)}: ${formatPrice(
                box?.recommendedPrice,
              ) || ''}`}
            />
          </Grid>
          <Grid item xs={4}>
            <OutlinedNumberField
              disabled
              name="volume"
              labelText={formatMessage(productMessages.volumeCm3)}
              endAdornment="cc"
              mask={numberMask}
              value={box?.volume}
            />
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

export default Details;
