import { AddressInterface } from './address.types';
import { SectionProps } from './form.types';

export enum Language {
  NL = 'NL',
  FR = 'FR',
  EN = 'EN',
}

export enum KitLabelLanguage {
  EN = 'EN',
  FR = 'FR',
  NL = 'NL',
  DE = 'DE',
  ES = 'ES',
  RO = 'RO',
  TR = 'TR',
  PL = 'PL',
  PT = 'PT',
  AR = 'AR',
}

export enum ReminderFrequency {
  NONE = 'None',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
  QUARTERLY = 'Quarterly',
  BIANNUAL = 'Biannual',
  YEARLY = 'Yearly',
}

export enum Sector {
  CONSTRUCTION = 'Construction',
  CHEMISTRY_RUBBER_PLASTIC = 'Chemistry Rubber Plastic',
  VARIOUS_SERVICES = 'Various services',
  SERVICES_TO_PERSONS = 'Services to persons',
  ENERGY_WATER_WASTE = 'Energy water waste',
  FINANCIAL_SERVICES = 'Financial services',
  HEALTHCARE = 'Healthcare',
  GRAPHIC_INDUSTRY = 'Graphic industry',
  CATERING_TOURISM = 'Catering tourism',
  WOOD_FURNITURE = 'Wood furniture',
  ICT = 'ICT',
  SOCIAL_SERVICES = 'Social services',
  METAL = 'Metal',
  EDUCATION = 'Education',
  RECREATION_CULTURE_SPORT = 'Recreation culture sport',
  PUBLIC_AUTHORITIES = 'Public authorities',
  OTHER_INDUSTRY = 'Other industry',
  OTHER_SERVICES = 'Other services',
  PRIMARY_SECTOR = 'Primary sector',
  PRODUCTION_BUILDING_MATERIALS = 'Production building materials',
  TEXTILE_CLOTHING_FOOTWEAR = 'Textile clothing footwear',
  TRANSPORT_LOGISTICS = 'Transport logistics',
  EMPLOYMENT_JOB_PLACEMENT = 'Employment job placement',
  BUSINESS_SERVICES = 'Business services',
}

export type SectorKey = keyof typeof Sector;

export type CompanySectionProps<T> = SectionProps<T> & {
  isCustomer?: boolean;
  distributorDisabled?: boolean;
};

export interface GeneralSection {
  name: string;
  vat: string;
  distributor?: number | null;
}

export type AddressSection = {
  addresses: Omit<AddressInterface, 'id' | 'createdAt' | 'updatedAt'>[];
};

export interface ContactSection {
  email: string;
  phone?: string;
}

export interface CostsSection {
  defaultDeliveryCost?: number | null;
}

export interface InfoSection {
  sector: Sector | null;
  numberOfEmployees: number | null;
  additionalInfo: string;
  language: Language | null;
  primaryLabelLanguage: KitLabelLanguage | null;
  secondaryLabelLanguage: KitLabelLanguage | null;
}

export type CompanyForm = GeneralSection &
  ContactSection &
  AddressSection & {
    id?: number;
  } & CostsSection;

export type CustomerForm = CompanyForm & InfoSection;
