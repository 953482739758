import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';
import ExtensionIcon from '@material-ui/icons/Extension';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { SectionProps, Constraint } from '../../../../types/form.types';
import { ProductInfoInputUpdate, Module } from '../../../../types/graphql-generated.types';
import { productMessages } from '../../../../messages';
import { OutlinedTextField, OutlinedNumberField, CheckboxField } from '../../../inputs';
import { DEFAULT_INTEGER_MASK, DEFAULT_PRICE_MASK } from '../../../../constants/number-mask';
import { formatPrice } from '../../../../helpers/format';

const decimalMask = createNumberMask({ ...DEFAULT_PRICE_MASK });
const numberMask = createNumberMask({ ...DEFAULT_INTEGER_MASK });

type DetailsDistributorProps = SectionProps<ProductInfoInputUpdate> & {
  module?: Module | null;
};

const Detail: FC<DetailsDistributorProps> = ({ fields, module, onChange, errors }) => {
  const { formatMessage } = useIntl();

  return (
    <Grid item xs={12}>
      <div className="form-section">
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <div className="form-section-title">
              <div className="section-icon">
                <ExtensionIcon />
              </div>
              <p>{formatMessage(productMessages.moduleDetails)}</p>
            </div>
          </Grid>
          <Grid item xs={8}>
            <OutlinedTextField
              disabled
              name="nameEN"
              labelText={formatMessage(productMessages.nameEN)}
              value={module?.nameEN}
              onChange={() => {}}
            />
          </Grid>
          <Grid item xs={4} className="module-form-active">
            <CheckboxField
              disabled
              name="active"
              labelText={formatMessage(productMessages.statusActive)}
              value={!!module?.active}
              onChange={() => {}}
            />
          </Grid>
          <Grid item xs={4}>
            <OutlinedTextField
              disabled
              name="moduleId"
              labelText={formatMessage(productMessages.moduleId)}
              value={module?.moduleId}
              onChange={() => {}}
            />
          </Grid>
          <Grid item xs={4}>
            <OutlinedTextField
              disabled
              name="moduleSubId"
              labelText={formatMessage(productMessages.moduleSubId)}
              value={module?.moduleSubId}
              onChange={() => {}}
            />
          </Grid>
          <Grid item xs={4}>
            <OutlinedNumberField
              disabled
              name="productCode"
              labelText={formatMessage(productMessages.productCode)}
              mask={numberMask}
              value={module?.productCode}
              onChange={() => {}}
            />
          </Grid>
          <Grid item xs={4}>
            <OutlinedNumberField
              disabled
              name="volume"
              labelText={formatMessage(productMessages.volumeCm3)}
              endAdornment="cc"
              mask={numberMask}
              value={module?.volume}
              onChange={() => {}}
            />
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={4}>
            <CheckboxField
              disabled
              name="expirable"
              labelText={formatMessage(productMessages.expirable)}
              value={!!module?.expirable}
              onChange={() => {}}
            />
          </Grid>
          <Grid item xs={4}>
            <OutlinedTextField
              name="articleId"
              labelText={formatMessage(productMessages.articleId)}
              value={fields?.articleId}
              onChange={onChange}
              error={errors?.articleId as Constraint}
              helperText={formatMessage(productMessages.articleIdDescription)}
            />
          </Grid>
          <Grid item xs={4}>
            <OutlinedNumberField
              name="price"
              labelText={formatMessage(productMessages.price)}
              startAdornment="€"
              value={fields?.price}
              mask={decimalMask}
              onChange={onChange}
              error={errors?.price as Constraint}
              helperText={`${formatMessage(productMessages.recommended)}: ${formatPrice(
                module?.recommendedPrice,
              ) || ''}`}
            />
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

export default Detail;
