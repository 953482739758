import React, { FC } from 'react';
import classNames from 'classnames';
import { GridProps, Grid, makeStyles, createStyles, Typography, Tooltip } from '@material-ui/core';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      padding: '0 16px',
    },
    label: {
      alignItems: 'center',
      color: theme.palette.text.secondary,
    },
    primary: {
      fontWeight: 600,
      color: theme.palette.text.primary,
    },
    body: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

type TableCellProps = GridProps & {
  label?: number | string | null;
  primary?: boolean;
  body?: boolean;
  ellipsis?: boolean;
};

const TableCell: FC<TableCellProps> = props => {
  const { primary, body, label, children, ellipsis = true, ...gridProps } = props;
  const classes = useStyles(props);

  const className = classNames(classes.root, {
    [classes.body]: body,
  });

  const labelClassName = classNames(classes.label, {
    [classes.primary]: primary,
  });

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Grid className={className} item {...gridProps}>
      {label ? (
        <Tooltip enterDelay={500} enterNextDelay={500} title={label} placement="top" arrow>
          <Typography className={labelClassName} noWrap={ellipsis}>
            {label}
          </Typography>
        </Tooltip>
      ) : (
        <>{children}</>
      )}
    </Grid>
  );
};

export default TableCell;
