import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import { Grid, makeStyles, createStyles, Box } from '@material-ui/core';
import { productMessages, commonMessages, labelMessages, quoteMessages } from '../../../messages';
import Button from '../../Button';
import { QuoteItem, Box as BoxType } from '../../../types/graphql-generated.types';
import { TableRow, TableCell, TableHeader } from '../../table';
import { formatPrice } from '../../../helpers/format';
import { sortBoxQuoteItemsFn } from '../../../helpers/quote';

const useStyles = makeStyles(theme =>
  createStyles({
    button: {
      '& span': {
        display: 'inline-block',
        marginRight: '15px',
      },
    },
    emptyRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.text.secondary,
    },
    table: {
      width: '88%',

      [theme.breakpoints.up('lg')]: {
        width: '88%',
      },
    },
    amountContainer: {
      width: '10%',
      marginLeft: 'auto',

      [theme.breakpoints.up('lg')]: {
        width: '10%',
      },
    },
    amount: {
      padding: 0,
      justifyContent: 'center',
    },
    amountRow: {
      padding: 0,
    },
    amountHeader: {
      padding: 0,
      textAlign: 'center',
    },
  }),
);

interface BoxesProps {
  onSelect?: () => void;
  quoteItems: QuoteItem[];
  disableSelect?: boolean;
  disable?: boolean;
}

const Boxes: FC<BoxesProps> = props => {
  const { quoteItems, disable, disableSelect, onSelect = () => {} } = props;

  const classes = useStyles(props);
  const { formatMessage } = useIntl();

  return (
    <Grid item xs={12}>
      <div className="form-section">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className="form-section-title">
              <div className="section-icon">
                <WorkOutlineIcon />
              </div>
              <p>{formatMessage(productMessages.boxes)}</p>
              {!disable && (
                <Button
                  className="button-outlined"
                  text={formatMessage(commonMessages.select)}
                  onClick={() => onSelect()}
                  disabled={disableSelect}
                />
              )}
            </div>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Box className={classes.table}>
                <TableHeader>
                  {() => (
                    <>
                      <TableCell xs={5} label={formatMessage(labelMessages.name)} />
                      <TableCell xs={4} lg={3} label={formatMessage(productMessages.articleId)} />
                      <TableCell xs={3} lg={4} label={formatMessage(productMessages.price)} />
                    </>
                  )}
                </TableHeader>
              </Box>
              <Box className={classes.amountContainer}>
                <TableHeader classes={{ root: classes.amountRow }}>
                  {() => <TableCell classes={{ root: classes.amountHeader }} xs label="#" />}
                </TableHeader>
              </Box>
            </Grid>
            {quoteItems.length ? (
              quoteItems.sort(sortBoxQuoteItemsFn).map(quoteItem => {
                const box = quoteItem.product as BoxType;
                const productInfo = quoteItem.product?.productInfo?.[0];

                return (
                  <Grid container key={box.id!}>
                    <Box className={classes.table}>
                      <TableRow>
                        <TableCell primary body xs={5} label={box.name} />
                        <TableCell body xs={4} lg={3} label={productInfo?.articleId} />
                        <TableCell body xs={3} lg={4} label={formatPrice(productInfo?.price)} />
                      </TableRow>
                    </Box>
                    <Box className={classes.amountContainer}>
                      <TableRow classes={{ root: classes.amountRow }}>
                        <TableCell
                          classes={{ root: classes.amount }}
                          body
                          ellipsis={false}
                          xs
                          label={`x ${quoteItem.quantity}`}
                        />
                      </TableRow>
                    </Box>
                  </Grid>
                );
              })
            ) : (
              <TableRow>
                <TableCell
                  classes={{ root: classes.emptyRow }}
                  body
                  xs={12}
                  label={formatMessage(quoteMessages.noBoxes)}
                />
              </TableRow>
            )}
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

export default Boxes;
