import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';
import ExtensionIcon from '@material-ui/icons/Extension';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { SectionProps, Constraint } from '../../../../types/form.types';
import { productMessages } from '../../../../messages';
import { OutlinedTextField, OutlinedNumberField, CheckboxField } from '../../../inputs';
import { BoxInputUpdate } from '../../../../types/graphql-generated.types';
import { DEFAULT_INTEGER_MASK, DEFAULT_PRICE_MASK } from '../../../../constants/number-mask';

const decimalMask = createNumberMask({ ...DEFAULT_PRICE_MASK });
const numberMask = createNumberMask({ ...DEFAULT_INTEGER_MASK });

const Details: FC<SectionProps<BoxInputUpdate>> = ({ fields, onChange, errors }) => {
  const { formatMessage } = useIntl();

  return (
    <Grid item xs={12}>
      <div className="form-section">
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <div className="form-section-title">
              <div className="section-icon">
                <ExtensionIcon />
              </div>
              <p>{formatMessage(productMessages.boxDetails)}</p>
            </div>
          </Grid>
          <Grid item xs={6}>
            <OutlinedTextField
              name="name"
              labelText={formatMessage(productMessages.name)}
              value={fields?.name}
              onChange={onChange}
              error={errors?.name as Constraint}
            />
          </Grid>
          <Grid item xs={6} className="box-form-active">
            <CheckboxField
              name="active"
              labelText={formatMessage(productMessages.statusActive)}
              value={!!fields?.active}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={4}>
            <OutlinedNumberField
              name="productCode"
              labelText={formatMessage(productMessages.productCode)}
              mask={numberMask}
              value={fields?.productCode}
              onChange={onChange}
              error={errors?.productCode as Constraint}
            />
          </Grid>
          <Grid item xs={4}>
            <OutlinedNumberField
              name="volume"
              labelText={formatMessage(productMessages.volumeCm3)}
              endAdornment="cc"
              mask={numberMask}
              value={fields?.volume}
              onChange={onChange}
              error={errors?.volume as Constraint}
            />
          </Grid>
          <Grid item xs={4}>
            <OutlinedNumberField
              name="recommendedPrice"
              labelText={formatMessage(productMessages.recommendedPrice)}
              startAdornment="€"
              value={fields?.recommendedPrice}
              mask={decimalMask}
              onChange={onChange}
              error={errors?.recommendedPrice as Constraint}
            />
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

export default Details;
