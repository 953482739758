import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import { useMutation, useQuery } from 'react-apollo';
import { useParams } from 'react-router';
import PageHeader from '../../../components/PageHeader';
import FormActions from '../../../components/FormActions';
import { usePageView, useForm } from '../../../hooks';
import { DetailsDistributor as DetailsDistributorModule } from '../../../components/products/modules/sections';
import { DetailsDistributor as DetailsDistributorBox } from '../../../components/products/boxes/sections';
import {
  GET_PRODUCT_INFO,
  CREATE_PRODUCT_INFO,
  UPDATE_PRODUCT_INFO,
} from '../../../graphql/queries/product-info';
import {
  GetProductInfoQuery,
  ProductInfoInputUpdate,
  GetModuleQuery,
  GetBoxQuery,
  Module as ModuleType,
  Box as BoxType,
} from '../../../types/graphql-generated.types';
import { getProductInfoFields } from '../../../constants/form-constants';
import { useUser } from '../../../providers/UserProvider';
import { GET_MODULE } from '../../../graphql/queries/module';
import { GET_BOX } from '../../../graphql/queries/box';
import history from '../../../lib/history';
import { Loadable } from '../../../components';

const initialFields = getProductInfoFields();

export enum ProductType {
  Module = 'Module',
  Box = 'Box',
}

type DetailProps = {
  productType: ProductType;
};

const Detail: FC<DetailProps> = ({ productType }) => {
  usePageView('edit_prices');

  const { companyId } = useUser();
  const { id, productInfoId } = useParams();

  const productId = parseInt(id!, 10);
  const infoId = productInfoId != null ? parseInt(productInfoId, 10) : undefined;

  const isModule = productType === ProductType.Module;
  const isCreate = infoId === undefined;
  const [update, { loading: isSaving }] = useMutation(
    isCreate ? CREATE_PRODUCT_INFO : UPDATE_PRODUCT_INFO,
  );
  const serializer = (f: ProductInfoInputUpdate) => (isCreate ? { ...f, product: productId } : f);
  const {
    fields,
    handleChange,
    handleCreateUpdate,
    errors,
    disabled,
    updateFields,
    updateField,
  } = useForm<ProductInfoInputUpdate>(update, initialFields, isCreate, infoId);

  const redirectTo = isModule ? '/products' : '/products/boxes';

  // Skip if there is no ProductInfo for the Module yet
  const { data, loading } = useQuery<GetProductInfoQuery>(GET_PRODUCT_INFO, {
    skip: isCreate,
    variables: {
      companyId,
      id: infoId,
    },
    onCompleted(result) {
      if (result.getProductInfo) {
        const { id: _, product, ...productInfo } = result.getProductInfo;

        if (product?.id !== productId) {
          history.push(redirectTo);
        } else {
          updateFields(productInfo);
        }
      } else {
        history.push(redirectTo);
      }
    },
  });

  const productQuery = isModule ? GET_MODULE : GET_BOX;

  // Skip if we already have the Module / Box on the ProductInfo
  const { data: productData, loading: productLoading } = useQuery<GetModuleQuery | GetBoxQuery>(
    productQuery,
    {
      skip: !isCreate,
      variables: {
        id: productId,
        companyId,
      },
      onCompleted(result) {
        const product = isModule
          ? (result as GetModuleQuery).getModule
          : (result as GetBoxQuery).getBox;
        if (product) {
          const { recommendedPrice } = product;
          updateField('price', recommendedPrice);
        } else {
          history.push(redirectTo);
        }
      },
    },
  );

  const name = isModule
    ? (data?.getProductInfo?.product as ModuleType)?.nameEN ||
      (productData as GetModuleQuery)?.getModule?.nameEN
    : (data?.getProductInfo?.product as BoxType)?.name ||
      (productData as GetBoxQuery)?.getBox?.name;

  return (
    <Loadable loading={loading || productLoading}>
      <PageHeader title={name || ''} back={redirectTo} />
      <div className="add-edit-form module-form">
        <Grid container spacing={3}>
          {isModule ? (
            <DetailsDistributorModule
              module={
                (data?.getProductInfo?.product as ModuleType) ||
                (productData as GetModuleQuery)?.getModule
              }
              fields={fields}
              errors={errors}
              onChange={handleChange}
            />
          ) : (
            <DetailsDistributorBox
              box={
                (data?.getProductInfo?.product as BoxType) || (productData as GetBoxQuery)?.getBox
              }
              fields={fields}
              errors={errors}
              onChange={handleChange}
            />
          )}
        </Grid>
      </div>
      <FormActions
        onCreateUpdate={() => handleCreateUpdate(redirectTo, { companyId }, serializer)}
        disabled={disabled}
        create={isCreate}
        loading={isSaving}
      />
    </Loadable>
  );
};

export default Detail;
