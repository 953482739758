import { useRef, useState, useEffect } from 'react';

const useLabel = () => {
  const [labelWidth, setLabelWidth] = useState(0);
  const labelRef = useRef<HTMLLabelElement>(null);

  useEffect(() => {
    if (labelRef.current) {
      setLabelWidth(labelRef.current.offsetWidth);
    }
  }, []);

  return { labelWidth, labelRef };
};

export default useLabel;
