import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import EuroIcon from '@material-ui/icons/Euro';
import { Grid, Typography, Button } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { productMessages, quoteMessages } from '../../../messages';
import { QuoteItem, Module, Box } from '../../../types/graphql-generated.types';
import { formatPrice, formatNumber } from '../../../helpers/format';
import { Kit, calculateTotalOfKit, sortKitsFn } from '../../../helpers/quote';

const useStyles = makeStyles(theme =>
  createStyles({
    bolder: {
      fontWeight: 600,
    },
    lighter: {
      color: theme.palette.text.secondary,
    },
    larger: {
      fontSize: '20px',
      lineHeight: '17px',
    },
    separator: {
      background: `repeating-linear-gradient(to right,${theme.palette.text.primary} 0,${theme.palette.text.primary} 5px,transparent 5px,transparent 7px)`,
      height: '1px',
      marginBottom: '16px',
    },
    entry: {
      marginBottom: '16px',
    },
    entryTitle: {
      fontWeight: 600,
      color: theme.palette.secondary.dark,
    },
    emptyQuote: {
      opacity: 0.55,
    },
    alignRight: {
      marginLeft: 'auto',
    },
    name: {
      width: '75%',
    },
    column: {
      lineHeight: '16px',
      fontSize: '16px',
    },
    amount: {
      marginRight: '5px',
      fontSize: '14px',
      lineHeight: '16px',
    },
    showAllButton: {
      textTransform: 'none',
    },
  }),
);

const KIT_LIMIT = 10;

interface PriceProps {
  totalPrice: number;
  discountedTotal: number;
  grandTotal: number;
  deliveryCost: number;
  discount: number;
  kits: Kit[];
  modules: QuoteItem[];
  boxes: QuoteItem[];
}

const Price: FC<PriceProps> = ({
  totalPrice,
  discountedTotal,
  grandTotal,
  deliveryCost,
  discount,
  kits,
  modules,
  boxes,
}) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  const [showAllKits, setShowAllKits] = useState(false);

  const kitsClass = classNames({ [classes.emptyQuote]: !kits.length });
  const modulesClass = classNames({ [classes.emptyQuote]: !modules.length });
  const boxesClass = classNames({ [classes.emptyQuote]: !boxes.length });

  const sortedKits = [...kits].sort(sortKitsFn);
  const displayKits = showAllKits ? sortedKits : sortedKits.slice(0, KIT_LIMIT);

  return (
    <Grid item xs={12}>
      <div className="form-section form-section-price">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className="form-section-title">
              <div className="section-icon">
                <EuroIcon />
              </div>
              <p>{formatMessage(productMessages.price)}</p>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid className={classNames(kitsClass, classes.entry)} item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <span className={classes.entryTitle}>{formatMessage(quoteMessages.kits)}</span>
                  </Grid>
                  {displayKits.length ? (
                    <>
                      {displayKits.map(([id, kit]) => (
                        <Grid key={id} container justify="space-between" item xs={12}>
                          <Typography className={classNames(classes.column, classes.name)} noWrap>
                            {kit.location}
                          </Typography>
                          <Typography className={classNames(classes.column, classes.bolder)} noWrap>
                            {formatPrice(calculateTotalOfKit(kit))}
                          </Typography>
                        </Grid>
                      ))}
                      {sortedKits.length > 10 && (
                        <Grid item xs={12} container justify="center">
                          <Button
                            classes={{ root: classes.showAllButton }}
                            color="secondary"
                            onClick={() => setShowAllKits(!showAllKits)}
                          >
                            <Typography noWrap>
                              {formatMessage(
                                showAllKits
                                  ? quoteMessages.showLessKits
                                  : quoteMessages.showAllKits,
                              )}
                            </Typography>
                          </Button>
                        </Grid>
                      )}
                    </>
                  ) : (
                    <Grid container justify="space-between" item xs={12}>
                      <span>{formatMessage(quoteMessages.noKits)}</span>
                      <span>{formatPrice(0)}</span>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid className={classes.separator} item xs={12} />
              <Grid className={modulesClass} item xs={12}>
                <Grid container className={classes.entry} spacing={2}>
                  <Grid item xs={12}>
                    <span className={classes.entryTitle}>
                      {formatMessage(productMessages.modules)}
                    </span>
                  </Grid>
                  {modules.length ? (
                    modules.map(quoteItem => (
                      <Grid
                        key={quoteItem.product!.id!}
                        container
                        alignContent="flex-end"
                        item
                        xs={12}
                      >
                        <span className={classNames(classes.lighter, classes.amount)}>
                          {`${quoteItem.quantity} x `}
                        </span>
                        <span className={classNames(classes.column, classes.name)}>
                          {(quoteItem.product as Module)?.nameEN}
                        </span>
                        <span
                          className={classNames(classes.bolder, classes.alignRight, classes.column)}
                        >
                          {formatPrice(quoteItem.totalPrice)}
                        </span>
                      </Grid>
                    ))
                  ) : (
                    <Grid container justify="space-between" item xs={12}>
                      <span>{formatMessage(quoteMessages.noModules)}</span>
                      <span>{formatPrice(0)}</span>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid className={boxesClass} item xs={12}>
                <Grid container className={classes.entry} spacing={2}>
                  <Grid item xs={12}>
                    <span className={classes.entryTitle}>
                      {formatMessage(productMessages.boxes)}
                    </span>
                  </Grid>
                  {boxes.length ? (
                    boxes.map(quoteItem => (
                      <Grid key={quoteItem.product!.id!} container item xs={12}>
                        <span className={classNames(classes.lighter, classes.amount)}>
                          {quoteItem.quantity}x
                        </span>
                        <span className={classNames(classes.column, classes.name)}>
                          {(quoteItem.product as Box)?.name}
                        </span>
                        <span className={classNames(classes.bolder, classes.alignRight)}>
                          {formatPrice(quoteItem.totalPrice)}
                        </span>
                      </Grid>
                    ))
                  ) : (
                    <Grid container justify="space-between" item xs={12}>
                      <span>{formatMessage(quoteMessages.noBoxes)}</span>
                      <span>{formatPrice(0)}</span>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid className={classes.separator} item xs={12} />
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Grid container className={classes.entry} spacing={1}>
                  <Grid container justify="flex-end" item xs={12}>
                    <span className={classes.bolder}>
                      {formatMessage(quoteMessages.total)}: {formatPrice(totalPrice)}
                    </span>
                  </Grid>
                  <Grid container justify="flex-end" item xs={12}>
                    <span className={classes.lighter}>
                      {formatNumber(discount || 0)}% {formatMessage(quoteMessages.discount)}
                    </span>
                  </Grid>
                  <Grid container justify="flex-end" item xs={12}>
                    <span className={classes.bolder}>
                      {formatMessage(quoteMessages.discountedTotal)}: {formatPrice(discountedTotal)}
                    </span>
                  </Grid>
                  <Grid container justify="flex-end" item xs={12}>
                    <span className={classes.lighter}>
                      + {formatPrice(deliveryCost || 0)} {formatMessage(quoteMessages.deliveryCost)}
                    </span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid className={classes.separator} item xs={12} />
              <Grid container justify="flex-end" item xs={12}>
                <span className={`${classes.entryTitle} ${classes.larger}`}>
                  {formatMessage(quoteMessages.grandTotal)}: {formatPrice(grandTotal)}
                </span>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

export default Price;
