import React, { FC } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { Module } from '../../types/graphql-generated.types';
import ProductIcon from './ProductIcon';

interface ProductIconList {
  modules: Module[];
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  icon: {
    marginRight: '4px',
    marginTop: '4px',
    marginBottom: '4px',
  },
});

const ProductIconList: FC<ProductIconList> = props => {
  const { modules } = props;

  const classes = useStyles(props);

  return (
    <Grid container>
      {modules.map(module => (
        <Grid key={module.id!} item>
          <ProductIcon className={classes.icon} svg={module.icon?.svg} rgb={module.rgb} />
        </Grid>
      ))}
    </Grid>
  );
};

export default ProductIconList;
