import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { Container, ThemeProvider } from '@material-ui/core';
import { ApolloProvider } from 'react-apollo';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { PrivateRoute, HomeRoute } from './components';
import history from './lib/history';
import theme from './lib/theme';
import CompanyContainer from './common/CompanyContainer';
import { useAuth0 } from './providers/AuthProvider';
import UserProvider, { Role } from './providers/UserProvider';
import apolloClient from './lib/apollo';
import initLogRocket from './initializers/logrocket';
import './styles/App.scss';
import { CreateDistributor, DistributorDetail, DistributorOverview } from './views/distributors';
import { CompanySelector } from './views/profile';
import { CustomerOverview, CustomerDetail, CreateCustomer } from './views/customers';
import { UserOverview, UserDetail, CreateUser } from './views/users';
import { CreateModule, EditModule } from './views/products/modules';
import { ProductsOverview } from './views/products';
import { CreateQuote, QuotesOverview, QuoteDetail } from './views/quotes';
import { OrdersOverview, OrderDetail } from './views/orders';
import LocaleProvider from './components/LocaleProvider';
import { CreateBox, EditBox } from './views/products/boxes';
import { DetailProductInfo } from './views/products/info';
import { ProductType } from './views/products/info/Detail';
import { Inventory, Inventories } from './views/inventory';
import { Landing } from './views/kit';
import Privacy from './views/privacy';
import CookiePolicy from './views/cookiePolicy';

const sentryOptions: Sentry.BrowserOptions = {
  enabled: !!process.env.REACT_APP_SENTRY_DSN,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENV,
};
Sentry.init(sentryOptions);

initLogRocket();

const App: React.FC = () => {
  const { loading, user, logout } = useAuth0();

  const handleLogout: () => void = () => {
    logout();
  };

  return (
    <ApolloProvider client={apolloClient}>
      <ThemeProvider theme={theme}>
        <LocaleProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Router history={history}>
              <Switch>
                <Route path="/kit" exact component={Landing} />
                <Route path="/inventory/:id" component={Inventory} />
                <Route>
                  <UserProvider>
                    <CompanyContainer user={user} onLogout={handleLogout} loading={loading}>
                      <Container classes={{ root: 'container' }}>
                        <Switch>
                          <PrivateRoute
                            roles={[Role.Admin, Role.Distributor, Role.Customer]}
                            path="/privacy"
                            component={Privacy}
                          />
                          <PrivateRoute
                            roles={[Role.Admin, Role.Distributor, Role.Customer]}
                            path="/cookie-policy"
                            component={CookiePolicy}
                          />
                          <PrivateRoute
                            roles={[Role.Admin, Role.Distributor, Role.Customer]}
                            path="/select-company"
                            component={CompanySelector}
                          />
                          <PrivateRoute
                            roles={[Role.Admin]}
                            path="/distributors/add"
                            component={CreateDistributor}
                          />
                          <PrivateRoute
                            roles={[Role.Admin]}
                            path="/distributor/:id"
                            component={DistributorDetail}
                          />
                          <PrivateRoute
                            roles={[Role.Admin]}
                            path="/distributors"
                            component={DistributorOverview}
                          />
                          <PrivateRoute
                            roles={[Role.Distributor, Role.Admin]}
                            path="/customers/add"
                            component={CreateCustomer}
                          />
                          <PrivateRoute
                            path="/customer/:id/quote"
                            roles={[Role.Distributor, Role.Admin]}
                            component={CreateQuote}
                          />
                          <PrivateRoute
                            roles={[Role.Distributor, Role.Admin]}
                            path="/customer/:id"
                            component={CustomerDetail}
                          />
                          <PrivateRoute
                            roles={[Role.Distributor, Role.Admin]}
                            path="/customers"
                            component={CustomerOverview}
                          />
                          <PrivateRoute
                            path="/products/modules/add"
                            roles={[Role.Admin]}
                            component={CreateModule}
                          />
                          <PrivateRoute
                            path="/products/modules/:id/info/:productInfoId?"
                            roles={[Role.Distributor]}
                            component={() => <DetailProductInfo productType={ProductType.Module} />}
                          />
                          <PrivateRoute
                            path="/products/modules/:id"
                            roles={[Role.Admin]}
                            component={EditModule}
                          />
                          <PrivateRoute
                            path="/products/boxes/add"
                            roles={[Role.Admin]}
                            component={CreateBox}
                          />
                          <PrivateRoute
                            path="/products/boxes/:id/info/:productInfoId?"
                            roles={[Role.Distributor]}
                            component={() => <DetailProductInfo productType={ProductType.Box} />}
                          />
                          <PrivateRoute
                            path="/products/boxes/:id"
                            roles={[Role.Admin]}
                            component={EditBox}
                          />
                          <PrivateRoute
                            path="/products"
                            roles={[Role.Admin, Role.Distributor]}
                            component={ProductsOverview}
                          />
                          <PrivateRoute
                            path="/quotes/:id"
                            roles={[Role.Admin, Role.Distributor, Role.Customer]}
                            component={QuoteDetail}
                          />
                          <PrivateRoute
                            path="/quotes"
                            roles={[Role.Admin, Role.Distributor, Role.Customer]}
                            component={QuotesOverview}
                          />
                          <PrivateRoute
                            path="/orders/:id"
                            roles={[Role.Admin, Role.Distributor, Role.Customer]}
                            component={OrderDetail}
                          />
                          <PrivateRoute
                            path="/orders"
                            roles={[Role.Admin, Role.Distributor, Role.Customer]}
                            component={OrdersOverview}
                          />
                          <PrivateRoute
                            path="/inventory"
                            roles={[Role.Admin, Role.Distributor, Role.Customer]}
                            component={Inventories}
                          />
                          <PrivateRoute
                            path="/users/invite"
                            roles={[Role.Admin, Role.Distributor, Role.Customer]}
                            component={CreateUser}
                          />
                          <PrivateRoute
                            path="/users/:id"
                            roles={[Role.Admin, Role.Distributor, Role.Customer]}
                            component={UserDetail}
                          />
                          <PrivateRoute
                            path="/profile"
                            roles={[Role.Admin, Role.Distributor, Role.Customer]}
                            component={UserDetail}
                          />
                          <PrivateRoute
                            path="/users"
                            roles={[Role.Admin, Role.Distributor, Role.Customer]}
                            component={UserOverview}
                          />
                          <HomeRoute />
                        </Switch>
                      </Container>
                    </CompanyContainer>
                  </UserProvider>
                </Route>
              </Switch>
            </Router>
          </MuiPickersUtilsProvider>
        </LocaleProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
};

export default App;
