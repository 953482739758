import { useState, ReactElement } from 'react';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';

const usePDF = (): [boolean, (fileName: string, component: ReactElement) => void] => {
  const [downloading, setDownloading] = useState(false);

  const onDownload = async (fileName: string, component: ReactElement) => {
    setDownloading(true);
    try {
      const blob = await pdf(component).toBlob();
      saveAs(blob, fileName);
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    } finally {
      setDownloading(false);
    }
  };

  return [downloading, onDownload];
};

export default usePDF;
