import { defineMessages } from 'react-intl';
import { Errors } from '../types/graphql-generated.types';

const messages = defineMessages({
  arrayMinSize: {
    id: 'array_min_size',
    defaultMessage: 'There must be at least one element given.',
  },
  isEmail: {
    id: 'is_email',
    defaultMessage: 'Given input is not a valid e-mail address.',
  },
  isNotEmpty: {
    id: 'is_not_empty',
    defaultMessage: 'This is a required field.',
  },
  isNotEmptyString: {
    id: 'is_not_empty_string',
    defaultMessage: 'This is a required field.',
  },
  isNumber: {
    id: 'is_number',
    defaultMessage: 'Value must be a valid number.',
  },
  isNumberString: {
    id: 'is_number_string',
    defaultMessage: 'Only numeric values allowed.',
  },
  isValidVatNumber: {
    id: 'is_valid_vat_number',
    defaultMessage: 'The number is not a valid EU VAT number.',
  },
  maxLength: {
    id: 'max_length',
    defaultMessage: 'Maximum length has been exceeded.',
  },
  isPositive: {
    id: 'is_positive',
    defaultMessage: 'Number must be positive',
  },
  min: {
    id: 'min_number',
    defaultMessage: 'Number must not be smaller than {minValue}',
  },
  isCMYK: {
    id: 'is_valid_cmyk',
    defaultMessage: 'Every CMYK component must be between 0 and 100',
  },
  isEnum: {
    id: 'is_enum',
    defaultMessage: 'Only one of the specified values is allowed',
  },
  notFound: {
    id: 'not_found',
    defaultMessage: 'Could not be found',
  },
  [Errors.RestockExpiredBatchError]: {
    id: Errors.RestockExpiredBatchError.toLowerCase(),
    defaultMessage: 'Batch has expired',
  },
  [Errors.UserAlreadyExistsError]: {
    id: Errors.UserAlreadyExistsError.toLowerCase(),
    defaultMessage: 'This e-mail is already in use, please pick another e-mail address',
  },
  [Errors.UserAccessLevelError]: {
    id: Errors.UserAccessLevelError.toLowerCase(),
    defaultMessage: 'This user is already registered with a different access level',
  },
});

export type ErrorKeys = keyof typeof messages;

export default messages;
