import React, { FC } from 'react';
import { makeStyles, createStyles, Grid, Chip } from '@material-ui/core';
import classNames from 'classnames';
import { useQuery } from 'react-apollo';
import { useIntl } from 'react-intl';
import { useImpersonator } from '../../hooks';
import { useUser } from '../../providers/UserProvider';
import { GET_LABELS_FOR_FILTER } from '../../graphql/queries/label';
import {
  GetLabelsForFilterQuery,
  GetLabelsForFilterQueryVariables,
} from '../../types/graphql-generated.types';
import { commonMessages } from '../../messages';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      paddingBottom: theme.spacing(4),
    },
    label: {
      fontWeight: 400,
    },
    allLabel: {
      textTransform: 'uppercase',
    },
  }),
);

export const ALL_ID = 0;

interface Props {
  selected: number[];
  onUpdateSelected: (ids: number[]) => void;
}

const LabelsFilter: FC<Props> = props => {
  const { selected, onUpdateSelected } = props;
  const classes = useStyles(props);
  const { formatMessage } = useIntl();

  const { getContextVariables } = useUser();
  const { isImpersonating, getFakeVariables } = useImpersonator();

  const { data } = useQuery<GetLabelsForFilterQuery, GetLabelsForFilterQueryVariables>(
    GET_LABELS_FOR_FILTER,
    {
      variables: isImpersonating ? getFakeVariables() : getContextVariables(),
    },
  );

  const onClickFilter = (id: number, btn: HTMLButtonElement): void => {
    let ids: number[] = [];

    if (id === ALL_ID && selected.includes(ALL_ID)) {
      ids = selected;
    } else if (id === ALL_ID && !selected.includes(ALL_ID)) {
      ids = [ALL_ID];
    } else if (!selected.includes(id)) {
      ids = selected.filter(s => s !== ALL_ID).concat(id);
    } else {
      ids = selected.filter(s => s !== id);
    }

    if (ids.length === 0) {
      ids = [ALL_ID];
    }

    btn.blur();

    onUpdateSelected(ids);
  };

  const getColor = (id: number) => (selected.includes(id) ? 'secondary' : 'default');

  return (
    <Grid className={classes.root} container spacing={1}>
      <Grid item>
        <Chip
          className={classNames(classes.label, classes.allLabel)}
          component="button"
          label={formatMessage(commonMessages.filterAll)}
          variant="outlined"
          color={getColor(ALL_ID)}
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
            onClickFilter(ALL_ID, e.currentTarget)
          }
        />
      </Grid>
      {data?.getLabels.map(l => (
        <Grid item key={l.id!}>
          <Chip
            className={classes.label}
            component="button"
            label={l.name}
            variant="outlined"
            color={getColor(l.id!)}
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
              onClickFilter(l.id!, e.currentTarget)
            }
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default LabelsFilter;
