import React, { FC, KeyboardEvent } from 'react';
import classNames from 'classnames';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { makeStyles, createStyles, Typography, Tooltip } from '@material-ui/core';
import { SortDirection } from '../types/graphql-generated.types';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      cursor: 'pointer',
      display: 'flex',
    },
    label: {
      color: theme.palette.text.secondary,
    },
    selected: {
      color: theme.palette.text.hint,
    },
  }),
);

interface SortableTableHeaderProps {
  name: string;
  current: string;
  direction: SortDirection;
  onChange: (name: string) => void;
  children: string;
}

const SortableTableHeader: FC<SortableTableHeaderProps> = props => {
  const { name, current, direction, onChange, children } = props;
  const classes = useStyles(props);

  const handleEnter = (event: KeyboardEvent) => {
    if (event.keyCode === 13) {
      onChange(name);
    }
  };

  const labelClass = classNames(classes.label, { [classes.selected]: current === name });

  const arrowIcon = direction === SortDirection.Asc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
  return (
    <span
      role="button"
      tabIndex={0}
      onKeyDown={handleEnter}
      onClick={() => onChange(name)}
      className={classes.root}
    >
      <Tooltip enterDelay={500} enterNextDelay={500} title={children} placement="top" arrow>
        <Typography className={labelClass} noWrap>
          {children}
        </Typography>
      </Tooltip>
      {current === name && arrowIcon}
    </span>
  );
};

export default SortableTableHeader;
