import React from 'react';
import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import { useMutation } from 'react-apollo';
import PageHeader from '../../../components/PageHeader';
import FormActions from '../../../components/FormActions';
import { usePageView, useForm } from '../../../hooks';
import messages from '../../../messages/products';
import { CREATE_BOX } from '../../../graphql/queries/box';
import { BoxInputUpdate } from '../../../types/graphql-generated.types';
import { Details } from '../../../components/products/boxes/sections';
import { getBoxFields } from '../../../constants/form-constants';

const initialFields = getBoxFields();

const CreateBox = () => {
  usePageView('product_new_box');

  const { formatMessage } = useIntl();

  const [createBox, { loading: isSaving }] = useMutation(CREATE_BOX);
  const { fields, handleChange, errors, handleCreateUpdate, disabled } = useForm<BoxInputUpdate>(
    createBox,
    initialFields,
    true,
  );

  return (
    <>
      <PageHeader title={formatMessage(messages.newBox)} back="/products/boxes" />
      <div className="add-edit-form box-form">
        <Grid container spacing={3}>
          <Details fields={fields} errors={errors} onChange={handleChange} />
        </Grid>
      </div>
      <FormActions
        onCreateUpdate={() => handleCreateUpdate('/products/boxes')}
        create
        disabled={disabled}
        loading={isSaving}
      />
    </>
  );
};

export default CreateBox;
