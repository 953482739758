import React, { FC } from 'react';
import { Typography, makeStyles, createStyles, Grid } from '@material-ui/core';
import { ProductIcon } from '../products';
import { Module } from '../../types/graphql-generated.types';

const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      width: '56px',
    },
    name: {
      maxWidth: 'calc(100% - 56px)',
    },
  }),
);

interface Props {
  module: Module;
}

const ModuleHeader: FC<Props> = props => {
  const classes = useStyles(props);
  const { module } = props;

  return (
    <Grid container alignItems="center">
      <Grid item className={classes.icon}>
        <ProductIcon rgb={module.rgb} svg={module.icon?.svg} />
      </Grid>
      <Grid item xs className={classes.name}>
        <Typography align="left" variant="h3" noWrap>
          {module.nameEN}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ModuleHeader;
