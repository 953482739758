import { gql } from 'apollo-boost';

export const productInfoFragments = {
  Item: gql`
    fragment ProductInfoItem on ProductInfo {
      __typename
      id
      articleId
      price
    }
  `,
};

export const GET_PRODUCT_INFO = gql`
  query getProductInfo($companyId: Int!, $id: Int!) {
    getProductInfo(companyId: $companyId, id: $id) {
      ...ProductInfoItem
      product {
        __typename
        ... on Box {
          id
          name
          active
          recommendedPrice
          volume
          productCode
        }
        ... on Module {
          id
          nameEN
          productCode
          volume
          moduleSubId
          moduleId
          expirable
          active
          recommendedPrice
        }
      }
    }
  }
  ${productInfoFragments.Item}
`;

export const CREATE_PRODUCT_INFO = gql`
  mutation createProductInfo($companyId: Int!, $type: ProductInfoInputCreate!) {
    createProductInfo(companyId: $companyId, productInfo: $type) {
      id
    }
  }
`;

export const UPDATE_PRODUCT_INFO = gql`
  mutation updateProductInfo($id: Int!, $companyId: Int!, $type: ProductInfoInputUpdate!) {
    updateProductInfo(id: $id, companyId: $companyId, productInfo: $type) {
      id
    }
  }
`;
