import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { formatISO } from 'date-fns';

type DateType = Date | string | null;

interface FormatISO {
  format?: 'extended' | 'basic' | undefined;
  representation?: 'complete' | 'date' | 'time' | undefined;
}

export const DEFAULT_FORMAT = 'dd-MM-yyyy';

export const parseDate = (date: DateType) =>
  typeof date === 'string' ? parseISO(date) : date || new Date();

export const formatDate = (date: DateType, formatNotation = DEFAULT_FORMAT, options = {}) =>
  format(parseDate(date), formatNotation, options);

export const formatDateISO = (date: DateType, options: FormatISO = {}) =>
  formatISO(parseDate(date), options);

export const getTodayString = (formatNotation?: string) => formatDate(new Date(), formatNotation);
