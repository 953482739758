import { gql } from 'apollo-boost';
import { CustomerFilter } from '../../types/graphql-generated.types';

export const createSearch = (isAdmin: boolean, value: string): CustomerFilter => {
  const filters: CustomerFilter[] = [{ name: { contains: [value] } }];

  if (isAdmin) {
    filters.push({ distributor: { name: { contains: [value] } } });
  }

  return { OR: filters };
};

export const GET_CUSTOMER = gql`
  query getCustomer($id: Int!, $companyId: Int) {
    getCustomer(id: $id, companyId: $companyId) {
      id
      name
      email
      phone
      vat
      sector
      numberOfEmployees
      reminderFrequency
      additionalInfo
      language
      primaryLabelLanguage
      secondaryLabelLanguage
      distributor {
        id
        name
      }
      addresses {
        id
        addressLineOne
        addressLineTwo
        postalCode
        city
        country
      }
    }
  }
`;

export const GET_CUSTOMER_FOR_QUOTE = gql`
  query getCustomerForQuote($id: Int!, $companyId: Int) {
    getCustomer(id: $id, companyId: $companyId) {
      id
      name
      distributor {
        id
        defaultDeliveryCost
      }
      addresses {
        id
        addressLineOne
        addressLineTwo
        postalCode
        city
        country
      }
    }
  }
`;

export const GET_CUSTOMERS = gql`
  query getPagedCustomers(
    $offset: Int!
    $limit: Int!
    $sortColumn: String
    $sortDirection: SortDirection
    $companyId: Int
    $filter: CustomerFilter
  ) {
    getPagedCustomers(
      offset: $offset
      limit: $limit
      sortColumn: $sortColumn
      sortDirection: $sortDirection
      companyId: $companyId
      filter: $filter
    ) {
      items {
        id
        status
        name
        distributor {
          id
          name
        }
      }
      pageInfo {
        itemCount
        pageCount
        total
      }
    }
  }
`;

export const CREATE_CUSTOMER = gql`
  mutation createCustomer($type: CustomerInputCreate!, $companyId: Int) {
    createCustomer(customer: $type, companyId: $companyId) {
      name
      email
      phone
      vat
      addresses {
        addressLineOne
        addressLineTwo
        postalCode
        city
        country
      }
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation updateCustomer($id: Int!, $type: CustomerInputUpdate!, $customerId: Int) {
    updateCustomer(id: $id, customer: $type, customerId: $customerId) {
      name
      email
      phone
      vat
      addresses {
        id
        addressLineOne
        addressLineTwo
        postalCode
        city
        country
      }
    }
  }
`;
