const firebaseConfig = {
  apiKey: 'AIzaSyA3abeKsbqHtdFZYEn-6kZqWHAAIOlQCEo',
  authDomain: 'pharmacar-modulaid-prod.firebaseapp.com',
  databaseURL: 'https://pharmacar-modulaid-prod.firebaseio.com',
  projectId: 'pharmacar-modulaid-prod',
  storageBucket: 'pharmacar-modulaid-prod.appspot.com',
  messagingSenderId: '945015547918',
  appId: '1:945015547918:web:fefc4b82466d78dd94f45f',
  measurementId: 'G-J5G3H99XV0',
};

export default firebaseConfig;
