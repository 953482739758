import React, { FC, useState } from 'react';
import { Typography, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { useMutation } from 'react-apollo';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import get from 'get-value';
import {
  InventoryItem,
  Module,
  UpdateInventoryItemMutation,
  InventoryItemStatus,
} from '../../../types/graphql-generated.types';
import { inventoryMessages, commonMessages, errorMessages } from '../../../messages';
import { OutlinedNumberField } from '../../inputs';
import Button from '../../Button';
import { UPDATE_INVENTORY_ITEM } from '../../../graphql/queries/inventory';
import ModuleHeader from '../ModuleHeader';
import { useDialogStyles } from '../hooks';
import { DEFAULT_INTEGER_MASK } from '../../../constants/number-mask';
import { Constraint } from '../../../types/form.types';

const numberMask = createNumberMask({ ...DEFAULT_INTEGER_MASK });

interface Props {
  item: InventoryItem;
  onClose: () => void;
  onSuccess: () => void;
}

const RestockItemDialog: FC<Props> = props => {
  const { item, onClose, onSuccess } = props;
  const classes = useDialogStyles(props);
  const { formatMessage } = useIntl();

  const [batchId, setBatchId] = useState<number>();
  const [error, setError] = useState<Constraint>();

  const [updateInventoryItem, { loading }] = useMutation<UpdateInventoryItemMutation>(
    UPDATE_INVENTORY_ITEM,
  );

  const module = item.product as Module;

  const onUpdateBatchId = async () => {
    try {
      await updateInventoryItem({
        variables: {
          id: item.id!,
          inventoryItem: { batchId, status: InventoryItemStatus.Available },
        },
      });
      onSuccess();
    } catch (err) {
      const code: string = get(err, 'graphQLErrors.0.extensions.code');
      setError({ [Object.keys(errorMessages).includes(code) ? code : 'notFound']: '' });
    }
  };

  return (
    <Dialog fullWidth open onClose={onClose} classes={{ paper: classes.root }}>
      <DialogTitle className={classNames(classes.paddingH, classes.paddingV)}>
        <ModuleHeader module={module} />
      </DialogTitle>
      <DialogContent className={classNames(classes.paddingH, classes.gutter)}>
        <Typography className={classes.description}>
          {formatMessage(inventoryMessages.restockDescription)}
        </Typography>
        <OutlinedNumberField
          className={classes.gutter}
          name="batchId"
          labelText="Batch nr"
          value={batchId}
          mask={numberMask}
          updateOnBlur={false}
          error={error}
          onChange={({ value }) => {
            setError(undefined);
            setBatchId(value as number);
          }}
        />
      </DialogContent>
      <DialogActions className={classNames(classes.paddingH, classes.paddingV, classes.actions)}>
        <Button
          className="button-outlined"
          onClick={onClose}
          text={formatMessage(commonMessages.cancel)}
        />
        <Button
          className="button-filled"
          onClick={() => onUpdateBatchId()}
          text={formatMessage(inventoryMessages.restock)}
          loading={loading}
          disabled={batchId == null}
        />
      </DialogActions>
    </Dialog>
  );
};

export default RestockItemDialog;
