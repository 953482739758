import React, { FC } from 'react';
import classNames from 'classnames';
import { GridProps, Grid, makeStyles, createStyles, ButtonBase } from '@material-ui/core';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      minHeight: '48px',
      margin: '0 0 8px 0',
    },
    row: {
      boxShadow: '0 2px 7px 0 rgba(21, 46, 62, 0.1)',
      transition: 'box-shadow 0.15s ease-in',
      paddingLeft: '16px',
      backgroundColor: theme.palette.common.white,
    },
    hover: {
      '&:hover': {
        boxShadow: '0 2px 7px 0 rgba(21, 46, 62, 0.2)',
      },
    },
    active: {
      borderLeft: `solid 8px ${theme.palette.secondary.main}`,
      transition: 'padding-left 0.10s ease-in, border-left 0.10s ease-in',
      paddingLeft: '8px',
    },
    clickable: {
      width: '100%',

      '& > .MuiGrid-container': {
        height: '100%',
      },
    },
    firstCell: {
      '& > .MuiGrid-item:first-of-type': {
        paddingLeft: 0,
      },
    },
    disabled: {
      opacity: 0.45,
    },
  }),
);

type TableRowProps = GridProps & {
  active?: boolean;
  disabled?: boolean;
  onClick?: () => void;
};

const TableRow: FC<TableRowProps> = props => {
  const { onClick, active, disabled, children, ...gridProps } = props;
  const classes = useStyles(props);

  const className = classNames(classes.root, {
    [classes.active]: active && !onClick,
    [classes.firstCell]: !onClick,
    [classes.row]: !onClick,
    [classes.hover]: onClick && !disabled,
  });

  const clickableClassName = classNames(classes.row, classes.firstCell, {
    [classes.active]: active,
    [classes.disabled]: disabled,
  });

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Grid className={className} container {...gridProps}>
      {onClick ? (
        <ButtonBase
          disabled={disabled}
          disableRipple
          component="div"
          className={classes.clickable}
          onClick={onClick}
        >
          <Grid className={clickableClassName} container>
            {children}
          </Grid>
        </ButtonBase>
      ) : (
        <>{children}</>
      )}
    </Grid>
  );
};

export default TableRow;
