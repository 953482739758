import React, { FC } from 'react';
import { useMutation } from 'react-apollo';
import { DialogProps } from '../../../../types/form.types';
import { BatchInputUpdate } from '../../../../types/graphql-generated.types';
import BatchDialog from './Dialog';
import { useForm } from '../../../../hooks';
import { CREATE_BATCH } from '../../../../graphql/queries/batch';
import { formatDateISO, getTodayString } from '../../../../helpers/date';

type CreateProps = DialogProps & {
  isExpirable: boolean;
  moduleId: number;
};

const Create: FC<CreateProps> = ({ moduleId, isExpirable, isOpen, onCancel, onConfirm }) => {
  const [createBatch] = useMutation(CREATE_BATCH);
  const { fields, handleChange, errors, disabled, handleCreateUpdate } = useForm<BatchInputUpdate>(
    createBatch,
    {
      module: moduleId,
      expiryDate: formatDateISO(isExpirable ? getTodayString('yyyy-MM-dd') : '2050-01-01', {
        representation: 'date',
      }),
    },
    true,
  );

  const onCreate = async () => {
    const isSuccess = await handleCreateUpdate();

    if (isSuccess) {
      onConfirm();
    }
  };

  return (
    <BatchDialog
      isCreate
      isExpirable={isExpirable}
      isOpen={isOpen}
      isDisabled={disabled}
      onCancel={onCancel}
      onConfirm={onCreate}
      fields={fields}
      onChange={handleChange}
      errors={errors}
    />
  );
};

export default Create;
