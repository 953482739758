import React, { FC, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';
import convert from 'color-convert';
import classNames from 'classnames';
import TabUnselectedIcon from '@material-ui/icons/TabUnselected';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import get from 'get-value';
import { SectionProps, ConstraintWithContext, Constraint } from '../../../../types/form.types';
import {
  ModuleInputUpdate,
  GetModuleIconsQuery,
  CmykInput,
} from '../../../../types/graphql-generated.types';
import messages from '../../../../messages/products';
import { StickerSelectField, OutlinedNumberField } from '../../../inputs';
import { Error } from '../../../form/Field';
import { DEFAULT_INTEGER_MASK } from '../../../../constants/number-mask';

type StickerProps = SectionProps<ModuleInputUpdate> & {
  icons?: GetModuleIconsQuery['getModuleIcons'];
};

const isCMYKValid = (cmyk: CmykInput) =>
  Object.values(cmyk).filter(f => f != null && f <= 100).length === 4;

const numberMask = createNumberMask({
  ...DEFAULT_INTEGER_MASK,
  integerLimit: 3,
});

const Sticker: FC<StickerProps> = ({ fields, errors, icons, onChange }) => {
  const { formatMessage } = useIntl();

  const cmyk = fields?.cmyk;
  const rgb = fields?.rgb;

  useEffect(() => {
    if (cmyk && isCMYKValid(cmyk)) {
      const { C, M, Y, K } = cmyk;
      const newRGB = convert.cmyk.hex([C as number, M as number, Y as number, K as number]);

      if (newRGB !== rgb) {
        onChange({
          path: 'rgb',
          value: newRGB,
        });
      }
    }
  }, [onChange, cmyk, rgb]);

  const previewStyle = {
    backgroundColor: `#${fields?.rgb || '000'}`,
  };

  const CMYKLabelClass = classNames({
    'sticker-section-cmyk-label': true,
    error: !!errors?.cmyk,
  });

  return (
    <Grid item xs={12}>
      <div className="form-section sticker-section">
        <Grid container item xs={12}>
          <div className="form-section-title">
            <div className="section-icon">
              <TabUnselectedIcon />
            </div>
            <p>{formatMessage(messages.moduleSticker)}</p>
          </div>
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={5}>
            <StickerSelectField
              name="icon"
              icons={icons}
              value={fields?.icon}
              onChange={onChange}
              error={errors?.icon as Constraint}
            />
          </Grid>
        </Grid>
        <Grid className="sticker-section-cmyk" item xs={12} container>
          <Grid className={CMYKLabelClass} item xs={10}>
            <span>Colour value (CMYK)</span>
            {errors?.cmyk && <Error errors={errors.cmyk as ConstraintWithContext} />}
          </Grid>
          <Grid className="sticker-section-cmyk-label" item xs={2}>
            <span>Preview</span>
          </Grid>
          {['C', 'M', 'Y', 'K'].map(c => (
            <Grid key={c} item xs={2}>
              <OutlinedNumberField
                labelText={`${c} value`}
                name={`cmyk.${c}`}
                mask={numberMask}
                value={get(fields, `cmyk.${c}`)}
                onChange={u => onChange({ ...u, errorPath: 'cmyk' })}
              />
            </Grid>
          ))}
          <Grid item xs={2}>
            <div style={previewStyle} className="sticker-section-cmyk-preview" />
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

export default Sticker;
