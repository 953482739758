import React, { FC } from 'react';
import { useQuery } from 'react-apollo';
import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { usePageView, useOptions, useImpersonator } from '../../../hooks';
import { GET_PAGED_BOXES, GET_PAGED_BOXES_DISTRIBUTOR } from '../../../graphql/queries/box';
import { SortableTableHeader, Pagination, ProductStatus, Loadable } from '../../../components';
import messages from '../../../messages/products';
import { setSortableQuery } from '../../../helpers/query';
import { formatNumber, formatPrice } from '../../../helpers/format';
import {
  GetPagedBoxesQuery,
  GetPagedBoxesDistributorQuery,
  SortDirection,
} from '../../../types/graphql-generated.types';
import { useUser, Role } from '../../../providers/UserProvider';

type AdminBox = Exclude<GetPagedBoxesQuery['getPagedBoxes']['items'], null>[number];
type DistributorBox = Exclude<
  GetPagedBoxesDistributorQuery['getPagedBoxes']['items'],
  null
>[number];

const Boxes: FC = () => {
  usePageView('products_boxes_overview');
  const { formatMessage } = useIntl();
  const { whenRole, getContextVariables } = useUser();
  const { isImpersonating, getFakeVariables, whenFakeRole, isRole } = useImpersonator();

  const isAdmin = isRole(Role.Admin);

  const { options, changeParams } = useOptions({
    page: 0,
    pageCount: 1,
    offset: 0,
    limit: 20,
    sortColumn: 'box.name',
    sortDirection: SortDirection.Asc,
  });

  const { pageCount, page, ...rest } = options;

  const query = isAdmin ? GET_PAGED_BOXES : GET_PAGED_BOXES_DISTRIBUTOR;
  const { loading, data } = useQuery<GetPagedBoxesQuery | GetPagedBoxesDistributorQuery>(query, {
    variables: { ...(isImpersonating ? getFakeVariables() : getContextVariables()), ...rest },
    onCompleted(result) {
      if (result?.getPagedBoxes?.pageInfo?.pageCount) {
        changeParams('pageCount', result.getPagedBoxes.pageInfo.pageCount);
      }
    },
  });

  const onChangeSortColumn = (type: string) => {
    let nextDir = SortDirection.Asc;

    if (options.sortColumn === type) {
      nextDir =
        options.sortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc;
    }

    setSortableQuery(type, nextDir);
  };

  const getLink = (box: DistributorBox | AdminBox) =>
    whenRole({
      [Role.Default]: `/products/boxes/${box.id}`,
      [Role.Distributor]: `/products/boxes/${box.id}/info/${(box as DistributorBox).productInfo?.[0]
        ?.id || ''}`,
    });

  const TableHeader: FC<{ name: string; children: string }> = ({ name, children }) => (
    <SortableTableHeader
      name={name}
      current={options.sortColumn}
      direction={options.sortDirection}
      onChange={onChangeSortColumn}
    >
      {children}
    </SortableTableHeader>
  );

  return (
    <div className="table boxes">
      <Grid className="table-header" container justify="space-between">
        <Grid
          item
          xs={whenFakeRole({
            [Role.Distributor]: 3,
            [Role.Default]: 4,
          })}
        >
          <TableHeader name="box.name">{formatMessage(messages.name)}</TableHeader>
        </Grid>
        {whenFakeRole({
          [Role.Distributor]: (
            <Grid item xs={2}>
              <TableHeader name="productInfo.articleId">
                {formatMessage(messages.articleId)}
              </TableHeader>
            </Grid>
          ),
          [Role.Default]: null,
        })}
        <Grid
          item
          xs={whenFakeRole({
            [Role.Distributor]: 2,
            [Role.Default]: 3,
          })}
        >
          <TableHeader name="box.volume">{formatMessage(messages.volumeCm3)}</TableHeader>
        </Grid>
        {whenFakeRole({
          [Role.Distributor]: (
            <Grid item xs={2}>
              <TableHeader name="productInfo.price">{formatMessage(messages.price)}</TableHeader>
            </Grid>
          ),
          [Role.Default]: (
            <Grid item xs={3}>
              <TableHeader name="box.recommendedPrice">
                {formatMessage(messages.recommendedPrice)}
              </TableHeader>
            </Grid>
          ),
        })}

        <Grid item xs={2}>
          <TableHeader name="box.active">{formatMessage(messages.status)}</TableHeader>
        </Grid>
      </Grid>

      <Loadable loading={loading}>
        {whenFakeRole({
          [Role.Distributor]: (data as GetPagedBoxesDistributorQuery)?.getPagedBoxes?.items,
          [Role.Default]: (data as GetPagedBoxesQuery)?.getPagedBoxes?.items,
        })?.map((box: DistributorBox | AdminBox) => (
          <Grid className="table-row-container" key={box.id!} container>
            <Link to={getLink(box)} className="table-row-link">
              <Grid className="table-row" container justify="space-between">
                <Grid
                  className="table-row-primary"
                  item
                  xs={whenFakeRole({
                    [Role.Distributor]: 3,
                    [Role.Default]: 4,
                  })}
                >
                  <span>{box.name}</span>
                </Grid>
                {whenFakeRole({
                  [Role.Distributor]: (
                    <Grid item xs={2}>
                      <span>{(box as DistributorBox).productInfo?.[0]?.articleId}</span>
                    </Grid>
                  ),
                  [Role.Default]: null,
                })}
                <Grid
                  item
                  xs={whenFakeRole({
                    [Role.Distributor]: 2,
                    [Role.Default]: 3,
                  })}
                >
                  <span>{formatNumber(box.volume, 2)}</span>
                </Grid>
                {whenFakeRole({
                  [Role.Distributor]: (
                    <Grid item xs={2}>
                      <span>{formatPrice((box as DistributorBox).productInfo?.[0]?.price)}</span>
                    </Grid>
                  ),
                  [Role.Default]: (
                    <Grid item xs={3}>
                      <span>{formatPrice((box as AdminBox).recommendedPrice)}</span>
                    </Grid>
                  ),
                })}
                <Grid className="table-row-status" item xs={2}>
                  <ProductStatus active={!!box.active} />
                </Grid>
              </Grid>
            </Link>
          </Grid>
        ))}
      </Loadable>

      <Pagination options={options} />
    </div>
  );
};

export default Boxes;
