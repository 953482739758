import React, { FC } from 'react';
import { CircularProgress, Grid, makeStyles } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

const useStyles = makeStyles({
  container: {
    padding: '70px',
  },
});

interface LoadableProps {
  loading: boolean;
  style?: CSSProperties;
}

const Loadable: FC<LoadableProps> = ({ children, loading, style }) => {
  const classes = useStyles();

  return loading ? (
    <Grid style={style || {}} container className={classes.container} justify="center">
      <Grid item>
        <CircularProgress />
      </Grid>
    </Grid>
  ) : (
    <>{children}</>
  );
};

export default Loadable;
