import React, { FC } from 'react';
import {
  Slide,
  Paper,
  Modal,
  makeStyles,
  createStyles,
  Backdrop,
  WithStyles,
  Box,
} from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      flex: '1 0 auto',
      position: 'fixed',
      top: 0,
      right: 0,
      left: 'auto',
      outline: 0,
    },
    content: {
      padding: '152px 32px 0px 48px',
      height: '100%',
      overflowY: 'auto',

      '&:after': {
        content: "''",
        display: 'block',
        paddingBottom: '80px',
      },
    },
  }),
);

interface PanelProps extends Partial<WithStyles> {
  open: boolean;
  onClose?: () => void;
}

const Panel: FC<PanelProps> = props => {
  const { open, children, onClose } = props;
  const classes = useStyles(props);

  return (
    <Modal open={open} BackdropComponent={Backdrop} onClose={onClose}>
      <Slide direction="left" in={open}>
        <Paper className={classes.root} elevation={3} square>
          <Box className={classes.content}>{children}</Box>
        </Paper>
      </Slide>
    </Modal>
  );
};

export default Panel;
