import React, { FC, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { Button } from '..';
import { commonMessages, quoteMessages } from '../../messages';
import { OutlinedNumberField } from '../inputs';
import { DEFAULT_INTEGER_MASK } from '../../constants/number-mask';

const MAX_COPIES = 300;

const numberMask = createNumberMask({ ...DEFAULT_INTEGER_MASK, integerLimit: 3 });

const useStyles = makeStyles(theme =>
  createStyles({
    description: {
      fontSize: '16px',
      lineHeight: 1.35,
      color: theme.palette.text.hint,
      marginBottom: theme.spacing(2),
    },
  }),
);

interface Props {
  open: boolean;
  onCancel: () => void;
  onConfirm: (quantity: number) => void;
}

const CopyKitDialog: FC<Props> = ({ open, onCancel, onConfirm }) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  const [quantity, setQuantity] = useState(0);

  return (
    <Dialog onClose={onCancel} open={open}>
      <DialogTitle>{formatMessage(quoteMessages.copyKit)}</DialogTitle>
      <DialogContent>
        <Typography className={classes.description}>
          {formatMessage(quoteMessages.copyKitDescription)}
        </Typography>
        <Grid container>
          <Grid item xs>
            <OutlinedNumberField
              mask={numberMask}
              name="quantity"
              value={quantity}
              defaultTo={0}
              updateOnBlur={false}
              helperText={formatMessage(quoteMessages.copyKitInputHelper, {
                maxCopies: MAX_COPIES,
              })}
              onChange={({ value }) => setQuantity(value as number)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          className="button-outlined"
          onClick={onCancel}
          text={formatMessage(commonMessages.cancel)}
        />
        <Button
          className="button-filled"
          onClick={() => {
            onConfirm(quantity);
            setQuantity(0);
          }}
          text={formatMessage(commonMessages.confirm)}
          disabled={quantity == null || quantity === 0 || quantity > MAX_COPIES}
        />
      </DialogActions>
    </Dialog>
  );
};

export default CopyKitDialog;
