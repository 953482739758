// eslint-disable-next-line import/no-extraneous-dependencies
import 'fast-text-encoding';
import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import history from './history';

export interface Auth0User extends Omit<IdToken, '__raw'> {}

const onAuthRedirectCallback = (redirectResult?: RedirectLoginResult) => {
  const targetUrl =
    redirectResult && redirectResult.appState && redirectResult.appState.targetUrl
      ? redirectResult.appState.targetUrl
      : window.location.pathname;

  history.push(targetUrl);
};

const initOptions: Auth0ClientOptions = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  redirect_uri: window.location.origin,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
};

export class Auth {
  client!: Auth0Client;

  user!: Auth0User;

  isAuthenticated = false;

  loading = true;

  async initClient() {
    this.client = await createAuth0Client(initOptions);

    if (window.location.search.includes('code=')) {
      let appState: RedirectLoginResult = {};

      try {
        ({ appState } = await this.client.handleRedirectCallback());
      } finally {
        onAuthRedirectCallback(appState);
      }
    }

    const authed = await this.client.isAuthenticated();

    if (authed) {
      this.user = await this.client.getUser();
      this.isAuthenticated = true;
    }

    this.loading = false;
  }
}

const auth = new Auth();

export async function initAuth() {
  await auth.initClient();
}

export default auth;
