import React, { FC } from 'react';
import { useMutation } from 'react-apollo';
import { DialogProps } from '../../../../types/form.types';
import { Batch, BatchInputUpdate } from '../../../../types/graphql-generated.types';
import BatchDialog from './Dialog';
import { UPDATE_BATCH } from '../../../../graphql/queries/batch';
import { useForm } from '../../../../hooks';
import { formatDateISO } from '../../../../helpers/date';

type EditProps = DialogProps & {
  isExpirable: boolean;
  batch: Batch;
};

const Edit: FC<EditProps> = ({ batch, isExpirable, isOpen, onCancel, onConfirm }) => {
  const { id, ...rest } = batch;
  const [updateBatch] = useMutation(UPDATE_BATCH);
  const { fields, handleChange, errors, disabled, handleCreateUpdate } = useForm<BatchInputUpdate>(
    updateBatch,
    { ...rest, expiryDate: formatDateISO(rest.expiryDate, { representation: 'date' }) },
    false,
    id!,
  );

  const onSave = async () => {
    const isSuccess = await handleCreateUpdate();

    if (isSuccess) {
      onConfirm();
    }
  };

  return (
    <BatchDialog
      isCreate={false}
      isExpirable={isExpirable}
      isOpen={isOpen}
      isDisabled={disabled}
      onCancel={onCancel}
      onConfirm={onSave}
      fields={fields}
      onChange={handleChange}
      errors={errors}
    />
  );
};

export default Edit;
