import { gql } from 'apollo-boost';
import { Role } from '../../providers/UserProvider';
import { InventoryFilter } from '../../types/graphql-generated.types';

export const createSearch = (isRole: (role: Role) => boolean, value: string): InventoryFilter => {
  const filters: InventoryFilter[] = [{ location: { contains: [value] } }];

  if (isRole(Role.Admin) || isRole(Role.Distributor)) {
    filters.push({ customer: { name: { contains: [value] } } });
  }

  if (isRole(Role.Admin)) {
    filters.push({ distributor: { name: { contains: [value] } } });
  }

  return { OR: filters };
};

export const createLabelsFilter = (labels: number[]): InventoryFilter => ({
  labels: { id: { eq: labels } },
});

export const GET_INVENTORY = gql`
  query getInventoryForKit($id: String!) {
    getInventoryForKit(id: $id) {
      id
      location
      product {
        ... on Box {
          id
          name
        }
      }
      uid
      labels {
        id
        name
      }
      customer {
        id
      }
      children {
        id
        status
        batchId
        batch {
          id
          lotNumber
          expiryDate
        }
        lastReorderQuote {
          id
          createdAt
        }
        product {
          ... on Module {
            id
            moduleId
            nameEN
            nameNL
            nameFR
            nameDE
            nameES
            namePL
            namePT
            nameRO
            nameTR
            nameAR
            icon {
              id
              svg
            }
            rgb
          }
        }
      }
    }
  }
`;

export const GET_PAGED_INVENTORIES = gql`
  query getPagedInventories(
    $companyId: Int
    $customerId: Int
    $offset: Int!
    $limit: Int!
    $sortColumn: String
    $sortDirection: SortDirection
    $filter: InventoryFilter
  ) {
    getPagedInventories(
      limit: $limit
      offset: $offset
      companyId: $companyId
      customerId: $customerId
      sortColumn: $sortColumn
      sortDirection: $sortDirection
      filter: $filter
    ) {
      pageInfo {
        itemCount
        pageCount
        total
      }
      items {
        id
        uid
        location
        customer {
          id
          name
          distributor {
            id
            name
          }
        }
        lastKitUpdate
        createdAt
        children {
          id
          product {
            ... on Module {
              id
              moduleId
              nameEN
              icon {
                id
                svg
              }
              rgb
            }
          }
        }
      }
    }
  }
`;

export const SEARCH_INVENTORY = gql`
  query searchInventory($id: String!) {
    getInventoryForKit(id: $id) {
      id
      uid
    }
  }
`;

export const SEARCH_BATCHES = gql`
  query searchBatches($moduleId: Int!, $filter: BatchFilter!) {
    getBatches(moduleId: $moduleId, filter: $filter) {
      id
      lotNumber
      expiryDate
    }
  }
`;

export const UPDATE_INVENTORY_ITEM = gql`
  mutation updateInventoryItem(
    $id: Int!
    $inventoryItem: InventoryItemUpdate!
    $summary: String
    $isPartial: Boolean
  ) {
    updateInventoryItem(
      id: $id
      inventoryItem: $inventoryItem
      summary: $summary
      isPartial: $isPartial
    ) {
      id
      batch {
        id
        lotNumber
        expiryDate
      }
    }
  }
`;

export const UPDATE_KIT = gql`
  mutation updateKit($id: Int!, $inventoryItem: InventoryItemUpdate!) {
    updateKit(id: $id, inventoryItem: $inventoryItem) {
      id
    }
  }
`;

export const REORDER_INVENTORY = gql`
  mutation reorderInventory($id: Int!) {
    reorderInventory(id: $id)
  }
`;

export const GET_PAGED_HISTORY = gql`
  query getPagedHistoryForKit($offset: Int!, $limit: Int!, $id: Int!, $customerId: Int) {
    getPagedHistoryForKit(limit: $limit, offset: $offset, id: $id, customerId: $customerId) {
      pageInfo {
        itemCount
        pageCount
        total
      }
      items {
        id
        action
        summary
        createdAt
        inventoryItem {
          id
          product {
            ... on Module {
              id
              moduleId
              nameEN
              icon {
                id
                svg
              }
              rgb
            }
            ... on Box {
              id
              name
            }
          }
        }
      }
    }
  }
`;
