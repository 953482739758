import countryList, { Country as C } from 'countries-list';

type Country = C & {
  id: string;
};

export const countries: Country[] = Object.keys(countryList.countries)
  .map(key => {
    // @ts-ignore
    const country = countryList.countries[key];

    if (country.continent === 'EU') {
      return { ...country, id: key };
    }

    return null;
  })
  .filter(c => c);
