import React, { FC } from 'react';
import { Grid, makeStyles, createStyles, Typography } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/AssignmentOutlined';
import CallMadeIcon from '@material-ui/icons/CallMade';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { commonMessages } from '../../messages';
import Button from '../Button';
import history from '../../lib/history';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      height: '100%',
    },
    description: {
      color: theme.palette.text.hint,
      fontSize: '16px',
    },
    button: {
      width: '100%',
      padding: `0 ${theme.spacing(2)}px`,

      '& span': {
        width: '100%',
      },

      '& svg': {
        marginRight: theme.spacing(1),
      },
    },
  }),
);

interface Props {
  type: 'customer' | 'distributor';
  id: number;
  name: string;
}

type Destination = 'quotes' | 'orders' | 'inventory' | 'products';
type Link = [{ id: string; defaultMessage: string }, Destination];

const CompanyLinks: FC<Props> = props => {
  const { type, id, name } = props;
  const { formatMessage } = useIntl();

  const classes = useStyles(props);

  const goTo = (destination: Destination) => {
    history.push(`/${destination}?${type}=${id}&company-name=${name}`);
  };

  const links = [
    [commonMessages.viewQuotes, 'quotes'],
    [commonMessages.viewInventory, 'inventory'],
    [commonMessages.viewOrders, 'orders'],
    [commonMessages.viewUsers, 'users'],
    type === 'distributor' ? [commonMessages.viewProducts, 'products'] : null,
  ].filter((f): f is Link => f != null);

  return (
    <Grid item xs={12} className={classes.root}>
      <div className="form-section">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className="form-section-title">
              <div className="section-icon">
                <AssignmentIcon />
              </div>
              <p>{formatMessage(commonMessages.links)}</p>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.description}>
              {formatMessage(
                type === 'customer'
                  ? commonMessages.customerLinks
                  : commonMessages.distributorLinks,
              )}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {links.map(([translation, destination]) => (
                <Grid item key={destination} md={6} xs={12}>
                  <Button
                    text={formatMessage(translation)}
                    className={classNames('button-outlined', classes.button)}
                    onClick={() => goTo(destination)}
                    icon={<CallMadeIcon />}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

export default CompanyLinks;
