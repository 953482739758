import React, { FC } from 'react';
import { Box, makeStyles, createStyles } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { TableRow, TableHeader, TableCell } from '../../table';
import { productMessages, commonMessages } from '../../../messages';
import { ProductIdCell, createProductIdCellStyles } from '../../products';
import { Module, SortDirection } from '../../../types/graphql-generated.types';
import Loadable from '../../Loadable';
import { formatPrice } from '../../../helpers/format';
import { NumberStepInput } from '../../inputs';

const useStyles = makeStyles(theme =>
  createStyles({
    scrollableContainer: {
      overflowY: 'auto',
    },
    container: {
      padding: '0 2px',
      minWidth: '725px',
    },
    tableContainer: {
      width: '92%',
    },
    rowContainer: {
      display: 'flex',
    },
    amount: {
      minWidth: '90px',
      width: '8%',
      marginLeft: 'auto',
    },
    idCell: createProductIdCellStyles(theme).root,
  }),
);

interface ModulesTableProps {
  column: string;
  direction: SortDirection;
  onChangeSort: (key: string) => void;
  getQuantity: (id: number) => number;
  onChangeAmount: (module: Module, amount: number) => void;
  modules?: Module[];
  loading: boolean;
}

const ModulesTable: FC<ModulesTableProps> = props => {
  const { modules, loading, column, direction, onChangeSort, getQuantity, onChangeAmount } = props;
  const { formatMessage } = useIntl();
  const classes = useStyles(props);

  return (
    <Box className={classes.scrollableContainer}>
      <Box className={classes.container}>
        <Box className={classes.rowContainer}>
          <Box className={classes.tableContainer}>
            <TableHeader sortOptions={{ column, direction, onChange: onChangeSort }}>
              {({ Sortable }) => (
                <>
                  <TableCell classes={{ root: classes.idCell }} xs={2}>
                    <Sortable name="module.moduleId">ID</Sortable>
                    <span>SubID</span>
                  </TableCell>
                  <TableCell xs={3}>
                    <Sortable name="module.nameEN">{formatMessage(productMessages.name)}</Sortable>
                  </TableCell>
                  <TableCell xs={2}>
                    <Sortable name="productInfo.articleId">
                      {formatMessage(productMessages.articleId)}
                    </Sortable>
                  </TableCell>
                  <TableCell xs={2}>
                    <Sortable name="module.volume">
                      {formatMessage(productMessages.volumeCm3)}
                    </Sortable>
                  </TableCell>
                  <TableCell xs={2}>
                    <Sortable name="productInfo.price">
                      {formatMessage(productMessages.price)}
                    </Sortable>
                  </TableCell>
                </>
              )}
            </TableHeader>
          </Box>
          <Box className={classes.amount}>
            <TableHeader>
              {() => <TableCell ellipsis={false} xs label={formatMessage(commonMessages.amount)} />}
            </TableHeader>
          </Box>
        </Box>
        <Loadable loading={loading}>
          {modules?.map(module => (
            <Box key={module.id!} className={classes.rowContainer}>
              <Box className={classes.tableContainer}>
                <TableRow active={getQuantity(module.id!) > 0}>
                  <ProductIdCell module={module} />
                  <TableCell body primary xs={3} label={module.nameEN} />
                  <TableCell body xs={2} label={module.productInfo?.[0]?.articleId} />
                  <TableCell body xs={2} label={module.volume} />
                  <TableCell body xs={2} label={formatPrice(module.productInfo?.[0]?.price)} />
                </TableRow>
              </Box>
              <Box className={classes.amount}>
                <TableRow>
                  <TableCell body xs>
                    <NumberStepInput
                      disabled={module.productInfo?.[0]?.price == null}
                      value={getQuantity(module.id!)}
                      onChange={(amount: number) => onChangeAmount(module, amount)}
                    />
                  </TableCell>
                </TableRow>
              </Box>
            </Box>
          ))}
        </Loadable>
      </Box>
    </Box>
  );
};

export default ModulesTable;
