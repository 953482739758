import React from 'react';
import { Grid } from '@material-ui/core';
import { useMutation, useQuery } from 'react-apollo';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import set from 'set-value';
import produce from 'immer';
import { ReactComponent as IconAddQuote } from '../../assets/icons/icons-create-offer.svg';
import { PageHeader, FormActions, Loadable, Button } from '../../components';
import { General, Address, Contact, Info } from '../../components/company/section';
import { useForm, usePageView } from '../../hooks';
import { GET_CUSTOMER, UPDATE_CUSTOMER } from '../../graphql/queries/customer';
import { customerFields } from '../../constants/form-constants';
import { CustomerForm } from '../../types/company.types';
import { quoteMessages } from '../../messages';
import history from '../../lib/history';
import { countries } from '../../helpers/country';
import { useUser } from '../../providers/UserProvider';
import { CompanyLinks } from '../../components/company';

const Create = () => {
  usePageView('customer_detail');

  const { isCustomer, isDistributor, isAdmin, companyId } = useUser();
  const { id } = useParams();
  const { formatMessage } = useIntl();
  const parsedId = parseInt(id!, 10);

  const [updateCustomer, { loading: isSaving }] = useMutation<CustomerForm>(UPDATE_CUSTOMER);
  const { fields, errors, disabled, handleChange, handleCreateUpdate, updateFields } = useForm(
    updateCustomer,
    customerFields,
    false,
    parsedId,
  );

  const { loading } = useQuery(GET_CUSTOMER, {
    variables: {
      id: parsedId,
      ...(isDistributor && { companyId }),
    },
    onCompleted(result) {
      if (result.getCustomer) {
        const { id: _, ...noId } = result.getCustomer;
        noId.distributor = noId.distributor.id;
        updateFields(noId);
      } else {
        history.push('/customers');
      }
    },
  });

  const serializer = (f: CustomerForm) => {
    if (!countries.find(c => f.addresses?.[0].country === c.id)) {
      return produce(f, draft => {
        set(draft, 'addresses.0.country', null);
      });
    }

    return f;
  };

  return (
    <Loadable loading={loading}>
      <PageHeader title={fields.name} back="/customers">
        {!isCustomer && (
          <Button
            className="button-filled-icon"
            text={formatMessage(quoteMessages.createQuote)}
            onClick={() => history.push(`/customer/${parsedId}/quote`)}
            icon={<IconAddQuote />}
          />
        )}
      </PageHeader>
      <div className="add-edit-form">
        <Grid container spacing={3}>
          <General
            fields={fields}
            onChange={handleChange}
            errors={errors}
            isCustomer
            distributorDisabled
            variant={isAdmin || isDistributor ? 'semi' : 'full'}
          />
          {(isAdmin || isDistributor) && (
            <Grid item xs={6}>
              <CompanyLinks type="customer" id={parsedId} name={fields.name} />
            </Grid>
          )}
          <Info fields={fields} onChange={handleChange} errors={errors} />
          <Address fields={fields} onChange={handleChange} errors={errors} />
          <Contact fields={fields} onChange={handleChange} errors={errors} />
        </Grid>
      </div>
      <FormActions
        onCreateUpdate={() =>
          handleCreateUpdate('/customers', { customerId: parsedId }, serializer)
        }
        disabled={disabled}
        loading={isSaving}
      />
    </Loadable>
  );
};

export default Create;
