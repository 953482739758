import React, { MouseEvent } from 'react';
import { useIntl } from 'react-intl';
import { Container, Grid } from '@material-ui/core';
import history from '../lib/history';
import Button from './Button';
import messages from '../messages/common';

interface Props {
  onCreateUpdate: (event: MouseEvent<HTMLButtonElement>) => void;
  onCancel?: () => void;
  create?: boolean;
  disabled?: boolean;
  loading?: boolean;
  createLabel?: string;
}

const FormActions: React.FC<Props> = ({
  onCreateUpdate,
  onCancel,
  create,
  disabled,
  loading,
  createLabel,
}) => {
  const { formatMessage } = useIntl();
  const handleCancel = () => {
    history.goBack();
  };

  const label = create ? formatMessage(messages.create) : formatMessage(messages.save);

  return (
    <Container className="form-actions">
      <Grid className="form-actions-wrapper" container>
        <Button
          text={formatMessage(messages.cancel)}
          className="button-outlined"
          onClick={onCancel || handleCancel}
        />
        <Button
          disabled={disabled}
          text={createLabel || label}
          className="button-filled"
          onClick={onCreateUpdate}
          loading={loading}
        />
      </Grid>
    </Container>
  );
};

export default FormActions;
