import React, { FC, useState } from 'react';
import { useParams } from 'react-router';
import { useIntl } from 'react-intl';
import { Grid, Box, Container, makeStyles } from '@material-ui/core';
import { useQuery, useMutation, useLazyQuery } from 'react-apollo';
import classNames from 'classnames';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import { PageHeader, Loadable, Button } from '../../components';
import { quoteMessages, commonMessages } from '../../messages';
import {
  General,
  Kits,
  Modules,
  Boxes,
  DeliveryDiscount,
  Price,
  ShippingAddress,
} from '../../components/quotes/sections';
import { GET_QUOTE, UPDATE_QUOTE, GET_CONFIRMED_ADDRESSES } from '../../graphql/queries/quote';
import {
  GetQuoteQuery,
  QuoteItem,
  QuoteStatus,
  Module,
  Quote,
  UpdateQuoteMutation,
  UpdateQuoteMutationVariables,
  GetConfirmedAddressesQuery,
  GetConfirmedAddressesQueryVariables,
  Address,
  QuoteInputUpdate,
} from '../../types/graphql-generated.types';
import history from '../../lib/history';
import { usePageView, useForm } from '../../hooks';
import {
  Kit,
  deserializeQuoteItems,
  getCreatedBy,
  getFileName,
  getModulesFromKit,
  generateDataURLs,
  removeKitFromList,
  serializeUpdatedKits,
} from '../../helpers/quote';
import { useUser } from '../../providers/UserProvider';
import { formatDate } from '../../helpers/date';
import { ConfirmDialog } from '../../components/quotes';
import { QuoteOrderPDF } from '../../components/quotes/pdf';
import { usePDF } from '../../components/quotes/hooks';
import { KitsPanel } from '../../components/quotes/panels';
import { getDisplayAddress } from '../../components/quotes/sections/ShippingAddress';
import { FieldUpdate } from '../../helpers/form';

const useStyles = makeStyles({
  buttonMargin: {
    marginLeft: '15px',
  },
});

const QuoteDetail: FC = () => {
  usePageView('quote_detail');

  const classes = useStyles();
  const intl = useIntl();
  const { formatMessage, locale } = intl;
  const { companyId, isDistributor, isCustomer } = useUser();
  const { id } = useParams();
  const quoteId = parseInt(id!, 10);

  const [downloading, onDownload] = usePDF();

  const [openAcceptDialog, setOpenAcceptDialog] = useState(false);
  const [openRejectDialog, setOpenRejectDialog] = useState(false);

  const [selectedAddress, setSelectedAddress] = useState<Address>();

  const [isKitsPanelOpen, setKitsPanelOpen] = useState(false);
  const [editingKit, setEditingKit] = useState<Kit>();

  const [kits, setKits] = useState<Kit[]>([]);
  const [modules, setModules] = useState<QuoteItem[]>([]);
  const [boxes, setBoxes] = useState<QuoteItem[]>([]);

  const [dirtyKits, setDirtyKits] = useState<Kit[]>([]);

  const [updateQuote, { loading: isSaving }] = useMutation<
    UpdateQuoteMutation,
    UpdateQuoteMutationVariables
  >(UPDATE_QUOTE);

  const { fields, handleChange, errors, updateFields, handleCreateUpdate, disabled } = useForm<
    QuoteInputUpdate
  >(updateQuote, {}, false, quoteId);

  const [
    fetchConfirmedAddresses,
    { data: addressesData, loading: addressesLoading },
  ] = useLazyQuery<GetConfirmedAddressesQuery, GetConfirmedAddressesQueryVariables>(
    GET_CONFIRMED_ADDRESSES,
  );

  const { data, loading } = useQuery<GetQuoteQuery>(GET_QUOTE, {
    variables: {
      id: quoteId,
      ...(isDistributor && { companyId }),
      ...(isCustomer && { customerId: companyId }),
    },
    onCompleted(result) {
      if (!result.getQuote) {
        history.push('/quotes');
      } else {
        if (result.getQuote.customer?.id) {
          fetchConfirmedAddresses({ variables: { customerId: result.getQuote.customer.id } });
        }

        const quoteItems = deserializeQuoteItems(result.getQuote);

        setKits(quoteItems.kits);
        setModules(quoteItems.modules);
        setBoxes(quoteItems.boxes);

        const { status, shippingAddress } = result.getQuote;
        updateFields({ status, shippingAddress });
        setSelectedAddress(shippingAddress!);
      }
    },
  });

  const onUpdateQuote = async (status?: QuoteStatus) => {
    try {
      await handleCreateUpdate(
        '/quotes',
        {
          ...(isDistributor && { companyId }),
          ...(isCustomer && { customerId: companyId }),
        },
        q => ({
          ...(status && { status }),
          ...(dirtyKits.length && {
            quoteItems: { update: serializeUpdatedKits(dirtyKits) },
          }),
          shippingAddress: {
            addressLineOne: q.shippingAddress?.addressLineOne,
            addressLineTwo: q.shippingAddress?.addressLineTwo,
            postalCode: q.shippingAddress?.postalCode,
            city: q.shippingAddress?.city,
            country: q.shippingAddress?.country,
          },
        }),
      );
    } catch (error) {
      console.error('Something went wrong updating the quote: ', error);
    }
  };

  const isReorder = !!data?.getQuote?.reorderLocation;

  const onOpenKit = (kit: Kit) => {
    setEditingKit(kit);
    setKitsPanelOpen(true);
  };

  const onCloseKitPanel = () => {
    setEditingKit(undefined);
    setKitsPanelOpen(false);
  };

  const onUpdateKit = (kit: Kit) => {
    setDirtyKits([...removeKitFromList(dirtyKits, kit), kit]);
    setKits([...removeKitFromList(kits, kit), kit]);
  };

  const onSelectAddress = (address: Address) => {
    handleChange({ path: 'shippingAddress.addressLineOne', value: address.addressLineOne });
    handleChange({ path: 'shippingAddress.addressLineTwo', value: address.addressLineTwo });
    handleChange({ path: 'shippingAddress.postalCode', value: address.postalCode });
    handleChange({ path: 'shippingAddress.city', value: address.city });
    handleChange({ path: 'shippingAddress.country', value: address.country });

    setSelectedAddress(address);
  };

  const onUpdateAddress = (update: FieldUpdate) => {
    handleChange(update);
    setSelectedAddress(undefined);
  };

  const getTitle = (quote: Quote, includeReference = true): string => {
    const title = isReorder
      ? formatMessage(quoteMessages.reorderFor, { location: quote.reorderLocation })
      : formatMessage(quoteMessages.quote);

    return includeReference ? `${title} - ${quote.reference}` : title;
  };

  const onPrintPDF = async () => {
    if (data?.getQuote) {
      const quote = data.getQuote;

      const svgDataURLs: Record<number, string> = await generateDataURLs([
        ...modules.map(m => m.product as Module),
        ...kits.reduce((acc, x) => acc.concat(getModulesFromKit(x)), [] as Module[]),
      ]);

      onDownload(
        getFileName(quote),
        <QuoteOrderPDF
          isReorder={isReorder}
          intl={intl}
          title={getTitle(quote, false)}
          customer={{ ...quote.customer, addresses: [quote.shippingAddress] }}
          distributor={quote.customer?.distributor}
          createdBy={getCreatedBy(quote)}
          createdAt={formatDate(quote.createdAt)}
          reference={quote.reference || ''}
          grandTotal={quote.totalPrice || 0}
          totalPrice={quote.subTotalPrice || 0}
          discount={quote.discount || 0}
          discountedTotal={(quote.subTotalPrice || 0) - (quote.discountPrice || 0)}
          deliveryCost={quote.deliveryCost || 0}
          kits={kits}
          modules={modules}
          boxes={boxes}
          svgDataURLs={svgDataURLs}
        />,
      );
    }
  };

  const shippingAddress = data?.getQuote?.shippingAddress;

  return (
    <Loadable loading={loading}>
      {data?.getQuote && (
        <>
          <PageHeader title={getTitle(data.getQuote)} back="/quotes">
            <Button
              onClick={onPrintPDF}
              className="button-outlined-icon"
              text={formatMessage(commonMessages.printAsPDF)}
              icon={<PictureAsPdfIcon />}
              loading={downloading}
            />
          </PageHeader>
          <Box className="add-edit-form quote-detail">
            <Grid container spacing={3}>
              <General
                customer={data.getQuote.customer?.name || ''}
                createdBy={getCreatedBy(data.getQuote)}
                createdAt={formatDate(data.getQuote.createdAt)}
              />
              {data.getQuote.status === QuoteStatus.Pending ? (
                <Loadable loading={addressesLoading && loading}>
                  {addressesData?.getConfirmedAddresses && shippingAddress && (
                    <ShippingAddress
                      addresses={[
                        shippingAddress,
                        ...addressesData?.getConfirmedAddresses.filter(
                          a =>
                            getDisplayAddress(a, locale) !==
                            getDisplayAddress(shippingAddress, locale),
                        ),
                      ]}
                      selectedAddress={selectedAddress}
                      onSelectAddress={onSelectAddress}
                      fields={fields}
                      onChange={onUpdateAddress}
                      errors={errors}
                    />
                  )}
                </Loadable>
              ) : (
                <ShippingAddress disabled fields={fields} onChange={() => {}} errors={errors} />
              )}
              {!isReorder && (
                <Kits
                  disable
                  onOpenKit={data?.getQuote?.status === QuoteStatus.Pending ? onOpenKit : undefined}
                  kits={kits}
                />
              )}
              <Grid item xs={12} lg={6}>
                <Grid container spacing={3}>
                  <Modules quoteItems={modules} disable />
                  {!isReorder && (
                    <>
                      <Boxes quoteItems={boxes} disable />
                      <DeliveryDiscount
                        fields={{
                          deliveryCost: data.getQuote.deliveryCost,
                          discount: data.getQuote.discount,
                        }}
                        disable
                      />
                    </>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Grid container spacing={3}>
                  <Price
                    totalPrice={data.getQuote.subTotalPrice!}
                    grandTotal={data.getQuote.totalPrice!}
                    discountedTotal={data.getQuote.subTotalPrice! - data.getQuote.discountPrice!}
                    discount={data.getQuote.discount!}
                    deliveryCost={data.getQuote.deliveryCost!}
                    kits={kits}
                    modules={modules}
                    boxes={boxes}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
          {data.getQuote.customer?.distributor?.id &&
            data?.getQuote?.status === QuoteStatus.Pending && (
              <KitsPanel
                onlyLocation
                kit={editingKit}
                companyId={data.getQuote.customer?.distributor.id}
                open={isKitsPanelOpen}
                onClose={onCloseKitPanel}
                onUpdate={onUpdateKit}
              />
            )}
        </>
      )}

      <Container className="form-actions">
        <Grid className="form-actions-wrapper" container>
          <Button
            className="button-outlined"
            text={formatMessage(commonMessages.cancel)}
            onClick={() => history.push('/quotes')}
          />
          {data?.getQuote?.status === QuoteStatus.Pending && (
            <>
              <Button
                className={classNames('button-outlined-icon', classes.buttonMargin)}
                icon={<NotInterestedIcon />}
                text={formatMessage(quoteMessages.reject)}
                onClick={() => setOpenRejectDialog(true)}
                loading={isSaving}
              />
              <Button
                className="button-filled-icon"
                icon={<CheckCircleOutlineIcon />}
                text={formatMessage(quoteMessages.accept)}
                onClick={() => setOpenAcceptDialog(true)}
                loading={isSaving}
              />
              {(!!dirtyKits.length || !disabled) && (
                <Button
                  className="button-filled-icon"
                  text={formatMessage(quoteMessages.saveChanges)}
                  onClick={() => onUpdateQuote()}
                  loading={isSaving}
                />
              )}
            </>
          )}
        </Grid>
      </Container>

      <ConfirmDialog
        open={openAcceptDialog}
        loading={isSaving}
        onCancel={() => setOpenAcceptDialog(false)}
        onConfirm={() => onUpdateQuote(QuoteStatus.Accepted)}
        title={formatMessage(quoteMessages.acceptQuoteTitle)}
        content={formatMessage(quoteMessages.acceptQuoteContent)}
      />
      <ConfirmDialog
        open={openRejectDialog}
        loading={isSaving}
        onCancel={() => setOpenRejectDialog(false)}
        onConfirm={() => onUpdateQuote(QuoteStatus.Rejected)}
        title={formatMessage(quoteMessages.rejectQuoteTitle)}
        content={formatMessage(quoteMessages.rejectQuoteContent)}
      />
    </Loadable>
  );
};

export default QuoteDetail;
