import React, { MouseEvent, ReactNode, useEffect, useState } from 'react';
import { CircularProgress, Typography } from '@material-ui/core';

type ButtonProps = {
  text?: ReactNode;
  icon?: any;
  className?: string;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  loading?: boolean;
};

const DELAY_SPINNER_RENDER = 500;

const Button: React.FC<ButtonProps> = ({ text, icon, className, onClick, disabled, loading }) => {
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSpinner(!!loading);
    }, DELAY_SPINNER_RENDER);

    return () => clearTimeout(timer);
  }, [loading]);

  return (
    <button type="button" className={className} onClick={onClick} disabled={loading || disabled}>
      {showSpinner ? (
        <CircularProgress size={20} className="button-loading" />
      ) : (
        <>
          {icon}
          <Typography style={{ fontWeight: 600 }} component="span" noWrap>
            {text}
          </Typography>
        </>
      )}
    </button>
  );
};

export default Button;
