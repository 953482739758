import React, { FC } from 'react';
import { Box, makeStyles, createStyles, Grid, Typography } from '@material-ui/core';
import { InventoryItem, Module } from '../../types/graphql-generated.types';
import { ProductIcon } from '../products';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      minHeight: '56px',
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      ...theme.mixins.card(0.16),
    },
    content: {
      width: '100%',
      boxSizing: 'border-box',
      padding: theme.spacing(1),
    },
    icon: {
      width: '48px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    info: {
      maxWidth: 'calc(100% - 48px)',
    },
  }),
);

interface Props {
  item: InventoryItem;
}

const InventoryItemCard: FC<Props> = props => {
  const { item } = props;
  const classes = useStyles(props);

  const module = item.product as Module;

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <Grid container spacing={1}>
          <Grid item className={classes.icon}>
            <ProductIcon rgb={module.rgb} svg={module.icon?.svg} />
          </Grid>
          <Grid container alignItems="center" item xs className={classes.info}>
            <Typography variant="subtitle1">{`${module.moduleId} - ${module.nameEN}`}</Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default InventoryItemCard;
