import { Errors, GraphQLError } from '../types/form.types';

function formatErrors(errors: GraphQLError[], isArrayElement?: boolean): Errors;
function formatErrors(errors: GraphQLError[], isArrayElement?: boolean): Errors[];
function formatErrors(errors: GraphQLError[], isArrayElement = false): any {
  return errors.reduce<Errors[] | Errors>(
    (acc, error) => {
      const hasChildren = error.children.length;
      const isArray = !!(hasChildren && Number.isInteger(parseInt(error.children[0].property, 10)));
      const key = error.property;

      if (Array.isArray(acc)) {
        return [...((acc as []) || []), formatErrors(error.children)];
      }

      if (hasChildren) {
        return {
          ...acc,
          [key]: formatErrors(error.children, isArray),
        };
      }

      return { ...acc, [key]: { ...error.constraints, context: error.contexts } };
    },
    isArrayElement ? [] : {},
  );
}

export default formatErrors;
