import React from 'react';
import { Grid } from '@material-ui/core';
import get from 'get-value';
import { useIntl } from 'react-intl';
import i18nCountries from 'i18n-iso-countries';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { OutlinedTextField, OutlinedSelectField } from '../../inputs';
import { labelMessages, quoteMessages } from '../../../messages';
import { countries } from '../../../helpers/country';
import {
  Address,
  QuoteInputCreate,
  QuoteInputUpdate,
} from '../../../types/graphql-generated.types';
import { SectionProps } from '../../../types/form.types';

type Props = SectionProps<QuoteInputCreate | QuoteInputUpdate> & {
  selectedAddress?: Address | undefined;
  addresses?: Address[];
  disabled?: boolean;
  onSelectAddress?: (address: Address) => void;
};

export const getDisplayAddress = (address: Address, locale: string): string =>
  `${address.addressLineOne}${address.addressLineTwo ? ` ${address.addressLineTwo}` : ''}, ${
    address.postalCode
  } ${address.city}, ${i18nCountries.getName(address.country!, locale)}`;

const ShippingAddress: React.FC<Props> = ({
  selectedAddress,
  addresses,
  disabled,
  onSelectAddress,
  fields,
  onChange,
  errors,
}) => {
  const { formatMessage, locale } = useIntl();
  return (
    <Grid item xs={12}>
      <div className="form-section">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className="form-section-title">
              <div className="section-icon">
                <LocationOnIcon />
              </div>
              <p>{formatMessage(quoteMessages.shippingAddress)}</p>
            </div>
          </Grid>
          <Grid item xs={6}>
            <OutlinedTextField
              disabled={disabled}
              name="shippingAddress.addressLineOne"
              labelText={formatMessage(labelMessages.addressLineOne)}
              value={fields.shippingAddress?.addressLineOne}
              onChange={onChange}
              maxLength={255}
              error={get(errors, 'shippingAddress.addressLineOne')}
            />
          </Grid>
          {disabled ? (
            <Grid item xs={6} />
          ) : (
            <Grid item xs={6}>
              <OutlinedTextField
                name="shippingAddress.addressLineTwo"
                labelText={`${formatMessage(labelMessages.addressLineTwo)} (${formatMessage(
                  labelMessages.optional,
                )})`}
                value={fields.shippingAddress?.addressLineTwo}
                onChange={onChange}
                maxLength={255}
                error={get(errors, 'shippingAddress.addressLineTwo')}
              />
            </Grid>
          )}
          <Grid item xs={4}>
            <OutlinedTextField
              disabled={disabled}
              name="shippingAddress.postalCode"
              labelText={formatMessage(labelMessages.postalCode)}
              value={fields.shippingAddress?.postalCode}
              onChange={onChange}
              error={get(errors, 'shippingAddress.postalCode')}
              maxLength={20}
            />
          </Grid>
          <Grid item xs={4}>
            <OutlinedTextField
              disabled={disabled}
              name="shippingAddress.city"
              labelText={formatMessage(labelMessages.city)}
              value={fields.shippingAddress?.city}
              onChange={onChange}
              maxLength={255}
              error={get(errors, 'shippingAddress.city')}
            />
          </Grid>
          <Grid item xs={4}>
            <OutlinedSelectField
              disabled={disabled}
              name="shippingAddress.country"
              labelText={formatMessage(labelMessages.country)}
              value={fields.shippingAddress?.country}
              onChange={onChange}
              error={get(errors, 'shippingAddress.country')}
            >
              {countries.map(country => (
                <option key={country.id} value={country.id}>
                  {i18nCountries.getName(country.id, locale)}
                </option>
              ))}
            </OutlinedSelectField>
          </Grid>
          {!disabled && addresses && onSelectAddress && (
            <Grid item xs={12}>
              <OutlinedSelectField
                name="shippingAddressSelect"
                labelText={formatMessage(quoteMessages.availableShippingAddresses)}
                value={selectedAddress ? getDisplayAddress(selectedAddress, locale) : ''}
                onChange={({ value }) => {
                  const selected = addresses.find(
                    a => getDisplayAddress(a, locale) === (value as string),
                  );

                  if (selected) {
                    onSelectAddress(selected);
                  }
                }}
              >
                <option disabled />
                {addresses.map(address => {
                  const displayAddress = getDisplayAddress(address, locale);
                  return (
                    <option key={displayAddress} value={displayAddress}>
                      {displayAddress}
                    </option>
                  );
                })}
              </OutlinedSelectField>
            </Grid>
          )}
        </Grid>
      </div>
    </Grid>
  );
};
export default ShippingAddress;
