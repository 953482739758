import React from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { InputBaseComponentProps } from '@material-ui/core';
import { DEFAULT_INTEGER_MASK } from '../../constants/number-mask';

/**
 * Workaround for firefox number input accepting non numeric values on type number
 */
interface Props {
  inputRef: (ref: HTMLInputElement | null) => void;
}

const NumberMask: React.FC<Props & InputBaseComponentProps> = props => {
  const { inputRef, mask, ...other } = props;

  const numberMask = mask || createNumberMask({ ...DEFAULT_INTEGER_MASK });

  return (
    <MaskedInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
      ref={(ref: any) => {
        inputRef(ref?.inputElement);
      }}
      mask={numberMask}
    />
  );
};

export default NumberMask;
