import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useIntl } from 'react-intl';
import history from '../lib/history';
import messages from '../messages/common';

type BackToOverviewProps = {
  back: string;
};

const BackToOverview = ({ back }: BackToOverviewProps) => {
  const { formatMessage } = useIntl();
  const handleBack = () => {
    history.push(back);
  };

  return (
    <div className="page-header-back">
      <div className="back" onClick={handleBack} aria-hidden="true">
        <div>
          <ArrowBackIcon />
          {formatMessage(messages.backToOverview)}
        </div>
      </div>
    </div>
  );
};

export default BackToOverview;
