import { Config } from 'cookie-though/dist/types/types';

export const DEFAULT_COOKIE_THOUGH_CONFIG: Config = {
  policies: [
    {
      id: 'essential',
      label: 'Essential cookies',
      description: 'This allows for page navigation and authentication',
      category: 'essential',
    },
    {
      id: 'analytics',
      label: 'Analytics',
      description: "We'll know where we should improve your experience",
      category: 'statistics',
    },
  ],
  header: {
    subTitle: "You're probably fed up with these banners...",
    title: 'cookie though?',
    description:
      "Everybody wants to show his best side - and so do we. That's why we use cookies to guarantee you a better experience.",
  },
  permissionLabels: {
    accept: 'Accept',
    acceptAll: 'Accept all',
    decline: 'Decline',
  },
  essentialLabel: 'Always on',
  customizeLabel: 'Customize',
  cookiePolicy: {
    url: '',
    label: '',
  },
};
