import React, { ChangeEvent, KeyboardEvent } from 'react';
import classNames from 'classnames';
import { OutlinedInput, InputAdornment } from '@material-ui/core';
import { useLabel } from '../../hooks';
import { InputProps } from '../../types/form.types';
import Field from '../form/Field';
import { getUpdate } from '../../helpers/form';

const OutlinedTextField: React.FC<InputProps> = ({ onChange = () => {}, className, ...props }) => {
  const { labelWidth, labelRef } = useLabel();

  const fieldClassName = classNames('outlined-text-container', className);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode === 13 && props.onEnter) {
      props.onEnter();
    }
  };

  return (
    <Field className={fieldClassName}>
      {({ Label, Error, HelperText }) => (
        <>
          {props.labelText && (
            <Label labelRef={labelRef} label={props.labelText} error={!!props.error} />
          )}
          <OutlinedInput
            error={!!props.error}
            disabled={props.disabled}
            multiline={props.multiline}
            rows={props.rows}
            type={props.type}
            inputComponent={props.inputComponent}
            endAdornment={
              props.endAdornment ? (
                <InputAdornment position="end" disableTypography>
                  {props.endAdornment}
                </InputAdornment>
              ) : null
            }
            startAdornment={
              props.startAdornment ? (
                <InputAdornment position="start" disableTypography>
                  {props.startAdornment}
                </InputAdornment>
              ) : null
            }
            id="component-outlined"
            value={props.value != null ? props.value : ''}
            inputProps={{ maxLength: props.maxLength, ...(props.inputProps || {}) }}
            name={props.name}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            onKeyDown={handleKeyDown}
            labelWidth={labelWidth}
            onChange={e => onChange(getUpdate(e as ChangeEvent<HTMLInputElement>))}
            aria-describedby={props.error ? 'error-helper-text' : ''}
          />
          {props.error && <Error errors={props.error} />}
          {props.helperText && <HelperText text={props.helperText} />}
        </>
      )}
    </Field>
  );
};

export default OutlinedTextField;
