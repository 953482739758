import React, { FC, useState } from 'react';
import { Box, makeStyles, createStyles, ButtonBase } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import { useIntl } from 'react-intl';
import Button from './Button';
import { commonMessages } from '../messages';
import { OutlinedTextField } from './inputs';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    input: {
      marginRight: theme.spacing(),
      width: '250px',
      backgroundColor: theme.palette.background.default,

      '& .MuiOutlinedInput-input': {
        padding: '10.5px 14px',
      },
    },
  }),
);

interface Props {
  onSearch: (value: string) => void;
  onClear: () => void;
}

const OverviewSearch: FC<Props> = props => {
  const { onSearch, onClear } = props;
  const classes = useStyles(props);
  const { formatMessage } = useIntl();

  const [search, setSearch] = useState('');
  const [isSearched, setIsSearched] = useState(false);

  const handleSearch = () => {
    onSearch(search);
    setIsSearched(true);
  };

  return (
    <Box className={classes.root}>
      <OutlinedTextField
        className={classes.input}
        name="search"
        value={search}
        startAdornment={<SearchIcon fontSize="small" />}
        maxLength={200}
        endAdornment={
          isSearched ? (
            <ButtonBase
              disableRipple
              onClick={() => {
                onClear();
                setSearch('');
                setIsSearched(false);
              }}
            >
              <ClearIcon />
            </ButtonBase>
          ) : null
        }
        onChange={({ value }) => setSearch(value as string)}
        onEnter={() => handleSearch()}
      />
      <Button
        disabled={search === ''}
        className="button-outlined"
        onClick={() => handleSearch()}
        text={formatMessage(commonMessages.search)}
      />
    </Box>
  );
};

export default OverviewSearch;
