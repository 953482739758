import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import ExtensionIcon from '@material-ui/icons/Extension';
import { Grid, makeStyles, createStyles, Box } from '@material-ui/core';
import { productMessages, commonMessages, labelMessages, quoteMessages } from '../../../messages';
import Button from '../../Button';
import { QuoteItem, Module } from '../../../types/graphql-generated.types';
import { TableHeader, TableCell, TableRow } from '../../table';
import { ProductIcon } from '../../products';
import { formatPrice } from '../../../helpers/format';
import { sortModuleQuoteItemsFn } from '../../../helpers/quote';

const useStyles = makeStyles(theme =>
  createStyles({
    button: {
      '& span': {
        display: 'inline-block',
        marginRight: '15px',
      },
    },
    emptyRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.text.secondary,
    },
    icon: {
      justifyContent: 'center',

      [theme.breakpoints.up('lg')]: {
        maxWidth: '11%',
        flexBasis: '11%',
        paddingRight: '0',
        minWidth: '55px',
      },
    },
    table: {
      width: '88%',
    },
    amountContainer: {
      width: '10%',
      marginLeft: 'auto',
    },
    amountRow: {
      paddingLeft: 0,
    },
    amount: {
      padding: 0,
      justifyContent: 'center',
    },
    amountHeader: {
      padding: 0,
      textAlign: 'center',
    },
  }),
);

interface ModulesProps {
  onSelect?: () => void;
  quoteItems: QuoteItem[];
  disableSelect?: boolean;
  disable?: boolean;
}

const Modules: FC<ModulesProps> = ({ disable, quoteItems, disableSelect, onSelect = () => {} }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  return (
    <Grid item xs={12}>
      <div className="form-section">
        <Grid container>
          <Grid item xs={12}>
            <div className="form-section-title">
              <div className="section-icon">
                <ExtensionIcon />
              </div>
              <p>{formatMessage(productMessages.modules)}</p>
              {!disable && (
                <Button
                  className="button-outlined"
                  text={formatMessage(commonMessages.select)}
                  onClick={() => onSelect()}
                  disabled={disableSelect}
                />
              )}
            </div>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Box className={classes.table}>
                <TableHeader>
                  {() => (
                    <>
                      <TableCell classes={{ root: classes.icon }} xs={1} />
                      <TableCell xs={4} label={formatMessage(labelMessages.name)} />
                      <TableCell xs={4} lg={3} label={formatMessage(productMessages.articleId)} />
                      <TableCell xs={3} label={formatMessage(productMessages.price)} />
                    </>
                  )}
                </TableHeader>
              </Box>
              <Box className={classes.amountContainer}>
                <TableHeader classes={{ root: classes.amountRow }}>
                  {() => <TableCell classes={{ root: classes.amountHeader }} xs label="#" />}
                </TableHeader>
              </Box>
            </Grid>
            {quoteItems.length ? (
              [...quoteItems].sort(sortModuleQuoteItemsFn).map(quoteItem => {
                const module = quoteItem.product as Module;
                const productInfo = quoteItem.product?.productInfo?.[0];

                return (
                  <Grid container key={module.id!}>
                    <Box className={classes.table}>
                      <TableRow>
                        <TableCell classes={{ root: classes.icon }} body ellipsis={false} xs={1}>
                          <ProductIcon rgb={module.rgb} svg={module.icon?.svg} />
                        </TableCell>
                        <TableCell primary body xs={4} label={module.nameEN} />
                        <TableCell body xs={4} lg={3} label={productInfo?.articleId} />
                        <TableCell body xs={3} label={formatPrice(productInfo?.price)} />
                      </TableRow>
                    </Box>
                    <Box className={classes.amountContainer}>
                      <TableRow classes={{ root: classes.amountRow }}>
                        <TableCell
                          classes={{ root: classes.amount }}
                          body
                          ellipsis={false}
                          xs
                          label={`x ${quoteItem.quantity}`}
                        />
                      </TableRow>
                    </Box>
                  </Grid>
                );
              })
            ) : (
              <TableRow>
                <TableCell
                  classes={{ root: classes.emptyRow }}
                  body
                  xs={12}
                  label={formatMessage(quoteMessages.noModules)}
                />
              </TableRow>
            )}
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

export default Modules;
