import React from 'react';
import { Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { ReactComponent as ContactIcon } from '../../../assets/icons/icons-contact.svg';
import { OutlinedTextField } from '../../inputs';
import { Constraint } from '../../../types/form.types';
import { CompanySectionProps, ContactSection } from '../../../types/company.types';
import { commonMessages, labelMessages } from '../../../messages';

const Contact: React.FC<CompanySectionProps<ContactSection>> = ({ fields, onChange, errors }) => {
  const { formatMessage } = useIntl();
  return (
    <Grid item xs={12}>
      <div className="form-section">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className="form-section-title">
              <div className="section-icon">
                <ContactIcon />
              </div>
              <p>{formatMessage(commonMessages.contact)}</p>
            </div>
          </Grid>
          <Grid item xs={6}>
            <OutlinedTextField
              name="email"
              labelText={`${formatMessage(labelMessages.email)} (${formatMessage(
                labelMessages.optional,
              )})`}
              maxLength={255}
              value={fields.email}
              onChange={onChange}
              error={(errors?.additionalErrors || errors?.email) as Constraint}
            />
          </Grid>
          <Grid item xs={6}>
            <OutlinedTextField
              name="phone"
              labelText={`${formatMessage(labelMessages.phoneNumber)} (${formatMessage(
                labelMessages.optional,
              )})`}
              maxLength={50}
              value={fields.phone}
              onChange={onChange}
              error={errors?.phone as Constraint}
            />
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};
export default Contact;
