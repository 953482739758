import { gql } from 'apollo-boost';
import { moduleFragments } from './module';
import { boxFragments } from './box';
import { productInfoFragments } from './product-info';
import { Role } from '../../providers/UserProvider';
import { QuoteFilter } from '../../types/graphql-generated.types';

export const createSearch = (isRole: (role: Role) => boolean, value: string): QuoteFilter => {
  const filters: QuoteFilter[] = [
    { status: { contains: [value] } },
    { createdBy: { firstName_lastName: { contains: [value] } } },
  ];

  if (isRole(Role.Admin) || isRole(Role.Distributor)) {
    filters.push({ customer: { name: { contains: [value] } } });
  }

  if (isRole(Role.Admin)) {
    filters.push({ distributor: { name: { contains: [value] } } });
  }

  return { OR: filters };
};

export const GET_QUOTE = gql`
  query getQuote($id: Int!, $companyId: Int, $customerId: Int) {
    getQuote(id: $id, companyId: $companyId, customerId: $customerId) {
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
      deliveryCost
      discount
      discountPrice
      id
      status
      reorderLocation
      shippingAddress {
        addressLineOne
        addressLineTwo
        postalCode
        city
        country
      }
      customer {
        id
        name
        vat
        addresses {
          addressLineOne
          addressLineTwo
          postalCode
          city
          country
        }
        distributor {
          id
          vat
          name
        }
      }
      reference
      subTotalPrice
      totalPrice
      quoteItems {
        createdAt
        id
        location
        product {
          ... on Box {
            ...BoxItem
            productInfo {
              ...ProductInfoItem
            }
          }
          ... on Module {
            ...ModuleItem
            productInfo {
              ...ProductInfoItem
            }
          }
        }
        children {
          id
          location
          product {
            ... on Module {
              ...ModuleItem
              productInfo {
                ...ProductInfoItem
              }
            }
          }
          quantity
          totalPrice
          unitPrice
        }
        quantity
        totalPrice
        unitPrice
      }
    }
  }
  ${moduleFragments.Item}
  ${boxFragments.Item}
  ${productInfoFragments.Item}
`;

export const GET_PAGED_QUOTES = gql`
  query getPagedQuotes(
    $companyId: Int
    $customerId: Int
    $offset: Int!
    $limit: Int!
    $sortColumn: String
    $sortDirection: SortDirection
    $filter: QuoteFilter
  ) {
    getPagedQuotes(
      limit: $limit
      offset: $offset
      companyId: $companyId
      customerId: $customerId
      sortColumn: $sortColumn
      sortDirection: $sortDirection
      filter: $filter
    ) {
      pageInfo {
        itemCount
        pageCount
        total
      }
      items {
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
        customer {
          id
          name
          distributor {
            id
            name
          }
        }
        totalPrice
        status
        id
      }
    }
  }
`;

export const GET_CONFIRMED_ADDRESSES = gql`
  query getConfirmedAddresses($customerId: Int!) {
    getConfirmedAddresses(customerId: $customerId) {
      addressLineOne
      addressLineTwo
      postalCode
      city
      country
    }
  }
`;

export const CREATE_QUOTE = gql`
  mutation createQuote($customerId: Int!, $companyId: Int!, $type: QuoteInputCreate!) {
    createQuote(customerId: $customerId, companyId: $companyId, quote: $type) {
      id
    }
  }
`;

export const UPDATE_QUOTE = gql`
  mutation updateQuote($id: Int!, $companyId: Int, $customerId: Int, $type: QuoteInputUpdate!) {
    updateQuote(id: $id, companyId: $companyId, customerId: $customerId, quote: $type) {
      id
    }
  }
`;
