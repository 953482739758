import { useEffect } from 'react';
import { setPageView } from '../initializers/analytics';

const usePageView = (name: string): void => {
  useEffect(() => {
    setPageView(name);
  }, [name]);
};

export default usePageView;
