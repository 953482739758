import React, { FC } from 'react';
import { Grid, GridSize } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useQuery } from 'react-apollo';
import { Link } from 'react-router-dom';
import { Pagination, SortableTableHeader, ProductStatus, Loadable } from '../../../components';
import { useOptions, usePageView, useImpersonator } from '../../../hooks';
import messages from '../../../messages/products';
import { GET_PAGED_MODULES, GET_PAGED_MODULES_DISTRIBUTOR } from '../../../graphql/queries/module';
import { setSortableQuery } from '../../../helpers/query';
import { formatPrice } from '../../../helpers/format';
import {
  GetPagedModulesQuery,
  GetPagedModulesDistributorQuery,
  SortDirection,
} from '../../../types/graphql-generated.types';
import { useUser, Role } from '../../../providers/UserProvider';
import { ProductIcon } from '../../../components/products';

type DistributorModule = Exclude<
  GetPagedModulesDistributorQuery['getPagedModules']['items'],
  null
>[number];
type AdminModule = Exclude<GetPagedModulesQuery['getPagedModules']['items'], null>[number];

const Products: FC = () => {
  const { formatMessage } = useIntl();
  const { whenRole, getContextVariables } = useUser();
  const { isImpersonating, getFakeVariables, whenFakeRole, isRole } = useImpersonator();

  const isAdmin = isRole(Role.Admin);

  usePageView(
    whenFakeRole({
      [Role.Distributor]: 'prices_overview_distributor',
      [Role.Default]: 'products_modules_overview',
    }),
  );

  const { options, changeParams } = useOptions({
    page: 0,
    pageCount: 1,
    offset: 0,
    limit: 20,
    sortColumn: 'module.moduleId',
    sortDirection: SortDirection.Asc,
  });

  const { pageCount, page, ...rest } = options;

  const query = isAdmin ? GET_PAGED_MODULES : GET_PAGED_MODULES_DISTRIBUTOR;
  const { loading, data } = useQuery<GetPagedModulesQuery | GetPagedModulesDistributorQuery>(
    query,
    {
      variables: { ...(isImpersonating ? getFakeVariables() : getContextVariables()), ...rest },
      onCompleted(result) {
        if (result.getPagedModules?.pageInfo?.pageCount) {
          changeParams('pageCount', result.getPagedModules.pageInfo.pageCount);
        }
      },
    },
  );

  const onChangeSortColumn = (type: string) => {
    let nextDir = SortDirection.Asc;

    if (options.sortColumn === type) {
      nextDir =
        options.sortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc;
    }

    setSortableQuery(type, nextDir);
  };

  const getLink = (module: DistributorModule | AdminModule) =>
    whenRole({
      [Role.Default]: `/products/modules/${module.id}`,
      [Role.Distributor]: `/products/modules/${module.id}/info/${(module as DistributorModule)
        .productInfo?.[0]?.id || ''}`,
    });

  const TableHeader: FC<{ name: string; children: string }> = ({ name, children }) => (
    <SortableTableHeader
      name={name}
      current={options.sortColumn}
      direction={options.sortDirection}
      onChange={onChangeSortColumn}
    >
      {children}
    </SortableTableHeader>
  );

  return (
    <div className="table modules">
      <Grid className="table-header" container justify="space-between">
        <Grid item xs={2}>
          <TableHeader name="module.moduleId">ID</TableHeader>
          <span>SubID</span>
        </Grid>
        <Grid
          item
          xs={whenFakeRole<GridSize | boolean>({
            [Role.Distributor]: 3,
            [Role.Default]: true,
          })}
        >
          <TableHeader name="module.nameEN">{formatMessage(messages.name)}</TableHeader>
        </Grid>
        {whenFakeRole({
          [Role.Distributor]: (
            <>
              <Grid item xs={2}>
                <TableHeader name="productInfo.articleId">
                  {formatMessage(messages.articleId)}
                </TableHeader>
              </Grid>
              <Grid item xs={2}>
                <TableHeader name="productInfo.price">{formatMessage(messages.price)}</TableHeader>
              </Grid>
            </>
          ),
          [Role.Default]: (
            <Grid item xs={3}>
              <TableHeader name="module.recommendedPrice">
                {formatMessage(messages.recommendedPrice)}
              </TableHeader>
            </Grid>
          ),
        })}
        <Grid item xs={2}>
          <TableHeader name="module.active">{formatMessage(messages.status)}</TableHeader>
        </Grid>
      </Grid>

      <Loadable loading={loading}>
        {whenFakeRole({
          [Role.Distributor]: (data as GetPagedModulesDistributorQuery)?.getPagedModules?.items,
          [Role.Default]: (data as GetPagedModulesQuery)?.getPagedModules?.items,
        })?.map((module: DistributorModule | AdminModule) => (
          <Grid className="table-row-container" key={module.id!} container>
            <Link to={getLink(module)} className="table-row-link">
              <Grid key={module.id!} className="table-row" container justify="space-between">
                <Grid item xs={2}>
                  <span>{module.moduleId}</span>
                  <span>{module.moduleSubId}</span>
                  <ProductIcon className="table-row-icon" rgb={module.rgb} svg={module.icon?.svg} />
                </Grid>
                <Grid
                  className="table-row-primary"
                  item
                  xs={whenFakeRole<GridSize | boolean>({
                    [Role.Distributor]: 3,
                    [Role.Default]: true,
                  })}
                >
                  <span>{module.nameEN}</span>
                </Grid>
                {whenFakeRole({
                  [Role.Distributor]: (
                    <>
                      <Grid item xs={2}>
                        <span>{(module as DistributorModule).productInfo?.[0]?.articleId}</span>
                      </Grid>
                      <Grid item xs={2}>
                        <span>
                          {formatPrice((module as DistributorModule).productInfo?.[0]?.price)}
                        </span>
                      </Grid>
                    </>
                  ),
                  [Role.Default]: (
                    <Grid className="table-row-price" item xs={3}>
                      <span>{formatPrice((module as AdminModule).recommendedPrice)}</span>
                    </Grid>
                  ),
                })}
                <Grid className="table-row-status" item xs={2}>
                  <ProductStatus active={!!module.active} />
                </Grid>
              </Grid>
            </Link>
          </Grid>
        ))}
      </Loadable>

      <Pagination options={options} />
    </div>
  );
};

export default Products;
