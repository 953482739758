import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';
import PageHeader from '../../components/PageHeader';
import FormActions from '../../components/FormActions';
import { Address, General, Contact, Costs } from '../../components/company/section';
import { CREATE_DISTRIBUTOR } from '../../graphql/queries/distributor';
import { useForm, usePageView } from '../../hooks';
import { CompanyForm } from '../../types/company.types';
import { companyFields } from '../../constants/form-constants';
import messages from '../../messages/distributors';

const CreateEditDistributor = () => {
  usePageView('distributor_new');

  const { formatMessage } = useIntl();
  const [createDistributor, { loading: isSaving }] = useMutation<CompanyForm>(CREATE_DISTRIBUTOR);
  const { fields, handleChange, handleCreateUpdate, errors, disabled, updateField } = useForm(
    createDistributor,
    companyFields,
    true,
  );

  return (
    <>
      <PageHeader title={formatMessage(messages.newDistributor)} back="/distributors" />
      <div className="add-edit-form">
        <Grid container spacing={3}>
          <General fields={fields} onChange={handleChange} errors={errors} />
          <Address fields={fields} onChange={handleChange} errors={errors} />
          <Contact fields={fields} onChange={handleChange} errors={errors} />
          <Costs fields={fields} onChange={handleChange} errors={errors} setField={updateField} />
        </Grid>
      </div>
      <FormActions
        onCreateUpdate={() => handleCreateUpdate('/distributors')}
        create
        disabled={disabled}
        loading={isSaving}
      />
    </>
  );
};

export default CreateEditDistributor;
