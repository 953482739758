import React, { FC, ChangeEvent } from 'react';
import { InputProps } from '../../types/form.types';
import { toNumber, floatToString, getUpdate } from '../../helpers/form';
import NumberMask from './NumberMask';
import { OutlinedTextField } from './index';

type NumberInputProps = InputProps & {
  mask: Record<string, unknown>;
  defaultTo?: number;
  updateOnBlur?: boolean;
};

const OutlinedNumberField: FC<NumberInputProps> = ({
  name,
  className,
  defaultTo,
  disabled,
  labelText,
  startAdornment,
  endAdornment,
  value,
  mask,
  helperText,
  error,
  onFocus,
  updateOnBlur = true,
  onChange = () => {},
  onBlur = () => {},
}) => {
  return (
    <OutlinedTextField
      className={className}
      disabled={disabled}
      name={name}
      labelText={labelText}
      helperText={helperText}
      startAdornment={startAdornment}
      endAdornment={endAdornment}
      value={floatToString(value as number)}
      inputComponent={NumberMask}
      inputProps={{ mask }}
      onFocus={onFocus}
      onChange={update => {
        if (!updateOnBlur) {
          onChange(toNumber(update));
        }
      }}
      onBlur={(e: ChangeEvent<HTMLInputElement>) => {
        const parsed = toNumber(getUpdate(e));
        let update = parsed;

        if (defaultTo != null && update.value == null) {
          update = { ...update, value: defaultTo };
        }

        onChange(update);
        onBlur(update);
      }}
      error={error}
    />
  );
};

export default OutlinedNumberField;
