import { useCallback, useMemo } from 'react';
import { useImmer } from 'use-immer';
import { QuoteItem, Box, Module } from '../../../types/graphql-generated.types';
import { createQuoteItemFromProduct } from '../../../helpers/quote';

type Product = Module | Box;

const toQuoteItemsMap = (quoteItems: QuoteItem[]) =>
  quoteItems.reduce((acc, item) => ({ ...acc, [item.product!.id!]: item }), {});

const useProductPanel = (quoteItems: QuoteItem[]) => {
  const [quoteItemsMap, setQuoteItemsMap] = useImmer<Record<number, QuoteItem | undefined>>(
    toQuoteItemsMap(quoteItems),
  );

  const totalAmount = useMemo(
    () => Object.values(quoteItemsMap).reduce((acc, item) => acc + (item?.quantity || 0), 0),
    [quoteItemsMap],
  );

  const totalPrice = useMemo(
    () => Object.values(quoteItemsMap).reduce((acc, item) => acc + (item?.totalPrice || 0), 0),
    [quoteItemsMap],
  );

  const totalVolume = useMemo(
    () =>
      Object.values(quoteItemsMap).reduce((acc, item) => {
        const volume = item?.product?.volume;
        const quantity = item?.quantity;

        if (volume != null && quantity != null) {
          return acc + volume * quantity;
        }

        return acc;
      }, 0),
    [quoteItemsMap],
  );

  const updateQuoteItems = useCallback(
    (product: Product, amount: number): void => {
      setQuoteItemsMap(draft => {
        const quoteItem = createQuoteItemFromProduct(product, amount);

        if (quoteItem && amount === 0) {
          delete draft[product.id!];
        } else if (quoteItem) {
          draft[product.id!] = quoteItem;
        }
      });
    },
    [setQuoteItemsMap],
  );

  const resetQuoteItems = useCallback(() => {
    setQuoteItemsMap(() => toQuoteItemsMap(quoteItems));
  }, [setQuoteItemsMap, quoteItems]);

  const setToSingleQuoteItem = useCallback(
    (product: Product) => {
      const quoteItem = createQuoteItemFromProduct(product, 1);

      if (quoteItem) {
        setQuoteItemsMap(() => toQuoteItemsMap([quoteItem]));
      }
    },
    [setQuoteItemsMap],
  );

  const initQuoteItems = useCallback(
    (items: QuoteItem[]) => {
      setQuoteItemsMap(() => toQuoteItemsMap(items));
    },
    [setQuoteItemsMap],
  );

  const getQuantity = useCallback(
    (id: number): number => {
      const quantity = quoteItemsMap[id]?.quantity;
      return quantity || 0;
    },
    [quoteItemsMap],
  );

  const getQuoteItems = useCallback(
    () => Object.values(quoteItemsMap).filter((f): f is QuoteItem => f !== undefined),
    [quoteItemsMap],
  );

  return {
    totalAmount,
    totalPrice,
    totalVolume,
    updateQuoteItems,
    resetQuoteItems,
    initQuoteItems,
    getQuantity,
    getQuoteItems,
    setToSingleQuoteItem,
  };
};

export default useProductPanel;
