import { useState } from 'react';
import { getNextDirection } from '../../../helpers/sort';
import { SortDirection } from '../../../types/graphql-generated.types';

const usePanelSort = (sortBy: string) => {
  const [direction, setDirection] = useState(SortDirection.Asc);
  const [column, setColumn] = useState(sortBy);

  const onChangeSort = (key: string) => {
    setDirection(getNextDirection(key, column, direction));
    setColumn(key);
  };

  return { direction, column, onChangeSort };
};

export default usePanelSort;
