import React from 'react';
import { Grid } from '@material-ui/core';
import { useQuery } from 'react-apollo';
import { useIntl } from 'react-intl';
import { ReactComponent as DeliveryIcon } from '../../../assets/icons/icons-delivery.svg';
import { ReactComponent as GeneralIcon } from '../../../assets/icons/icons-general.svg';
import { OutlinedTextField, OutlinedSelectField } from '../../inputs';
import { Constraint } from '../../../types/form.types';
import { CompanySectionProps, GeneralSection } from '../../../types/company.types';
import { GET_DISTRIBUTORS } from '../../../graphql/queries/distributor';
import { toNumber } from '../../../helpers/form';
import { commonMessages, labelMessages } from '../../../messages';
import { Loadable } from '../../index';
import { useUser } from '../../../providers/UserProvider';

const General: React.FC<CompanySectionProps<GeneralSection>> = ({
  onChange,
  fields,
  errors,
  variant = 'full',
  isCustomer = false,
  distributorDisabled = false,
}) => {
  const { isAdmin } = useUser();
  const { formatMessage } = useIntl();
  const { loading, data } = useQuery(GET_DISTRIBUTORS, { skip: !isAdmin });

  const isFull = variant === 'full';

  return (
    <Grid item xs={isFull ? 12 : 6}>
      <div className="form-section">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className="form-section-title">
              <div className="section-icon">{!isCustomer ? <DeliveryIcon /> : <GeneralIcon />}</div>
              <p>{formatMessage(commonMessages.general)}</p>
            </div>
          </Grid>
          <Loadable loading={!isAdmin ? false : loading}>
            {isCustomer && isAdmin && (
              <>
                <Grid item xs={isFull ? 6 : 12} classes={{ root: 'input-margin' }}>
                  <OutlinedSelectField
                    name="distributor"
                    labelText={formatMessage(labelMessages.distributor)}
                    value={fields?.distributor}
                    onChange={u => onChange(toNumber(u))}
                    error={errors?.distributor as Constraint}
                    distributorDisabled={distributorDisabled}
                  >
                    <option />
                    {data?.getDistributors.map((distributor: any) => (
                      <option key={distributor.id} value={distributor.id}>
                        {distributor.name}
                      </option>
                    ))}
                  </OutlinedSelectField>
                </Grid>
                {isFull && <Grid item xs={6} classes={{ root: 'input-margin' }} />}
              </>
            )}
            <Grid item xs={isFull ? 6 : 12}>
              <OutlinedTextField
                name="name"
                labelText={formatMessage(labelMessages.name)}
                maxLength={255}
                value={fields.name}
                onChange={onChange}
                error={errors?.name as Constraint}
              />
            </Grid>
            <Grid item xs={isFull ? 6 : 12}>
              <OutlinedTextField
                name="vat"
                labelText={`${formatMessage(labelMessages.vat)} (${formatMessage(
                  labelMessages.optional,
                )})`}
                maxLength={50}
                value={fields.vat}
                onChange={onChange}
                error={errors?.vat as Constraint}
              />
            </Grid>
          </Loadable>
        </Grid>
      </div>
    </Grid>
  );
};
export default General;
