export const DEFAULT_INTEGER_MASK = {
  prefix: '',
  integerLimit: 9,
  includeThousandsSeparator: false,
};

export const DEFAULT_PRICE_MASK = {
  prefix: '',
  integerLimit: 13,
  allowDecimal: true,
  decimalLimit: 2,
  decimalSymbol: ',',
  includeThousandsSeparator: false,
};

export const DEFAULT_PERCENTAGE_MASK = {
  prefix: '',
  integerLimit: 2,
  allowDecimal: true,
  decimalLimit: 2,
  decimalSymbol: ',',
  includeThousandsSeparator: false,
};
