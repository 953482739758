import React, { FC } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { Button } from '../../../../components';
import { commonMessages, productMessages } from '../../../../messages';
import { DialogProps, SectionProps, Constraint } from '../../../../types/form.types';
import { BatchInputUpdate } from '../../../../types/graphql-generated.types';
import { OutlinedNumberField, OutlinedDateField } from '../../../../components/inputs';
import { DEFAULT_INTEGER_MASK } from '../../../../constants/number-mask';

type BatchDialogProps = DialogProps &
  SectionProps<BatchInputUpdate> & {
    isExpirable: boolean;
    isCreate: boolean;
  };

const numberMask = createNumberMask({ ...DEFAULT_INTEGER_MASK });

const BatchDialog: FC<BatchDialogProps> = ({
  isOpen,
  isCreate,
  isDisabled,
  isExpirable,
  fields,
  errors,
  onChange,
  onCancel,
  onConfirm,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Dialog className="dialog batch-dialog" onClose={onCancel} open={isOpen}>
      <DialogTitle>
        {formatMessage(isCreate ? productMessages.addBatch : productMessages.editBatch)}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={isExpirable ? 4 : 0}>
          <Grid item xs={isExpirable ? 6 : 12}>
            <OutlinedNumberField
              mask={numberMask}
              name="lotNumber"
              labelText={formatMessage(productMessages.nr)}
              value={fields?.lotNumber}
              onChange={onChange}
              error={errors?.lotNumber as Constraint}
            />
          </Grid>
          {isExpirable && (
            <Grid item xs={6}>
              <OutlinedDateField
                name="expiryDate"
                labelText={formatMessage(productMessages.expiryDate)}
                value={fields?.expiryDate}
                onChange={onChange}
                error={errors?.expiryDate as Constraint}
                disablePast={isCreate}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          className="button-outlined"
          onClick={onCancel}
          text={formatMessage(commonMessages.cancel)}
        />
        <Button
          className="button-filled"
          disabled={isDisabled}
          onClick={onConfirm}
          text={formatMessage(isCreate ? commonMessages.create : commonMessages.save)}
        />
      </DialogActions>
    </Dialog>
  );
};

export default BatchDialog;
