import axios from 'axios';

interface GuestToken {
  access_token: string;
  expires_at: number;
  expires_in: number;
}

let guestToken: GuestToken;

export default async function getGuestToken(): Promise<string | undefined> {
  try {
    if (guestToken) {
      if (guestToken?.expires_at && guestToken.expires_at > +new Date()) {
        return guestToken.access_token;
      }
    }

    const { data: newToken }: { data: GuestToken } = await axios.get(
      process.env.REACT_APP_GUEST_TOKEN_URI!,
    );

    newToken.expires_at = +new Date() + 86400000;

    guestToken = newToken;

    return guestToken.access_token;
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.error('Something went wrong requesting guest token: ', error);
    }

    return undefined;
  }
}
