import { createMuiTheme } from '@material-ui/core';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const breakpoints = createBreakpoints({});

const palette = {
  primary: {
    light: '#fa8b77', // primary-200
    main: '#ef5236', // primary-300
    dark: '#e24428', // primary-500
  },
  secondary: {
    light: '#e7f1f7', // secondary-200
    main: '#81a4bb', // secondary-300
    dark: '#477ea2', // secondary-400
  },
  background: {
    primary: '#ffffff', // neutral-100
    secondary: '#f0f4f7', // secondary-100
  },
  neutral: {
    primary: '#161c21', // neutral-600
    secondary: '#848d93', // neutral-400
    tertiary: '#d8dadc', // neutral-300
    quaternary: '#f7fafc', // neutral-200
    quinary: '#3d464c', // neutral-500,
    senary: '#979797', // neutral-900,
  },
  transparent: {
    primary: 'rgba(71, 126, 162, 0.06)',
    shadow: 'rgba(21, 46, 62, 0.06)',
  },
  status: {
    positive: '#5bceae',
    neutral: '#81a4bb',
    negative: '#fa8b77',
  },
  statusBackground: {
    positive: '#e6fff8',
    neutral: '#e7f1f7',
    negative: '#fdd6d0',
  },
  divider: '#f0f0f0',
};

export default createMuiTheme({
  palette: {
    background: {
      default: palette.background.primary,
    },
    primary: palette.primary,
    secondary: palette.secondary,
    error: {
      light: palette.primary.light,
      main: palette.primary.main,
      dark: palette.primary.dark,
    },
    text: {
      primary: palette.neutral.primary,
      secondary: palette.neutral.secondary,
      disabled: palette.neutral.secondary,
      hint: palette.neutral.quinary,
    },
    grey: {
      '300': palette.neutral.tertiary,
      '900': palette.neutral.senary,
    },
    divider: palette.divider,
    status: {
      light: palette.status.positive,
      main: palette.status.neutral,
      dark: palette.status.negative,
    },
    statusBackground: {
      light: palette.statusBackground.positive,
      main: palette.statusBackground.neutral,
      dark: palette.statusBackground.negative,
    },
  },
  typography: {
    fontFamily: ['Open Sans', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    fontWeightBold: 600,
    h1: {
      color: palette.primary.main,
      fontSize: '36px',
      fontWeight: 600,
    },
    h2: {
      color: palette.secondary.dark,
      fontSize: '36px',
      fontWeight: 600,
    },
    h3: {
      color: palette.secondary.dark,
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: 1.2,
    },
    h4: {
      color: palette.secondary.main,
      fontSize: '16px',
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: '16px',
      lineHeight: 1.3,
      color: palette.neutral.quinary,
      fontWeight: 600,
    },
    body2: {
      fontSize: '12px',
      color: palette.neutral.quinary,
      lineHeight: 1.1,
    },
  },
  mixins: {
    card: (shadowAlpha = 0.06) => ({
      borderRadius: '5px',
      backgroundColor: palette.background.primary,
      boxShadow: `0 2px 7px 0 rgba(21, 46, 62, ${shadowAlpha})`,
    }),
  },
  overrides: {
    MuiChip: {
      root: {
        borderRadius: '4px',
        fontSize: '16px',
      },
      outlined: {
        borderColor: palette.neutral.tertiary,
        color: palette.neutral.secondary,
      },
      outlinedSecondary: {
        borderWidth: '2px',
        borderColor: palette.secondary.main,
        backgroundColor: palette.background.secondary,
      },
      deleteIcon: {
        height: '16px',
        width: '16px',
      },
    },
    MuiFormLabel: {
      root: {
        '&$error': {
          color: palette.primary.light,
        },
        '&$focused': {
          color: palette.secondary.dark,
        },
      },
    },
    MuiInputAdornment: {
      root: {
        fontSize: '14px',
        color: palette.neutral.secondary,
      },
    },
    MuiFormHelperText: {
      root: {
        color: palette.neutral.quinary,
        '&$focused': {
          color: palette.secondary.dark,
        },
        '&$error': {
          color: palette.primary.light,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '&$focused $notchedOutline': {
          borderColor: palette.secondary.dark,
        },
        '&:hover $notchedOutline': {
          borderColor: palette.secondary.dark,
        },
        '&$disabled $notchedOutline': {
          borderColor: palette.neutral.quaternary,
        },
      },
      notchedOutline: {
        borderColor: palette.neutral.tertiary,
        borderWidth: '2px',
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '24px',
        textAlign: 'center',
        color: palette.secondary.dark,
      },
    },
    MuiDialog: {
      paperFullWidth: {
        width: 'calc(100% - 16px)',
      },
    },
    MuiDialogActions: {
      root: {
        background: palette.transparent.primary,
        padding: '21px 10px 15px 10px',
        display: 'flex',
        justifyContent: 'flex-end',
      },
    },
    MuiTabs: {
      root: {
        minHeight: 'unset',
      },
    },
    MuiTab: {
      root: {
        fontWeight: 600,
        marginRight: '10px',
        padding: '0 12px',
        textTransform: 'capitalize',
        minHeight: 'unset',
        [breakpoints.up('sm')]: {
          minWidth: 'unset',
        },
      },
      textColorSecondary: {
        color: palette.secondary.main,
        '&$selected': {
          color: palette.primary.main,
        },
      },
      wrapper: {
        fontSize: '16px',
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
      },
    },
  },
});
